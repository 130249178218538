import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Translation } from './models/Translation';

export const PromoClassifiersTypes = types.model(
  'PromoClassifiersTypes',
  {
    name: types.maybe(types.string),
    tag: types.maybe(types.string),
    priority: types.maybe(types.number),
    name_translations: types.optional(Translation, {})
  },
  {}
);

export const PromoClassifiersStore = types.model(
  'PromoClassifiersStore',
  {
    promo: types.optional(types.array(PromoClassifiersTypes), []),
    state: types.optional(
      types.enumeration(['pending', 'done', 'error']),
      'done'
    ),

    get isFetched() {
      return this.state === 'done';
    },

    get isPending() {
      return this.state === 'pending';
    },

    get isError() {
      return this.state === 'error';
    }
  },
  {
    fetch(params = {}) {
      this.setState('pending');

      instance
        .get('/api/classifiers/promo_deal_options', { params: params })
        .then((response) => this.resetStore(response))
        .then(() => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(this, { promo: data.data });
      }

      return data.data;
    },

    setState(state) {
      this.state = state;
    },

    errorHandler(error) {
      this.setState('error');
      return Promise.reject(error);
    }
  }
);
