import currencySymbolMap from './currencySymbolMap';

function getSymbolFromCurrency(currencyCode) {
  if (typeof currencyCode !== 'string') return undefined;

  const code = currencyCode.toUpperCase();
  if (!currencySymbolMap[code]) return undefined;

  return currencySymbolMap[code];
}

export default getSymbolFromCurrency;
