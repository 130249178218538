import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Reservation } from './Reservation';

export const ReservationStore = types.model('ReservationStore', {
  reservation: types.maybe(Reservation),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(id) {
    this.setState('pending');

    instance.get(`/api/reservations/${id}`)
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch((error) => this.errorHandler(error));
  },

  destroy() {
    const { id } = this.reservation;
    return instance.delete(`/api/reservations/${id}`);
  },

  confirmOrder() {
    const { order } = this.reservation;
    this.setState('pending');

    return instance.put(`/api/orders/${order.id}/confirm`)
      .then((response) => this.setState('done', response))
      .catch((error) => this.errorHandler(error));
  },

  cancelOrder() {
    const { order } = this.reservation;
    this.setState('pending');

    return instance.put(`/api/orders/${order.id}/cancel`)
      .then((response) => this.setState('done', response))
      .catch((error) => this.errorHandler(error));
  },

  clear() {
    const data = { data: null };
    applySnapshot(this, data);
  },

  setState(state, response = undefined) {
    this.state = state;
    return response;
  },

  applySnapshot(response) {
    const { data } = response;
    applySnapshot(this, data);

    return this;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  }
});
