import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Events from '../Events';

@withRouter
@observer
class Bed extends Component {
  render() {
    const { events } = this.props.bed;

    return (
      <div className='events'>
        <Events events={events} />
      </div>
    );
  }
}

Bed.propTypes = {
  bed: PropTypes.object.isRequired
};

export default Bed;
