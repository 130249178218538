import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Floor from './Floor';

@withRouter
@observer
class Floors extends Component {
  render() {
    const listItems = this.props.floors.map((item) => {
      return <Floor key={item.number} floor={item} />;
    });

    return (
      <div className='groups__item-content'>
        <div className='collapse'>{listItems}</div>
      </div>
    );
  }
}

Floors.propTypes = {
  floors: PropTypes.array.isRequired
};

export default Floors;
