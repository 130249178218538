import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Orders from '../Orders';
import { PageBody } from './PageContent.styled';
import PageCaption from 'Pages/Orders/containers/List/components/PageCaption';
import FilterBar from 'Pages/Orders/containers/List/components/Orders/FilterBar';

@withRouter
@inject('ordersStore')
@observer
class PageContent extends Component {
  downloadHandler = (e) => {
    e.preventDefault();

    const { ordersStore } = this.props;

    ordersStore.download()
      .catch(error => this.errorSubmitHandler(error));
  }

  render() {
    const { orders } = this.props.ordersStore;

    return (
      <PageBody>
        <PageCaption />

        <FilterBar />

        <div className='page__content'>
          {true && <Orders orders={orders} />}
        </div>
      </PageBody>
    );
  }
}

PageContent.propTypes = {
  ordersStore: PropTypes.object.isRequired
};

export default PageContent;
