import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TariffVisiblePeriod from './TariffVisiblePeriod';

@observer
class TariffDateTime extends Component {
  render() {
    const {
      available_period
    } = this.props.tariff;

    const { labels } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {labels.available_period}
          </span>

          <TariffVisiblePeriod
            className='value'
            period={available_period}
          />
        </div>
      </div>
    );
  }
}

TariffDateTime.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffDateTime;
