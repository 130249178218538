import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { FeedTypesStore } from 'Stores/FeedTypesStore';
import SingleSelect from 'Shared/form/SingleSelectObj';

@observer
class FeedType extends Component {
  componentDidMount() {
    const { feedTypesStore } = this.props;
    feedTypesStore.fetch();
  }

  @computed get isLoading() {
    const { feedTypesStore } = this.props;
    return feedTypesStore.isPending;
  }

  @computed get options() {
    const { feedTypesStore } = this.props;

    return feedTypesStore.isFetched
      ? feedTypesStore.feed_types.toJSON()
      : [];
  }

  render() {
    const { field } = this.props;

    return (
      <SingleSelect
        field={field}
        simpleValue={false}
        valueKey='tag'
        labelKey='name'
        searchable={false}
        clearable
        isLoading={this.isLoading}
        options={this.options}
      />
    );
  }
}

FeedType.defaultProps = {
  feedTypesStore: FeedTypesStore.create()
};

FeedType.propTypes = {
  field: PropTypes.object.isRequired,
  feedTypesStore: PropTypes.object.isRequired
};

export default FeedType;
