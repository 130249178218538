import styled from 'styled-components';
import { SingleDatePicker } from 'react-dates';

export const Wrapper = styled.div`
  min-width: 250px;
`;

export const SingleDatePickerStyled = styled(SingleDatePicker)`
  background-color: #D2D1CC;
`;
