import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import MdLocationOn from 'react-icons/lib/md/location-on';

@observer
class Marker extends Component {
  render() {
    return (
      <i className='icon'>
        <MdLocationOn />
      </i>
    );
  }
}

export default Marker;
