import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ServiceActions from './ServiceActions';

class ServiceContent extends Component {
  render() {
    const { description } = this.props.service;

    return (
      <div className='collapse__item-content'>
        <div className='description'>
          <p>{description}</p>
        </div>

        <ServiceActions service={this.props.service} />
      </div>
    );
  }
}

ServiceContent.propTypes = {
  service: PropTypes.object.isRequired
};

export default ServiceContent;
