import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import instance from 'connection/instance';
import { Async } from 'react-select';

import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 170px;
`;

@observer
class Type extends Component {
  handleChange = ({ label, value }) => {
    const { onChange, source } = this.props;
    source.set(value);
    onChange('source', label, value);
  }

  getOptions = (input) => {
    const options = { params: { query: { chars: input } } };

    return instance.get('/api/tariffs/sources', options)
      .then(response => this.parseFilterData(response));
  }

  parseFilterData(response) {
    const { data } = response;

    const options = data.sources.map(source => (
      { value: source.id, label: source.name }
    ));

    return { options };
  }

  render() {
    const { source } = this.props;

    return (
      <Wrapper>
        <Async
          clearable={false}
          searchable
          simpleValue={false}
          noResultsText='По Вашему запросу ничего не найдено'
          placeholder={source.placeholder}
          loadOptions={this.getOptions}
          arrowRenderer={() => null}
          onChange={this.handleChange}
        />
      </Wrapper>
    );
  }
}

Type.propTypes = {
  source: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Type;
