import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const kf = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const S = {
  Container: styled.div`
    height: 100%;
    width: 100%;
    position: relative;
  `,

  Date: styled.div`
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 13px;
    opacity: 0.7;
    font-weight: 300;
    ${({ isNow }) => isNow ? 'color: red; font-weight: bold;' : ''}
  `,

  Quantity: styled.div`
    position: absolute;
    left: 5px;
    bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  `,

  SecondaryQuantity: styled.div`
    position: absolute;
    right: -1px;
    top: -1px;
    background: #52AB18;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    color: #fff;
    font-size: 8px;
  `,

  Hint: styled.div`
    position: absolute;
    left: -1px;
    top: calc(100% + 4px);
    padding: 12px;
    background: #fff;
    color: #333;
    font-size: 13px;
    border: 1px solid #DCD8D2;
    box-shadow: 0 1px 9px rgba(0,0,0,0.28);
    animation: ${kf} 0.3s;
  `,

  HintRow: styled.div`
    display: flex;
    justify-content: space-between;
    width: 130px;
    line-height: 1;
    margin-bottom: 10px;
    
    &:last-child { margin-bottom: 0; }
  `,

  HintValue: styled.div`
    color: ${({ color }) => color}
  `
};

let hoveredTimer;

export default class Day extends Component {
  static propTypes = {
    day: PropTypes.object.required,
    busy: PropTypes.number,
    quota: PropTypes.number,
    free: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  handleMouseEnter = () => {
    clearTimeout(hoveredTimer);
    hoveredTimer = setTimeout(() => {
      this.setState({ hovered: true });
    }, 700);
  };

  handleMouseLeave = () => {
    clearTimeout(hoveredTimer);
    this.setState({ hovered: false });
  };

  render() {
    const { day, busy, quota, free, isNow } = this.props;

    return (
      <S.Container
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <S.Date className='date' isNow={isNow}>{day.format('D')}</S.Date>
        <S.Quantity className='quantity'>{quota}</S.Quantity>
        <S.SecondaryQuantity className='secondary-quantity'>{free}</S.SecondaryQuantity>
        {this.state.hovered &&
          <S.Hint role='hint'>
            <S.HintRow>Свободно<S.HintValue color='#329833'>{free}</S.HintValue></S.HintRow>
            <S.HintRow>Занято<S.HintValue color='#FB908F'>{busy}</S.HintValue></S.HintRow>
            <S.HintRow>Квота<S.HintValue color='#333'>{quota}</S.HintValue></S.HintRow>
          </S.Hint>}
      </S.Container>
    );
  }
}
