import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.span`
  font-size: 30px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 12px;
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 24px;
`;

export const Buttons = styled.div`
  margin-top: 24px;
`;

export const NumberInput = styled(NumberFormat)`
  width: 192px;
`;
