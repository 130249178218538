import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, withRouter } from 'react-router-dom';

@withRouter
@inject('store')
@observer
class PrivateRoute extends Component {
  @computed get isAuthenticated() {
    return this.props.store.userStore.authenticated;
  }

  @computed get shouldRunWizard() {
    if (!this.props.store.userStore.authenticated) return false;

    const { store, path } = this.props;
    const setting = store.userStore.setting;

    return setting.should_run_wizard && path != '/wizard';
  }

  render() {
    if (!this.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location }
          }}
        />
      );
    }

    if (this.shouldRunWizard) {
      return (
        <Redirect
          to={{
            pathname: '/wizard',
            state: { from: this.props.location }
          }}
        />
      );
    }

    return <Route {...this.props} />;
  }
}

export default PrivateRoute;
