import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";

import Form from "pages/Tariffs/components/Form/FormLengthStayRestrictions";

@observer
class LengthOfStayRestrictions extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className="groups__item">
        <div className="groups__item-header">
          <FormattedMessage id="tariff.length_stay_restrictions.title" />
        </div>
        <div className="groups__item-content">
          <div className="segment">
            <Form form={form} />
          </div>
        </div>
      </div>
    );
  }
}

LengthOfStayRestrictions.propTypes = {
  form: PropTypes.object.isRequired,
};

export default LengthOfStayRestrictions;
