export const tabUniqId = (name) => {
  const tabID = sessionStorage.tabID && sessionStorage.closedLastTab !== '2'
    ? sessionStorage.tabID
    : sessionStorage.tabID = Math.random();
  sessionStorage.closedLastTab = '2';

  window.onbeforeunload = function () {
    sessionStorage.closedLastTab = '1';
  };

  return [name, tabID].join('-');
};
