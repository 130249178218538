import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FullRefund from './FullRefund';
import PolicyRules from './PolicyRules';

@observer
class CancellationPolicy extends Component {
  render() {
    const { cancellation_policy, tariff } = this.props;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='tariff.cancellation_policy' />
        </div>

        <div className='value'>
          {cancellation_policy.has_full_refund
            ? <FullRefund />
            : <PolicyRules
                tariff={tariff}
                cancellation_policy={cancellation_policy}
              />}
        </div>
      </div>
    );
  }
}

CancellationPolicy.propTypes = {
  cancellation_policy: PropTypes.object.isRequired,
  tariff: PropTypes.object.isRequired
};

export default CancellationPolicy;
