import { useState, useEffect } from 'react';

import instance from 'connection/instance';

function useTariff({ id }) {
  const [isPending, setIsPending] = useState(true);
  const [isFetched, setIsFetched] = useState(false);
  const [tariff, setTariff] = useState();
  const fetch = async () => {
    const { data: { tariff } } = await instance.get(`/api/tariffs/${id}`);
    setTariff(tariff);
    setIsPending(false);
    setIsFetched(true);
  };

  const removeTariff = async ({ tariff }) => {
    return instance.delete(`/api/tariffs/${tariff.id}`);
  };

  const updateTariff = async ({ tariff, values }) => {
    const params = { tariff: values };
    setIsPending(true);
    const { data: { tariff: updatedTariff } } = await instance.put(`/api/tariffs/${tariff.id}`, params);
    setIsPending(false);

    return updatedTariff;
  };

  useEffect(() => {
    fetch();
  }, [id]);

  return [{ isPending, tariff, isFetched }, { updateTariff, removeTariff }];
}

export default useTariff;
