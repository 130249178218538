import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import _remove from 'lodash/remove';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';

import Photo from './Photo';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import Carousel, { Modal, ModalGateway } from 'react-images';

import styled from 'styled-components';

const S = {
  Group: styled.div`
    padding: 10px;
    margin-bottom: 20px;
    background: #f5f5f5;
    h2 {
      font-size: 18px;
      margin-bottom: 3px;
      font-weight: normal;
    }
  `
};

@inject('hotelStore')
@observer
class List extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired
  }

  static defaultProps = {
    tags: [
      { tag: 'promo', name_translations: { ru: 'Акция', en: 'Stock' } },
      { tag: 'new_year', name_translations: { ru: 'Новый год', en: 'New year' } }
    ]
  }

  constructor(props) {
    super(props);
    this.state = { currentModal: null };
  }

  defaultTag = { tag: 'other', name_translations: { ru: 'Все остальные', en: 'Other' } }

  @observable isOpen = false
  @observable currentImage = 0

  @computed get hasImages() {
    const { images } = this.props.hotelStore.hotel;
    return images.length > 0;
  }

  @computed get images() {
    const { hotel } = this.props.hotelStore;
    return hotel.images
      .sort((left, right) => left.priority - right.priority)
      .map(image => {
        return { ...image, src: image.url };
      });
  }

  gotoNext = () => { this.currentImage = this.currentImage + 1; }

  gotoPrevious = () => { this.currentImage = this.currentImage - 1; }

  gotoImage = index => { this.currentImage = index; }

  closeLightbox = () => {
    this.isOpen = false;
    this.currentImage = 0;
  }

  onZoomHandler = (idx, image) => {
    this.setState({ currentModal: idx });
  }

  onRemoveHandler = (index, image) => {
    const { hotelStore } = this.props;
    hotelStore.removeImages([image]);
  }

  onMove = (dragIndex, hoverIndex, info) => {
    const { drag, hover } = info;
    const cards = [...this.props.hotelStore.hotel.images].sort((left, right) => left.priority - right.priority);

    /* const imagesByGroups = _groupBy(cards, img => img.promo?.tag);
    const dragCards = (drag.promo ? imagesByGroups[drag.promo.tag] : imagesByGroups[undefined]).slice();
    const hoverCards = (hover.promo ? imagesByGroups[hover.promo.tag] : imagesByGroups[undefined]).slice(); */

    const dragCard = cards[dragIndex];
    const hoverCard = cards[hoverIndex];

    /* if (hover.promo) {
      dragCard.setPromo(hover.promo);
    } else {
      dragCard.setPromo(null);
    }
    */

    cards.splice(dragIndex, 1);
    cards.splice(hoverIndex, 0, dragCard);

    this.props.hotelStore.setImages(
      cards
        .map((card, index) => ({ ...card, priority: index + 1 }))
        .sort((left, right) => left.priority - right.priority)
    );
  }

  onEndDrag = (props) => {
    this.props.hotelStore.updateImagesSorting();
  }

  onSelectTag = (imageId, tag) => {
    const image = this.props.hotelStore.hotel.images.find(image => image.id === imageId);
    if (_isEqual(tag, this.defaultTag)) {
      image.setPromo(null);
    } else {
      image.setPromo(tag);
    }
    this.props.hotelStore.updateImagesSorting();
  };

  handleBlur = (index) => (value, language) => {
    const { hotelStore } = this.props;
    const images = [...hotelStore.hotel.images].sort((left, right) => left.priority - right.priority)
    images[index] = {
      ...images[index],
      alt_translations: {
        ...images[index].alt_translations,
        [language]: value ?? null,
      }
    }
    hotelStore.setImages(images)
    hotelStore.updateImagesSorting();
  };

  render() {
    const {
      images,
      state: { currentModal }
    } = this;

    const tags = [...this.props.tags, this.defaultTag];

    /* const imagesByGroups = _groupBy(images, img => img.promo?.tag);
    let throughIndex = 0;
    const groups = _map(imagesByGroups, (groupItems, tag) => {
      const listItems = groupItems
        /!*.sort((left, right) => left.priority - right.priority)*!/
        .map((image, index) => (
          <Photo
            key={image.id}
            image={image}
            index={index}
            throughIndex={throughIndex++}
            priority={image.priority}
            promo={image.promo}
            onEndDrag={this.onEndDrag}
            onMove={this.onMove}
            onRemoveHandler={this.onRemoveHandler}
            onZoomHandler={this.onZoomHandler}
          />
        ));

      let groupName = this.props.tags.find(g => g.tag === tag)?.name;
      if (!groupName) {
        groupName = Object.keys(imagesByGroups).length > 1 ? this.defaultTag.name : 'Все';
      }

      return listItems.length > 0
        ? (<S.Group key={tag}>
          <h2>{groupName}</h2>
          {listItems}
        </S.Group>)
        : null;
    }); */

    const listItems = images
      // .sort((left, right) => left.priority - right.priority)
      .map((image, index) => (
        <Photo
          key={image.id}
          image={image}
          index={index}
          priority={image.priority}
          promo={image.promo}
          onEndDrag={this.onEndDrag}
          onMove={this.onMove}
          onRemoveHandler={this.onRemoveHandler}
          onZoomHandler={this.onZoomHandler}
          onSelectTag={this.onSelectTag.bind(this, image.id)}
          tags={tags}
          defaultTag={this.defaultTag}
          onBlur={this.handleBlur(index)}
          alt_translations={image.alt_translations}
        />
      ));

    return (
      <div className='photos'>
        {this.hasImages ? (
          <>
            {/* groups */}
            {listItems}

            <ModalGateway>
              {Number.isInteger(currentModal) && (
                <Modal
                  allowFullscreen={false}
                  closeOnBackdropClick={false}
                  onClose={this.onZoomHandler}
                >
                  <Carousel
                    currentIndex={currentModal}
                    components={{ Footer: null }}
                    views={images}
                  />
                </Modal>
              )}
            </ModalGateway>
          </>
        ) : (
          <p className='muted end'>
            <FormattedMessage id='shared.no_photos' />
          </p>
        )}
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(List);
