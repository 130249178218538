import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Sticky from 'react-stickynode';
import { FormattedMessage } from 'react-intl';

import LanguageControl from 'Shared/components/LanguageFormControl';
import { UserProvider } from 'Context/FormLanguageContext';

import Form from '../Form';

export default class Add extends Component {
  state = {
    language: 'ru'
  };

  onClickHandler = (language) => {
    if (this.state.language === language) return;

    this.setState({ language: language });
  }

  render() {
    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className='page__info wide'>
          <div className='page__info-wrapper'>
            <Sticky>
              <div className='info__content'>
                <div className='layout__language-action'>
                  <div className='content'>
                    <div className='control'>
                      <LanguageControl
                        onClick={this.onClickHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className='header'>
                  <FormattedMessage id='services.new' />
                </div>

                <div className='content'>
                  <Form />
                </div>
              </div>
            </Sticky>
          </div>
        </div>
      </UserProvider>
    );
  }
}
