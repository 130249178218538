import { types } from 'mobx-state-tree';

const Author = types.model('Author', {
  email: types.string,
  first_name: types.string,
  last_name: types.string,

  get shortName() {
    const { first_name, last_name } = self;

    const initial = first_name[0] ? (first_name[0].toUpperCase() + '.') : '';

    return [last_name, initial].join(' ');
  }
});

export default Author;
