import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

const Service = types.model('Manipulation', {
  id: types.identifier(types.string),
  name: types.string
}, {});

export const ServiceClassifier = types.model('ServiceClassifier', {
  data: types.optional(types.array(Service), []),

  state: types.optional(
    types.enumeration(['pending', 'done', 'error']),
    'done'
  ),

  get isFetched() { return this.state === 'done'; },
  get isPending() { return this.state === 'pending'; },
  get isError() { return this.state === 'error'; }
}, {
  fetch(params = {}) {
    this.setState('pending');

    const data = { data: params };

    return instance.get('/api/tariffs/services', { params: data })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { data: [] };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { status, data } = response;
    if (status === 200) applySnapshot(this, data);

    return data;
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  }
});
