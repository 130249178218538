import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Rates from '../../Rates';
import TariffDateTime from './TariffDateTime';

@observer
class TariffDateTimeAndRatesBlock extends Component {
  render() {
    const { tariff, labels } = this.props;

    return (
      <div className='row'>
        <div className='col col-4'>
          <TariffDateTime tariff={tariff} labels={labels} />
        </div>
        <div className='col col-8'>
          {tariff.seasons.length === 1 && <Rates rates={tariff.seasons[0].rates} labels={labels} />}
        </div>
      </div>
    );
  }
}

TariffDateTimeAndRatesBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffDateTimeAndRatesBlock;
