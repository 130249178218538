import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  ContentLeftBlock,
  EditQuota,
  EditQuotaContainer
} from './Labels.styled';
import CapacityModal from 'Pages/RoomTypes/containers/CapacityModal';

import styled from 'styled-components';

const S = {
  ActionsPanel: styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    top: 0;
    right: calc(-100vw + 36.2rem);
    ${({ menuopened }) =>
      !menuopened &&
      `
      right: calc(-100vw + 20.2rem);
    `}
  `
};

@inject('store', 'quotasStore')
@withRouter
@observer
class Header extends Component {
  constructor(props) {
    super(props);

    const {
      room_type: {
        capacity: { total }
      }
    } = props;

    this.state = {
      capacity: total,
      isOpenModal: false,
      isCapacityUpdate: false
    };
  }

  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  openModal = () => {
    this.setState({ isOpenModal: true });
  };

  handleSetCapacity = (capacity) => {
    const { quotasStore } = this.props;

    this.setState({ capacity });

    const options = {
      check_in: quotasStore.check_in,
      check_out: quotasStore.check_out
    };

    quotasStore.fetch(options);
  };

  toggleHideRooms = (id) => {
    const { addHiddenRoom, setHideAllRooms } = this.props.quotasStore;
    const { currentIsHidden } = this.props;
    addHiddenRoom(id, !currentIsHidden);
    setHideAllRooms();
  };

  render() {
    const { name, capacity, id } = this.props.room_type;
    const { hasRooms, currentIsHidden } = this.props;

    const className = capacity.total > 0 ? 'muted' : 'alert';
    const { openned: menuOpened } = this.props.store.menuStore;

    return (
      <div className="content">
        <ContentLeftBlock>
          <div>
            {name}
            <div className="sub_header">
              <span className={className}>
                <FormattedMessage
                  id="dashboard.total"
                  values={{ total: this.state.capacity }}
                />
              </span>
            </div>
          </div>
          <EditQuotaContainer>
            <EditQuota onClick={this.openModal} />
          </EditQuotaContainer>
        </ContentLeftBlock>

        <S.ActionsPanel menuopened={menuOpened}>
          <div
            onClick={() => this.toggleHideRooms(id)}
            className={!hasRooms ? 'button gray disabled' : 'button gray'}
          >
            <FormattedMessage
              id={
                currentIsHidden
                  ? 'dashboard.show_one_room'
                  : 'dashboard.hide_one_room'
              }
            />
          </div>

          <NavLink exact to={`/dashboard/quota/${id}`} className="button green">
            <FormattedMessage id="dashboard.quota.edit.self" />
          </NavLink>
        </S.ActionsPanel>

        {this.state.isOpenModal && (
          <CapacityModal
            isOpen={this.state.isOpenModal}
            id={id}
            closeModal={this.closeModal}
            setCapacity={this.handleSetCapacity}
          />
        )}
      </div>
    );
  }
}

Header.propTypes = {
  room_type: PropTypes.object.isRequired,
  store: PropTypes.object,
  quotasStore: PropTypes.object,
  hasRooms: PropTypes.bool,
  currentIsHidden: PropTypes.bool
};

export default Header;
