import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div className='layout__page'>
        404
      </div>
    );
  }
}
