import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';

@observer
class Quantity extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired
  }

  render() {
    const { field } = this.props;

    return (
      <FormItemInput
        field={field}
        type='number'
        min='0'
        max='100'
      />
    );
  }
}

export default Quantity;
