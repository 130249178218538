import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { reaction, computed } from 'mobx';
import { FormattedMessage } from 'react-intl';

import SingleSelect from 'Shared/form/SingleSelectObj';

import AvailabilitiesStore from 'Stores/HotelAvailabilitiesStore';

@observer
class Availabilities extends Component {
  componentDidMount() {
    const { form, availabilitiesStore } = this.props;

    this.datesHandler = reaction(
      () => {
        const check_in = form.$('check_in').value;
        const check_out = form.$('check_out').value;

        return { check_in, check_out };
      },

      ({ check_in, check_out }) => {
        if (check_in && check_out) { availabilitiesStore.fetch({ check_in, check_out }); }
      }
    );
  }

  componentWillUnmount() {
    this.datesHandler();

    const { availabilitiesStore } = this.props;
    availabilitiesStore.clear();
  }

  @computed get availabilitiesOptions() {
    const { hotel, isFetched } = this.props.availabilitiesStore;

    return hotel && isFetched
      ? hotel.availabilities.toJSON()
      : [];
  }

  onChange = (value) => {
    value ? this.setSelected(value) : this.unsetSelected();
  }

  setSelected = (value) => {
    const { form, availabilitiesStore } = this.props;
    let { tariff, room_type, rooms } = value;

    tariff = availabilitiesStore.findTariff(tariff);
    if (!tariff) return;
    form.$('tariff').set(tariff.toJSON());
    form.rates = tariff.rates.toJSON();
    const availableRooms = rooms.map((room) => availabilitiesStore.findRoom(room).toJSON());
    form.rooms = availableRooms;

    room_type = availabilitiesStore.findRoomType(room_type);
    if (!room_type) return;
    form.$('room_type').set(room_type.toJSON());

    let hotel = availabilitiesStore.hotel;
    if (!hotel) return;
    hotel = { id: hotel.id };
    form.$('hotel').set(hotel);

    form.$('availability').set(value);
  }

  unsetSelected = () => {
    const { form } = this.props;
    form.$('tariff').clear();
    form.rates = [];
    form.rooms = [];
    form.$('room_type').clear();
    form.$('hotel').clear();
    form.$('availability').clear();
  }

  render() {
    const { form, availabilitiesStore } = this.props;

    return (
      <SingleSelect
        field={form.$('availability')}
        valueKey='id'
        labelKey='name'
        simpleValue={false}
        onChange={this.onChange}
        options={this.availabilitiesOptions}
        selectFirst
        isLoading={availabilitiesStore.isPending}
      />
    );
  }
}

Availabilities.defaultProps = {
  availabilitiesStore: AvailabilitiesStore.create()
};

Availabilities.propTypes = {
  form: PropTypes.object.isRequired
};

export default Availabilities;
