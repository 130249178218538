import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, observable, action } from 'mobx';
import classNames from 'classnames';
import { formatMessage } from 'Utils/formatMessage';
import _sortedUniq from 'lodash/sortedUniq';
import enhanceWithClickOutside from 'react-click-outside';

import { Scrollbars } from 'react-custom-scrollbars';
import Option from './Option';

@enhanceWithClickOutside
@observer
class Select extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  @computed get valueString() {
    const { field } = this.props;
    const currentValue = _sortedUniq(field.value || []);

    if (currentValue.length === 0) {
      return formatMessage(
        'tariffs.manipulation.quantity_string',
        { count: 0 }
      );
    }

    if (currentValue.length === 1) {
      return formatMessage(
        'tariffs.manipulation.quantity_string',
        { count: currentValue[0] }
      );
    }

    if (currentValue[0] === 0) {
      return formatMessage(
        'tariffs.manipulation.quantity_to',
        { count: currentValue.reverse()[0] }
      );
    }

    return formatMessage(
      'tariffs.manipulation.quantity_between',
      { from_count: currentValue[0], to_count: currentValue.reverse()[0] }
    );
  }

  @observable isOpenned = false;

  @action
  openPopover() {
    this.isOpenned = true;
  }

  @action
  closePopover() {
    this.isOpenned = false;
  }

  handleFocus = (e) => {
    this.openPopover();
  }

  handleClickOutside = (e) => {
    this.closePopover();
  }

  render() {
    const { field } = this.props;

    const popoverCls = classNames('popover popover_quantity', {
      error: field.error && !field.focused,
      is_openned: this.isOpenned
    });

    const inputProps = field.bind({
      onFocus: this.handleFocus,
      onChange: () => {},
      value: this.valueString
    });

    const listOptions = (field.extra || []).map(i => (
      <Option key={i} value={i} field={field} />
    ));

    return (
      <div className={popoverCls}>
        <div className='form__field'>
          <label htmlFor={field.id}>
            {field.label}
          </label>

          <input
            className='input'
            {...inputProps}
          />
        </div>

        {this.isOpenned && (
          <div className='popover__body'>
            <Scrollbars
              autoHeight
              autoHeightMin={34}
              autoHeightMax={290}
            >
              <div className='quantity__list'>
                {listOptions}
              </div>
            </Scrollbars>
          </div>
        )}
      </div>
    );
  }
}

export default Select;
