import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { SelectableGroup, Registry } from 'Shared/components/Selectable';

import Tariffs from './Tariffs';
import Overflow from './Overflow';

@withRouter
@inject('priceboardStore')
@observer
class Row extends Component {
  constructor(props) {
    super(props);

    this.registryStore = new Registry();
  }

  componentWillUnmount() {
    this.registryStore.clear();
  }

  render() {
    const { tariffs } = this.props.room_type;

    return (
      <Provider registryStore={this.registryStore}>
        <div className='item'>
          <div className='header' />

          <SelectableGroup
            className='priceboard__type-content'
            ref='selectable'
            tolerance={0}
            fixedPosition={false}
            selectOnMouseMove
            showBoundary={false}
            store={this.registryStore}
          >
            <Tariffs tariffs={tariffs} />
          </SelectableGroup>

          {this.registryStore.hasSelected && <Overflow />}
        </div>
      </Provider>
    );
  }
}

Row.propTypes = {
  room_type: PropTypes.object.isRequired
};

export default Row;
