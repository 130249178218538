import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import DateRangeInput from 'Shared/form/DateRangeInput';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TopBarSearchBox = ({ form, toggleModalOpen, quotasStore }) => {
  const { toggleHideAllRooms, hideAllRooms } = quotasStore;

  return (
    <Wrapper>
      <form method="post" action="" className="form">
        <div className="row between">
          <div className="col col-4">
            <div className="form-item">
              <DateRangeInput
                startDate={form.$('check_in')}
                endDate={form.$('check_out')}
                showLabel={false}
              />
            </div>
          </div>
          <ButtonsWrapper>
            <div onClick={toggleHideAllRooms} className="button gray">
              <FormattedMessage
                id={
                  hideAllRooms
                    ? 'dashboard.show_all_rooms'
                    : 'dashboard.hide_all_rooms'
                }
              />
            </div>
            <div onClick={toggleModalOpen} className="button green">
              <FormattedMessage id="dashboard.groups_quotas_btn" />
            </div>
          </ButtonsWrapper>
        </div>
      </form>
    </Wrapper>
  );
};

TopBarSearchBox.propTypes = {
  form: PropTypes.object.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  toggleHideAllRooms: PropTypes.func.isRequired
};
export default inject('quotasStore')(observer(TopBarSearchBox));
