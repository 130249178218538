import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { formatMessage } from 'Utils/formatMessage';

import TariffAvailablePeriod from 'pages/Tariffs/containers/List/components/Tariffs/TariffAvailablePeriod';

@inject('priceListState')
@observer
class TariffHeader extends React.Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    priceListState: PropTypes.object.isRequired
  }

  @computed get type() {
    const { type } = this.props.tariff;
    const typeStr = ['tariffs', type, 'name'].join('.');

    return formatMessage(typeStr);
  }

  @computed get isChecked() {
    const { priceListState, tariff } = this.props;
    return priceListState.includeTariff(tariff.id);
  }

  onChangeHandler = (e) => {
    const { priceListState, tariff } = this.props;
    priceListState.toggleTariff(tariff.toJSON());
  }

  render() {
    const { onClickHandler, tariff } = this.props;

    const {
      id,
      name,
      available_period,
      source,
      room_type
    } = this.props.tariff;

    return (
      <div className='caption'>
        <div className='item checkbox'>
          <input
            className='input'
            value={id}
            type='checkbox'
            defaultChecked={this.isChecked}
            onChange={this.onChangeHandler}
          />
        </div>
        <div className='item name' onClick={onClickHandler(tariff)}>
          {name}
          <div className='sub_header'>{room_type.name}</div>
        </div>

        <div className='item type'>
          {this.type}
        </div>

        <div className='item source'>
          {source
            ? source.name
            : formatMessage('tariff.not_specified')}
        </div>

        <div className='item period'>
          <TariffAvailablePeriod period={available_period} />
        </div>
      </div>
    );
  }
}

export default TariffHeader;
