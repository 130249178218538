import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Beds from '../Beds';

@withRouter
@observer
class Room extends Component {
  render() {
    const { beds } = this.props.room;
    const { beds_quantity } = this.props;

    return (
      <div className="beds">
        <Beds beds={beds} beds_quantity={beds_quantity} />
      </div>
    );
  }
}

Room.propTypes = {
  room: PropTypes.object.isRequired,
  beds_quantity: PropTypes.number.isRequired
};

export default Room;
