import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@observer
class Building extends Component {
  render() {
    const { number, building } = this.props;
    const { id, name } = this.props.building;

    return (
      <div className='buildings__item'>
        <NavLink
          to={`/buildings/${id}`}
        >{name}
        </NavLink>
      </div>
    );
  }
}

Building.propTypes = {
  number: PropTypes.number.isRequired,
  building: PropTypes.object.isRequired
};

export default Building;
