import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { dateRangeFormat } from 'Utils/dateFormat';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Price from 'Shared/ui/Price';
import Slots from '../Slots';
import getStatus from 'Pages/Orders/utils/getStatus';

@withRouter
@observer
class Reservation extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    const { id } = this.props.reservation;
    this.props.history.push(`/dashboard/${id}`);
  }

  render() {
    const {
      id,
      slots,
      price,
      check_in,
      check_out,
      tariff,
      room_type,
      state
    } = this.props.reservation;

    return (
      <div className='section'>
        <div className='section__header' onClick={this.handleClick}>
          <div className='content'>
            {[room_type.name, tariff.name].join(' — ')}
            <div className='sub'>
              {dateRangeFormat(check_in, check_out)}
            </div>
          </div>
          <div className='price'>
            <Price value={price} />

            <span className={classNames('reservation-status', getStatus(state).label)}>
              {getStatus(state).text}
            </span>
          </div>
        </div>

        <div className='section__content'>
          <Slots
            tariff={tariff}
            slots={slots}
            reservationId={id}
            reservationStatus={state}
          />
        </div>
      </div>
    );
  }
}

Reservation.propTypes = {
  reservation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Reservation;
