import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';

import QuotasShow from '../QuotasShow';
import QuotasEdit from '../QuotasEdit';
import Loader from 'Pages/Tariffs/components/Loader';

import RoomTypeStore from 'Stores/RoomTypesStore/One';
import FormState, { fields } from 'Pages/RoomTypes/components/FormState';
import { objectClean } from 'Utils/objectClean';

@inject('store')
@observer
class Quotas extends Component {
  @observable isEditMode = false

  @observable form = new FormState(fields)

  @observable roomTypeStore = RoomTypeStore.create()

  componentDidMount() {
    const { values } = this.props;

    this.roomTypeStore.resetStore({
      status: 200,
      data: { room_type: values, state: 'done' }
    });

    this.formUpdater = reaction(
      () => this.roomTypeStore.isFetched,
      (fetched) => fetched && this.updateForm(),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.formUpdater();
    this.roomTypeStore.clear();
  }

  updateForm = () => {
    const { room_type } = this.roomTypeStore;
    this.form.update(room_type.toJSON());
  }

  handleEdit = (e) => {
    e.preventDefault();
    this.isEditMode = true;
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.isEditMode = false;
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    this.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler
    });
  }

  successSubmitHandler = (form) => {
    const { room_type } = this.roomTypeStore;

    let data = form.values();
    data = objectClean(data);
    data = { room_type: data };

    room_type.update(data)
      .then(_ => this.isEditMode === false)
      .then(this.updateForm)
      .catch(error => this.errorSubmitHandler(error));
  }

  errorSubmitHandler(error) {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    const { room_type } = this.roomTypeStore;

    return (
      this.isEditMode
        ? (room_type &&
          <div className='content'>
            {room_type.isPending && <Loader />}

            <QuotasEdit quotas={this.form.$('quotas')} />

            <div className='buttons'>
              <button className='button green' onClick={this.handleOnSubmit}>
                Сохранить
              </button>
              <button className='button gray' onClick={this.handleCancel}>
                Отмена
              </button>
            </div>
          </div>
          )
        : (room_type &&
          <div className='content'>
            <QuotasShow quotas={room_type.quotas} />

            <div className='buttons'>
              <button className='button gray' onClick={this.handleEdit}>
                <FormattedMessage id='shared.change' />
              </button>
            </div>
          </div>
          )
    );
  }
}

Quotas.propTypes = {
  values: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default Quotas;
