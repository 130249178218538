import { types } from 'mobx-state-tree';

import { Price } from 'Stores/models/Price';

export const Rate = types.model('Rate', {
  price: Price,
  type: types.string,
  code: types.maybe(types.string),
  description: types.string,
  age_group: types.string,
  occupation: types.string
});
