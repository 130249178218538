import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Footer, FooterLeft, ButtonsWrapper } from './Buttons.styled';
import Button from 'Components/ui/Button';

@withRouter
@inject('tariffsStore', 'hotelStore')
@observer
class Actions extends Component {
  @computed get isValid() {
    const { form } = this.props;

    if (form.editedPeriodId) {
      return false;
    }

    if (form.isOpenFormIndex || form.isAddStopSalePeriod) {
      return false;
    }

    if (form.hasDuplicateRules) {
      return false;
    }

    if (form.changed && !form.validating) {
      return form.isValid;
    }

    if (form.touched && !form.validating) {
      return form.isValid;
    }

    return form.focused || form.isValid;
  }

  @computed get isLoading() {
    const { tariffsStore } = this.props;

    return tariffsStore.isPending;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.form.hasDuplicateRules || this.props.form.editedPeriodId) { return false; }

    this.props.form.onSubmit(e, {
      onSuccess: this.successHandler,
      onError: this.errorHandler
    });
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.navigateBack();
  }

  successHandler = (form) => {
    const { tariffsStore } = this.props;

    this.isValid &&
    tariffsStore.saveTariff(form)
      .then(data => this.navigateBack(data));
  }

  errorHandler = (form) => {
    console.log(form.errors());
  }

  navigateBack = (data) => {
    const { history } = this.props;
    const tariffPath = data ? data.data.tariff.id : '';

    history.push(`/tariffs/${tariffPath}`);
  }

  render() {
    return (
      <Footer>
        {!this.isValid &&
          <p className='text error'>
            <FormattedMessage id='shared.check_for_errors' />
          </p>}

        <ButtonsWrapper>
          <FooterLeft>
            <Button
              size='large'
              loading={this.isLoading}
              onClick={this.handleSubmit}
            ><FormattedMessage id='shared.confirm' />
            </Button>
            <Button
              size='large'
              variant='secondary'
              onClick={this.handleCancel}
            ><FormattedMessage id='shared.cancel' />
            </Button>
          </FooterLeft>
        </ButtonsWrapper>
      </Footer>
    );
  }
}

Actions.propTypes = {
  form: PropTypes.object.isRequired,
  tariffsStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Actions;
