import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

export const SourceSettings = types.model('SourceSettings', {
  booking_expiration_time: types.number,
  should_be_confirmed: types.boolean,
  has_cancellation_policy: types.boolean
}, {});

export const Sources = types.model('Sources', {
  id: types.identifier(types.string),
  name: types.string,
  settings: SourceSettings
}, {});

export const SourcesStore = types.model('SourcesStore', {
  sources: types.optional(types.array(Sources), []),
  state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get sourcesOptions() {
    return this.sources.map((item) => {
      return { value: item.id, label: item.name };
    });
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    instance.get('/api/tariffs/sources', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { sources: [] };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) {
      applySnapshot(this, data);
    }

    return data;
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  }
});
