import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { serialize } from 'object-to-formdata';

@withRouter
@inject('hotelStore')
@observer
class Control extends Component {
  submitHandler = (e) => {
    e.preventDefault();

    const { files, hotelStore, selectedTag } = this.props;
    const MIN_PRIORITY = 9999;

    const data = {
      hotel: {
        images: []
      }
    };

    files.forEach(file => {
      if (!selectedTag) {
        data.hotel.images.push({
          priority: MIN_PRIORITY,
          image: file
        });
      } else {
        data.hotel.images.push({
          priority: MIN_PRIORITY,
          image: file,
          promo: selectedTag
        });
      }
    });

    hotelStore.updateRaw(serialize(data))
      .then(_ => this.props.history.push('/photo'));
  }

  render() {
    const { files } = this.props;

    return (
      <div className='form__control'>
        {files.length > 0 &&
          <button
            className='green button'
            onClick={this.submitHandler}
          >
            <FormattedMessage id='shared.confirm' />
          </button>}

        <Link to='/photo' className='gray button'><FormattedMessage id='shared.cancel' /></Link>
      </div>
    );
  }
}

Control.propTypes = {
  files: PropTypes.object.isRequired,
  selectedTag: PropTypes.oneOfType([
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    PropTypes.oneOf([null]).isRequired
  ])
};

export default Control;
