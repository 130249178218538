import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Available from './Available';
import WritingValue from './WritingValue';

@withRouter
@observer
class Quota extends Component {
  render() {
    const { quota, room_type, source, expand } = this.props;

    const { day, restriction, busy } = quota;

    const selectableKey = [day, source.id].join('_');

    return (
      <div className='quota'>
        <Available
          room_type={room_type}
          quota={quota}
        />

        {expand &&
          <>
            <div className='item busy'>
              <span className='text'>{busy}</span>
            </div>

            <div className='item'>
              <WritingValue
                selectableKey={selectableKey}
                day={day}
                source={source.insurance}
                room_type={room_type}
                value={restriction}
              />
            </div>
          </>}
      </div>
    );
  }
}

Quota.propTypes = {
  quota: PropTypes.object.isRequired,
  room_type: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired
};

export default Quota;
