import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import Order from './Order';
import Header from './Header';

@withRouter
@inject('searchForm', 'filterForm', 'sortForm', 'ordersStore')
@observer
class Orders extends Component {
  @observable opennedRows = new Map();

  toggleRow = ({ id } = {}) => {
    if (!id) {
      this.opennedRows.clear();
      return;
    }

    this.opennedRows.has(id)
      ? this.opennedRows.delete(id)
      : this.opennedRows.set(id)
    ;
  }

  isActive = ({ id }) => {
    return this.opennedRows.has(id);
  }

  handleInfiniteLoad = () => {
    const { sortForm, filterForm, searchForm, ordersStore } = this.props;

    const filters = filterForm.values().tags;
    const sort = sortForm.values();
    const search = searchForm.values();

    const { page, total_pages } = ordersStore.meta.paginate;

    if (page < total_pages) {
      ordersStore.fetch({ filters, sort, search });
    };
  }

  render() {
    const { orders } = this.props;

    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={this.handleInfiniteLoad}
        initialLoad={false}
        hasMore
        loader=''
        threshold={320}
      >
        <Header />

        <div className='orders-table__body'>
          {orders.map(order => (
            <Order
              key={order.id}
              filters={this.props.filterForm.values().tags}
              order={order}
              handleRowChange={this.toggleRow}
              isRowOpen={this.isActive(order)}
            />
          ))}
        </div>
      </InfiniteScroll>
    );
  }
}

Orders.propTypes = {
  orders: PropTypes.object.isRequired,
  sortForm: PropTypes.object.isRequired,
  filterForm: PropTypes.object.isRequired,
  searchForm: PropTypes.object.isRequired,
  ordersStore: PropTypes.object.isRequired
};

export default Orders;
