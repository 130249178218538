import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';
import { objectClean } from 'Utils/objectClean';

const OrderStore = types.model('OrderStore', {
  order: types.frozen,
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  saveOrder(form) {
    this.setState('pending');
    let data = form.values();

    data = objectClean(data);
    data = { order: { reservations: [data] } };

    const promise = form.$('id').isEmpty
      ? this.createOrder(data)
      : this.updateOrder(form.$('id').value, data);

    return promise
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  createOrder(data) {
    return instance.post('/api/orders', data);
  },

  updateOrder(id, data) {
    return instance.put(`/api/orders/${id}`, data);
  },

  create(form) {
    let data = form.values();
    data = objectClean(data);
    data = { order: data };

    return instance.post('/api/orders', data)
      .then(response => this.applySnapshot(response))
      .catch(error => this.errorHandler(error));
  },

  fetch(id, params = {}) {
    this.setState('pending');

    return instance.get(`/api/orders/${id}`, { params: params })
      .then((response) => this.applySnapshot(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  refresh() {
    const id = this.order.id;

    return instance.get(`/api/orders/${id}`)
      .then((response) => this.applySnapshot(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  download(type = 'orders') {
    const { id, order_code } = this.order;
    const apiPath = `/api/reports/${type}/${id}`;

    return instanceBlob.get(apiPath)
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const filename = ['order_', order_code, '.xlsx'].join('');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { order: null };
    applySnapshot(this, data);
  },

  setState(state, response) {
    const order = response && response.data.order;

    this.state = state;
    return order;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { data } = response;

    applySnapshot(this, data);
    return this;
  }
});

export default OrderStore;
