import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
class Toggle extends Component {
  onSelectHandler = item => e => {
    const { field } = this.props;
    field.set(item.key);
  }

  render() {
    const { field, className } = this.props;

    const toggleCls = classNames('form-toggle', className);

    const items = field.extra || [];

    const listItems = items.map(item => {
      const itemCls = classNames('form-toggle__item', {
        active: field.value === item.key
      });

      return (
        <div
          key={item.key}
          className={itemCls}
          onClick={this.onSelectHandler(item)}
        >
          <div className='header'>
            {item.name}
          </div>
          <div className='description'>
            {item.description}
          </div>
        </div>
      );
    });

    return (
      <div className={toggleCls}>
        {listItems}
      </div>
    );
  }
}

Toggle.defaultProps = {
  className: undefined
};

Toggle.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired
};

export default Toggle;
