import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import FormattedInputField from 'Shared/form/FormattedInputField';
import SingleSelect from 'Shared/form/SingleSelect';
import FormItemText from 'Shared/form/FormItemText';

import { FormLanguageContext } from 'Context/FormLanguageContext';

@observer
class Form extends Component {
  static contextType = FormLanguageContext;
  render() {
    const { form } = this.props;
    const currentLanguage = this.context.currentLanguage;
    return (
      <div className='form__body'>
        {/* <FormItemInput field={form.$(`name.${currentLanguage}`)} />
        <FormItemText field={form.$(`description.${currentLanguage}`)} /> */}
        <FormItemInput field={form.$('name')} />
        <FormItemText field={form.$('description')} />

        {false &&
          <FormattedInputField
            suffix=' руб.'
            thousandSeparator={' '}
            field={form.$('price')}
            className='form__field w30'
          />}

        <SingleSelect field={form.$('category')} />
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
