import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { withRouter, Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

import FormState, { fields } from 'Pages/Rooms/components/FormState';
import Form from 'Pages/Rooms/components/Form';
import Loader from 'Pages/Rooms/components/Loader';
import Actions from '../Actions';

@withRouter
@observer
class PageContent extends Component {
  @observable form = new FormState(fields);

  @computed get isLoading() {
    const { room } = this.props;
    return room.isPending;
  }

  componentDidMount() {
    const { room } = this.props;
    this.form.set(room.toForm());
  }

  updateHandler = (e) => {
    e.preventDefault();

    this.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler
    });
  }

  successSubmitHandler = (form) => {
    const { room } = this.props;
    const values = form.values();

    room.update(values)
      .then(this.navigateToShow);
  }

  navigateToShow = () => {
    const { room, history } = this.props;
    history.push(`/rooms/${room.id}`);
  }

  render() {
    const { room } = this.props;

    return (
      <div className='info__content'>
        {this.isLoading && <Loader />}

        <div className='header'>
          Номер №{room.number}
        </div>

        <div className='content'>
          <Form form={this.form} />
          <Actions form={this.form} room={room} />
        </div>
      </div>
    );
  }
}

PageContent.propTypes = {
  room: PropTypes.object.isRequired
};

export default PageContent;
