import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import styled, { css } from 'styled-components';
import Popover from 'react-popover';

const activeItemRules = css`
  color: #000;
  background: #efefef;
`;

const Item = styled.div`
  cursor: pointer;
  line-height: 18px;
  padding: 5px 10px;

  :hover {
    ${activeItemRules}
  }

  ${({ isActive }) => isActive && css`
    ${activeItemRules}`
  }
`;

const Content = styled.div`
  width: 416px;
  padding: 10px 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  overflow: hidden;
`;

const Wrapper = styled.div`
`;

@observer
class AutoComplete extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    dictionary: PropTypes.object.isRequired
  };

  @observable.ref matches = [];

  @computed get isOpen() {
    return this.matches.length > 0;
  }

  @computed get selectedItem() {
    return this.matches[this.hintIndex];
  }

  @action
  setMatches(values) {
    this.matches = values;
  }

  @action
  unsetMatches() {
    this.matches = [];
  }

  @observable hintIndex = 0;

  @action
  moveUp() {
    const currValue = this.hintIndex;
    this.hintIndex = currValue === 0 ? 0 : this.hintIndex - 1;
  }

  @action
  moveDown() {
    const currValue = this.hintIndex;

    this.hintIndex = this.matches.length - 1 === currValue
      ? currValue
      : currValue + 1;
  }

  @action
  resetIndex() {
    this.hintIndex = 0;
  }

  @action
  closePopover() {
    this.resetIndex();
    this.unsetMatches();
  }

  fetchData = async () => {
    try {
      const { dictionary, field } = this.props;

      await dictionary.fetch({ chars: field.value });
      const data = dictionary.data.toJSON();

      this.setMatches(data);
    } catch {
      this.unsetMatches();
    }
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        this.moveUp();
        break;

      case 'ArrowDown':
        e.preventDefault();
        this.moveDown();
        break;

      case 'Enter':
        if (this.selectedItem) {
          this.handleClick(this.selectedItem)(e);
        }

        break;

      case 'Escape':
        e.preventDefault();
        this.closePopover();
        break;

      default:
        this.fetchData();
    }
  };

  handleClick = (item) => (e) => {
    e.preventDefault();

    const { field } = this.props;
    field.set(item.name);

    this.closePopover();
  };

  render() {
    const { children, className } = this.props;

    const listMatches = this.matches.map((item, index) => (
      <Item
        key={item.id}
        onClick={this.handleClick(item)}
        isActive={this.hintIndex === index}
      >
        {item.name}
      </Item>
    ));

    return (
      <Popover
        className={className}
        body={<Content>{listMatches}</Content>}
        isOpen={this.isOpen}
        place='below'
        preferPlace='below'
        tipSize={0.01}
        enterExitTransitionDurationMs={0}
      >
        <Wrapper onKeyDown={this.handleKeyDown}>
          {children}
        </Wrapper>
      </Popover>
    );
  }
}

export default AutoComplete;
