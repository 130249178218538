import { types } from 'mobx-state-tree';

import { RoomType } from './RoomType';
import { Source } from './Source';
import { Service } from './Service';

export const TariffsMeta = types.model('TariffsMeta', {
  room_types: types.optional(types.array(RoomType), []),
  sources: types.optional(types.array(Source), []),
  services: types.optional(types.array(Service), []),
  billing_hour: types.maybe(types.string)
});
