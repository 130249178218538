import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import _without from 'lodash/without';
import { FormattedMessage } from 'react-intl';

@observer
class Option extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    field: PropTypes.object.isRequired
  }

  handleChange = (e) => {
    const { value } = this.props;

    e.currentTarget.checked
      ? this.selectValue(value)
      : this.deselectValue(value);
  }

  selectValue = (item) => {
    const { field } = this.props;
    const currentValue = field.value || [];

    // TODO: reset before update should be fixed after update mobx-react-form
    field.reset();
    field.update([...currentValue, item]);
  }

  deselectValue = (item) => {
    const { field } = this.props;
    const currentValue = field.value || [];

    // TODO: reset before update should be fixed after update mobx-react-form
    field.reset();
    field.update(_without(currentValue, item));
  }

  @computed get checked() {
    const { field, value } = this.props;
    const currentValue = field.values() || [];

    return currentValue.includes(value);
  }

  render() {
    const { value } = this.props;

    return (
      <div className='quantity__item'>
        <label htmlFor={value}>
          <FormattedMessage
            id='tariffs.manipulation.quantity_string'
            values={{ count: value }}
          />
        </label>

        <input
          className='input'
          id={value}
          type='checkbox'
          checked={this.checked}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default Option;
