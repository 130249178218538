import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Option from './Option';

@observer
class Options extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    dictionary: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { field } = this.props;

    if (field.size === 0) {
      this.addOption();
    }
  }

  handleAdd = (e) => {
    e.preventDefault();
    this.addOption();
  }

  addOption = () => {
    const { field } = this.props;
    field.add();
  }

  handleRemoveOption = (field) => {
    field.del();
  }

  render() {
    const { field, dictionary } = this.props;

    const listItems = field.fields.values().map((item, num) => (
      <Option
        key={item.id}
        field={item}
        number={num + 1}
        onRemove={this.handleRemoveOption}
        dictionary={dictionary}
      />
    ));

    return (
      <div className='form__section'>
        <div className='header'>
          {field.label}
        </div>

        <div className='content'>
          {listItems}
        </div>

        <div className='footer'>
          <button
            onClick={this.handleAdd}
            className='green button'
          >
            <FormattedMessage id='tariffs.manipulation.add' />
          </button>
        </div>
      </div>
    );
  }
}

export default Options;
