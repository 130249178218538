import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import _remove from "lodash/remove";

import { Description } from "./LengthStayArrivalBasedRestriction.styled";
import Button from "Components/ui/Button";
import LengthStayArrivalBasedRestrictionForm from "./LengthStayArrivalBasedRestrictionForm";
import LengthStayArrivalBasedRestrictionList from "./LengthStayArrivalBasedRestrictionList";

@observer
class FormLengthStayArrivalBasedRestrictions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }

  handleAddPeriod = () => {
    this.setState({ isOpened: "newForm" });
  };

  handleClose = () => {
    this.setState({ isOpened: false });
  };

  handleEditOpen = (index) => {
    this.setState({ isOpened: index + 1 });
  };

  handleDeleteRestrictionPeriod = (index) => {
    const { form } = this.props;
    const arr = Array.from(form.$("length_of_stay_arrival_based_restrictions").values());
    _remove(arr, (period, perIndex) => perIndex === index);
    form.update({ length_of_stay_arrival_based_restrictions: [...arr] });
  };

  render() {
    const { form } = this.props;
    const { isOpened } = this.state;
    const lengthStayArrivalBasedRestrictions = Array.from(
      form.$("length_of_stay_arrival_based_restrictions").values()
    );

    return (
      <form className="form">
        <Description>
          <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.description" />
        </Description>

        <LengthStayArrivalBasedRestrictionList
          data={lengthStayArrivalBasedRestrictions}
          form={form}
          onClose={this.handleClose}
          onEdit={this.handleEditOpen}
          onDelete={this.handleDeleteRestrictionPeriod}
          isOpened={isOpened}
        />

        {isOpened === "newForm" && (
          <div className="row">
            <LengthStayArrivalBasedRestrictionForm form={form} onClose={this.handleClose} />
          </div>
        )}

        {isOpened !== "newForm" && (
          <div className={"row"}>
            <Button onClick={this.handleAddPeriod}>
              <FormattedMessage id="periods.add" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

FormLengthStayArrivalBasedRestrictions.propTypes = {
  form: PropTypes.object.isRequired,
};

export default FormLengthStayArrivalBasedRestrictions;
