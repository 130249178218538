import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { dateFormat } from 'Utils/dateFormat';

@observer
class Source extends Component {
  render() {
    const { source } = this.props.order;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='orders.source' />
        </div>
        <div className='value'>
          {source.name}
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  order: PropTypes.object.isRequired
};

export default Source;
