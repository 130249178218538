import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import LocalizedLabel from 'Components/ui/LocalizedLabel';

import Tariffs from '../../Tariffs';
import Quotas from '../../Quotas';

const Content = styled.div`
  width: 907px;
  min-width: 907px;
`;

function RoomType({ fetchTariffs, item: { roomType, tariffs } }) {
  const { name, quotas } = roomType;

  return (
    <div className='groups__item'>
      <div className='groups__item-header'>
        <LocalizedLabel content={name} />
      </div>

      <Content className='groups__item-content'>
        <div className='groups__item-blocks'>
          <div className='groups__item-block'>
            <div className='header'>
              <FormattedMessage id='tariffs.available' />
            </div>
            <div className='content'>
              <Tariffs tariffs={tariffs} fetchTariffs={fetchTariffs} />
            </div>
          </div>

          {false &&
            <div className='groups__item-block'>
              <div className='header'>
                <FormattedMessage id='quotas.self' />
              </div>
              <div className='content'>
                <Quotas values={roomType} quotas={quotas} />
              </div>
            </div>}
        </div>
      </Content>
    </div>
  );
}

RoomType.propTypes = {
  item: PropTypes.object.isRequired,
  fetchTariffs: PropTypes.func
};

export default RoomType;
