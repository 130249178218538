import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import SingleSelect from 'Shared/form/SingleSelect';
import { PromoClassifiersStore } from 'Stores/PromoClassifiers';

@observer
class IsManual extends Component {
  componentDidMount() {
    const { promoClassifiersStore } = this.props;
    promoClassifiersStore.fetch();
  }

  @computed get isLoading() {
    const { promoClassifiersStore } = this.props;
    return promoClassifiersStore.isPending;
  }

  @computed get options() {
    const { promoClassifiersStore } = this.props;
    if (!promoClassifiersStore.isFetched) return [];

    return promoClassifiersStore.promo.toJSON().map((el) => ({
      value: el.tag,
      label: el.name
    }));
  }

  render() {
    const { field } = this.props;

    return (
      <SingleSelect
        field={field}
        simpleValue={false}
        valueKey='value'
        labelKey='label'
        searchable={false}
        clearable
        isLoading={this.isLoading}
        options={this.options}
        showLabel={false}
      />
    );
  }
}

IsManual.defaultProps = {
  promoClassifiersStore: PromoClassifiersStore.create()
};
IsManual.propTypes = {
  field: PropTypes.object.isRequired,
  promoClassifiersStore: PropTypes.object.isRequired
};

export default IsManual;
