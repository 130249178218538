import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MdMailOutline from 'react-icons/lib/md/mail-outline';
import MdAdd from 'react-icons/lib/md/add';

import Email from './Email';

@observer
class Emails extends Component {
  render() {
    const listItems = this.props.emails.map((item) => {
      return <Email key={item.value} email={item} />;
    });

    return (
      <div className='form__section'>
        <div className='header'>
          <FormattedMessage id='hotel.email' />
        </div>
        <div className='content'>
          <div className='options'>
            {listItems}
          </div>
        </div>
      </div>
    );
  }
}

Emails.propTypes = {
  emails: PropTypes.array.isRequired
};

export default Emails;
