import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { formatMessage } from 'Utils/formatMessage';
import GeneralInfo from 'Pages/PriceList/components/Form/GeneralInfo';

@observer
class Edit extends Component {
  render() {
    const { form, onCancelHandler, onConfirmHandler } = this.props;
    return (
      <div className='groups__item-block'>
        <GeneralInfo priceListState={form} />
        <div className='actions'>
          <div className='buttons'>

            <button
              type='submit'
              className='button green'
              onClick={onConfirmHandler}
            >
              {formatMessage('shared.confirm')}
            </button>
            <button
              type='cancel'
              className='button red'
              onClick={onCancelHandler}
            >
              {formatMessage('shared.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Edit.defaultProps = {
  form: PropTypes.object.isRequired
};

export default Edit;
