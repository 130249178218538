import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Values from './Values';

@observer
class Rate extends Component {
  render() {
    const { rate } = this.props;
    return (
      <div className='rate'>
        <Values rate={rate} />
      </div>
    );
  }
}

Rate.propTypes = {
  rate: PropTypes.object.isRequired
};

export default Rate;
