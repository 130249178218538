import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import RoomsStore from 'Stores/RoomStore/List';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';

import List from './containers/List';
import Add from './containers/Add';
import Show from './containers/Show';
import Edit from './containers/Edit';

@withRouter
@observer
class Rooms extends Component {
  componentDidMount() {
    const { roomsStore } = this.props;
    roomsStore.fetch();
  }

  componentWillUnmount() {
    const { roomsStore } = this.props;
    roomsStore.clear();
  }

  render() {
    const { roomsStore } = this.props;

    return (
      <Provider roomsStore={roomsStore}>
        <div className='layout__page'>
          <TopBar isLoading={roomsStore.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            <List />

            <Switch>
              <Route
                exact
                path='/rooms/new'
                component={Add}
              />

              <Route
                exact
                path='/rooms/:id'
                component={Show}
              />

              <Route
                exact
                path='/rooms/:id/edit'
                component={Edit}
              />
            </Switch>
          </div>
        </div>
      </Provider>
    );
  }
}

Rooms.defaultProps = {
  roomsStore: RoomsStore.create()
};

export default Rooms;
