import { types } from 'mobx-state-tree';
import { Translation } from 'Types/Translation';

const Option = types.model('Option', {
  name: types.maybe(types.string),
  name_translations: types.optional(Translation, {})
});

const Manipulation = types.model('Manipulation', {
  disclaimer: types.maybe(types.string),
  disclaimer_translations: types.maybe(Translation),
  is_manual: types.boolean,
  quantity: types.array(types.number),
  options: types.optional(types.array(Option), [])
});

export default Manipulation;
