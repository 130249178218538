const fields = [
  'sort_by',
  'direction'
];

const labels = {
  sort_by: 'Сортировать по',
  direction: 'Направление сортировки'
};

const placeholders = {
  sort_by: 'Сортировать по',
  direction: 'Направление сортировки'
};

const values = {
  sort_by: 'updated_at',
  direction: -1
};

export default { fields, labels, placeholders, values };
