import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';

@withRouter
@observer
class TopBarActionBox extends Component {
  render() {
    return (
      <div className='action-box'>
        <form method='post' action='' className='form'>
          <Link
            to='/rooms/new'
            className='button green'
          >
            <FormattedMessage id='rooms.add' />
          </Link>
        </form>
      </div>
    );
  }
}

export default TopBarActionBox;
