import { types, getParent } from 'mobx-state-tree';

const default_message = 'Неизвестная ошибка. Пожалуйста, обратитесь в службу технической поддержки';

export const Notification = types.model('Notification', {
  id: types.identifier(types.string),
  type: types.optional(types.enumeration(['success', 'error', 'warning']), 'success'),
  header: types.string,
  messages: types.optional(types.array(types.string), [default_message])
}, {
  remove() {
    const store = getParent(this, 2);
    store.removeNotification(this);
  }
});
