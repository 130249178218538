import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import enhanceWithClickOutside from 'react-click-outside';
import styled from 'styled-components';

import RatePrice from 'components/v2/RatePrice';

import AgeGroup from './AgeGroup';
import { formatMessage } from 'Utils/formatMessage';
import _uniqueId from 'lodash/uniqueId';
import _cloneDeep from 'lodash/cloneDeep';
import { isEqualFields } from 'Pages/Tariffs/components/FormState/ratesCompare';
import { RowGutters, RatesFormRight } from 'Styles/styled';

const RatesFormBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 15px 0px;
  align-items: flex-start;
`;

@enhanceWithClickOutside
@observer
class PriceForm extends Component {
  componentDidMount() {
    this.rateChangeHandler = reaction(
      () => this.props.rateForm.values(),
      () => (this.rateErrors = [])
    );
  }

  componentWillUnmount() {
    this.rateChangeHandler();
  }

  @observable rateErrors = [];
  @computed get hasRate() {
    const { rateForm } = this.props;
    return rateForm.has('id') && rateForm.$('id').value && !this.props.isAdding;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.rateForm.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler
    });
  };

  successSubmitHandler = () => {
    const { form, rateForm, control } = this.props;
    const values = rateForm.values();
    const rates = form.$('rates').values();
    // id дублируются
    const index = rates.findIndex(rate => rate.id === values.id);
    const errors = form.isInclude(values);

    if (values.age_group === 'child' && values.age_period.from > values.age_period.to) {
      this.rateErrors = [formatMessage('rates.age_period_equal_error')];
      return;
    }

    // проверяем, изменяем ли мы какую либо категорию
    if (index !== -1 && !this.props.isAdding) {
      const currentRate = _cloneDeep(rates[index]); // забираем данные редактируемой секции и делаем копию
      const newRate = { ...currentRate };
      const equalResult = isEqualFields(values, currentRate); // сравниваем новые данные и редактируемую категорию

      if (equalResult.length) {
        // если различия есть
        const isTypeDouble = currentRate.type === values.type; // проверяем не изменился ли тип у категории
        const isTypeDoubleAllRates = rates.some(
          rate => rate.type === values.type
        );

        if (!isTypeDouble && isTypeDoubleAllRates) {
          // если тип изменился и категория с таким же типом уже существует
          this.rateErrors = [formatMessage('rates.include_error')];
          return;
        }

        equalResult.forEach(eqRes => {
          // обновляем данные редактируемой категории
          newRate[eqRes.path.split('/')[1]] = values[eqRes.path.split('/')[1]];
          return eqRes.path.split('/')[1];
        });

        const isChangeAgePeriod = equalResult.some(changedField => (changedField.path === '/age_period/from' || changedField.path === '/age_period/to') && errors.length);

        if (newRate.age_group === 'child' && isChangeAgePeriod) {
          const errors = form.isInclude(values);
          this.rateErrors = errors;
          return;
        }

        rates.splice(index, 1, newRate);
        form.update({ rates: [...rates.sort(form.sortRatesStore)] });

        form.rateChange(currentRate, newRate);
        this.props.addHandler(false);
      } // если различий нет

      // отрисовываем текст ошибки
      if (errors.length) this.rateErrors = errors;
    } else {
      values.id = _uniqueId();

      const errors = form.isInclude(values);
      if (errors.length) {
        this.rateErrors = errors;
        return;
      }

      this.props.addHandler(false);
      form.addRate(values);

      control.close();
    }

    this.clearPrice();
    rateForm.reset();
  };

  clearPrice = () => {
    const { rateForm } = this.props;

    rateForm.$('price.amount').set(0);
  }

  errorSubmitHandler = () => {
    const { rateForm } = this.props;
    console.log(rateForm.errors());
  };

  handleCancel = e => {
    const { rateForm } = this.props;

    e.preventDefault();
    this.props.addHandler(false);
    this.clearPrice();

    rateForm.reset();
  };

  handleChangePrice = val => {
    const { rateForm } = this.props;

    rateForm.$('price.amount').set(val);
  }

  render() {
    const { form, rateForm } = this.props;
    const ageGroup = rateForm.$('age_group');
    const agePeriod = rateForm.$('age_period');

    return (
      <>
        <RowGutters>
          <RatesFormBody>
            <AgeGroup
              rateForm={rateForm}
              ageGroup={ageGroup}
              agePeriod={agePeriod}
              form={form}
            />

            <RatesFormRight>
              <RatePrice
                price={rateForm.$('price').value}
                onChange={this.handleChangePrice}
                error={rateForm.$('price.amount').error}
              />
            </RatesFormRight>
          </RatesFormBody>

          <div className='rates__form--control'>
            {this.rateErrors.length > 0 && (
              <p className='text error'>{this.rateErrors.join(', ')}</p>
            )}

            <div className='buttons'>
              <button className='button green' onClick={this.handleSubmit}>
                {this.hasRate
                  ? formatMessage('shared.change')
                  : formatMessage('shared.add')}
              </button>

              <button className='button gray' onClick={this.handleCancel}>
                {formatMessage('shared.cancel')}
              </button>
            </div>
          </div>
        </RowGutters>
      </>
    );
  }
}

PriceForm.propTypes = {
  form: PropTypes.object.isRequired,
  rateForm: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAdding: PropTypes.bool.isRequired,
  addHandler: PropTypes.func.isRequired
};

export default PriceForm;
