import { types, getParent } from 'mobx-state-tree';
import instance from 'connection/instance';
import _ from 'lodash';

import { TariffRateRule } from './TariffRateRule';
import { TariffRateValue } from './TariffRateValue';
import { TariffRateAgePeriod } from './TariffRateAgePeriod';

export const TariffPrice = types.model('TariffPrice', {
  amount: types.number,
  currency: types.string
});

export const TariffRate = types.model('TariffRate', {
  id: types.identifier(types.string),
  type: types.string,
  description: types.string,
  price: types.maybe(TariffPrice),
  rules: types.optional(types.array(TariffRateRule), []),
  values: types.optional(types.array(TariffRateValue), []),
  age_group: types.maybe(types.string),
  age_period: types.maybe(TariffRateAgePeriod),
  count: types.number,
  occupation: types.maybe(types.string),

  get rulesMap() {
    const data = new Map();

    this.rules.forEach((rule) => {
      data.set(rule.date, rule);
    });

    return data;
  },

  get tariff() {
    return getParent(this, 2);
  }
}, {
  update(attrs) {
    const data = { tariff_rate: attrs };

    instance.put(`/api/tariffs/${this.tariff.id}/tariff_rates/${this.id}`, data)
      .then(response => this.updateRules(response));
  },

  updateRules(response) {
    const { status, data } = response;

    if (status === 200) {
      this.rules = data.tariff_rate.rules;
    }
  },

  resetValues(startDate, endDate) {
    const tariff = this.tariff;
    if (!tariff) return [];

    const data = [];
    const currentDate = startDate.clone();
    const { fromDateObj, toDateObj } = tariff.available_period;

    while (!currentDate.isSame(endDate, 'day')) {
      if (currentDate.isSameOrAfter(fromDateObj, 'day') &&
          currentDate.isSameOrBefore(toDateObj, 'day')) {
        const id = _.uniqueId('value-');
        const dateStr = currentDate.format('YYYY-MM-DD');

        const rule = this.rulesMap.get(dateStr);
        const price = rule ? rule.price : this.price;

        data.push({
          id: id,
          date: dateStr,
          price: price,
          tariff_id: this.tariff.id,
          rate_id: this.id
        });
      }

      currentDate.add(1, 'days');
    }

    this.values = data;
  }
});
