import React from 'react';
import PropTypes from 'prop-types';
import { StopSalesElement, StopSalesLabel } from './TariffStopSales.styled';
import { formatMessage } from 'Utils/formatMessage';
import { StopIcon } from "components/ui/Icons";

const StopSales = (props) => {
  const { tariff, labels } = props;

  const reverseDate = (date) => {
    return date.split('-').reverse().join('.');
  };

  const listItems = tariff.stop_sales.map((stop_sale, index) => {
    return <StopSalesElement key={index}><StopIcon/> {reverseDate(stop_sale.from)} - {reverseDate(stop_sale.to)} </StopSalesElement>;
  });

  return (
    <div>
      <StopSalesLabel className='key'>
        {labels.stop_sales}
      </StopSalesLabel>


      <div className='value'>
        {tariff.stop_sales.length > 0
          ? (
              listItems
            )
          : (
            <StopSalesElement> {formatMessage('tariff.not_specified')} </StopSalesElement>
            )}
      </div>
    </div>
  );
};

StopSales.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default StopSales;
