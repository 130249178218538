import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import MdDelete from 'react-icons/lib/md/delete';
import MdZoomOutMap from 'react-icons/lib/md/zoom-out-map';
import { FormLanguageContext } from "Context/FormLanguageContext";
import AltInput from './AltInput'

const ItemTypes = {
  PHOTO: "photo",
};

const photoTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const monitorItem = monitor.getItem();

    const dragIndex = monitorItem.index;
    const dragThroughIndex = monitorItem.throughIndex;
    const dragPriority = monitorItem.priority;
    const dragPromo = monitorItem.promo;

    const hoverIndex = props.index;
    const hoverThroughIndex = props.throughIndex;
    const hoverPriority = props.priority;
    const hoverPromo = props.promo;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.onMove(
      dragIndex,
      hoverIndex,
      {
        drag: {
          index: dragIndex,
          throughIndex: dragThroughIndex,
          priority: dragPriority,
          promo: dragPromo
        },
        hover: {
          index: hoverIndex,
          throughIndex: hoverThroughIndex,
          priority: hoverPriority,
          promo: hoverPromo
        }
      }
    );

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

@observer
class Photo extends Component {
  static contextType = FormLanguageContext;
  @observable isOtherTagsShow = false;

  zoomPhoto = (e) => {
    e.preventDefault();

    const { index, image, onZoomHandler } = this.props;
    onZoomHandler(index, image);
  }

  removePhoto = (e) => {
    e.preventDefault();

    const { index, image, onRemoveHandler } = this.props;
    onRemoveHandler(index, image);
  }

  toggleOtherTags = () => {
    this.isOtherTagsShow = !this.isOtherTagsShow;
  }

  closeTagSelect = () => {
    this.isOtherTagsShow = false;
  }

  onSelectTag = (tag) => {
    this.props.onSelectTag(tag);
    this.closeTagSelect();
  }

  render() {
    const {
      image: { url },
      isDragging,
      connectDragSource,
      connectDropTarget,
      index,
      priority,
      promo,
      throughIndex,
      tags,
      defaultTag,
      onBlur,
      alt_translations
    } = this.props;

    const { currentLanguage } = this.context;

    const tag = promo || defaultTag;
    const otherTags = tags.filter(t => t.tag !== tag.tag).map(t => {
      return (
        <a className='tag' key={t.tag} onClick={() => this.onSelectTag(t)}>{t.name_translations[currentLanguage]}</a>
      );
    });

    const installedTagClass = classNames('tag', {
      disabled: this.isOtherTagsShow
    });

    const otherTagClass = classNames('other-tags', {
      opened: this.isOtherTagsShow
    });

    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      connectDropTarget(
        <div className='photo' style={{ opacity }}>
          <div className='image'>
            <img src={url} />
          </div>
          <div className='actions'>
            <a className='zoom' onClick={this.zoomPhoto}>
              <MdZoomOutMap />
            </a>
            <a className='remove' onClick={this.removePhoto}>
              <MdDelete />
            </a>
          </div>
          <a className={installedTagClass} onClick={this.toggleOtherTags}>
            {tag.name_translations[currentLanguage]}
          </a>
          <div className={otherTagClass}>
            {otherTags}
          </div>

          {/* <S.TagSelect isOpen={this.state.isTagSelectOpen} onSelect={this.onSelectTag} tags={tags} /> */}
          <AltInput alt_translations={alt_translations} onBlur={this.props.onBlur}/>
        </div>

      )
    );
  }
}

Photo.propTypes = {
  image: PropTypes.object.isRequired,
  alt_translations: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  onZoomHandler: PropTypes.func.isRequired,
  onSelectTag: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default DropTarget(ItemTypes.PHOTO, photoTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource(
    ItemTypes.PHOTO,
    {
      beginDrag({ id, index, priority, promo, throughIndex }) {
        return {
          id,
          index,
          priority,
          promo,
          throughIndex,
        };
      },
      endDrag(props, monitor, component) {
        props.onEndDrag({ ...props });
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Photo)
);
