import MenuLeaf from './MenuLeaf';
import MenuNode from './MenuNode';
import ExternalLink from './ExternalLink';

// TODO HIDE invoices
export const tree = [
  {
    id: 1,
    name: 'menu.property_management',
    isOpen: true,
    customComponent: MenuNode,
    children: [
      {
        id: 1,
        name: 'menu.bookings_dashboard',
        path: '/dashboard',
        customComponent: MenuLeaf
      },
      {
        id: 2,
        name: 'menu.orders_list',
        path: '/orders',
        customComponent: MenuLeaf
      },
      {
        id: 3,
        name: 'menu.property_description',
        path: '/hotel',
        customComponent: MenuLeaf
      },
      {
        id: 4,
        name: 'menu.property_photos',
        path: '/photo',
        customComponent: MenuLeaf
      },
      {
        id: 5,
        name: 'menu.settings',
        path: '/settings',
        customComponent: MenuLeaf
      }
    ]
  },
  {
    id: 2,
    name: 'menu.inventory',
    isOpen: false,
    customComponent: MenuNode,
    children: [
      {
        id: 1,
        name: 'menu.buildings_and_blocks',
        path: '/buildings',
        customComponent: MenuLeaf
      },
      {
        id: 2,
        name: 'menu.room_types',
        path: '/room_types',
        customComponent: MenuLeaf
      }
      // {
      //   id: 3,
      //   name: 'menu.inventory',
      //   path: '/rooms',
      //   customComponent: MenuLeaf
      // }
    ]
  },
  {
    id: 4,
    name: 'menu.rates_and_rules',
    isOpen: false,
    customComponent: MenuNode,
    children: [
      {
        id: 1,
        name: 'menu.rates',
        path: '/tariffs',
        customComponent: MenuLeaf
      },
      // {
      //   id: 2,
      //   name: 'menu.services',
      //   path: "/services",
      //   customComponent: MenuLeaf,
      //   children: []
      // },
      {
        id: 3,
        name: 'menu.rates_dashboard',
        path: '/rates',
        customComponent: MenuLeaf
      },
      {
        id: 4,
        name: 'menu.rates_price_list',
        path: '/price_lists',
        customComponent: MenuLeaf
      },
      {
        id: 5,
        name: 'menu.rates_mappings',
        path: '/sogaz/mappings',
        customComponent: MenuLeaf
      }
    ]
  },
  // {
  //   id: 5,
  //   name: 'menu.invoices',
  //   path: '/invoices',
  //   isOpen: false,
  //   customComponent: MenuNode,
  //   children: []
  // },
  {
    id: 6,
    name: 'menu.documentation',
    path: '/docs',
    isOpen: false,
    customComponent: ExternalLink,
    children: []
  }
];
