import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';
import Sticky from 'react-stickynode';

import DropForm from './DropForm';
import InfoLoader from './InfoLoader';
import { Scrollbars } from 'react-custom-scrollbars';

@inject('hotelStore')
@observer
class Add extends Component {
  render() {
    const { hotel } = this.props.hotelStore;

    return (
      <div className='page__info wide'>
        <div className='page__info-wrapper'>
          <Scrollbars>
            {hotel && <DropForm />}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Add;
