import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import getStatus from 'Pages/Orders/utils/getStatus';

@inject('orderStore')
@observer
class Header extends Component {
  render() {
    const { order } = this.props.orderStore;

    return (
      <div className='header'>
        <FormattedMessage
          id='orders.order_number'
          values={{ code: order.order_code }}
        />

        <span className={classNames('order-status', getStatus(order.state).label)}>
          {getStatus(order.state).text}
        </span>
      </div>
    );
  }
}

export default Header;
