import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class TariffDescriptionBlock extends Component {
  render() {
    const { description } = this.props.tariff;

    return (
      <div className='row'>
        <div className='col col-12'>
          <div className='options'>
            <div className='options__item'>
              <span className='key'>{formatMessage('tariff.description')}</span>
              <p className='value'>
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TariffDescriptionBlock.propTypes = {
  tariff: PropTypes.object.isRequired
};

export default TariffDescriptionBlock;
