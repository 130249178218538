import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import SingleSelect from 'Shared/form/SingleSelect';

@observer
class Traveller extends Component {
  componentDidMount() {
    this.ageGroupHandler = autorun(() => {
      const { slot } = this.props;
      const ageGroup = slot.$('rate.age_group').value;

      if (ageGroup) {
        this.updateTravellersAgeGroup(ageGroup);
      }
    });
  }

  componentWillUnmount() {
    this.ageGroupHandler();
  }

  updateTravellersAgeGroup = (ageGroup) => {
    const { traveller } = this.props;
    traveller.update({ age_group: ageGroup });
  }

  render() {
    const { traveller } = this.props;

    return (
      <div className='form__fields-group'>
        <div className='form__fields'>
          <FormItemInput field={traveller.$('last_name')} className='grow' />
          <FormItemInput field={traveller.$('first_name')} className='w50' />
        </div>

        <div className='form__fields'>
          <FormItemInput field={traveller.$('middle_name')} className='grow' />
          <SingleSelect field={traveller.$('gender')} className='w50' />
        </div>
      </div>
    );
  }
}

Traveller.propTypes = {
  slot: PropTypes.object.isRequired,
  traveller: PropTypes.object.isRequired
};

export default Traveller;
