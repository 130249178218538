import { types } from 'mobx-state-tree';
import { Translation } from './Translation';

export const TreatmentProfiles = types.model('TreatmentProfiles', {
  id: types.string,
  name: types.optional(types.string, ''),
  tag: types.string,
  name_translations: types.optional(Translation, {}),
  short_name: types.string
});
