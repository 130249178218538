import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FormItemInput from 'Shared/form/FormItemInput';
import SingleDateInput from 'Shared/form/SingleDateInput';
import moment from 'Utils/moment';

@observer
class Form extends Component {
  leftBound = moment().subtract(5, 'years')

  rightBound = moment().add(1, 'years')

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) ||
      this.rightBound.isBefore(day);
  }

  render() {
    const { form } = this.props;

    return (
      <form className='form'>
        <div className='form__body'>
          <div className='row form__fields-wrapper'>
            <div className='col col-5'>
              <FormItemInput field={form.$('doc_number')} />

              <SingleDateInput
                date={form.$('doc_date')}
                customValidation
                enableOutsideDays
                isOutsideRange={this.isOutsideRange}
              />
            </div>

            {form.has('source') &&
              <div className='col col-6 offset-1'>
                <p>
                  <br />
                  <FormattedMessage id='price_lists.tips' /> <b>{form.$('source.name').value}</b>
                </p>
              </div>}
          </div>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
