import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class CalculationRuleTips extends Component {
  render() {
    return (
      <div className='form__field price-calculation-rule'>
        <div className='price-calculation-rule__tips'>
          <FormattedMessage id='tariffs.price_calculation_rules.example' />
        </div>

        <div className='price-calculation-rule__tips'>
          <FormattedMessage id='tariffs.price_calculation_rules.base_tips' />
        </div>

        <div className='price-calculation-rule__tips'>
          <FormattedMessage id='tariffs.price_calculation_rules.fixed_tips' />
        </div>

        <div className='price-calculation-rule__tips'>
          <FormattedMessage id='tariffs.price_calculation_rules.float_tips' />
        </div>
      </div>
    );
  }
}

export default CalculationRuleTips;
