import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import moment from 'Utils/moment';

@observer
class TitleWithTraveller extends Component {
  render() {
    const { rate, contract, travellers } = this.props.slot;

    const { hasDocument, hasDateOfBirth, travellerName, dateOfBirth } = travellers.reduce((acc, traveller) => ({
      ...acc,
      hasDocument: !!traveller?.document,
      hasDateOfBirth: !!traveller?.date_of_birth,
      dateOfBirth: `${moment(traveller?.date_of_birth)?.format('DD MMM YYYY')} г.`,
      travellerName: [traveller?.last_name, traveller?.first_name, traveller?.middle_name].join(' ')
    }), {
      hasDocument: false,
      hasDateOfBirth: false,
      dateOfBirth: '',
      travellerName: ''
    });

    const showDateOfBirth = !hasDocument && hasDateOfBirth;

    return (
      <div className="policies__column title">
        <div className="person">
          <span className="name">{travellerName}</span>
          {showDateOfBirth && <span>{dateOfBirth}</span>}

          <span className="rate">{rate.description}</span>

          {contract && <span className="gray">{contract.insured_name}</span>}
        </div>
      </div>
    );
  }
}

TitleWithTraveller.propTypes = {
  slot: PropTypes.object.isRequired
};

export default TitleWithTraveller;
