import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Tree, { TreeNode } from 'rc-tree';
import _orderBy from 'lodash/orderBy';
import store from 'store';

@observer
class AmenityTree extends Component {
  render() {
    const { amenities } = this.props;
    const generalLanguage = store.get('language');
    const loop = (data) => {
      return _orderBy(data, 'name', 'asc').map((item) => {
        return item.children
          ? <TreeNode
              key={item.tag}
              title={item.name_translations[generalLanguage]}
              className='rc_checkbox__field'
            >{loop(item.children)}
          </TreeNode>

          : <TreeNode key={item.tag} title={item.name_translations[generalLanguage]} />;
      });
    };

    const treeNodes = loop(amenities);
    const checkedKeys = amenities.map(amenity => amenity.tag);

    return (
      <div className='form'>
        <Tree
          selectable={false}
          checkedKeys={checkedKeys}
          defaultExpandAll
          showIcon={false}
        >
          {treeNodes}
        </Tree>
      </div>
    );
  }
}

AmenityTree.propTypes = {
  amenities: PropTypes.array.isRequired
};

export default AmenityTree;
