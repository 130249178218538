import { types } from "mobx-state-tree";
import { Translation } from "./Translation";

export const CardOption = types.model("CardOption", {
  id: types.maybe(types.string),
  name: types.maybe(types.string),
  name_translations: types.optional(Translation, {}),
  tag: types.maybe(types.string),
  priority: types.maybe(types.number),
});
