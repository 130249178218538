import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import _remove from "lodash/remove";

import { Description } from "./AdvanceBookingRestrictions.styled";
import Button from "Components/ui/Button";
import AdvanceBookingRestrictionForm from "./AdvanceBookingRestrictionForm";
import AdvanceBookingRestrictionsList from "./AdvanceBookingRestrictionsList";

@observer
class FormAdvanceBookingRestrictions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }

  handleAddPeriod = () => {
    this.setState({ isOpened: "newForm" });
  };

  handleClose = () => {
    this.setState({ isOpened: false });
  };

  handleEditOpen = (index) => {
    this.setState({ isOpened: index + 1 });
  };

  handleDeleteRestrictionPeriod = (index) => {
    const { form } = this.props;
    const arr = Array.from(form.$("advance_booking_restrictions").values());
    _remove(arr, (period, perIndex) => perIndex === index);
    form.update({ advance_booking_restrictions: [...arr] });
  };

  render() {
    const { form } = this.props;
    const { isOpened } = this.state;
    const advanceBookingRestrictionsValues = Array.from(
      form.$("advance_booking_restrictions").values()
    );

    return (
      <form className="form">
        <Description>
          <FormattedMessage id="tariff.advance_booking_restrictions.description" />
        </Description>

        <AdvanceBookingRestrictionsList
          data={advanceBookingRestrictionsValues}
          form={form}
          onClose={this.handleClose}
          onEdit={this.handleEditOpen}
          onDelete={this.handleDeleteRestrictionPeriod}
          isOpened={isOpened}
        />

        {isOpened === "newForm" && (
          <div className="row">
            <AdvanceBookingRestrictionForm form={form} onClose={this.handleClose} />
          </div>
        )}

        {isOpened !== "newForm" && (
          <div className={"row"}>
            <Button onClick={this.handleAddPeriod}>
              <FormattedMessage id="periods.add" />
            </Button>
          </div>
        )}
      </form>
    );
  }
}

FormAdvanceBookingRestrictions.propTypes = {
  form: PropTypes.object.isRequired,
};

export default FormAdvanceBookingRestrictions;
