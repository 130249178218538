import { types } from 'mobx-state-tree';

export const Settings = types.model('Settings', {
  should_be_confirmed: types.boolean
});

export const Source = types.model('Source', {
  id: types.identifier(types.string),
  name: types.string,
  settings: types.maybe(Settings)
});
