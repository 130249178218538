import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Categories from './Categories';

@inject('servicesStore')
@observer
class Services extends Component {
  componentDidMount() {
    this.props.servicesStore.fetch();
  }

  render() {
    const { servicesStore } = this.props;

    return (
      <div className='page__body'>
        <Categories servicesStore={servicesStore} />
      </div>
    );
  }
}

export default Services;
