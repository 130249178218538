import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'start_date',
  'end_date',
  'source',
  'source.id',
  'source.name',
  'status',
  'doc_number',
  'doc_date',
  'tariffs[]',
  'tariffs[].id',
  'tariffs[].name'
];

const labels = {
  doc_number: formatMessage('price_list.doc_number'),
  doc_date: formatMessage('price_list.doc_date')
};

const placeholders = {};

const rules = {
  doc_number: 'string|required',
  doc_date: 'string|required',
  tariffs: 'array|required|min:1',
  'tariffs[].id': 'string|required',
  'tariffs[].name': 'string|required',
  'source.id': 'string|required',
  'source.name': 'string|required'
};

const extra = {};

const values = {};

export default {
  fields,
  labels,
  extra,
  placeholders,
  rules,
  values
};
