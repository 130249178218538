import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import MdExpandMore from 'react-icons/lib/md/expand-more';

@observer
class Header extends Component {
  @computed get subTitle() {
    const { slot } = this.props;

    if (slot.has('rate') && slot.$('rate').has('description')) { return slot.$('rate.description').value; }

    if (slot.$('type').value == 'main') { return 'Основное место'; }

    if (slot.$('type').value == 'extra') { return 'Дополнительное место'; }

    return null;
  }

  @computed get title() {
    const { number, slot } = this.props;

    const traveller = slot.$('travellers').value
      .filter(t => t.first_name && t.last_name)
      .map(t => [t.last_name, t.first_name].join(' '));

    if (traveller.length) return traveller;

    return `Гость ${number}`;
  }

  render() {
    const { slot } = this.props;

    return (
      <div className='Collapsible__header'>
        {this.title}

        <div className='sub-header'>
          {this.subTitle}
        </div>

        <i className='icon'>
          <MdExpandMore />
        </i>
      </div>
    );
  }
}

Header.propTypes = {
  number: PropTypes.number.isRequired,
  slot: PropTypes.object.isRequired
};

export default Header;
