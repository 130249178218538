import { types, applySnapshot, resolveIdentifier, detach } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Hotel } from './Hotel';
import { Tariff } from './Tariff';
import { RoomType } from './RoomType';
import { Room } from './Room';

const HotelAvailabilitiesStore = types.model('HotelAvailabilitiesStore', {
  hotel: types.maybe(Hotel),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  }

}, {
  fetch(values) {
    this.setState('pending');

    const options = { params: { hotel: values } };

    return instance.get('/api/hotel/get_availabilities', options)
      .then(response => this.resetStore(response))
      .then(response => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { hotel: null };
    applySnapshot(this, data);
    detach(this);
  },

  resetStore(response) {
    const { data } = response;

    applySnapshot(this, data);
    return this;
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');

    return Promise.reject(error);
  },

  findTariff(tariff_id) {
    return resolveIdentifier(Tariff, this, tariff_id);
  },

  findRoomType(room_type_id) {
    return resolveIdentifier(RoomType, this, room_type_id);
  },

  findRoom(room_id) {
    return resolveIdentifier(Room, this, room_id);
  }
});

export default HotelAvailabilitiesStore;
