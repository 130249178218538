import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router-dom';

import Edit from './Edit';
import Show from './Show';
import PriceListState, { fields } from 'Pages/PriceList/components/PriceListState';

@withRouter
@inject('store')
@observer
class PriceList extends Component {
  @observable form = new PriceListState(fields);

  @observable isEditMode = false;

  componentDidMount() {
    const { price_list } = this.props.priceListStore;

    this.form.update(price_list.toForm());
  }

  toggleEdit() {
    this.isEditMode = !this.isEditMode;
  }

  onEditHandler = (e) => {
    e.preventDefault();
    this.isEditMode = true;
  }

  onCancelHandler = (e) => {
    e.preventDefault();
    this.isEditMode = false;
  }

  onConfirmHandler = (e) => {
    e.preventDefault();

    this.form.onSubmit(e, {
      onSuccess: this.onSuccessHandler,
      onError: this.onErrorHandler
    });
  }

  onErrorHandler = (form) => {
    console.log(form.errors());
  }

  onSuccessHandler = (form) => {
    const { priceListStore } = this.props;
    const values = form.values();

    priceListStore.update(values)
      .then(_response => this.toggleEdit())
      .catch(error => this.errorSubmitHandler(error));
  }

  errorSubmitHandler = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  downloadHandler = (e) => {
    e.preventDefault();

    const { priceListStore } = this.props;

    priceListStore.download()
      .catch(error => this.errorSubmitHandler(error));
  }

  destroyHandler = (e) => {
    e.preventDefault();

    const { priceListStore } = this.props;

    priceListStore.destroy()
      .then(response => this.navigateTo('/price_lists'))
      .catch(this.errorSubmitHandler);
  }

  navigateTo = (path) => {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    const { price_list } = this.props.priceListStore;

    return (
      <div className='groups__item'>
        <div className='groups__item-content'>
          <div className='groups__item-blocks'>
            {!this.isEditMode && price_list &&
              <Show
                price_list={price_list}
                onEditHandler={this.onEditHandler}
                downloadHandler={this.downloadHandler}
                destroyHandler={this.destroyHandler}
              />}

            {this.isEditMode && <Edit
              form={this.form}
              onCancelHandler={this.onCancelHandler}
              onConfirmHandler={this.onConfirmHandler}
                                />}
          </div>
        </div>
      </div>
    );
  }
}

PriceList.defaultProps = {
  priceListStore: PropTypes.object.isRequired
};

export default PriceList;
