import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Tree, { TreeNode } from 'rc-tree';

import store from 'store';

@observer
class FeedTypeTree extends Component {
  render() {
    const feedTypes = this.props.hotel.feed_types;
    const generalLanguage = store.get('language');

    const loop = (data) => {
      return data.map((item, index) => {
        return (
          <div
            key={item.id}
            className={index + 1 !== feedTypes.length ? 'radio_list_item' : ''}
          >
            {item.name_translations[generalLanguage]}
          </div>
        );
      });
    };

    const treeNodes = loop(feedTypes);

    return (
      <div className='form'>
        {treeNodes}
      </div>
    );
  }
}

FeedTypeTree.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default FeedTypeTree;
