import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import NumberFormat from 'react-number-format';

@observer
class FormattedInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, values) {
    const { value } = values;
    this.props.field.set(value);
  }

  render() {
    const {
      field,
      type,
      placeholder,
      label,
      className,
      showLabel,
      allowNegative,
      decimalPrecision,
      ...restProps
    } = this.props;

    const inputCls = classNames({
      error: field.error && !field.focused
    });

    return (
      <div className={className}>

        {showLabel && <label htmlFor={field.id}>
          {label || field.label}
          <span className='error'>{field.error && '*'}</span>
        </label>}

        <NumberFormat
          {...field.bind(type, placeholder)}
          type={type}
          value={field.value.amount}
          placeholder={field.placeholder}
          className={inputCls}
          onChange={this.onChange}
          allowNegative={false}
          decimalPrecision={2}
          {...restProps}
        />
      </div>
    );
  }
}

FormattedInputField.defaultProps = {
  placeholder: null,
  type: 'text',
  className: 'form__field',
  showLabel: true
};

FormattedInputField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalPrecision: PropTypes.number
};

export default FormattedInputField;
