import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import Price from './Price';

@observer
class Value extends Component {
  render() {
    const { value, value: { id, price } } = this.props;

    return (
      <div className='rate__value'>
        {price && (
          <Price
            selectableKey={id}
            item={value}
          />
        )}
      </div>
    );
  }
}

Value.propTypes = {
  value: PropTypes.object.isRequired
};

export default Value;
