import React from 'react';
import PropTypes from 'prop-types';

import {
  SliderLongMark,
  SliderMarkContainer,
  SelectedValue,
  MarkValue
} from 'Styles/styled';

const LongMarkSlider = ({ ...props }) => {
  const { count, value } = props;

  return (
    <SliderMarkContainer>
      <SliderLongMark />

      {count === value && (
        <SelectedValue size='long'>
          {count}
        </SelectedValue>
      )}

      {count !== value && (
        <MarkValue>
          {count < 10 ? `0${count}` : count}
        </MarkValue>
      )}
    </SliderMarkContainer>
  );
};

LongMarkSlider.propTypes = {
  count: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired
};

export default LongMarkSlider;
