import { types } from 'mobx-state-tree';

import { Service } from './Service';
import { Rate } from './Rate';

export const Tariff = types.model('Tariff', {
  id: types.identifier(types.string),
  name: types.string,
  description: types.string,
  services: types.optional(types.array(Service), []),
  rates: types.optional(types.array(Rate), []),
  type: types.maybe(types.enumeration(['base', 'tour', 'package']))
});
