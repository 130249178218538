import { css } from 'styled-components';

const theme = css`
  display: flex-inline;
  align-self: center;
  width: 1em;
  height: 1em;
`;

export default theme;
