import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { computed } from 'mobx';
import { formatMessage } from 'Utils/formatMessage';

import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 150px;
`;

class Status extends Component {
  handleChange = ({ label, value }) => {
    const { onChange } = this.props;
    onChange('state', label, value);
  }

  @computed get selectOptions() {
    const { state } = this.props;
    return state.extra || [];
  }

  render() {
    return (
      <Wrapper>
        <Select
          className=''
          placeholder={formatMessage('orders.status')}
          options={this.selectOptions}
          showLabel={false}
          clearable={false}
          onChange={this.handleChange}
          arrowRenderer={() => null}
        />
      </Wrapper>
    );
  }
}

Status.propTypes = {
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Status;
