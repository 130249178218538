import MobxReactForm from 'mobx-react-form';
import validatorjs from './validator';

class FormState extends MobxReactForm {
  options() {
    return {
      validateOnInit: false,
      showErrorsOnReset: false,
      showErrorsOnClear: false
    };
  }

  plugins() {
    return {
      dvr: validatorjs
    };
  }
}

export default FormState;
