import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import InfinityMenu from '../InfinityMenu';
import { Scrollbars } from 'react-custom-scrollbars';

import { tree } from './tree';

class MenuContent extends Component {
  state = { tree: tree }

  onNodeMouseClick = (event, tree) => {
    this.setState({ tree: tree });
  }

  render() {
    return (
      <div className='layout__menu-content'>
        <Scrollbars>
          <InfinityMenu
            tree={this.state.tree}
            disableDefaultHeaderContent
            onNodeMouseClick={this.onNodeMouseClick}
          />
        </Scrollbars>
      </div>
    );
  }
}

export default MenuContent;
