import { types, resolveIdentifier, getParent } from 'mobx-state-tree';

import { Tariff } from './Tariff';
import { RoomType } from './RoomType';
import { Room } from './Room';
import { Price } from './Price';

export const Availability = types.model('Availability', {
  id: types.identifier(types.string),
  name: types.string,
  description: types.maybe(types.string),
  min_price: Price,
  multi_seat: types.boolean,
  tariff: types.reference(Tariff),
  room_type: types.reference(RoomType),
  rooms: types.array(types.reference(Room)),
  selected: types.optional(types.boolean, false),

  hotel() {
    return getParent(self, 2);
  },

  capacity() {
    return self.rooms
      .filter(room => room.isFree)
      .reduce((capacity, room) => {
        capacity.rooms_count++;
        capacity.beds_count += room.capacity.totalCount;

        return capacity;
      }, { rooms_count: 0, beds_count: 0 });
  }
}, {
  select() {
    self.selected = true;
  },

  deselect() {
    self.selected = false;
  },

  toggle() {
    self.selected ? self.deselect() : self.select();
  },

  findRoom(room_id) {
    return resolveIdentifier(Room, self.rooms, room_id);
  },

  getRoom() {
    return self.rooms
      .sort((p, n) => {
        if (p.travellersCount > n.travellersCount) return 1;
        if (p.travellersCount < n.travellersCount) return -1;
        return 0;
      })
      .find(room => room.isFree);
  },

  busyRoom(room_id) {
    const room = self.findRoom(room_id);
    room.makeBusy();

    return room;
  },

  freeRoom(room_id) {
    const room = self.findRoom(room_id);
    room.makeFree();

    return room;
  }
});
