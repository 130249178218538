import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import Bed from './Bed';

@observer
class Room extends Component {
  @computed get mainBeds() {
    const { beds } = this.props.room;
    const { beds_quantity } = this.props;

    const data = beds
      .filter(bed => bed.type === 'main')
      .sort((a, b) => a.number - b.number);

    for (let i = data.length; i < beds_quantity; i++) {
      const emptyBed = Object.assign(
        {}, data[i - 1], { number: i + 1, events: [] });

      data.push(emptyBed);
    }

    return data;
  }

  @computed get extraBeds() {
    const { beds } = this.props.room;

    const data = beds
      .filter(bed => bed.type === 'extra')
      .sort((a, b) => a.number - b.number);

    return data;
  }

  render() {
    const { number } = this.props.room;

    const mainItems = this.mainBeds
      .map(bed => <Bed key={[bed.number, bed.type].join()} bed={bed} />);

    const extraItems = this.extraBeds
      .map(bed => <Bed key={[bed.number, bed.type].join()} bed={bed} />);

    return (
      <div className='item__room'>
        <div className='room'>
          <span className='number'>
            № {number}
          </span>
        </div>
        <div className='beds'>
          {[mainItems, extraItems]}
        </div>
      </div>
    );
  }
}

Room.propTypes = {
  room: PropTypes.object.isRequired,
  beds_quantity: PropTypes.number.isRequired
};

export default Room;
