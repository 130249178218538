import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as BackBlackIcon } from 'Icons/BackBlackIcon.svg';
import { ButtonContent } from './TopBar.styled';

export default class TopBarContent extends Component {
  render() {
    return (
      <div className='layout__topbar-content'>
        <div className='layout__topbar-content-wrapper'>
          <div className='search-box'>
            <Link
              to='/room_types'
              className='button gray'
            > <ButtonContent>
              <BackBlackIcon /> <FormattedMessage id='room_types.self' />
              </ButtonContent>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
