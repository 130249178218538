import styled from 'styled-components';

export const AmenitiesSegment = styled.div`
  position: relative;
  width: inherit;
  height: 680px;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

export const GroupsItemContent = styled.div`
  border: 1px solid #DCD8D2;
  background-color: #FFF;
  border-radius: 4px;
`;

export const AmenitiesTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
`;

export const AmenitiesGroupsItem = styled.div`
  margin-left: 34px;
  margin-bottom: 38px;
`;
