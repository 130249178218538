import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formatMessage } from 'Utils/formatMessage';
import styled from 'styled-components';

import TariffInfoBlock from './TariffInfoBlock';
import TariffContentActions from './TariffContentActions';
import TariffSeasonsBlock from './TariffSeasonsBlock';
import TariffDescriptionBlock from './TariffDescriptionBlock';
import TariffDateTimeAndRatesBlock from './TariffDateTimeAndRatesBlock';
import TariffCancellationBlock from './TariffCancellationBlock';
import TariffRulesBlock from './TariffRulesBlock';
import TariffStayPeriodBlock from './TariffStayPeriodBlock';
// import TariffReleaseBlock from './TariffReleaseBlock';
import TariffFeedTypeAndMealOptionBlock from './TariffFeedTypeAndMealOptionBlock';
import Manipulation from './Manipulation';
import Service from './Service';
import Promo from './Promo';
import StopSale from './TariffStopSales';
import TariffContentHeader from './TariffContentHeader';
import TariffLengthStayRestrictions from './TariffLengthStayRestrictions';
import TariffAdvanceBookingRestrictions from './TariffAdvanceBookingRestrictions';
import TariffLengthStayArrivalBasedRestrictions from './TariffLengthStayArrivalBasedRestrictions';
import TariffClosedByDateRestrictions from './TariffClosedByDateRestrictions';
// import TariffClosedByWeekdayRestrictions from './TariffClosedByWeekdayRestrictions'

const Wrapper = styled.div``;

// TODO hide restriction by weekday
// TODO hide TariffReleaseBlock
function TariffContent({ tariff, tariffsList, removeTariff, fetchTariffs }) {
  const getStatus = () => {
    const { status } = tariff;

    switch (status) {
      case 'created':
        return {
          label: 'status booked',
          text: formatMessage('tariffs.statuses.created')
        };

      case 'uploaded':
        return {
          label: 'status draft',
          text: formatMessage('tariffs.statuses.uploaded')
        };

      case 'confirmed':
        return {
          label: 'status confirmed',
          text: formatMessage('tariffs.statuses.confirmed')
        };

      default:
        return {
          label: 'status',
          text: formatMessage('tariffs.statuses.unknown')
        };
    }
  };

  const getLabels = () => {
    const tariff_type = tariff.type === 'base' ? 'tariff' : tariff.type;

    const periodStr = [tariff_type, 'available_period'].join('.');
    const rateStr = [tariff_type, 'prices'].join('.');
    const seasonStr = [tariff_type, 'periods'].join('.');
    const typeStr = [tariff_type, 'type_name'].join('.');
    const stayPeriodStr = [tariff_type, 'stay_period'].join('.');
    const cancellationStr = [tariff_type, 'cancellation_policy'].join('.');

    return {
      available_period: formatMessage(periodStr),
      rates: formatMessage(rateStr, { billing_hour: tariff.billing_hour }),
      seasons: formatMessage(seasonStr, { billing_hour: tariff.billing_hour }),
      status: getStatus(),
      type: formatMessage(typeStr),
      stay_period: formatMessage(stayPeriodStr),
      cancellation_policy: formatMessage(cancellationStr),
      price_calculation_rule: formatMessage('tariffs.price_calculation_rules.self'),
      services_included: formatMessage('tariff.services_included'),
      release_period: formatMessage('tariff.release_period'),
      feed_type_and_meal_option: formatMessage('tariff.feed_type_and_meal_option'),
      stop_sales: formatMessage('tariff.stop_sales.title'),
      length_stay_restrictions: formatMessage('tariff.length_stay_restrictions.title'),
      advance_booking_restrictions: formatMessage('tariff.advance_booking_restrictions.title'),
      length_of_stay_arrival_based_restrictions: formatMessage('tariff.length_of_stay_arrival_based_restrictions.title'),
      closed_by_date_restrictions: formatMessage('tariff.closed_by_date_restrictions.title'),
      closed_by_weekday_restrictions: formatMessage('tariff.closed_by_weekday_restrictions.title')
    };
  };

  const labels = useMemo(() => getLabels(), [tariff]);

  return (
    <Wrapper className='content'>
      <TariffContentHeader tariff={tariff} tariffsList={tariffsList} />

      <div className='description'>
        <TariffInfoBlock tariff={tariff} labels={labels} />

        <TariffDescriptionBlock tariff={tariff} />

        <TariffDateTimeAndRatesBlock tariff={tariff} labels={labels} />

        <StopSale tariff={tariff} labels={labels} />

        <TariffLengthStayRestrictions tariff={tariff} labels={labels} />

        <TariffAdvanceBookingRestrictions tariff={tariff} labels={labels} />

        <TariffLengthStayArrivalBasedRestrictions tariff={tariff} labels={labels} />

        <TariffClosedByDateRestrictions tariff={tariff} labels={labels} />

        {/* <TariffClosedByWeekdayRestrictions tariff={tariff} labels={labels} /> */}

        <TariffSeasonsBlock tariff={tariff} labels={labels} />

        <TariffStayPeriodBlock tariff={tariff} labels={labels} />

        <TariffRulesBlock tariff={tariff} labels={labels} />

        {/* <TariffReleaseBlock tariff={tariff} labels={labels} /> */}

        <TariffCancellationBlock tariff={tariff} labels={labels} />

        <TariffFeedTypeAndMealOptionBlock
          tariff={tariff}
          labels={labels}
        />

        <Manipulation tariff={tariff} />

        <Service tariff={tariff} />

        <Promo tariff={tariff} />
      </div>

      <TariffContentActions tariff={tariff} removeTariff={removeTariff} fetchTariffs={fetchTariffs} />
    </Wrapper>
  );
}

TariffContent.propTypes = {
  removeTariff: PropTypes.func,
  tariff: PropTypes.object.isRequired,
  tariffsList: PropTypes.array.isRequired,
  fetchTariffs: PropTypes.func
};

export default withRouter(TariffContent);
