import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';

import Header from './Header';
import Labels from './Labels';
import Grid from './Grid';
import Rows from './Rows';

@inject('priceboardStore')
@observer
class Priceboard extends Component {
  constructor(props) {
    super(props);

    this.leftBtnHandler = this.leftBtnHandler.bind(this);
    this.rightBtnHandler = this.rightBtnHandler.bind(this);

    this.handleContentScroll = this.handleContentScroll.bind(this);
  }

  componentDidMount() {
    this.setRowsSize();
  }

  setRowsSize() {
    const { width } = this.gridEl.getBoundingClientRect();
    this.rowsEl.style.width = width + 'px';
  }

  handleContentScroll(e) {
    if (this.contentEl) {
      const values = this.contentEl.getValues();
      this.setHeaderPosition(values);
      this.setLablePosition(values);
    }
  }

  leftBtnHandler(e) {
    e.preventDefault();

    const { scrollStep } = this.props.priceboardStore;
    const { scrollLeft } = this.contentEl.getValues();
    this.contentEl.scrollLeft(scrollLeft - scrollStep);
  }

  rightBtnHandler(e) {
    e.preventDefault();

    const { scrollStep } = this.props.priceboardStore;
    const { scrollLeft } = this.contentEl.getValues();
    this.contentEl.scrollLeft(scrollLeft + scrollStep);
  }

  setHeaderPosition(values) {
    if (this.headerEl) {
      const { scrollLeft } = values;
      this.headerEl.style.left = -scrollLeft + 'px';
    }
  }

  setLablePosition(values) {
    if (this.labelEl) {
      const { scrollTop } = values;
      this.labelEl.style.top = -scrollTop + 'px';
    }
  }

  render() {
    const { grid, room_types } = this.props.priceboardStore;

    return (
      <div className='priceboard'>
        <div className='priceboard__header'>
          <div className='ctrl left'>
            <a href='' onClick={this.leftBtnHandler}>&lsaquo;</a>
          </div>
          <div className='ctrl right'>
            <a href='' onClick={this.rightBtnHandler}>&rsaquo;</a>
          </div>

          <div className='priceboard__header--wrapper'>
            <div
              className='priceboard__header--grid'
              ref={(node) => { this.headerEl = node; }}
            >
              <Header />
            </div>
          </div>
        </div>

        <div className='priceboard__label'>
          <div
            className='priceboard__label--wrapper'
            ref={(node) => { this.labelEl = node; }}
          ><Labels />
          </div>
        </div>

        <div className='priceboard__content'>
          <Scrollbars
            ref={(node) => { this.contentEl = node; }}
            onScroll={this.handleContentScroll}
          >
            <div
              className='rows'
              ref={(node) => { this.rowsEl = node; }}
            >
              <div
                className='grid'
                ref={(node) => { this.gridEl = node; }}
              >
                <Grid />
              </div>

              <Rows />
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Priceboard;
