import moment from 'Utils/moment';
import { types } from 'mobx-state-tree';

export const TariffStopSalesPeriod = types.model('TariffStopSalesPeriod', {
  from: types.maybe(types.string),
  to: types.maybe(types.string),

  get fromDate() {
    return this.from
      ? moment(this.from).format('DD.MM.YYYY')
      : 'не задан';
  },

  get toDate() {
    return this.to
      ? moment(this.to).format('DD.MM.YYYY')
      : 'не задан';
  }
});
