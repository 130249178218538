import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StyledPopover,
  MenuItem,
  Control
} from './Popover.styled';

function Popover({ className, items, children, onChange, ...rest }) {
  const [isOpened, setIsOpened] = useState();

  const handleToggleOpened = () => {
    setIsOpened(!isOpened);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  const popoverItems = items.map((item, index) => {
    return (
      <MenuItem
        key={index}
        {...item}
      >
        {item.label}
      </MenuItem>
    );
  });

  return (
    <StyledPopover
      className={className}
      content={popoverItems}
      isOpen={isOpened}
      positions={['bottom', 'top', 'left', 'right']}
      reposition
      align='end'
      onClickOutside={handleClose}
      padding={4}
      {...rest}
    >
      <Control
        outline
        onClick={handleToggleOpened}
      >
        {children}
      </Control>
    </StyledPopover>
  );
}

Popover.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default styled(Popover)``;
