import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageContent from './components/PageContent';

import RoomTypeStore from 'Stores/RoomTypesStore/One';
import FormState, { fields } from 'Pages/RoomTypes/components/FormState';
import { UserProvider } from 'Context/FormLanguageContext';
import { LayoutPageContent } from './index.styled';
import Actions from './components/Actions';
import PropTypes from 'prop-types';

@withRouter
@observer
class Edit extends Component {
  @observable form = new FormState(fields);

  state = {
    language: 'ru'
  };

  @computed get room_type() {
    const { roomTypeStore } = this.props;
    if (!roomTypeStore.isFetched) return undefined;
    return roomTypeStore.room_type;
  }

  componentDidMount() {
    const { roomTypeStore, match } = this.props;
    const { id } = match.params;
    roomTypeStore.fetch(id);
  }

  componentDidUpdate() {
    if (this.room_type) {
      this.form.update(this.room_type.toForm());
    }
  }

  onClickHandler = (language) => {
    if (this.state.language === language) return;
    this.setState({ language: language });
  }

  render() {
    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className='layout__page'>
          <div className='layout__page-form-controls'>
            <TopBar>
              <TopBarContent />
            </TopBar>
          </div>

          <LayoutPageContent>
            <div className='page__body'>
              {this.room_type &&
                <Provider room_type={this.room_type}>
                  <PageContent room_type={this.room_type} form={this.form} />
                </Provider>}
            </div>
          </LayoutPageContent>
          <Actions form={this.form} room_type={this.room_type} type='edit' />
        </div>
      </UserProvider>
    );
  }
}

Edit.defaultProps = {
  roomTypeStore: RoomTypeStore.create()
};

Edit.propTypes = {
  roomTypeStore: PropTypes.object,
  match: PropTypes.object
};

export default Edit;
