import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import DateRangeInput from 'Shared/form/DateRangeInput';

@observer
class Dates extends Component {
  render() {
    const { form } = this.props;

    return (
      <DateRangeInput
        startDate={form.$('check_in')}
        endDate={form.$('check_out')}
      />
    );
  }
}

Dates.propTypes = {
  form: PropTypes.object.isRequired
};

export default Dates;
