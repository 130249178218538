import styled from 'styled-components';

import { FilterPicker } from '.';
import Close from 'react-icons/lib/md/close';

export const FilterInput = styled.div`
  position: relative;
  margin: 4px 28px 4px 4px;
`;

export const StyledCloseFilterIcon = styled(Close)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledFilterPicker = styled(FilterPicker)`
  margin: 4px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: calc(100% - 8px);
  margin-left: auto;
  padding: 0 8px;
  border-left: 1px solid silver;
`;

export const StyledCloseAllFilterIcon = styled(Close)`
  width: 100%;
  height: 100%;
  fill: currentColor;
`;
