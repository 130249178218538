import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { dateFormat } from 'Utils/dateFormat';
import { FormattedMessage } from 'react-intl';

@observer
class Quota extends Component {
  render() {
    const { start_date, end_date, quantity } = this.props.quota;

    return (
      <div className='quotas__item'>
        <div className='content'>
          <div className='quota__dates'>
            {dateFormat(start_date, 'L')} &ndash; {dateFormat(end_date, 'L')}
          </div>

          <div className='quota__quantity'>
            <span className='count'>
              <FormattedMessage
                id='quotas.rooms_count'
                values={{ count: quantity }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Quota.propTypes = {
  quota: PropTypes.object.isRequired
};

export default Quota;
