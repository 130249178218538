import React from 'react';
import PropTypes from 'prop-types';

import RoomTypes from '../RoomTypes';

function PageBody({ roomTypes, fetchTariffs }) {
  return (
    <div className='page__body wide'>
      <RoomTypes room_types={roomTypes} fetchTariffs={fetchTariffs} />
    </div>
  );
}

PageBody.propTypes = {
  roomTypes: PropTypes.array.isRequired,
  fetchTariffs: PropTypes.func
};

export default PageBody;
