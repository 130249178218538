import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import FormItemText from 'Shared/form/FormItemText';

import Map from '../Map';

@inject('form')
@observer
class Form extends Component {
  render() {
    const { form, currentLanguage } = this.props;

    return (
      <form className='form'>
        <div className='form__body'>
          <FormItemInput field={form.$(`name_translations.${currentLanguage}`)} isLocalizeField />
          <FormItemText field={form.$(`description_translations.${currentLanguage}`)} isLocalizeField size='medium' />
          <FormItemInput field={form.$(`address.location_translations.${currentLanguage}`)} isLocalizeField />
          <Map field={form.$('address')} />
        </div>
      </form>
    );
  }
}

export default Form;
