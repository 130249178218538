import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CustomCheckbox from '../CustomCheckbox';

const Wrapper = styled.div`
  max-width: 350px;
`;

const Rooms = observer(({ field, setAllRooms }) => {
  const handleChange = (e) => {
    field.$('selected').value = e.target.checked;
    setAllRooms({ allRooms: false });
  };

  return (
    <Wrapper>
      <div className="fields">
        <div className="field">
          <CustomCheckbox
            onChange={handleChange}
            id={field.$('selected').id}
            label={field.$('name').value}
            checked={field.$('selected').value}
          />
        </div>
        <div className="field">
          <input className="input" {...field.$('quantity').bind()} />
        </div>
      </div>
    </Wrapper>
  );
});

Rooms.propTypes = {
  field: PropTypes.object.isRequired,
  setAllRooms: PropTypes.func.isRequired
};

export default Rooms;
