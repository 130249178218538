import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { LocationTypesStore } from 'stores/LocationTypesStore';
import Loader from 'pages/Hotel/components/Loader';
import store from 'store';

@inject('hotelStore')
@observer
class LocationTypes extends React.Component {
  propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    locationTypesStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialTypes = form.$('location_types').value;

        this.setState({
          selectedTypes: initialTypes
        });
        this.fetchTypes();
      }
    );
  }

  state = {
    selectedTypes: []
  };

  fetchTypes = () => {
    const { locationTypesStore } = this.props;
    locationTypesStore.fetchChecked();
  };

  onSelect = currentType => {
    const { selectedTypes } = this.state;
    const { locationTypesStore, form } = this.props;
    const selectedType = locationTypesStore
      .toJSON()
      .types.filter(type => type.tag === currentType)[0];
    const isTypeSelected = this.state.selectedTypes.some(
      type => type.tag === selectedType.tag
    );
    let selectedData = [];

    if (isTypeSelected) {
      const index = selectedTypes.findIndex(
        type => type.tag === selectedType.tag
      );
      const copyStateData = [...selectedTypes];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedTypes, selectedType];
    }

    this.setState({
      selectedTypes: selectedData
    });

    form.update({ location_types: selectedData });
  };

  render() {
    const { locationTypesStore } = this.props;
    const typesData = locationTypesStore.toJSON().types;
    const generalLanguage = store.get('language');

    const checkboxList = typesData.map((data, index) => {
      return (
        <div key={data.tag}>
          <input
            type='checkbox'
            key={data.tag}
            value={data.tag}
            className='input radio_button'
            checked={this.state.selectedTypes.some(
              type => type.tag === data.tag
            )}
          />
          <span className='radio_button_name'>
            {data.name_translations[generalLanguage]}
          </span>
        </div>
      );
    });

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.location_types' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div>
              {locationTypesStore.isFetched
                ? (
                  <>
                    {checkboxList.map((checkbox, index) => (
                      <div
                        onClick={() => this.onSelect(checkbox.key)}
                        className={
                        index + 1 !== typesData.length
                          ? 'radio_list_item'
                          : 'radio_list_item_last'
                      }
                        key={checkbox.key}
                      >
                        {checkbox}
                      </div>
                    ))}
                  </>
                  )
                : (
                  <Loader />
                  )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LocationTypes.defaultProps = {
  form: PropTypes.object.isRequired,
  locationTypesStore: LocationTypesStore.create()
};

export default LocationTypes;
