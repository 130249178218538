import React, { Component } from 'react';
import { computed } from 'mobx';
import { Provider, observer } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import List from './components/List';
import Add from './components/Add';
import Edit from './components/Edit';

import { ServicesStore } from 'Stores/ServicesStore';

@withRouter
@observer
class Services extends Component {
  render() {
    const { servicesStore } = this.props;

    return (
      <Provider servicesStore={servicesStore}>
        <div className='layout__page'>
          <TopBar isLoading={servicesStore.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            <List />

            <Switch>
              <Route
                exact
                path='/services/new'
                component={Add}
              />

              <Route
                exact
                path='/services/:id/edit'
                component={Edit}
              />
            </Switch>
          </div>
        </div>
      </Provider>
    );
  }
}

Services.defaultProps = {
  servicesStore: ServicesStore.create()
};

export default Services;
