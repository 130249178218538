import { types, applySnapshot, applyPatch } from 'mobx-state-tree';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';
import _defaultsDeep from 'lodash/defaultsDeep';
import { objectClean } from './utils/objectClean';

import { Order } from './models/Order';
import { OrdersMeta } from './models/OrdersMeta';

const OrdersStore = types.model('OrdersStore', {
  orders: types.optional(types.array(Order), []),
  meta: types.optional(OrdersMeta, {
    count: 0,
    paginate: {
      limit: 5,
      page: 0,
      total_pages: 0
    }
  }),
  state: types.maybe(types.enumeration(['pending', 'done', 'error', 'download'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get isDownload() {
    return this.state === 'download';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    let { paginate } = this.meta.toJSON();

    paginate = Object.assign({}, paginate, {
      page: parseInt(paginate.page) + 1
    });

    let queryParams = Object.assign({}, params, {
      paginate: paginate
    });

    return instance.get('/api/orders', { params: queryParams })
      .then((response) => this.updateStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  reset({ filters, ...params }) {
    this.setState('pending');

    const queryParams = objectClean(params);
    const currentParams = {
      filters: [...filters],
      ...queryParams
    };

    return instance.get('/api/orders', { params: currentParams })
      .then((response) => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) { applySnapshot(this, data); }

    return this;
  },

  clear() {
    const data = { orders: [] };
    applySnapshot(this, data);
  },

  setState(state) {
    this.state = state;
  },

  updateStore(response) {
    const { status } = response;

    if (status === 200) {
      const { meta, orders } = response.data;

      const metaPatch = [{ op: 'replace', path: '/meta', value: meta }];

      const ordersPatch = orders.map(order => {
        return { op: 'add', path: '/orders/-', value: order };
      });

      applyPatch(this, metaPatch.concat(ordersPatch));
    }

    return this;
  },

  updateOne(order) {
    const values = order.toJSON();

    const orders = this.orders
      .map(item => {
        return item.id === order.id
          ? _defaultsDeep({}, values, item)
          : item;
      });

    this.orders = orders;
  },

  removeOne(order) {
    const orders = this.orders
      .filter(item => item.id !== order.id);

    this.orders = orders;
  },

  download(params = {}, type = 'orders') {
    this.setState('download');
    const apiPath = `/api/reports/${type}`;

    return instanceBlob.get(apiPath, { params })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  downloadLetter(reservationId, slotId) {
    this.setState('download');
    const apiPath = `/api/reservations/${reservationId}/slots/${slotId}/guarantee_letter`;

    return instanceBlob.get(apiPath)
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'letter.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  }
});

export default OrdersStore;
