import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { AccommodationFeaturesStore } from 'stores/AccommodationFeaturesStore';
import Loader from 'Pages/Hotel/components/Loader';
import store from 'store';

@inject('hotelStore')
@observer
class AccommodiationFeatures extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    accommodationFeaturesStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialFactors = form.$('accommodation_features').value;

        this.setState({
          selectedFeatures: initialFactors
        });
        this.fetchTypes();
      }
    );
  }

  state = {
    selectedFeatures: []
  };

  fetchTypes = () => {
    const { accommodationFeaturesStore } = this.props;
    accommodationFeaturesStore.fetchChecked();
  };

  onSelect = currentTag => {
    const { selectedFeatures } = this.state;
    const { accommodationFeaturesStore, form } = this.props;
    const selectedFeature = accommodationFeaturesStore
      .toJSON()
      .features.filter(type => type.tag === currentTag)[0];
    const isTypeSelected = this.state.selectedFeatures.some(
      type => type.tag === selectedFeature.tag
    );
    let selectedData = [];

    if (isTypeSelected) {
      const index = selectedFeatures.findIndex(
        type => type.tag === selectedFeature.tag
      );
      const copyStateData = [...selectedFeatures];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedFeatures, selectedFeature];
    }

    this.setState({
      selectedFeatures: selectedData
    });

    form.update({ accommodation_features: selectedData });
  };

  render() {
    const { accommodationFeaturesStore } = this.props;
    const featuresData = accommodationFeaturesStore.toJSON().features;
    const generalLanguage = store.get('language');

    const checkboxList = featuresData.map((data, index) => {
      return (
        <div
          key={data.tag}
        >
          <input
            type='checkbox'
            key={data.tag}
            value={data.tag}
            className='input radio_button'
            checked={this.state.selectedFeatures.some(
              type => type.tag === data.tag
            )}
          />
          <span className='radio_button_name'>
            {data.name_translations[generalLanguage]}
          </span>
        </div>
      );
    });

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.accommodation_features' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div>
              {accommodationFeaturesStore.isFetched
                ? (
                  <>
                    {checkboxList.map((checkbox, index) => (
                      <div
                        onClick={() => this.onSelect(checkbox.key)}
                        className={
                        index + 1 !== featuresData.length
                          ? 'radio_list_item'
                          : 'radio_list_item_last'
                      }
                        key={checkbox.key}
                      >
                        {checkbox}
                      </div>
                    ))}
                  </>
                  )
                : (
                  <Loader />
                  )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccommodiationFeatures.defaultProps = {
  form: PropTypes.object.isRequired,
  accommodationFeaturesStore: AccommodationFeaturesStore.create()
};

export default AccommodiationFeatures;
