import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';

import { ReactComponent as MastercardIcon } from 'Icons/mastercard.svg';
import { ReactComponent as MirIcon } from 'Icons/mir.svg';
import { ReactComponent as VisaIcon } from 'Icons/visa.svg';

@observer
class Card extends Component {
  toggleHandler = (e) => {
    e.preventDefault();

    const { card, isSelectable } = this.props;
    if (!isSelectable) return false;

    const currentValue = card.value;
    card.set(!currentValue);
  }

  getIcon(value) {
    switch (value) {
      case 'mastercard_card':
        return <MastercardIcon />;

      case 'visa_card':
        return <VisaIcon />;

      case 'mir_card':
        return <MirIcon />;

      default:
        return <i className='icon'>{value}</i>;
    }
  }

  render() {
    const { card, isSelectable } = this.props;

    const clsName = classNames('bank_card', {
      active: isSelectable && card.value
    });

    return (
      <div
        className={clsName}
        onClick={this.toggleHandler}
      >
        {this.getIcon(card.name)}
      </div>
    );
  }
}

Card.propTypes = {
  card: PropTypes.object.isRequired,
  isSelectable: PropTypes.bool.isRequired
};

export default Card;
