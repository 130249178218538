import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'Utils/moment';
import { FormattedMessage } from 'react-intl';
import _cloneDeep from 'lodash/cloneDeep';

import DateRangeInput from 'Shared/form/DateRangeInput';
import ClosedByDateRestrictionsState, { fields } from './state';
import RestrictionPeriodTips from '../RestrictionPeriodTips/RestrictionPeriodTips';
import { Wrapper, FormActions } from './ClosedByDateRestrictions.styled';
import Button from 'Components/ui/Button';
import SwitchPeriod from '../RestrictionPeriodSwitch/SwitchRestrictionPeriod';

@observer
class ClosedByDateRestrictionForm extends Component {
  constructor(props) {
    super(props);
    this.state = new ClosedByDateRestrictionsState(fields);
    if (props.period) {
      this.state.update(props.period);
    }
    this.leftBound = moment(props.form.values().available_period.from);
    this.rightBound = moment(props.form.values().available_period.to);
  }

  successSubmitHandler = (data) => {
    const { form, onClose, index } = this.props;
    const values = form.values();
    let arr = [];

    if (index >= 0) {
      arr = _cloneDeep(form.$('closed_by_date_restrictions').values());
      arr[index] = data.values();
    } else {
      arr = [...values.closed_by_date_restrictions, data.values()];
    }

    form.update({
      ...values,
      closed_by_date_restrictions: arr
    });
    onClose();
  };

  errorSubmitHandler(data) {
    console.log(data.values(), 'err');
  }

  updateHandler = (e) => {
    e.preventDefault();
    this.state.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler
    });
  };

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) || this.rightBound.isBefore(day);
  };

  handleChangeSwitch = () => {};

  render() {
    const { onClose } = this.props;
    return (
      <Wrapper>
        <div className="row">
          <div className="col col-5">
            <DateRangeInput
              initialVisibleMonth={() => this.leftBound}
              startDate={this.state.$('start_date')}
              endDate={this.state.$('end_date')}
              enableOutsideDays
              minimumNights={0}
              isOutsideRange={this.isOutsideRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-5">
            <SwitchPeriod
              title={'tariff.closed_by_date_restrictions.closedToArrival.title'}
              field={this.state.$('closed_to_arrival')}
            />
          </div>
          <div className="col col-6 offset-1">
            <div className="form__fields-group">
              <RestrictionPeriodTips
                text={
                  'tariff.closed_by_date_restrictions.closedToArrival.example'
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-5">
            <SwitchPeriod
              title={
                'tariff.closed_by_date_restrictions.closedToDeparture.title'
              }
              field={this.state.$('closed_to_departure')}
            />
          </div>
          <div className="col col-6 offset-1">
            <div className="form__fields-group">
              <RestrictionPeriodTips
                text={
                  'tariff.closed_by_date_restrictions.closedToDeparture.example'
                }
              />
            </div>
          </div>
        </div>
        <FormActions withMargin>
          <Button onClick={this.updateHandler}>
            <FormattedMessage id="shared.confirm" />
          </Button>

          <Button variant="secondary" onClick={onClose}>
            <FormattedMessage id="shared.cancel" />
          </Button>
        </FormActions>
      </Wrapper>
    );
  }
}

ClosedByDateRestrictionForm.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number,
  onClose: PropTypes.func
};

export default ClosedByDateRestrictionForm;
