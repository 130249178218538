import styled from 'styled-components';

export const StopSalesElement = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
`;

export const StopSalesLabel = styled.span`
color: #E16868;
`
