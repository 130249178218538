import React, { Component } from 'react';
import qs from 'qs';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

import FormItemInput from 'Shared/form/FormItemInput';
import FormControls from './controls';

import loginFormState from './state';

import { FormattedMessage, injectIntl } from 'react-intl';

@inject('store')
class LoginForm extends Component {
  static propTypes = {
    location: PropTypes.object,
    store: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.form = loginFormState;
    this.userStore = props.store.userStore;
  }

  componentDidMount() {
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    params.email && this.form.$('email').set(params.email);
  }

  render() {
    return (
      <form className='form padded'>
        <fieldset>
          <legend>
            <FormattedMessage id='shared.login_title' />
          </legend>
          <div className='form__body'>
            <FormItemInput field={this.form.$('email')} />
            <FormItemInput field={this.form.$('password')} type='password' />
          </div>
          <FormControls form={this.form} />
        </fieldset>
      </form>
    );
  }
}

export default injectIntl(LoginForm);
