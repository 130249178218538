import { NSHotel } from '@types';
import * as React from 'react';
import styled from 'styled-components';

import { Heading } from 'evergreen-ui';
import Tariffs from './Tariffs';

const Header = styled.div`
`;

const Body = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Header} + ${Body} {
    margin-top: 16px;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  roomType: NSHotel.TRoomType
}>

function RoomType({ roomType, className }: Props) {
  const { name: { ru }, tariffs } = roomType;

  return (
    <Wrapper className={className}>
      <Header>
        <Heading size={700}>
          {ru}
        </Heading>
      </Header>
      <Body>
        <Tariffs
          roomType={roomType}
          tariffs={tariffs}
        />
      </Body>
    </Wrapper>
  );
}

export default styled(RoomType)``;
