import React from 'react';
import PropTypes from 'prop-types';

import moment from 'Utils/moment';

const format = 'DD.MM.YYYY';

function TariffVisiblePeriod({ className, period: { from, to } }) {
  const fromDate = moment(from).format(format);
  const toDate = moment(to).format(format);

  return (
    <span className={className}>
      {fromDate} &mdash; {toDate}
    </span>
  );
}

TariffVisiblePeriod.defaultProps = {
  className: ''
};

TariffVisiblePeriod.propTypes = {
  period: PropTypes.object.isRequired,
  className: PropTypes.object.isRequired
};

export default TariffVisiblePeriod;
