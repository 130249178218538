import './styles/main.scss';

import React from 'react';
import { StateInspector } from 'reinspect';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import { store, chatStore, hotelStore, intl } from './base_store';
import App from './pages/App';

import { IntlProvider } from 'react-intl';

ReactDOM.render(
  <StateInspector>
    <Provider store={store} chatStore={chatStore} hotelStore={hotelStore}>
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        <App />
      </IntlProvider>
    </Provider>
  </StateInspector>,
  document.getElementById('root')
);
