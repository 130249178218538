import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Rate from './Rate';

import styled from 'styled-components';

const S = {
  PriceBlock: styled.div.attrs(() => ({ className: 'price-block' }))`
    width: auto
  `,

  Rate: styled(Rate)`
    align-content: flex-start;
    justify-content: flex-start;
  `
};

@observer
class Rates extends Component {
  render() {
    const { rates, labels } = this.props;

    const listItems = rates
      .sort((prev, curr) => prev.price.amount < curr.price.amount)
      .map((item) => <S.Rate key={item.type} rate={item} />);

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {labels.rates}
          </span>

          <div className='value'>
            <S.PriceBlock>
              {listItems}
            </S.PriceBlock>
          </div>
        </div>
      </div>
    );
  }
}

Rates.propTypes = {
  rates: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default Rates;
