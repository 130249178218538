import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class Preview extends Component {
  render() {
    const { url } = this.props;

    const style = {
      backgroundImage: `url(${url})`
    };

    return (
      <div className='photo'>
        <div
          style={style}
          className='image'
        />
      </div>
    );
  }
}

Preview.propTypes = {
  url: PropTypes.string.isRequired
};

export default Preview;
