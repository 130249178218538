import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PriceLists from '../PriceLists/index';

@withRouter
@inject('priceListsStore')
@observer
class List extends Component {
  render() {
    const { price_lists } = this.props.priceListsStore;

    return (
      <div className='page__body wide'>
        <div className='groups'>
          <div className='groups__item-content'>
            <div className='groups__item-blocks'>
              <div className='groups__item-block'>
                <div className='header'>
                  <FormattedMessage id='price_lists.available' />
                </div>

                <div className='content'>
                  {price_lists.length > 0
                    ? <PriceLists price_lists={price_lists} />
                    : <p className='text gray'>
                      <FormattedMessage id='price_lists.empty' />
                      </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default List;
