import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Preview from './Preview';

@inject('hotelStore')
@observer
class Previews extends Component {
  render() {
    const { hotel } = this.props.hotelStore;

    const listItems = this.props.files.map((file) => {
      return <Preview key={file.name} file={file} />;
    });

    return (
      <div className='photos small'>
        {listItems}
      </div>
    );
  }
}

Previews.propTypes = {
  files: PropTypes.object.isRequired
};

export default Previews;
