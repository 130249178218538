import { types } from 'mobx-state-tree';

const AvailablePeriod = types.model('AvailablePeriod', {
  from: types.string,
  to: types.string
});

export const Duplicate = types.model('Duplicate', {
  id: types.identifier(types.string),
  name: types.string,
  available_period: AvailablePeriod
});
