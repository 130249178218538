import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class Header extends Component {
  render() {
    const { order } = this.props.reservation;

    return (
      <div className='infoblock__header'>
        <FormattedMessage
          id='orders.order_number'
          values={{ code: order.order_code }}
        />
      </div>
    );
  }
}

Header.propTypes = {
  reservation: PropTypes.object.isRequired
};

export default Header;
