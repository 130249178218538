import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/ui/Button';
import { Footer, Description } from './StopSales.styled';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import StopSalesPeriod from './StopSalesPeriod';
import StopSaleChange from './StopSaleChange';

@observer
class StopSalesPeriods extends Component {
  handleAddPeriod = e => {
    const { form } = this.props;
    e.preventDefault();
    form.isAddStopSalePeriod = true;
    form.isOpenFormIndex = null;
  }

  handleDeletePeriods = e => {
    e.preventDefault();
    const { form } = this.props;
    form.deleteStopSalesPeriods();
  }

  render() {
    const { form } = this.props;
    const stopSalesValues = form.$('stop_sales').values();
    const listItems = stopSalesValues.map((stopSalePeriod, index) => <StopSalesPeriod period={stopSalePeriod} index={index} key={index} form={form} />);
    const isChange = form.isOpenFormIndex === 0 || Boolean(form.isOpenFormIndex);

    return (
      <form className='form'>
        <div className='form__body'>
          {listItems}

          {form.isAddStopSalePeriod && (
            <StopSaleChange form={form} />
          )}

          {stopSalesValues.length === 0 && !form.isAddStopSalePeriod && (
            <Description> <FormattedMessage id='tariff.stop_sales.description' /> </Description>
          )}

          {!form.isAddStopSalePeriod && !isChange && (
            <Footer showBorder={stopSalesValues.length > 0}>
              <Button
                onClick={this.handleAddPeriod}
              > <FormattedMessage id='periods.add' />
              </Button>
              {stopSalesValues.length > 0 &&
                <Button
                  variant='danger'
                  onClick={this.handleDeletePeriods}
                > <FormattedMessage id='periods.delete' />
                </Button>}
            </Footer>
          )}
        </div>
      </form>
    );
  }
}

StopSalesPeriods.propTypes = {
  form: PropTypes.object.isRequired
};

export default StopSalesPeriods;
