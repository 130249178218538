import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import PriceCalculationRule from './PriceCalculationRule';

@observer
class TariffRulesAndServicesBlock extends Component {
  render() {
    const { labels, tariff } = this.props;
    const { price_calculation_rule } = tariff;

    return (
      <div className='row'>
        {tariff.type === 'base' &&
          <div className='col col-8'>
            <PriceCalculationRule
              price_calculation_rule={price_calculation_rule}
              labels={labels}
            />
          </div>}
      </div>
    );
  }
}

TariffRulesAndServicesBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffRulesAndServicesBlock;
