import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Rate from './Rate';

@observer
class Rates extends Component {
  render() {
    const listItems = this.props.rates
      .filter(item => item.price.amount > 0)
      .map(item => <Rate key={item.type} rate={item} />);

    return (
      <div className='rates'>
        {listItems}
      </div>
    );
  }
}

Rates.propTypes = {
  rates: PropTypes.object.isRequired
};

export default Rates;
