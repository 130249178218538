import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Sources from '../Sources';
import Rooms from '../Rooms';
import QuotasSelector from '../QuotasSelector';

import {
  SelectableGroup,
  Registry
} from 'Pages/Dashboard/components/SelectableQuotas';

@withRouter
@inject('quotasStore')
@observer
class RoomType extends Component {
  static propTypes = {
    room_type: PropTypes.object.isRequired,
    quotasStore: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.registryStore = new Registry();
  }

  render() {
    const { room_type, quotasStore } = this.props;
    const rt = quotasStore.findOne(room_type.id);
    const sources = rt ? rt.sources : [];

    return (
      <Provider registryStore={this.registryStore}>
        <SelectableGroup
          className='room_type'
          store={this.registryStore}
          quotasSelectorPortal='dashboard'
          quotasSelector={QuotasSelector}
        >
          <div className='header'>
            <div className='content' />
          </div>

          <Sources
            room_type={room_type}
            sources={sources}
          />

          <div className='rooms'>
            <Rooms roomId={room_type.id} rooms={room_type.rooms} beds_quantity={room_type.beds} />
          </div>

          <div className='quotas-edit' />
        </SelectableGroup>
      </Provider>
    );
  }
}

export default RoomType;
