import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import arrayToTree from 'array-to-tree';

import AmenityTree from './AmenityTree';

class AmenitiesAndServices extends Component {
  render() {
    const { hotel } = this.props;
    const amenitiesTree = hotel.amenities
      ? arrayToTree(hotel.amenities.toJS(), { parentProperty: 'parent_tag', customID: 'tag' })
      : [];

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.amenities_and_services' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {amenitiesTree.length > 0
              ? <AmenityTree amenities={amenitiesTree} />

              : <p className='muted'>
                <FormattedMessage id='hotel.amenities_didnt_choosed' />
                </p>}
          </div>
        </div>
      </div>
    );
  }
}

AmenitiesAndServices.defaultProps = {
  hotel: PropTypes.object.isRequired
};

export default AmenitiesAndServices;
