import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class TopBarLoader extends Component {
  render() {
    return (
      <div className='layout__topbar-loader'>
        <div className='linear-progress-material'>
          <div className='bar bar1' />
          <div className='bar bar2' />
        </div>
      </div>
    );
  }
}

export default TopBarLoader;
