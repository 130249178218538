import {
  types,
  applySnapshot,
  applyPatch,
  resolveIdentifier,
  detach
} from 'mobx-state-tree';
import instance from 'connection/instance';
import { runInAction } from 'mobx';

import jsonpatch from 'fast-json-patch';
import _merge from 'lodash/merge';

import { RoomType } from './RoomType';
import { Insurance } from './Insurance';

const QuotasStore = types.model(
  'QuotasStore',
  {
    check_in: types.maybe(types.string),
    check_out: types.maybe(types.string),
    insurances: types.optional(types.array(Insurance), []),
    room_types: types.optional(types.array(RoomType), []),
    ignore_room_type_capacity: types.maybe(types.boolean),
    state: types.optional(
      types.enumeration(['pending', 'done', 'error']),
      'done'
    ),
    hideAllRooms: types.optional(types.boolean, false),
    hiddenRooms: types.optional(types.map(types.boolean), {}),

    get isFetched() {
      return this.state === 'done';
    },

    get isPending() {
      return this.state === 'pending';
    },

    get isError() {
      return this.state === 'error';
    }
  },
  {
    fetch(options = {}) {
      this.setState('pending');
      const params = { quota: options };

      return instance
        .get('/api/quota', { params })
        .then((response) => this.resetStore(response))
        .then((_) => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    getSources(input) {
      const options = { params: { query: { chars: input } } };

      return instance
        .get('/api/tariffs/sources', options)
        .then((response) => this.parseFilterData(response));
    },

    parseFilterData(response) {
      const { data } = response;

      const sources = data.sources.map((source) => ({
        id: source.id,
        name: source.name
      }));

      return { sources };
    },

    getRoomTypes(params) {
      return instance
        .get('/api/room_types', { params: params })
        .then((response) => this.parseRoomsData(response));
    },

    parseRoomsData(response) {
      const { data } = response;
      const rooms = data.room_types.map((room) => ({
        id: room.id,
        name: room.name
      }));

      return { rooms };
    },

    update(values, options = {}) {
      this.setState('pending');

      const endpoint = '/api/quota';
      const special = options.special ? `/${options.special}` : '';
      let params;

      switch (options.special) {
        case 'quota':
          params = { quota: values };
          break;
        case 'availability':
          params = { quota: values };
          break;
        default:
          params = _merge(values, {
            check_in: this.check_in,
            check_out: this.check_out
          });
          params = { quota: values };
          break;
      }

      return instance
        .put(endpoint + special, params)
        .then((response) => this.updateStore(response))
        .then((_) => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    updateQuotas(options = {}) {
      this.setState('pending');
      const params = { quota: options };

      return instance
        .put('/api/quota/groups', params)
        .then((response) => this.updateQuotasStore(response))
        .then((_) => this.setState('done'))
        .catch((error) => this.errorHandler(error));
    },

    clear() {
      detach(this);
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(this, data);
      }

      return this;
    },

    updateQuotasStore(response) {
      const { status, data } = response;

      if (status === 200 && data.attributes.room_types && data.attributes.room_types.length > 0) {
        const source = data.attributes.room_types[0];
        const store = this.findOne(source.id);

        const patch = jsonpatch
          .compare(store.toJSON(), source)
          .filter((action) => action.op === 'replace' || action.op === 'add');

        applyPatch(store, patch);
      }

      return this;
    },

    updateStore(response) {
      const { status, data } = response;

      if (status === 200) {
        const source = data.room_types[0];
        const store = this.findOne(source.id);

        const patch = jsonpatch
          .compare(store.toJSON(), source)
          .filter((action) => action.op === 'replace' || action.op === 'add');

        applyPatch(store, patch);
      }

      return this;
    },

    setState(state, response = undefined) {
      this.state = state;

      return response || this;
    },

    errorHandler(error) {
      this.setState('error');
      return Promise.reject(error);
    },

    findOne(room_type_id) {
      return resolveIdentifier(RoomType, this.room_types, room_type_id);
    },

    addHiddenRoom(id, isHidden) {
      this.hiddenRooms.set(id, isHidden);
    },

    setHideAllRooms() {
      this.hideAllRooms = !Array.from(this.hiddenRooms.values()).some((room) => room === false);
    },

    toggleHideAllRooms() {
      this.hideAllRooms = !this.hideAllRooms;
      const hiddenRooms = this.hiddenRooms;
      const roomTypes = this.room_types;
      runInAction(() => {
        roomTypes.forEach((room) => {
          hiddenRooms.set(room.id, this.hideAllRooms);
        });
      });
    }

  }
);

export { QuotasStore };
