import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Loader from 'react-loaders';

@observer
class InfoLoader extends Component {
  render() {
    return (
      <div className='loader'>
        <Loader
          type='line-scale'
          active
          color='#999'
        />
      </div>
    );
  }
}

export default InfoLoader;
