import React from 'react';

import { ReactComponent as SingleOccupationIcon } from 'Icons/single_occupation.svg';
import { ReactComponent as DoubleOccupationIcon } from 'Icons/double_occupation.svg';
import { ReactComponent as AdultOnMainBedIcon } from 'Icons/adult_on_main_bed.svg';
import { ReactComponent as ChildOnMainBedIcon } from 'Icons/child_on_main_bed.svg';
import { ReactComponent as AdultOnExtraBedIcon } from 'Icons/adult_on_extra_bed.svg';
import { ReactComponent as ChildOnExtraBedIcon } from 'Icons/child_on_extra_bed.svg';

const testOccupation = (occupation, value) => {
  const re = new RegExp(occupation, 'gi');
  const v = value.replace(/\d+_/gi, '');

  return re.test(v);
};

const getIcon = (type) => {
  if (testOccupation('adult_on_main_bed_at_1', type)) {
    return <SingleOccupationIcon width='14' />;
  }

  if (testOccupation('adult_on_main_bed_at_', type)) {
    return <AdultOnMainBedIcon width='14' />;
  }

  if (testOccupation('adult_on_extra_bed', type)) {
    return <AdultOnExtraBedIcon width='14' />;
  }

  if (testOccupation('child_on_main_bed', type)) {
    return <ChildOnMainBedIcon width='14' />;
  }

  if (testOccupation('child_on_extra_bed', type)) {
    return <ChildOnExtraBedIcon width='14' />;
  }

  if (testOccupation('child_on_no_bed', type)) {
    return <ChildOnMainBedIcon width='14' />;
  }

  if (testOccupation('adult_on_main_bed', type)) {
    return <DoubleOccupationIcon width='14' />;
  }

  return <span>{type}</span>;
};

export { getIcon };
