import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 100px;
  background: rgba(234, 233, 228, 0.5);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #D2D1CC;
  margin-top: 8px;
`;
