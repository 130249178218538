import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction, observable } from 'mobx';
import GoogleMap from 'google-map-react';

import Marker from './Marker';

@observer
class Map extends Component {
  @observable geocoder = undefined;

  componentWillUnmount() {
    this.geocoding && this.geocoding();
  }

  googleApiLoadedHandler = ({ map, maps }) => {
    this.geocoder = new maps.Geocoder();

    this.geocoding = reaction(
      () => this.props.field.$('location').value,
      (address) => this.getAddressCoordinates(address),
      { delay: 1000, fireImmediately: true }
    );
  }

  getAddressCoordinates = (address) => {
    const coordinates = this.props.field.$('coordinates');

    if (!address) {
      coordinates.set(null);
      return true;
    }

    this.geocoder.geocode({ address: address }, (result, status) => {
      if (status === 'OK') {
        const { location } = result[0].geometry;
        const lat = location.lat();
        const lng = location.lng();

        coordinates.set([lng, lat]);
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  render() {
    const options = {
      draggable: false,
      scaleControl: true,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      styles: [{
        featureType: 'all',
        stylers: [{
          saturation: -80
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          { hue: '#00ffee' },
          { saturation: 50 }
        ]
      }, {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }]
    };

    const coords = this.props.field.$('coordinates').value;

    return (
      <div className='map'>
        <GoogleMap
          options={options}
          bootstrapURLKeys={{ key: 'AIzaSyAXaiAVLHTskP3XBYbMObGbnY-al85kvgA' }}
          center={{ lat: coords[1], lng: coords[0] }}
          defaultZoom={13}
          onGoogleApiLoaded={this.googleApiLoadedHandler}
          yesIWantToUseGoogleMapApiInternals
        >
          {coords && <Marker lat={coords[1]} lng={coords[0]} />}
        </GoogleMap>
      </div>
    );
  }
}

Map.propTypes = {
  field: PropTypes.object.isRequired
};

export default Map;
