import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import Slider from 'rc-slider';
import { getSliderMarks } from 'Utils/getSliderMarks';
import { SliderLabel, SliderLabelMute, Time, TimeLabel } from 'Styles/styled';
import { FormattedMessage } from 'react-intl';

@observer
class TimeRangeInput extends Component {
  @computed get currentSource() {
    const { field, source } = this.props;
    return field.value.filter(item => item.insurance.id === source.id)[0];
  }

  @computed get rangeValue() {
    const { type } = this.props;
    const value = this.currentSource.settings[type].split(':')[0];

    return Number(value);
  }

  handleSliderChange = value => {
    const { field, type } = this.props;
    const fieldValuesCopy = [...field.value];
    const currentSourceCopy = { ...this.currentSource };

    let data = value;
    data = Math.floor(data);
    data = data === 24 ? 0 : data;
    data = [data, '00'].join(':');

    currentSourceCopy.settings[type] = data;

    const index = fieldValuesCopy.findIndex(item => item.id === currentSourceCopy.id);

    fieldValuesCopy.splice(index, 1, currentSourceCopy);

    field.set(fieldValuesCopy);
  };

  render() {
    const { min, max, step, isOut } = this.props;

    const marks = getSliderMarks(min, max + 1, this.rangeValue, 'timeSlider');

    return (
      <div className='form__field slider'>
        <SliderLabel>
          <SliderLabelMute>
            {isOut ? <FormattedMessage id='hotel.checkout_time_before' /> : <FormattedMessage id='hotel.checkin_time_after' /> }
          </SliderLabelMute>

          <TimeLabel>
            <>
              {isOut
                ? <FormattedMessage id='hotel.before' />
                : <FormattedMessage id='hotel.after' />}

              <Time>{this.rangeValue}:00</Time>
            </>
          </TimeLabel>
        </SliderLabel>

        <Slider
          allowCross={false}
          min={min}
          max={max}
          step={step}
          marks={marks}
          value={this.rangeValue}
          onChange={this.handleSliderChange}
          trackStyle={{ backgroundColor: isOut ? '#C9E6C8' : '#EEEBE7' }}
          railStyle={{ backgroundColor: isOut ? '#EEEBE7' : '#C9E6C8' }}
          handleStyle={[{ borderColor: '#69AC66', backgroundColor: '#69AC66' }]}
        />
      </div>
    );
  }
}

TimeRangeInput.defaultProps = {
  min: 1,
  max: 23,
  step: 1
};

TimeRangeInput.propTypes = {
  field: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  isOut: PropTypes.bool,
  source: PropTypes.object.isRequired,
  type: PropTypes.string
};

export default TimeRangeInput;
