import { types, applySnapshot, resolveIdentifier, detach } from 'mobx-state-tree';
import instance from 'connection/instance';

import { RoomType } from './RoomType';

const RoomTypesStore = types.model('RoomTypeStore', {
  room_types: types.optional(types.array(RoomType), []),
  state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    return instance.get('/api/room_types', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  create(formData) {
    this.setState('pending');

    return instance.post('/api/room_types', formData)
      .then((response) => this.add(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    detach(this);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) {
      applySnapshot(this, data);
    }

    return data;
  },

  setState(state, response = undefined) {
    this.state = state;

    return response || this;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  find(room_type_id) {
    return resolveIdentifier(
      RoomType, this.room_types, room_type_id
    );
  },

  add(response) {
    const { room_type } = response.data;
    this.room_types.push(room_type);

    return room_type;
  }
});

export default RoomTypesStore;
