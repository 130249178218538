const fields = [
  'price',
  'price.amount',
  'price.currency',
  'type'
];

const labels = {
  price: 'Цена'
};

const rules = {
  price: 'integer|required',
  type: 'string|required'
};

const values = {
  type: 'TariffRateSeasonRule'
};

export default {
  fields,
  labels,
  rules,
  values
};
