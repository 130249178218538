import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';

import PageContent from './components/PageContent';

import OrderStore from 'Stores/OrdStore';

@withRouter
@observer
class Info extends Component {
  constructor(props) {
    super(props);

    this.orderStore = OrderStore.create();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.fetchStore();
  }

  componentWillUnmount() {
    this.clearStore();
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params: { id: currId } } } = this.props;
    const { match: { params: { id: prevId } } } = prevProps;

    if (currId !== prevId) {
      this.fetchStore();
    }
  }

  fetchStore = () => {
    const { match: { params: { id } } } = this.props;
    this.orderStore.fetch(id);
  }

  clearStore = () => {
    this.orderStore.clear();
  }

  render() {
    return (
      <Provider orderStore={this.orderStore}>
        <div className='page__info very wide'>
          <div className='page__info-wrapper' ref={this.containerRef}>
            <Scrollbars>
              <PageContent parentRef={this.containerRef} />
            </Scrollbars>
          </div>
        </div>
      </Provider>
    );
  }
}

Info.propTypes = {
  match: PropTypes.object.isRequired
};

export default Info;
