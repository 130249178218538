import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FormLanguageContext } from 'Context/FormLanguageContext';

const Wrapper = styled.span``;

function LocalizedLabel({ className, content }) {
  const { currentLanguage } = useContext(FormLanguageContext);

  return (
    <Wrapper className={className}>
      {content[currentLanguage]}
    </Wrapper>
  );
}

LocalizedLabel.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object
};

export default styled(LocalizedLabel)``;
