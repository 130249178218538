import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ControlStore from './store';
import { RatesFormButtons } from 'Styles/styled';

@observer
class Control extends Component {
  handleAdd = (e) => {
    e.preventDefault();
    this.props.addHandler(true);

    const { control } = this.props;
    control.open();
  }

  render() {
    const { control } = this.props;

    const btnClass = classNames('button green', {
      disabled: control.isDisabled
    });

    return (
      <div className='form rates__form'>
        <div className='rates__form--control'>
          <RatesFormButtons>
            <button
              className={btnClass}
              onClick={this.handleAdd}
            ><FormattedMessage id='rates.add' />
            </button>
          </RatesFormButtons>
        </div>
      </div>
    );
  }
}

Control.propTypes = {
  control: PropTypes.object.isRequired,
  addHandler: PropTypes.func.isRequired
};

export { ControlStore };

export default Control;
