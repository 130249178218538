import React from 'react';
import ReactModal from 'react-modal';
import { computed, observable } from 'mobx';
import store from 'store';
import cleanDeep from 'clean-deep';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import RoomTypeStore from 'stores/RoomTypesStore/One';
import FormState, { fields } from 'pages/RoomTypes/components/FormState';
import { observer } from 'mobx-react';

import {
  ModalTitle,
  Description,
  ModalContent,
  Buttons,
  NumberInput
} from './CapacityModal.styled';

import { formatMessage } from 'Utils/formatMessage';

const style = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0,0,0,0.6)'
  },

  content: {
    position: 'absolute',
    top: '50%',
    bottom: 'auto',
    transform: 'translateY(-50%)',
    maxWidth: 434,
    margin: '0 auto',
    boxShadow: '0 0 9px rgba(0,0,0, 0.13)',
    background: '#fff',
    borderRadius: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '25px 30px 35px'
  }
};

@observer
class CapacityModal extends React.Component {
  @observable form = new FormState(fields);

  state = {
    isUpdate: false
  };

  @computed get isFetched() {
    const { roomTypeStore } = this.props;
    return roomTypeStore.room_types.length > 0;
  }

  @computed get room_type() {
    const { roomTypeStore } = this.props;
    if (!roomTypeStore.isFetched) return undefined;
    return roomTypeStore.room_type;
  }

  componentDidMount() {
    const { roomTypeStore, id } = this.props;
    roomTypeStore.fetch(id);
  }

  componentDidUpdate() {
    if (!this.state.isUpdate && this.room_type) this.updateForm();
  }

  updateForm = () => {
    this.form.update(this.room_type.toForm());
    this.setState({ isUpdate: true });
  }

  onChange = (e, value) => {
    this.form.$('capacity').set({ total: value.floatValue });
  };

  updateHandler = (e) => {
    e.preventDefault();

    this.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler
    });
  };

  successSubmitHandler = async (form) => {
    const { setCapacity, closeModal } = this.props;

    let data = cleanDeep({ ...form.values() });
    data.images = (data.images || []).filter(image => image._destroy === true);

    data = { room_type: data };
    data = serialize(data);

    const files = (this.form.$('uploads').files && this.form.$('uploads').files[0]) || [];
    files.forEach(file => {
      data.append('room_type[images][][image]', file);
    });

    try {
      await this.room_type.update(data);
      setCapacity(this.room_type.capacity.total);
    } catch (error) {
      this.errorSubmitHandler(error);
    }

    closeModal();
  };

  errorSubmitHandler = (error) => {
    console.log(error);
  }

  render() {
    const { closeModal, isOpen } = this.props;
    const generalLanguage = store.get('language');
    const capacity = this.room_type && this.room_type.capacity.total;

    return (
      <>
        {this.room_type &&
          <ReactModal
            style={style}
            isOpen={isOpen}
            onRequestClose={closeModal}
          >
            <ModalContent>
              <ModalTitle>
                {this.room_type.name_translations[generalLanguage]}
              </ModalTitle>

              <Description>
                {formatMessage('room_types.modal_description')}
              </Description>

              <NumberInput
                className='input'
                value={capacity}
                prefix={formatMessage('room_types.modal_room_counts')}
                onChange={this.onChange}
                allowNegative={false}
              />

              <Buttons>
                <button
                  className='button green'
                  onClick={this.updateHandler}
                > {formatMessage('shared.confirm')}
                </button>

                <button
                  className='button gray'
                  onClick={closeModal}
                  style={{ marginLeft: '16px' }}
                > {formatMessage('shared.cancel')}
                </button>
              </Buttons>
            </ModalContent>
          </ReactModal>}
      </>
    );
  }
}

CapacityModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  id: PropTypes.string,
  roomTypeStore: PropTypes.object,
  setCapacity: PropTypes.func
};

CapacityModal.defaultProps = {
  roomTypeStore: RoomTypeStore.create()
};

export default CapacityModal;
