import { types } from 'mobx-state-tree';
import moment from 'Utils/moment';
import _flatten from 'lodash/flatten';

import { RoomType } from './RoomType';
import { Slot } from './Slot';
import { Order } from './Order';
import { Tariff } from './Tariff';
import { TariffCancellationPolicy } from './TariffCancellationPolicy';
import { Price } from 'Stores/models/Price';

export const Reservation = types.model('Reservation', {
  id: types.identifier(types.string),
  check_in: types.string,
  check_out: types.string,
  room_type: RoomType,
  tariff: Tariff,
  slots: types.array(Slot),
  price: Price,
  order: types.maybe(Order),
  expiration_time: types.string,
  expires_at: types.maybe(types.string),
  cancellation_policy: types.maybe(TariffCancellationPolicy),
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),

  get travellers() {
    const data = this.slots.map(slot => slot.travellers.toJSON());
    return _flatten(data);
  },

  get date_range() {
    const check_in = moment(this.check_in);
    const check_out = moment(this.check_out);

    const range = [
      check_in.format('D MMM'),
      check_out.format('D MMM YYYY')
    ];

    return range;
  },

  get nights() {
    return Math.ceil(this.diff / (86400 * 1000));
  },

  get diff() {
    const check_in = moment(this.check_in);
    const check_out = moment(this.check_out);

    return check_out.diff(check_in);
  },

  get isFetched() {
    return this.status === 'done';
  },

  get isPending() {
    return this.status === 'pending';
  },

  get isError() {
    return this.status === 'error';
  },

  get isCancelled() {
    return this.state === 'cancelled';
  },

  get isBooked() {
    return this.state === 'booked';
  },

  get isConfirmed() {
    return this.state === 'confirmed';
  },

  get cashPrice() {
    return this.slots
      .filter(slot => slot.cash)
      .reduce((sum, slot) => {
        const amount = sum.amount + slot.amount;
        const currency = slot.currency;

        return { amount, currency };
      }, { amount: 0, currency: 'RUB' });
  },

  get nonCashPrice() {
    return this.slots
      .filter(slot => !slot.cash)
      .reduce((sum, slot) => {
        const amount = sum.amount + slot.amount;
        const currency = slot.currency;

        return { amount, currency };
      }, { amount: 0, currency: 'RUB' });
  },

  get bedsCount() {
    return this.slots.reduce((sum, slot) => {
      return sum + slot.count;
    }, 0);
  }
});
