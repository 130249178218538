import { types } from 'mobx-state-tree';

import { Bed } from './Bed';

export const Room = types.model('Room', {
  id: types.identifier(types.string),
  number: types.string,
  floor_number: types.number,
  beds: types.optional(types.array(Bed), [])
});
