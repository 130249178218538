import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Mappings from './containers/Mappings';
import Provider from './store/Provider';

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function Sogaz({ className }: Props) {
  return (
    <Switch>
      <Route
        exact
        path='/sogaz/mappings'
        component={() => (
          <Provider>
            <Mappings />
          </Provider>
        )}
      />
    </Switch>
  );
}

export default Sogaz;
