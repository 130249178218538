import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import PageBody from './components/PageBody';

@withRouter
@inject('buildingsStore')
@observer
class List extends Component {
  @computed get isFetched() {
    const { buildingsStore } = this.props;
    return buildingsStore.buildings.length > 0;
  }

  render() {
    const { buildingsStore } = this.props;

    return (this.isFetched && <PageBody />);
  }
}

export default List;
