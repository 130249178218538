import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import FormItemText from 'Shared/form/FormItemText';
import LanguageControl from 'Shared/components/LanguageFormControl';
import { formatMessage } from 'Utils/formatMessage';

import { FormLanguageContext } from 'Context/FormLanguageContext';

import {
  GeneralInfoHeader,
  GeneralInfoTitle,
  FormFields
} from './GeneralInfo.styled';
import Photos from '../../../containers/Edit/components/Photos';

@observer
class Form extends Component {
  static contextType = FormLanguageContext;
  render() {
    const { form } = this.props;
    const { currentLanguage, handleLanguageChange } = this.context;

    return (
      <div className='form'>
        <GeneralInfoHeader>
          <GeneralInfoTitle>
            {formatMessage('room_types.general')}{' '}
          </GeneralInfoTitle>
          <div className='room_type__language'>
            <div className='control'>
              <LanguageControl onClick={handleLanguageChange} />
            </div>
          </div>
        </GeneralInfoHeader>

        <div className='form__body'>
          <div className='form__fields-group'>
            <FormItemInput
              field={form.$(`name_translations.${currentLanguage}`)}
              className='w100'
              isLocalizeField
              mute
            />

            <div className='form__fields-group'>
              <FormItemText
                field={form.$(`description_translations.${currentLanguage}`)}
                isLocalizeField
                size='small'
                mute
              />
            </div>

            <FormFields>
              <FormItemInput field={form.$('rm_count')} className='w10' mute />

              <FormItemInput field={form.$('area')} className='w25' mute />

              <FormItemInput field={form.$('beds')} className='w25' mute />

              <FormItemInput field={form.$('extra_beds')} className='w25' mute />
            </FormFields>
            <Photos form={form} />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
