import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Row from './Row';

@withRouter
@inject('priceboardStore')
@observer
class Rows extends Component {
  render() {
    const { room_types } = this.props.priceboardStore;

    const listItems = room_types.map((room_type) => {
      return <Row key={room_type.id} room_type={room_type} />;
    });

    return listItems;
  }
}

export default Rows;
