import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import ServiceHeader from './ServiceHeader';
import ServiceContent from './ServiceContent';

@observer
class Service extends Component {
  render() {
    const { isActive } = this.props.service;

    const className = classNames('collapse__item', {
      active: isActive
    });

    return (
      <div className={className}>
        <ServiceHeader service={this.props.service} />
        {isActive && <ServiceContent service={this.props.service} />}
      </div>
    );
  }
}

Service.propTypes = {
  service: PropTypes.object.isRequired
};

export default Service;
