import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageBody from './components/PageBody';

import { PriceboardStore } from 'Stores/PriceboardStore';

@observer
class Rates extends Component {
  priceboard = PriceboardStore.create()

  componentDidMount() {
    const params = { presenter: 'rates_view' };
    this.priceboard.fetch(params);
  }

  componentWillUnmount() {
    this.priceboard.destroy();
  }

  render() {
    const { grid } = this.priceboard;

    return (
      <Provider priceboardStore={this.priceboard}>
        <div className='layout__page'>
          <TopBar isLoading={this.priceboard.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            {grid && <PageBody />}
          </div>
        </div>
      </Provider>
    );
  }
}

export default Rates;
