import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

import ArrowLeftIcon from 'react-icons/lib/md/chevron-left';
import ArrowRightIcon from 'react-icons/lib/md/chevron-right';
import CloseIcon from 'react-icons/lib/md/close';

import {
  Wrapper,
  SingleDatePickerStyled
} from './Dates.styled.js';

import moment from 'Utils/moment';
import _get from 'lodash/get';

@observer
class Dates extends Component {
  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
  }

  @observable focusedInput = false;

  @computed get isCheckIn() {
    return _get(this.props, 'check_in', false);
  }

  @computed get dateObjs() {
    const { value } = this.isCheckIn ? this.props.check_in : this.props.check_out;
    return value ? moment(value) : null;
  }

  handleDateChange = (date) => {
    if (!date) return;

    const { onChange } = this.props;
    const type = this.isCheckIn ? 'check_in' : 'check_out';
    const currentDate = moment(date).format('YYYY-MM-DD');
    const viewDate = moment(date).format('DD.MM.YYYY');

    onChange(type, viewDate, currentDate);
  }

  componentDidMount = () => {
    this.handleFocusChange({ focused: true });
  }

  handleFocusChange(focusedInput) {
    this.focusedInput = focusedInput.focused;
  }

  render() {
    const placeholder = this.isCheckIn
      ? formatMessage('orders.check_in')
      : formatMessage('orders.check_out');

    return (
      <Wrapper>
        <SingleDatePickerStyled
          date={this.dateObjs}
          showLabel={false}
          onFocusChange={this.handleFocusChange}
          focused={this.focusedInput}
          onDateChange={this.handleDateChange}
          navPrev={<ArrowLeftIcon />}
          navNext={<ArrowRightIcon />}
          customCloseIcon={<CloseIcon />}
          hideKeyboardShortcutsPanel
          placeholder={placeholder}
          isOutsideRange={() => false}
        />
      </Wrapper>
    );
  }
}

Dates.propTypes = {
  check_in: PropTypes.object.isRequired,
  check_out: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Dates;
