import moment from 'Utils/moment';
import { types } from 'mobx-state-tree';

export const TariffClosedByDateRestrictions = types.model('TariffClosedByDateRestrictions', {
    start_date: types.maybe(types.string),
    end_date: types.maybe(types.string),
    closed_to_arrival: types.boolean,
    closed_to_departure: types.boolean,

    get fromDate() {
        return this.start_date
            ? moment(this.start_date).format('DD.MM.YYYY')
            : 'не задан';
    },

    get toDate() {
        return this.end_date
            ? moment(this.end_date).format('DD.MM.YYYY')
            : 'не задан';
    }
});
