import { types } from 'mobx-state-tree';

export const Price = types.model('Price', {
  amount: types.number,
  currency: types.string
});

export const Service = types.model('Service', {
  id: types.identifier(types.string),
  name: types.string,
  price: types.maybe(Price),
  description: types.string,
  category: types.string,
  category_name: types.string,
  isActive: types.optional(types.boolean, false)
}, {
  toggle() {
    this.isActive = !this.isActive;
  }
});

export const Category = types.model('Category', {
  id: types.identifier(types.string),
  services: types.optional(types.array(Service), []),

  get name() {
    return this.services.length > 0
      ? this.services[0].category_name
      : undefined;
  }
});
