import { types } from 'mobx-state-tree';

import { Room } from './Room';

export const Capacity = types.model('Capacity', {
  total: types.number
});

export const RoomType = types.model('RoomType', {
  id: types.identifier(types.string),
  name: types.string,
  beds: types.number,
  extra_beds: types.number,
  capacity: Capacity,
  rooms: types.optional(types.array(Room), [])
});
