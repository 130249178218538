import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Traveller from './Traveller';

@observer
class Travellers extends Component {
  render() {
    const { slot, travellers } = this.props;

    const listItems = travellers.map((item) => {
      return <Traveller key={item.id} slot={slot} traveller={item} />;
    });

    return listItems;
  }
}

Travellers.propTypes = {
  slot: PropTypes.object.isRequired,
  travellers: PropTypes.object.isRequired
};

export default Travellers;
