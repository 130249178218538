import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DuplicateIcon from 'react-icons/lib/md/control-point-duplicate';

const ReadableDateRange = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const TopControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding-bottom: 13px;
  color: silver;

  > :last-child {
    width: 30%;
  }
`;

const Value = styled.div``;

const Label = styled.div``;

const RightBlock = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
`;

const StatusBlock = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding: 12px 0px 12px 0px;

  ${({ unavailable }) => unavailable && css`
    color: #999999;
  `};
`;

const PeriodShow = props => {
  const {
    period,
    onEdit,
    onDelete,
    startDate,
    endDate,
    copyPeriod,
    currencySymbol
  } = props;
  const startDateValue = startDate
    .split('-')
    .reverse()
    .join('.');

  const endDateValue = endDate
    .split('-')
    .reverse()
    .join('.');

  const cs = classNames('form__fields-wrapper');

  const listItems = period.rates.map((item, index) => {
    const price = item.price.amount;

    const getPriceLabel = () => {
      if (price == 0) {
        return <FormattedMessage id='shared.free' />;
      }

      if (price < 0) {
        return null;
      }

      return price;
    };

    return (
      <Row key={index} unavailable={price < 0}>
        <Label>{item.description}</Label>

        <RightBlock>
          <Value>
            <span>{getPriceLabel()}</span>
          </Value>

          <StatusBlock>
            {price < 0 && <FormattedMessage id='shared.anavailable' />}
          </StatusBlock>
        </RightBlock>
      </Row>
    );
  });

  return (
    <>
      <TopControls>
        <ReadableDateRange>
          {startDateValue} - {endDateValue}
        </ReadableDateRange>

        <div className='buttons'>
          <button className='button gray' onClick={onEdit}>
            <FormattedMessage id='shared.change' />
          </button>
          <button className='button gray' onClick={onDelete}>
            <FormattedMessage id='shared.remove' />
          </button>
          <button className='button gray' onClick={copyPeriod}>
            <i className='icon'>
              <DuplicateIcon />
            </i>
          </button>
        </div>
      </TopControls>

      <div className={cs}>
        <ListHeader>
          <div>Тип размещения</div>
          <div>Цена, {currencySymbol}</div>
        </ListHeader>

        {listItems}
      </div>
    </>
  );
};

PeriodShow.propTypes = {
  period: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  copyPeriod: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired
};

export default PeriodShow;
