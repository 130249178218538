import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { HotelStore } from 'Stores/HotelStore';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';

import Show from './containers/Show';
import Edit from './containers/Edit';

@withRouter
@observer
class Hotel extends Component {
  render() {
    const { hotelStore } = this.props;

    return (
      <Provider hotelStore={hotelStore}>
        <div className='layout__page'>
          <TopBar isLoading={hotelStore.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            <Switch>
              <Route
                exact
                path='/hotel'
                component={Show}
              />

              <Route
                exact
                path='/hotel/edit'
                component={Edit}
              />
            </Switch>
          </div>
        </div>
      </Provider>
    );
  }
}

Hotel.defaultProps = {
  hotelStore: HotelStore.create()
};

export default Hotel;
