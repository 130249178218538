import { types, applySnapshot } from "mobx-state-tree";
import instance from "connection/instance";
import { CardOption } from "stores/models/CardOption";

export const ObjectTypesStore = types.model(
  "ObjectTypesStore",
  {
    types: types.optional(types.array(CardOption), []),
    state: types.maybe(types.enumeration(["pending", "done", "error"])),

    get isFetched() {
      return this.state === "done";
    },
  },
  {
    fetchChecked() {
      this.setState("pending");

      return instance
        .get("/api/filters/card_options")
        .then((response) => this.resetStore(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error));
    },

    setState(state, response = undefined) {
      this.state = state;
      return response;
    },

    errorHandler(error) {
      this.setState("error");
      return Promise.reject(error);
    },

    resetStore(response) {
      applySnapshot(this, { types: response.data.data });
      return response.data.data;
    },
  }
);
