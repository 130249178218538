import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Block from './Block';

@withRouter
@observer
class Blocks extends Component {
  render() {
    const { buildings } = this.props;

    const listItems = buildings.map((item) =>
      <Block key={item.id} building={item} />
    );

    return (
      <div className='groups'>
        {listItems}
      </div>
    );
  }
}

Blocks.propTypes = {
  buildings: PropTypes.array.isRequired
};

export default Blocks;
