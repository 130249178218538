import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import moment from 'Utils/moment';
import currencySymbolMap from 'Utils/currencySymbolMap';
import OrderRow from './OrderRow';
import {
  OrdersTableRow,
  OpenRowIcon,
  CloseRowIcon,
  OrdersTableTd,
  Text,
  OrdersTableHeader
} from './Orders.styled';
import getStatus from 'Pages/Orders/utils/getStatus';

@withRouter
@observer
class Order extends Component {
  @computed get createdAt() {
    const { created_at } = this.props.order;
    return moment(created_at).format('DD.MM.YYYY');
  }

  @computed get updatedAt() {
    const { updated_at } = this.props.order;
    return moment(updated_at).format('HH:mm, DD.MM.YYYY');
  }

  @computed get checkIn() {
    const { check_in } = this.props.order;
    return moment(check_in).format('DD.MM.YYYY');
  }

  @computed get checkOut() {
    const { check_out } = this.props.order;
    return moment(check_out).format('DD.MM.YYYY');
  }

  @computed get status() {
    const { state } = this.props.order;

    return getStatus(state);
  }

  @computed get manager() {
    const { first_name, last_name } = this.props.order.manager;
    const initial = first_name ? (first_name.toUpperCase().charAt(0) + '.') : '';

    return [last_name, initial].join(' ');
  }

  @computed get infoPath() {
    const { id } = this.props.order;
    return ['/orders', id, 'info'].join('/');
  }

  @computed get isActive() {
    const { location, order } = this.props;
    return location.pathname === ['/orders', order.id, 'info'].join('/');
  }

  @computed get sourceName() {
    const { source } = this.props.order;
    return source ? source.name : '-';
  }

  @computed get price() {
    const { price } = this.props.order;
    return price || '';
  }

  @computed get orderCode() {
    const { order_code } = this.props.order;
    return order_code || '';
  }

  handleClick = (e) => {
    const { history, handleRowChange } = this.props;
    e.preventDefault();
    history.push(this.infoPath);
    handleRowChange();
  }

  handleRowToggle = (e) => {
    const { order, handleRowChange } = this.props;

    e.preventDefault();
    e.stopPropagation();
    handleRowChange(order);

    this.props.history.push('/orders');
  }

  render() {
    const { order, isRowOpen, isPrevRowOpen } = this.props;
    const { amount, currency } = this.price;
    const suffix = currencySymbolMap(currency);

    return (
      <OrdersTableRow isOpen={isRowOpen}>
        <OrdersTableHeader
          isOpen={isRowOpen}
          isPrevRowOpen={isPrevRowOpen}
          onClick={this.handleClick}
        >
          <OrdersTableTd>
            {isRowOpen
              ? <CloseRowIcon
                  onClick={this.handleRowToggle}
                />

              : <OpenRowIcon
                  onClick={this.handleRowToggle}
                />
            }

            <Text> {this.orderCode} </Text>
          </OrdersTableTd>

          <OrdersTableTd>
            <NumberFormat
              value={amount}
              displayType='text'
              suffix={suffix}
              thousandSeparator={' '}
            />
          </OrdersTableTd>

          <OrdersTableTd>{this.checkIn}</OrdersTableTd>
          <OrdersTableTd>{this.checkOut}</OrdersTableTd>
          <OrdersTableTd>{this.sourceName}</OrdersTableTd>
          <OrdersTableTd>{this.manager}</OrdersTableTd>
          <OrdersTableTd>{this.updatedAt}</OrdersTableTd>
          <OrdersTableTd>
            <span className={this.status.label}>{this.status.text}</span>
          </OrdersTableTd>
        </OrdersTableHeader>

        {isRowOpen && (
          <OrderRow
            id={order.id}
            filters={this.props.filters}
            rowOpen={isRowOpen}
          />
        )}
      </OrdersTableRow>
    );
  }
}

Order.propTypes = {
  order: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isRowOpen: PropTypes.bool,
  isPrevRowOpen: PropTypes.bool.isRequired,
  handleRowChange: PropTypes.func.isRequired
};

export default Order;
