import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Map from '../Map';
import MdStar from 'react-icons/lib/md/star';

@observer
class GeneralInfo extends Component {
  render() {
    const { name, address, description, star } = this.props.hotel;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.general_information' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div className='options'>
              <div className='options__item'>
                <div className='key'>
                  <FormattedMessage id='hotel.name' />
                </div>
                <div className='value'>
                  {name}
                </div>
              </div>

              <div className='options__item'>
                <div className='key'>
                  <FormattedMessage id='hotel.description' />
                </div>
                <div className='value'>
                  {description}
                </div>
              </div>

              <div className='options__item'>
                <div className='key'>
                  <FormattedMessage id='hotel.address' />
                </div>
                <div className='value'>
                  {address.location}
                </div>
              </div>

              <div className='options__item'>
                <div className='key'>
                  <FormattedMessage id='hotel.star' />
                </div>
                <div className='value_star'>
                  <MdStar className='star__icon' />
                  {star}
                </div>
              </div>

              <div className='options__item'>
                <Map address={address} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GeneralInfo.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default GeneralInfo;
