import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { phoneFormat } from 'Utils/phoneFormat';

import MdLocalPhone from 'react-icons/lib/md/local-phone';
import MdAdd from 'react-icons/lib/md/add';

import FormMaskedInput from 'Shared/form/FormMaskedInput';
import FormInput from 'Shared/form/FormInput';
import Checkbox from 'Shared/form/Checkbox';

@observer
class Phone extends Component {
  render() {
    const { phone } = this.props;

    return (
      <div className='options__item'>
        <div className='key'>
          {phone.text}
        </div>
        <div className='value'>
          {phoneFormat({ tel: phone.value, ext: phone.suffix })}
        </div>
      </div>
    );
  }
}

Phone.propTypes = {
  phone: PropTypes.object.isRequired
};

export default Phone;
