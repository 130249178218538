import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { TPriceListsAction, ACTIONS, TPriceListsShowResponse, TPriceListsListResponse } from '../types';
import { NSSogaz } from '@types';

const fetchServices = async (price_list: NSSogaz.TPriceList): Promise<NSSogaz.TPriceList> => {
  const { data }: AxiosResponse<TPriceListsShowResponse> =
    await instance.get(`/api/sogaz/price_lists/${price_list.id}`);

  return { ...price_list, ...data.data };
};

const fetchPriceLists = () => {
  return async (dispatch: Dispatch<TPriceListsAction>): Promise<void> => {
    try {
      dispatch({ type: ACTIONS.PRICE_LISTS_FETCH_PENDING });

      const { data }: AxiosResponse<TPriceListsListResponse> = await instance.get('/api/sogaz/price_lists');

      const payload = await Promise.all(
        data.data.map(price_list => fetchServices(price_list))
      );

      dispatch({ type: ACTIONS.PRICE_LISTS_FETCH_FULLFILLED, payload });
    } catch (error) {
      dispatch({ type: ACTIONS.PRICE_LISTS_FETCH_REJECTED });
    }
  };
};

export default fetchPriceLists;
