import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RoomType from './RoomType';

@withRouter
@observer
class RoomTypes extends Component {
  render() {
    const { room_types } = this.props;

    return room_types.map((rt) => (
      <RoomType key={rt.id} room_type={rt} />
    ));
  }
}

RoomTypes.propTypes = {
  room_types: PropTypes.object.isRequired
};

export default RoomTypes;
