import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import Labels from './Labels';
import Content from './Content';

@withRouter
@inject('dashboardStore')
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.headerEl = React.createRef();
    this.labelEl = React.createRef();
    this.contentEl = React.createRef();
  }

  componentDidMount() {
    this.reservationScrollHandler = reaction(
      () => this.props.dashboardStore.position,
      (position) => this.reservationScroll(position),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.reservationScrollHandler();
  }

  reservationScroll({ left, top }) {
    this.contentEl.current.scrollLeft = left;
    this.contentEl.current.scrollTop = top;
  }

  handleContentScroll = (e) => {
    if (this.contentEl.current) {
      const values = {
        scrollLeft: this.contentEl.current.scrollLeft,
        scrollTop: this.contentEl.current.scrollTop
      };

      this.setHeaderPosition(values);
      this.setLablePosition(values);
    }
  }

  leftBtnHandler = (e) => {
    e.preventDefault();

    const { form, history } = this.props;
    const search = form.toLeft();

    history.push({ search });
  }

  rightBtnHandler = (e) => {
    e.preventDefault();

    const { form, history } = this.props;
    const search = form.toRight();

    history.push({ search });
  }

  setHeaderPosition(values) {
    if (this.headerEl.current) {
      const { scrollLeft } = values;
      this.headerEl.current.style.left = -scrollLeft + 'px';
    }
  }

  setLablePosition(values) {
    if (this.labelEl.current) {
      const { scrollTop } = values;
      this.labelEl.current.style.top = -scrollTop + 'px';
    }
  }

  render() {
    const { check_in, check_out, dayWidth, isFetched } = this.props.dashboardStore;
    return (
      <div className='dashboard'>
        <div className='dashboard__header'>
          <div className='ctrl left'>
            <a href='' onClick={this.leftBtnHandler}>&lsaquo;</a>
          </div>
          <div className='ctrl right'>
            <a href='' onClick={this.rightBtnHandler}>&rsaquo;</a>
          </div>

          <div className='dashboard__header--wrapper'>
            <div
              className='dashboard__header--grid'
              ref={this.headerEl}
            >
              <Header
                check_in={check_in}
                check_out={check_out}
                dayWidth={dayWidth}
              />
            </div>
          </div>
        </div>

        <div className='dashboard__label'>
          <div
            className='dashboard__label--wrapper'
            ref={this.labelEl}
          >
            <Labels />
          </div>
        </div>

        <div
          className='dashboard__content'
          ref={this.contentEl}
          onScroll={this.handleContentScroll}
        >
          <Content />
        </div>
      </div>
    );
  }
}

export default Dashboard;
