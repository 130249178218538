import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class PolicyRuleDeadlineNotice extends Component {
  render() {
    return (
      <p className='muted'>
        {formatMessage('tariffs.cancellation_policy.max_deadline_amount')}
      </p>

    );
  }
}

export default PolicyRuleDeadlineNotice;
