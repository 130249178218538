import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { withRouter, Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

import FormState, { fields } from 'Pages/Buildings/components/FormState';
import Form from 'Pages/Buildings/components/Form';
import Loader from 'Pages/Buildings/components/Loader';
import Actions from '../Actions';

import LanguageControl from 'Shared/components/LanguageFormControl';
import { UserProvider } from 'Context/FormLanguageContext';

@withRouter
@observer
class PageContent extends Component {
  @observable form = new FormState(fields);

  @computed get isLoading() {
    const { building } = this.props;
    return building.isPending;
  }

  state = {
    language: 'ru'
  };

  onClickHandler = (language) => {
    if (this.state.language === language) return;

    this.setState({ language: language });
  }

  componentDidMount() {
    const { building } = this.props;
    this.form.set(building.toForm());
  }

  render() {
    const { building } = this.props;

    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className='info__content'>
          {this.isLoading && <Loader />}

          <div className='layout__language-action'>
            <div className='content'>
              <div className='control'>
                <LanguageControl
                  onClick={this.onClickHandler}
                />
              </div>
            </div>
          </div>

          <div className='header'>
            {building.name}
          </div>

          <div className='content'>
            <Form form={this.form} currentLanguage={this.state.language} handleLanguageChange={this.context.handleLanguageChange} />
            <Actions form={this.form} building={building} />
          </div>
        </div>
      </UserProvider>
    );
  }
}

PageContent.propTypes = {
  building: PropTypes.object.isRequired
};

export default PageContent;
