import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';
import PropTypes from 'prop-types';

@observer
class TariffSource extends Component {
  render() {
    const { source } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>{formatMessage('tariff.source')}</span>
          <span className='value'>
            {source
              ? source.name
              : formatMessage('tariff.not_specified')}
          </span>
        </div>
      </div>
    );
  }
}

TariffSource.defaultProps = {
  source: null
};

TariffSource.propTypes = {
  source: PropTypes.object.isRequired
};

export default TariffSource;
