import styled from 'styled-components';

export const GroupsItem = styled.div`
  margin-bottom: 38px;
`;

export const GroupsItemContent = styled.div`
  width: auto;
  height: auto;
  background-color: #FFF;
  border: 1px solid #DCD8D2;
  border-radius: 4px;
`;

export const Segment = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  padding: 26px 30px 26px 30px;
  box-sizing: border-box;
`;
