import { types } from 'mobx-state-tree';

import { Quota } from './Quota';
import { Insurance } from './Insurance';

export const Source = types.model('Source', {
  id: types.identifier(types.string),
  insurance: types.maybe(types.reference(Insurance)),
  availabilities: types.optional(types.array(Quota), [])
});
