import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class Insurant extends Component {
  static propTypes = {
    insurant: PropTypes.object
  }

  render() {
    const { insurant } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>{formatMessage('tariff.insurant')}</span>
          <span className='value'>
            {insurant
              ? insurant.short_name
              : formatMessage('tariff.not_specified')}
          </span>
        </div>
      </div>
    );
  }
}

export default Insurant;
