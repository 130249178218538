import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Room from './Room';

@withRouter
@inject('quotasStore')
@observer
class Rooms extends Component {
  render() {
    const { beds_quantity, rooms, roomId } = this.props;

    const { hiddenRooms } = this.props.quotasStore;
    const currentIsHidden = hiddenRooms.get(roomId) ?? false;
    const filteredData = currentIsHidden ? [] : rooms.toJSON();

    return filteredData.map((room) => {
      return <Room key={room.id} room={room} beds_quantity={beds_quantity} />;
    });
  }
}

Rooms.propTypes = {
  rooms: PropTypes.object.isRequired,
  beds_quantity: PropTypes.number.isRequired,
  roomId: PropTypes.string.isRequired,
  quotasStore: PropTypes.object
};

export default Rooms;
