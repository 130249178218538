import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import moment from 'Utils/moment';

const format = 'DD.MM.YYYY';

const NameContainer = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  font-weight: 600;
`;

const AvailablePeriod = styled.div`
  display: flex;
  margin-top: 2px;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  position: relative;
`;

const Wrapper = styled.div`
  cursor: pointer;

  &:hover {
    ${NameContainer}, ${AvailablePeriod} {
      color: #1F6FCC;
    }
  }

  ${({ isActive }) => isActive && css`
    cursor: default;

    &:hover {
      ${NameContainer}, ${AvailablePeriod} {
        color: #000000;
      }
    }

    ${AvailablePeriod} {
      color: #000000;
    }

    ${NameContainer} {
      color: #000000;
    }
  `}
`;

function TariffContentHeader({ className, tariff, isActive, history }) {
  const { from, to } = tariff.available_period;
  const fromDate = moment(from).format(format);
  const toDate = moment(to).format(format);

  const handleTariffClick = (e) => {
    e.preventDefault();
    if (isActive) return;

    history.push(`/tariffs/${tariff.id}/edit/`);
  };

  return (
    <Wrapper
      className={className}
      isActive={isActive}
      onClick={handleTariffClick}
    >
      <NameContainer>
        {tariff.name}
      </NameContainer>

      <AvailablePeriod>
        {fromDate} &mdash; {toDate}
      </AvailablePeriod>
    </Wrapper>
  );
}

TariffContentHeader.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object,
  isActive: PropTypes.bool,
  history: PropTypes.object
};

export default styled(withRouter(TariffContentHeader))``;
