import { types } from 'mobx-state-tree';
import { Price } from 'Stores/models/Price';

export const TariffRateValue = types.model('TariffRateValue', {
  id: types.identifier(types.string),
  type: types.optional(types.string, 'TariffRateDailyRule'),
  price: Price,
  date: types.string,
  rate_id: types.string,
  tariff_id: types.string
});
