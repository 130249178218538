import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

@inject('store')
@observer
class MenuAction extends Component {
  onSetLanguage = (e) => {
    e.preventDefault();

    const { language, onClickHandler } = this.props;
    onClickHandler(language);
  }

  render() {
    const { language, isActive } = this.props;

    const klass = classNames({
      active: isActive
    });

    return (
      <a className={klass} onClick={this.onSetLanguage}>
        {language}
      </a>
    );
  }
}

MenuAction.propTypes = {
  language: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default MenuAction;
