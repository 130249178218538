import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class ByCash extends React.Component {
  render() {
    const { field } = this.props;

    return (
      <div className='checkbox__field' data-id='hotelPaymentByCash'>
        <div className='key'>
          <input
            className='input'
            {...field.$('allow').bind({
              type: 'checkbox',
              checked: field.$('allow').value
            })}
          />
        </div>
        <div className='value'>
          <label htmlFor={field.$('allow').id}>
            {field.label}
          </label>
        </div>
      </div>
    );
  }
}

ByCash.propTypes = {
  field: PropTypes.object.isRequired
};

export default ByCash;
