import React, { Component } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

@observer
class RestrictionPeriodTips extends Component {
  render() {
    const { text } = this.props;
    return (
      <div className="form__field price-calculation-rule">
        <div className="price-calculation-rule__tips">
          <FormattedMessage id={text} />
        </div>
      </div>
    );
  }
}

RestrictionPeriodTips.propTypes = {
  text: PropTypes.string,
};

export default RestrictionPeriodTips;
