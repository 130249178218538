import { observable, computed } from 'mobx';

class ControlStore {
  @observable isOpenned = false;

  @observable isEnabled = false;

  @computed get isDisabled() {
    return !this.isEnabled;
  }

  toggle() {
    this.isOpenned = !this.isOpenned;
  }

  open() {
    this.isOpenned = true;
  }

  close() {
    this.isOpenned = false;
  }

  enable() {
    this.isEnabled = true;
  }

  disable() {
    this.isEnabled = false;
  }
}

export default ControlStore;
