import { types } from 'mobx-state-tree';

export const Tariff = types.model('Tariff', {
  id: types.identifier(types.string),
  name: types.string,
  description: types.string,
  type: types.maybe(types.string),
  price_calculation_rule: types.maybe(types.enumeration(['fixed', 'float'])),
  services: types.optional(types.array(types.frozen), []),
  billing_hour: types.string
});
