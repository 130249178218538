import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import RoomTypesStore from 'Stores/RoomTypesStore/List';
import SingleSelect from 'Shared/form/SingleSelectObj';

@observer
class RoomType extends Component {
  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    const { roomTypesStore } = this.props;
    roomTypesStore.clear();
  }

  async fetch() {
    const { roomTypesStore, form } = this.props;

    await roomTypesStore.fetch();

    const room_type = form.$('room_type').value;

    if (room_type.id) {
      const roomTypes = roomTypesStore.room_types.toJSON();
      const currentRoomType = roomTypes.find(r => room_type.id === r.id);

      form.$('room_type').update(currentRoomType);
    }
  }

  @computed get isLoading() {
    const { roomTypesStore } = this.props;
    return roomTypesStore.isPending;
  }

  @computed get roomTypesOptions() {
    const { roomTypesStore } = this.props;

    return roomTypesStore.isFetched
      ? roomTypesStore.room_types.toJSON()
      : [];
  }

  render() {
    const { form } = this.props;

    return (
      <SingleSelect
        field={form.$('room_type')}
        simpleValue={false}
        valueKey='id'
        labelKey='name'
        searchable
        clearable
        isLoading={this.isLoading}
        options={this.roomTypesOptions}
      />
    );
  }
}

RoomType.defaultProps = {
  roomTypesStore: RoomTypesStore.create()
};

RoomType.propTypes = {
  form: PropTypes.object.isRequired,
  roomTypesStore: PropTypes.object.isRequired
};

export default RoomType;
