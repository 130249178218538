import * as React from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { NSSogaz } from '@types';
import {
  fetchMappings,
  fetchTariffs,
  fetchPriceLists,
  setPriceList,
  blockTariffs,
  unblockTariffs
} from 'pages/Sogaz/store/actions';
import { STATUSES } from 'pages/Sogaz/store/types';
import { roomTypesSelector } from 'pages/Sogaz/store/selectors';
import { useSogaz } from 'pages/Sogaz/store/hooks';

import Layout, { Header, Body, Container } from 'components/v2/Layout';
import HeadingComponent from 'components/v2/Heading';
import PriceLists from './PriceLists';
import RoomTypes from './RoomTypes';
import Compare from './Compare';

const Heading = styled(HeadingComponent)`
  margin: 0;
`;

const Row = styled.div`
  width: inherit;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  ${Row} {
    flex-grow: 1;
  }
`;

const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: inherit;
  box-sizing: border-box;

  ${Column} + ${Column} {
    margin-top: 48px;
  }
`;

const Wrapper = styled(Body)`
  padding: 48px 0;
`;

const BlockBtn = styled.button`
  color: #fff;
  background-color: #41AB42;
  font-size: 14px;
  border-radius: 4px;
  min-height: 32px;
  padding: 6px 12px;
  font-weight: 400;
  cursor: pointer;
  line-height: 16px;
  -webkit-appearance: none;
  margin-right: 40px;
  box-sizing: border-box;
  
  &:hover {
    background-color: #2c742d;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function Mappings({ className }: Props) {
  const { state, dispatch } = useSogaz();

  const isBlockedBtn = state.tariffs.data.some(item => item.status !== 'confirmed');
  const isUnblockedBtn = state.tariffs.data.every(item => item.status === 'confirmed');

  const showBtn = !!Cookies.get('restriction');

  const handleBLock = () => {
    if (isBlockedBtn) {
      dispatch(blockTariffs());
      dispatch(fetchTariffs());
    }

    if (isUnblockedBtn) {
      dispatch(unblockTariffs());
      dispatch(fetchTariffs());
    }
  };

  React.useEffect(() => {
    dispatch(fetchMappings());
    dispatch(fetchTariffs());
    dispatch(fetchPriceLists());
  }, []);

  const roomTypes = React.useMemo(() => {
    if (state.tariffs.status !== STATUSES.FULFILLED) {
      return [];
    }

    if (state.price_lists.status !== STATUSES.FULFILLED) {
      return [];
    }

    if (state.mappings.status !== STATUSES.FULFILLED) {
      return [];
    }

    return roomTypesSelector(state);
  }, [state.tariffs, state.price_lists, state.mappings]);

  const handleSelect = (item: NSSogaz.TPriceList) => {
    dispatch(setPriceList(item));
  };

  return (
    <Layout className={className}>
      <Header>
        <Container $fullWidth>
          <Heading level={1}>
            {'Сопоставление тарифов АО "СОГАЗ"'}
          </Heading>
        </Container>
        {showBtn && <BlockBtn onClick={handleBLock}>{isBlockedBtn ? 'Заблокировать' : 'Разблокировать'}</BlockBtn>}
      </Header>

      <Container $fullWidth>
        <Wrapper>
          <Grid>
            <Column>
              <Row>
                <PriceLists
                  priceLists={state.price_lists.data}
                  onSelect={handleSelect}
                />
              </Row>
            </Column>

            <Column>
              <Row>
                <RoomTypes
                  roomTypes={roomTypes}
                />
              </Row>
            </Column>
          </Grid>
        </Wrapper>
      </Container>

      <Compare />
    </Layout>
  );
}

export default Mappings;
