import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { GroupsItem, Segment, GroupsItemContent } from './GeneralInfo.styled';

import Form from 'Pages/RoomTypes/components/Form/GeneralInfo';

@observer
class GeneralInfo extends Component {
  render() {
    const { form } = this.props;

    return (
      <GroupsItem>
        <GroupsItemContent>
          <Segment>
            <Form form={form} />
          </Segment>
        </GroupsItemContent>
      </GroupsItem>
    );
  }
}

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired
};

export default GeneralInfo;
