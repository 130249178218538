import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import Form from '../Form';

@inject('registryStore')
@observer
class Overflow extends Component {
  constructor(props) {
    super(props);

    this.cancelHandler = this.cancelHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    this.formStyle = this.calcFormPosition();
  }

  cancelHandler(e) {
    e.preventDefault();
    this.props.registryStore.selectItems([]);
  }

  submitHandler(e) {
    e.preventDefault();
  }

  calcFormPosition() {
    const { hasSelected, firstSelected, lastSelected } = this.props.registryStore;
    if (!hasSelected) return {};

    const rectFirst = firstSelected.domNode.getBoundingClientRect();
    const rectLast = lastSelected.domNode.getBoundingClientRect();
    const rectWrap = ReactDOM.findDOMNode(this).getBoundingClientRect();

    return {
      top: rectFirst.y - rectWrap.y,
      left: rectFirst.x - rectWrap.x,
      width: rectLast.x - rectFirst.x + rectLast.width,
      height: rectLast.y - rectFirst.y + rectLast.height
    };
  }

  @observable formStyle = {};

  render() {
    return (
      <div className='priceboard__overflow'>
        <div className='priceboard__form' style={this.formStyle}>
          <Form />
        </div>
      </div>
    );
  }
}

export default Overflow;
