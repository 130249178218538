import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';

import NumberFormat from 'react-number-format';

@observer
class FormFormattedInput extends React.Component {
  static defaultProps = {
    placeholder: null,
    type: 'text',
    disabled: false,
    className: '',
    customError: false
  }

  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    customError: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, values) {
    const { value } = values;
    this.props.field.set(value);
  }

  @computed get isError() {
    const { field, customError } = this.props;

    return (field.error || customError) && !field.focused;
  }

  render() {
    const { field, type, disabled, className, customError, placeholder, ...restProps } = this.props;

    const inputCls = classNames(className, 'input', {
      error: this.isError
    });

    return (
      <NumberFormat
        {...field.bind(type, placeholder)}
        type={type}
        value={field.value}
        placeholder={field.placeholder}
        className={inputCls}
        disabled={disabled}
        readOnly={disabled}
        onChange={this.onChange}
        {...restProps}
      />
    );
  }
}

FormFormattedInput.defaultProps = {
  placeholder: null,
  type: 'text',
  disabled: false,
  className: '',
  customError: false
};

FormFormattedInput.propTypes = {
  field: PropTypes.object.isRequired
};

export default FormFormattedInput;
