import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject } from 'mobx-react';

import TopBarSearchBox from './TopBarSearchBox';
import TopBarActionBox from './TopBarActionBox';
import ConfirmModal from 'Components/ui/ConfirmModal';

@inject('hotelStore')
export default class TopBarContent extends Component {
  state = {
    openModal: false
  }

  openModalHandler = () => {
    this.setState({ openModal: true });
  }

  closeModalHandler = () => {
    this.setState({ openModal: false });
  }

  deleteAllPhotos = () => {
    const { hotelStore } = this.props;

    hotelStore.removeAllImages();

    this.closeModalHandler();
  }

  render() {
    return (
      <div className='layout__topbar-content'>
        <div className='layout__topbar-content-wrapper'>
          <TopBarSearchBox />

          <button className='button red' onClick={this.openModalHandler}>
            <FormattedMessage id='shared.remove_all_photos' />
          </button>

          {this.state.openModal && (
            <ConfirmModal
              title={<FormattedMessage id='shared.remove_all_photos_ask' />}
              description={<FormattedMessage id='shared.remove_all_photos_descr'/>}
              onApply={this.deleteAllPhotos}
              onCancel={this.closeModalHandler}
            />
          )}
          <TopBarActionBox />
        </div>
      </div>
    );
  }
}
