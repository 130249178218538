import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/ui/Button';
import { StopSalesPeriodWrapper, FormActions, StopSalesPeriodTitle } from './StopSales.styled';
import { FormattedMessage } from 'react-intl';
import StopSaleChange from './StopSaleChange';
import { observer } from 'mobx-react';

@observer
class StopSalesPeriod extends Component {
  reverseDate = (date) => {
    return date.split('-').reverse().join('.');
  };

  handleChangePeriod = e => {
    const { form, index } = this.props;
    e.preventDefault();
    form.isOpenFormIndex = index;
  };

  handleDeletePeriod = (e) => {
    e.preventDefault();
    const { form, index } = this.props;
    form.deleteStopSalePeriod(index);
  }

  render() {
    const { period: { from, to }, index, form } = this.props;
    return (
      <>
        <StopSalesPeriodWrapper>
          <StopSalesPeriodTitle>
            {`${this.reverseDate(from)} - ${this.reverseDate(to)}`}
          </StopSalesPeriodTitle>

          <FormActions isEditPeriod>
            <Button
              variant='secondary'
              onClick={this.handleChangePeriod}
              disabled={form.isAddStopSalePeriod}
            > <FormattedMessage id='shared.change' />
            </Button>

            <Button
              variant='secondary'
              onClick={this.handleDeletePeriod}
            > <FormattedMessage id='shared.remove' />
            </Button>

          </FormActions>
        </StopSalesPeriodWrapper>

        {form.isOpenFormIndex === index &&
          <StopSaleChange form={form} index={index} />}
      </>
    );
  };
};

StopSalesPeriod.propTypes = {
  period: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

export default StopSalesPeriod;
