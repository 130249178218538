import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class StayPeriodTips extends Component {
  render() {
    const { stay_period, billing_hour } = this.props;
    const days = stay_period.$('min_days').value;

    return (
      <div className='form__field stay-period'>
        <div className='stay-period__tips'>
          {days > 0
            ? <FormattedMessage id='stay_period.seted' values={{ days: days, billing_hour: billing_hour }} />
            : <FormattedMessage id='stay_period.not_seted' />}
        </div>
      </div>
    );
  }
}

StayPeriodTips.propTypes = {
  stay_period: PropTypes.object.isRequired,
  billing_hour: PropTypes.string
};

export default StayPeriodTips;
