import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { computed, observable } from 'mobx';

import PriceListStore from 'Stores/PriceListStore/index';

import TopBar from 'Shared/components/TopBar/index';
import TopBarContent from './components/TopBarContent/index';
import PageBody from './components/PageBody/index';

@inject('store')
@observer
class Show extends Component {
  constructor(props) {
    super(props);

    this.priceListStore = PriceListStore.create();
  }

  @computed get isLoading() {
    return this.priceListStore.isPending;
  }

  componentDidMount() {
    const { match } = this.props;

    this.priceListStore.fetchOne(match.params.id)
      .catch(error => this.errorSubmitHandler(error));
  }

  componentWillUnmount() {
    this.priceListStore.clear();
  }

  errorSubmitHandler(error) {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    return (
      <Provider priceListStore={this.priceListStore}>
        <div className='layout__page'>
          <TopBar isLoading={this.isLoading}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            {this.priceListStore.price_list && <PageBody />}
          </div>
        </div>
      </Provider>
    );
  }
}

export default Show;
