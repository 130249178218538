import { types } from 'mobx-state-tree';
import { Quota } from 'Stores/RoomTypesStore/Quota';

export const RoomType = types.model('RoomType', {
  id: types.identifier(types.string),
  name: types.string,
  beds: types.number,
  extra_beds: types.number,
  count: types.number,
  area: types.optional(types.number, 10),
  quotas: types.optional(types.array(Quota), [])
});
