import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';

import DownIcon from 'react-icons/lib/md/keyboard-arrow-down';
import UpIcon from 'react-icons/lib/md/keyboard-arrow-up';
import { FormattedMessage } from 'react-intl';
import { OrdersTableTr } from './Orders.styled';

@inject('sortForm')
@observer
class Header extends Component {
  sortByHandler = (field) => (e) => {
    e.preventDefault();

    const { sortForm } = this.props;
    const { sort_by, direction } = sortForm.values();

    const values = sort_by === field
      ? { sort_by: field, direction: -1 * direction }
      : { sort_by: field, direction: -1 };

    sortForm.set(values);
  }

  @computed get SortIcon() {
    const { sortForm } = this.props;

    return sortForm.$('direction').value > 0
      ? <DownIcon />
      : <UpIcon />;
  }

  render() {
    const { sortForm } = this.props;

    const sortBy = sortForm.$('sort_by').value;

    return (
      <OrdersTableTr>
        <div className='orders-table__th code'>
          <FormattedMessage id='orders.code' />
        </div>

        <div className='orders-table__th price'>
          <FormattedMessage id='orders.price' />
        </div>

        <div
          onClick={this.sortByHandler('check_in')}
          className={classNames('orders-table__th date sort', { active: sortBy === 'check_in' })}
        >
          <FormattedMessage id='orders.check_in' />
          <span className='icon'>{this.SortIcon}</span>
        </div>

        <div
          onClick={this.sortByHandler('check_out')}
          className={classNames('orders-table__th date sort', { active: sortBy === 'check_out' })}
        >
          <FormattedMessage id='orders.check_out' />
          <span className='icon'>{this.SortIcon}</span>
        </div>

        <div className='orders-table__th source'>
          <FormattedMessage id='orders.source' />
        </div>

        <div className='orders-table__th manager'>
          <FormattedMessage id='orders.manager' />
        </div>

        <div
          onClick={this.sortByHandler('updated_at')}
          className={classNames('orders-table__th created_at sort', { active: sortBy === 'updated_at' })}
        >
          <FormattedMessage id='orders.last_changed' />
          <span className='icon'>{this.SortIcon}</span>
        </div>

        <div
          onClick={this.sortByHandler('aasm_state')}
          className={classNames('orders-table__th status sort', { active: sortBy === 'aasm_state' })}
        >
          <FormattedMessage id='orders.status' />
          <span className='icon'>{this.SortIcon}</span>
        </div>
      </OrdersTableTr>
    );
  }
}

Header.propTypes = {
  sortForm: PropTypes.object.isRequired
};

export default Header;
