import React from 'react';
import LongMarkSlider from 'Shared/ui/LongMarkSlider';
import ShortMarkSlider from 'Shared/ui/ShortMarkSlider';
import _range from 'lodash/range';

export const getSliderMarks = (startvalue, elementsCount, value, type) => {
  const countsArray = _range(startvalue, elementsCount);
  const marks = {};

  countsArray.forEach(count => {
    const x = count % 3;
    if (!x) {
      marks[count] = <LongMarkSlider count={count} value={value} type={type} />;
    } else {
      marks[count] = <ShortMarkSlider count={count} value={value} type={type} />;
    }
  });

  return marks;
};
