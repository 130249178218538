import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { PromoClassifiersStore } from 'Stores/PromoClassifiers';
@observer
class Promo extends Component {
  componentDidMount() {
    const { promoClassifiersStore } = this.props;
    promoClassifiersStore.fetch();
  }

  render() {
    const {
      tariff: {
        promo: { target, active, applied_percent_off: percent, disclaimer }
      }
    } = this.props;

    const promoDesc = this.props.promoClassifiersStore.promo
      .toJSON()
      .find((el) => el.tag === target)?.name;

    return (
      <div className="row">
        <div className="col col-12">
          <div className="options">
            <div className="options__item">
              <span className="key">{promoDesc}</span>

              <span className="value">
                {active &&
                  target !== 'new_year' &&
                  ((
                    <FormattedMessage
                      id="tariffs.promo.percent"
                      values={{ percent }}
                    />
                  ) || <span>&mdash;</span>)}
              </span>

              {disclaimer && <span className="value">{disclaimer}</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Promo.defaultProps = {
  promoClassifiersStore: PromoClassifiersStore.create()
};
Promo.propTypes = {
  tariff: PropTypes.object.isRequired,
  promoClassifiersStore: PropTypes.object.isRequired
};

export default Promo;
