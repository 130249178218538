import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Slot from './Slot';

@observer
class Slots extends Component {
  render() {
    const { tariff, slots } = this.props;

    const listItems = slots
      .filter(item => item.state != 'cancelled')
      .map((item, number) => (
        <Slot
          key={item.id}
          number={number + 1}
          slot={item}
          tariff={tariff}
        />
      ));

    return (
      <div className='policies divided'>
        {listItems}
      </div>
    );
  }
}

Slots.propTypes = {
  slots: PropTypes.object.isRequired,
  tariff: PropTypes.object.isRequired
};

export default Slots;
