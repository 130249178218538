import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

import TreatmentFactorsTree from './TreatmentFactorsTree';

@observer
class TreatmentFactors extends Component {
  render() {
    const { hotel } = this.props;
    const treatment_factors = this.props.hotel.treatment_factors.toJSON();

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.treatment_factors' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {!_isEmpty(treatment_factors)
              ? <TreatmentFactorsTree hotel={hotel} />

              : <p className='muted'>
                <FormattedMessage id='hotel.treatment_factors_didnt_choosed' />
                </p>}
          </div>
        </div>
      </div>
    );
  }
}

TreatmentFactors.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default TreatmentFactors;
