import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import store from 'store';
import _pick from 'lodash/pick';
import defaultLocale from 'Utils/defaultLocale';

import { PayloadStore } from './PayloadStore';
import { verifyJWT } from '../utils/jwt';

export const UserSetting = types.model('UserSettings', {
  language: types.optional(types.string, defaultLocale),
  should_run_wizard: types.optional(types.boolean, false)
}, {
  setWizardFlag(value) {
    this.should_run_wizard = false;
  }
});

export const UserStore = types.model('UserStore', {
  email: types.maybe(types.string),
  first_name: types.maybe(types.string),
  last_name: types.maybe(types.string),
  token: types.maybe(types.string),
  payload: types.maybe(PayloadStore),
  setting: types.optional(UserSetting, {}),

  get authenticated() {
    return this.payload && !this.payload.expired;
  }
}, {
  afterAttach() {
    const data = this.readFromLocalStorage();

    if (data) {
      this.setDefaulsHeader(data);
      this.updateUser(data);

      if (this.authenticated) { this.fetchProfile(data); }
    }
  },

  login(form) {
    const data = { client: form.values() };

    const config = {
      headers: { Authorization: null }
    };

    instance.post('/api/auth/sign_in', data, config)
      .then(response => verifyJWT(response))
      .then(data => this.setDefaulsHeader(data))
      .then(data => this.writeToLocalStorage(data))
      .then(data => this.updateUser(data))
      .then(data => this.fetchProfile(data))
      .then(() => form.reset())
      .catch(error => console.log(error));
  },

  logout() {
    instance.delete('/api/auth/sign_out')
      .catch(error => console.log('Catch: ', error))
      .then((response) => {
        store.remove('userStore');
        this.updateUser({});
        this.unsetDefaultHeader();
      });
  },

  fetchProfile(data) {
    instance.get('/api/profile')
      .then(response => this.assignSettings(response));
  },

  assignSettings(response) {
    const { setting } = response.data.client;
    applySnapshot(this.setting, setting);
  },

  updateUser(data) {
    data = _pick(data, ['email', 'token', 'payload']);
    applySnapshot(this, data);

    return data;
  },

  setDefaulsHeader(data) {
    const { token } = data;
    instance.defaults.headers.common.Authorization = ['Bearer', token].join(' ');

    return data;
  },

  unsetDefaultHeader() {
    instance.defaults.headers.common.Authorization = undefined;
  },

  writeToLocalStorage(data) {
    const json = _pick(data, ['email', 'first_name', 'last_name', 'token', 'payload']);
    store.set('userStore', json);

    return data;
  },

  readFromLocalStorage() {
    return store.get('userStore');
  }
});
