import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

export default class ServiceHeader extends Component {
  constructor(props) {
    super(props);
    this.toggleItem = this.toggleItem.bind(this);
  }

  toggleItem() {
    this.props.service.toggle();
  }

  render() {
    const { name, price } = this.props.service;

    return (
      <div className='collapse__item-header' onClick={this.toggleItem}>
        <div className='description'>
          {name}
        </div>

        {false &&
          <div className='price'>
            <NumberFormat
              value={price}
              displayType='text'
              suffix=' руб.'
              thousandSeparator={' '}
            />
          </div>}
      </div>
    );
  }
}
