import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _includes from 'lodash/includes';

import Card from './Card';

@observer
class Cards extends Component {
  render() {
    const listItems = this.props.items.map(item => {
      return <Card key={item} cardName={item} />;
    });

    return (
      <div className='bank_cards'>
        <div className='content'>
          {listItems}
        </div>
      </div>
    );
  }
}

Cards.propTypes = {
  items: PropTypes.array.isRequired
};

export default Cards;
