import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import Bed from './Bed';

@withRouter
@observer
class Beds extends Component {
  @computed get mainBeds() {
    const { beds, beds_quantity } = this.props;

    const data = beds
      .filter(bed => bed.type === 'main')
      .sort((a, b) => a.number - b.number);

    for (let i = data.length; i < beds_quantity; i++) {
      const emptyBed = Object.assign(
        {}, data[i - 1], { number: i + 1, events: observable([]) }
      );

      data.push(emptyBed);
    }

    return data;
  }

  @computed get extraBeds() {
    const { beds } = this.props;

    const data = beds
      .filter(bed => bed.type === 'extra')
      .sort((a, b) => a.number - b.number);

    return data;
  }

  render() {
    const mainItems = this.mainBeds
      .map(bed => <Bed key={[bed.number, bed.type].join()} bed={bed} />);

    const extraItems = this.extraBeds
      .map(bed => <Bed key={[bed.number, bed.type].join()} bed={bed} />);

    return [mainItems, extraItems];
  }
}

Beds.propTypes = {
  beds: PropTypes.object.isRequired,
  beds_quantity: PropTypes.number.isRequired
};

export default Beds;
