import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Rule from './Rule';

@observer
class RulesTable extends Component {
  render() {
    const { policy_rules, form } = this.props;

    const listItems = policy_rules.map((item) => {
      return <Rule key={item.id} rule={item} form={form} />;
    });

    return (
      <table className='table divided'>
        <tbody>
          {listItems}
        </tbody>
      </table>

    );
  }
}

RulesTable.propTypes = {
  policy_rules: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default RulesTable;
