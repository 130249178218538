import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Disease } from './Disease';

export const DiseasesStore = types.model('DiseasesStore', {
  diseases: types.optional(types.array(Disease), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  }
}, {
  fetch() {
    this.setState('pending');

    return instance.get('/api/filters/treatment_profiles')
      .then(response => this.resetStore(response.data))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  fetchChecked(data = {}) {
    this.setState('pending');

    return instance.get('/api/filters/treatment_profiles', { params: data })
      .then(response => this.resetStore(response.data))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  fetchOne(disease_id) {
    const disease = resolveIdentifier(Disease, this, disease_id);

    return disease.fetch();
  },

  clear() {
    const data = { diseases: [] };
    applySnapshot(this, data);
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  resetStore(response) {
    const { data } = response;

    applySnapshot(this, { diseases: data });
    return data;
  }
});
