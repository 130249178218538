import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {dateFormatWithoutOffset} from 'Utils/dateFormat';

@observer
class DatesItem extends Component {
  render() {
    const { start_date, end_date } = this.props.date;

    return (
      <div className='dates__item'>
        {dateFormatWithoutOffset(start_date, 'L')} &ndash; {dateFormatWithoutOffset(end_date, 'L')}
      </div>
    );
  }
}

DatesItem.propTypes = {
  date: PropTypes.object.isRequired
};

export default DatesItem;
