import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';

import Switch from 'components/ui/Switch/Switch';

const GroupItem = styled.div`
  margin-bottom: 2rem;
`;

const GroupItemContent = styled.div`
  width: auto;
  height: auto;
  background-color: #fff;
`;

const Wrapper = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  padding: 30px;
  border: 1px solid #dcd8d2;
  box-sizing: border-box;
  background-color: #fff;
  min-width: 319px;
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => (props.isConnected ? '#58CA97' : '#999')};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ContentHeader = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 326px;
`;

@inject('integrationsStore')
@observer
class TermsAccommondation extends Component {
  state = {
    settings: this.props.settings.fill_main_seats_for_no_bed
  };

  componentDidMount() {
    const { settings } = this.state;
    this.setState({ settings: !settings });
  }

  render() {
    const { settings } = this.props;
    const hasCookie = Cookies.get('restriction');
    const { fill_main_seats_for_no_bed } = settings;
    const status = fill_main_seats_for_no_bed === true ? 'Активен' : 'Неактивен';

    const handleChangeSwitch = async () => {
      const { settings } = this.state;
      this.setState({ settings: !settings });
      await this.props.integrationsStore.toggleSettings({
        settings: { fill_main_seats_for_no_bed: settings }
      });
    };

    return (
      <GroupItem>
        <GroupItemContent>
          <Wrapper>
            <Content>
              <ContentWrapper>
                <ContentHeader>
                  Учитывать заполнение основных мест при размещении детей без
                  места
                </ContentHeader>
                <Status>{status}</Status>
              </ContentWrapper>
              <Switch
                active={fill_main_seats_for_no_bed}
                onClick={handleChangeSwitch}
                disabled={!hasCookie}
              />
            </Content>
          </Wrapper>
        </GroupItemContent>
      </GroupItem>
    );
  }
}

TermsAccommondation.propTypes = {
  settings: PropTypes.object.isRequired,
  integrationsStore: PropTypes.any
};

export default TermsAccommondation;
