import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'Utils/moment';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import DateRangeInput from 'Shared/form/DateRangeInput';
import FormFormattedInput from 'Shared/form/FormFormattedInput';
import { FormattedMessage } from 'react-intl';

@observer
class Quota extends Component {
  handleDelete = (e) => {
    e.preventDefault();
    const { quota } = this.props;

    quota.has('id') && quota.$('id').value
      ? quota.update({ _destroy: true })
      : quota.del();
  }

  leftBound = moment().subtract(1, 'years')

  rightBound = moment().add(2, 'years')

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) ||
      this.rightBound.isBefore(day);
  }

  render() {
    const { quota } = this.props;

    return (
      <div className='quotas__item'>
        <div className='content'>
          <div className='quota__dates'>
            <DateRangeInput
              showLabel={false}
              startDate={quota.$('start_date')}
              endDate={quota.$('end_date')}
              enableOutsideDays
              isOutsideRange={this.isOutsideRange}
            />
          </div>

          <div className='quota__quantity'>
            <FormFormattedInput
              field={quota.$('quantity')}
            />
            <label htmlFor={quota.$('quantity').id}>
              <FormattedMessage
                id='quotas.rooms_count_only_label'
                values={{ count: quota.$('quantity').value }}
              />
            </label>
          </div>

          <div className='quota__action'>
            <a
              href=''
              className='quota__action-icon'
              onClick={this.handleDelete}
            >
              <CloseIcon />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Quota.propTypes = {
  quota: PropTypes.object.isRequired
};

export default Quota;
