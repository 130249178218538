import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { dateFormat } from 'Utils/dateFormat';

@observer
class ExpitedAt extends Component {
  render() {
    const { expiration_time } = this.props.reservation;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='orders.expired_at' />
        </div>
        <div className='value'>
          <span className='text error'>
            {dateFormat(expiration_time, 'LLL')}
          </span>
        </div>
      </div>
    );
  }
}

ExpitedAt.propTypes = {
  reservation: PropTypes.object.isRequired
};

export default ExpitedAt;
