import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Building } from './Building';

const BuildingsStore = types.model('BuildingsStore', {
  buildings: types.optional(types.array(Building), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    return instance.get('/api/buildings', { params: params })
      .then((response) => this.applySnapshot(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { meta: {}, buildings: [] };
    applySnapshot(this, data);
  },

  create(values) {
    this.setState('pending');

    const params = { building: values };

    return instance.post('/api/buildings', params)
      .then((response) => this.add(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  applySnapshot(response) {
    const { status, data } = response;

    if (status === 200) {
      applySnapshot(this, data);
    }

    return response;
  },

  find(id) {
    return resolveIdentifier(Building, this, id);
  },

  add(response) {
    const { building } = response.data;
    this.buildings.push(building);

    return building;
  }
});

export default BuildingsStore;
