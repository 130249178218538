import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Form from '../Form';

@observer
class Content extends Component {
  render() {
    const { service } = this.props;

    return (
      <div className='info__content'>
        <div className='header'>
          {service.name}
        </div>

        <div className='content'>
          <Form service={service} />
        </div>
      </div>
    );
  }
}

Content.propTypes = {
  service: PropTypes.object.isRequired
};

export default Content;
