import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from 'Pages/Tariffs/components/Form/Periods';

@observer
class RatesRules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  toggleSeasons = () => {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  }

  render() {
    const { form } = this.props;
    const { opened } = this.state;
    const hasPeriods = form?.periods?.length > 0;

    return (
      <div className='groups__item'>
        <div className="groups__item_wrapper">
          <div className='groups__item-header'>
            <FormattedMessage id='periods.self' />
          </div>
          {hasPeriods && <div className="showAll" onClick={this.toggleSeasons}>
            {!opened ? 'Показать все периоды' : 'Показать актуальные периоды'}
          </div>}
        </div>

          <div className='groups__item-content'>
          <div className='segment'>
            <Form form={form} periods={form.periods} opened={opened}/>
          </div>
        </div>
      </div>
    );
  }
}

RatesRules.propTypes = {
  form: PropTypes.object.isRequired
};

export default RatesRules;
