import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isNull from 'lodash/isNull';

function isObject(o) {
  // return Object.prototype.toString.call(o) === "[object Object]";
  return _isObject(o);
}

function isEmpty(value) {
  if (_isString(value) && _isEmpty(value)) {
    return true;
  }

  if (_isObject(value) && _isEmpty(value)) {
    return true;
  }

  if (_isNull(value)) {
    return true;
  }

  return false;
}

export function objectClean(d) {
  return Object.entries(d)
    .reduce((acc, [k, v]) => (
      isEmpty(v)
        ? acc
        : { ...acc, [k]: (isObject(v) ? objectClean(v) : v) }
    ), {});
}
