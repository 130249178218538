import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'hotel',
  'hotel.id',
  'check_in',
  'check_out',
  'availability',
  'availability.id',
  'availability.name',
  'availability.multi_seat',
  'tariff',
  'tariff.id',
  'tariff.name',
  'room_type',
  'room_type.id',
  'room_type.name',
  'room_type.beds',
  'room_type.extra_beds',
  'rooms[]',
  'rooms[].capacity',
  'rooms[].capacity.beds',
  'rooms[].capacity.extra_beds',
  'beds',
  'extra_beds',
  'slots[]',
  'slots[].id',
  'slots[].type',
  'slots[].rate',
  'slots[].rate.type',
  'slots[].rate.age_group',
  'slots[].rate.occupation',
  'slots[].rate.description',
  'slots[].cash',
  'slots[].travellers',
  'slots[].travellers[].id',
  'slots[].travellers[].first_name',
  'slots[].travellers[].middle_name',
  'slots[].travellers[].last_name',
  'slots[].travellers[].age_group',
  'slots[].travellers[].gender'
];

const labels = {
  check_in: formatMessage('dashboard.dates'),
  check_out: formatMessage('dashboard.dates'),
  tariff: formatMessage('dashboard.tariff'),
  availability: formatMessage('dashboard.tariff'),
  beds: formatMessage('dashboard.beds'),
  extra_beds: formatMessage('dashboard.extra_beds'),
  'slots[].rate': formatMessage('dashboard.rate'),
  'slots[].travellers[].first_name': formatMessage('shared.name'),
  'slots[].travellers[].last_name': formatMessage('shared.surname'),
  'slots[].travellers[].middle_name': formatMessage('shared.patronym'),
  'slots[].travellers[].age_group': formatMessage('shared.age_group'),
  'slots[].travellers[].gender': formatMessage('shared.gender'),
  'slots[].travellers[].document': 'Паспортные данные',
  'slots[].travellers[].document.number': formatMessage('shared.passport_no')
};

const placeholders = {
  check_in: formatMessage('dashboard.check_in_date'),
  check_out: formatMessage('dashboard.check_out_date'),
  tariff: formatMessage('shared.choose'),
  availability: formatMessage('shared.choose'),
  beds: '0',
  extra_beds: '0',
  'slots[].rate': formatMessage('shared.choose'),
  'slots[].travellers[].first_name': formatMessage('shared.ivan'),
  'slots[].travellers[].last_name': formatMessage('shared.ivanov'),
  'slots[].travellers[].middle_name': formatMessage('shared.ivanovich'),
  'slots[].travellers[].age_group': '',
  'slots[].travellers[].gender': 'Укажите пол',
  'slots[].travellers[].document.number': ''
};

const rules = {
  check_in: 'string|required',
  check_out: 'string|required',
  tariff: 'required',
  'tariff.id': 'string|required',
  hotel: 'required',
  'hotel.id': 'string|required',
  'slots[].rate.type': 'string|required',
  'slots[].travellers[].first_name': 'string|required',
  'slots[].travellers[].last_name': 'string|required',
  'slots[].travellers[].middle_name': 'string|required',
  'slots[].travellers[].gender': 'string|required',
  'slots[].travellers[].age_group': 'string|required'
};

const values = {
  beds: 0,
  extra_beds: 0,
  slots: []
};

const extra = {
  'slots[].travellers[].gender': [
    { value: 'male', label: formatMessage('shared.male') },
    { value: 'female', label: formatMessage('shared.female') }
  ]
};

export default {
  fields,
  labels,
  extra,
  placeholders,
  rules,
  values
};
