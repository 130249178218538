function getTariffsList({ tariff, list = [], id }) {
  list.push(tariff);

  if (tariff.children) {
    return getTariffsList({ tariff: tariff.children[0], list, id });
  }

  let index = list.findIndex(el => el.id === id);
  index <= 0 && (index = 1);
  index === list.length - 1 && list.length > 2 && (index = list.length - 2);

  return list.slice(index - 1, index + 2);
}

export default getTariffsList;
