import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

@inject('priceListState')
@observer
class SourceIsNotConfirmable extends Component {
  render() {
    const { name } = this.props.source;

    return (
      <div className='groups__item'>
        <div className='groups__item-content'>
          <div className='segment'>
            <p className='error'>
              Источник <b>{name}</b> не требует подтверждения тарифов. <br />
              Все тарифы созданнные с данным источником подтверждаются автоматически.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

SourceIsNotConfirmable.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
  }).isRequired
};

export default SourceIsNotConfirmable;
