import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default class TopBarSearchBox extends Component {
  render() {
    return (
      <div className='search-box'>
        <form method='post' action='' className='form'>
          <div className='form__fields'>
            <Link
              to='/price_lists'
              className='button gray'
            ><FormattedMessage id='price_lists.all' />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}
