import React from 'react';
import PropTypes from 'prop-types';
import _compact from 'lodash/compact';

import AgeInput from 'Shared/form/AgeInput';
import {
  RatesFormLeft,
  FormFieldsGroupLeft,
  FormFieldsGroupRight,
  AgeGroupSelect,
  OccupationSelect
} from 'Styles/styled';

const Rate = props => {
  const {
    rateForm,
    rateForm: { isChild },
    agePeriod,
    form
  } = props;

  const roomType = form.$('room_type').values();

  const options = _compact([
    rateForm.mainAdultOpts(roomType),
    rateForm.mainChildOpts(),
    rateForm.extraAdultOpts(),
    rateForm.extraChildOpts(),
    rateForm.noPlaceChildOpts()
  ]);

  const extraOptions = options.filter(
    option =>
      option.age_group === 'adult' &&
      option.occupation === 'main'
  )[0].options;

  const extraSelectedOptions = extraOptions.filter(extraOption =>
    extraOption.count === rateForm.values().count
  )[0];

  const selectedOption = options.filter(option =>
    option.age_group === rateForm.values().age_group &&
      option.occupation === rateForm.values().occupation
  )[0];

  const changeHandler = value => {
    if (!value) {
      return rateForm.reset();
    }

    rateForm.update(value);
  };

  return (
    <>
      <RatesFormLeft>
        <FormFieldsGroupLeft>
          <AgeGroupSelect
            isChild={isChild}
            labelKey='description'
            valueKey='description'
            clearable={false}
            searchable={false}
            options={options}
            onChange={changeHandler}
            value={selectedOption}
          />

          {rateForm.isMain && rateForm.isAdult && (
            <OccupationSelect
              onChange={changeHandler}
              clearable={false}
              searchable={false}
              options={extraOptions}
              valueKey='count'
              labelKey='descriptionOption'
              value={extraSelectedOptions}
              menuStyle={{ width: '240px', borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
            />
          )}
        </FormFieldsGroupLeft>

        {isChild &&
          <FormFieldsGroupRight>
            <AgeInput agePeriod={agePeriod} />
          </FormFieldsGroupRight>}

      </RatesFormLeft>
    </>
  );
};

Rate.propTypes = {
  rateForm: PropTypes.object.isRequired,
  agePeriod: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default Rate;
