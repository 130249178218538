import { formatMessage } from 'Utils/formatMessage';

const fields = [{
  name: 'id',
  rules: 'string'
}, {
  name: 'name',
  label: formatMessage('services.service'),
  placeholder: '',
  rules: 'required|string'
}, {
  name: 'description',
  label: formatMessage('services.description'),
  placeholder: '',
  rules: 'required|string'
}, {
  name: 'price',
  label: formatMessage('services.price'),
  rules: 'required'
}, {
  name: 'category',
  label: formatMessage('services.type'),
  placeholder: formatMessage('shared.choose'),
  rules: 'required|string',
  extra: [{
    value: 'general',
    label: formatMessage('services.general')
  }, {
    value: 'food',
    label: formatMessage('services.foodservice')
  }, {
    value: 'medicine',
    label: formatMessage('services.medical_and_rehabilitation')
  }]
}];

const values = {
  price: 0
};

export default { fields, values };
