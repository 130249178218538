import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import ByCash from './ByCash';
import ByCard from './ByCard';
import SingleSelect from 'Shared/form/SingleSelect';
import ByPrepayment from "./ByPrepayment";

@observer
class PaymentDetails extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.payment_details' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div className='form'>
              <div className='checkbox__fields'>
                <ByCash field={form.$('payment_details.by_cash')} />
                <ByCard field={form.$('payment_details.by_card')} />
                <ByPrepayment field={form.$('payment_details.by_prepayment')} />
                <SingleSelect
                  className='form__field w100'
                  disabled='true'
                  field={form.$('currency')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentDetails.propTypes = {
  form: PropTypes.object.isRequired
};

export default PaymentDetails;
