import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import Service from './Service';
import ServicesCaption from './ServicesCaption';

@observer
class Services extends Component {
  @computed get hasServices() {
    const { services } = this.props;
    return services && services.length > 0;
  }

  render() {
    const { services } = this.props;

    const listItems = services.map((item) => {
      return <Service key={item.id} service={item} />;
    });

    return (
      <div className='collapse'>{listItems}</div>
    );
  }
}

Services.propTypes = {
  services: PropTypes.array.isRequired
};

export default Services;
