import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { FormLanguageContext } from 'Context/FormLanguageContext';

import Language from './Language';

const LANGUAGE = ['ru', 'en'];

class MenuAction extends Component {
  static contextType = FormLanguageContext;
  onClickHandler = (language) => {
    this.props.onClick(language);
  }

  render() {
    const currentLanguage = this.context.currentLanguage;
    const listItems = LANGUAGE.map(language => {
      return (
        <Language
          key={language}
          language={language}
          isActive={language === currentLanguage}
          onClickHandler={this.onClickHandler}
        />
      );
    });

    return (
      <div className='control__language'>
        {listItems}
      </div>
    );
  }
}

export default MenuAction;
