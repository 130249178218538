import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import _range from 'lodash/range';

import Source from './Source';

@observer
class Sources extends Component {
  findQuotas(room_type_id) {
    const { quotasStore } = this.props;
    const room_type = quotasStore.findOne(room_type_id);

    return room_type
      ? room_type.quotas
      : [];
  }

  render() {
    const { room_type, sources } = this.props;

    const listItems = sources
      .map(s => <Source key={s.id} room_type={room_type} source={s} />);

    return (
      <div className='sources'>
        {listItems}
      </div>
    );
  }
}

Sources.propTypes = {
  room_type: PropTypes.object.isRequired,
  sources: PropTypes.object.isRequired
};

export default Sources;
