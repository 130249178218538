import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Source from './Source';
import CustomCheckbox from '../CustomCheckbox';

@observer
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allSources: false
    };
  }

  handleCheckboxChange = () => {
    const { form } = this.props;
    const allSelected = this.state.allSources;
    const sources = form.values().sources;

    this.setState({ allSources: !allSelected });

    const newSources = sources.map((source) => ({
      ...source,
      selected: !allSelected
    }));

    form.update({
      sources: newSources
    });
  };

  render() {
    const { form } = this.props;
    const sources = Array.from(form.$('sources').fields.values());

    return (
      <div className="quotas-form__body">
        <CustomCheckbox
          id="allSources"
          onChange={this.handleCheckboxChange}
          checked={this.state.allSources}
          label="Выбрать все"
        />
        {sources.map((source) => {
          return <Source key={source.id} field={source} setAllSources={this.setState.bind(this)}/>;
        })}
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
