import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Quota from './Quota';

@observer
class QuotasShow extends Component {
  render() {
    const { quotas } = this.props;

    const listItems = quotas.map((item) => {
      return <Quota key={item.id} quota={item} />;
    });

    return (
      <div className='quotas'>
        <div className='quotas__items'>
          {quotas.length > 0
            ? listItems
            : (
              <p className='text-muted'>
                <FormattedMessage id='quotas.empty' />
              </p>
              )}
        </div>
      </div>
    );
  }
}

QuotasShow.propTypes = {
  quotas: PropTypes.object.isRequired
};

export default QuotasShow;
