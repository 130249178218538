import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'start_date',
  'end_date',
  'min_days_before_check_in',
  'max_days_before_check_in'
];

const labels = {
  start_date: formatMessage(
    'tariff.advance_booking_restrictions.dateRangeInput'
  )
};

const rules = {
  min_days_before_check_in: 'numeric|required|min:0|max:365',
  max_days_before_check_in: 'numeric|required|min:0|max:365',
  start_date: 'string|required',
  end_date: 'string|required'
};

const defaults = {
  min_days_before_check_in: 0,
  max_days_before_check_in: 365
};

const values = {
  min_days_before_check_in: 0,
  max_days_before_check_in: 365
};

export default {
  fields,
  labels,
  rules,
  values,
  defaults
};
