import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "Utils/moment";
import { FormattedMessage } from "react-intl";
import _cloneDeep from "lodash/cloneDeep";
import { reaction } from "mobx";

import DateRangeInput from "Shared/form/DateRangeInput";
import AdvanceBookingTariffState, { fields } from "./state";
import RestrictionPeriod from "../RestrictionPeriod/RestrictionPeriod";
import RestrictionPeriodTips from "../RestrictionPeriodTips/RestrictionPeriodTips";
import { Wrapper, FormActions } from "./AdvanceBookingRestrictions.styled";
import Button from "Components/ui/Button";

@observer
class AdvanceBookingRestrictionForm extends Component {
  constructor(props) {
    super(props);
    this.state = new AdvanceBookingTariffState(fields);
    if (props.period) {
      this.state.update(props.period);
    }

    this.rateChangeHandler = reaction(
      () => this.state.values(),
      (values) => this.state.validate()
    );

    this.leftBound = moment(props.form.values().available_period.from);
    this.rightBound = moment(props.form.values().available_period.to);
  }

  successSubmitHandler = (data) => {
    const { form, onClose, index } = this.props;
    const values = form.values();
    let arr = [];

    if (index >= 0) {
      arr = _cloneDeep(form.$("advance_booking_restrictions").values());
      arr[index] = data.values();
    } else {
      arr = [...values.advance_booking_restrictions, data.values()];
    }

    form.update({
      ...values,
      advance_booking_restrictions: arr,
    });
    onClose();
  };

  errorSubmitHandler(data) {
    console.log(data.values(), "err");
  }

  updateHandler = (e) => {
    e.preventDefault();
    this.state.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler,
    });
  };

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) || this.rightBound.isBefore(day);
  };

  render() {
    const { onClose } = this.props;
    return (
      <Wrapper>
        <div className="row">
          <div className="col col-5">
            <DateRangeInput
              initialVisibleMonth={() => this.leftBound}
              startDate={this.state.$("start_date")}
              endDate={this.state.$("end_date")}
              enableOutsideDays
              isOutsideRange={this.isOutsideRange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-5">
            <div className="form__fields-group">
              <RestrictionPeriod
                title={
                  "tariff.advance_booking_restrictions.minimumAdvanceBooking.title"
                }
                field={this.state.$("min_days_before_check_in")}
              />
            </div>
          </div>
          <div className="col col-6 offset-1">
            <div className="form__fields-group">
              <RestrictionPeriodTips
                text={
                  "tariff.advance_booking_restrictions.minimumAdvanceBooking.example"
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-5">
            <div className="form__fields-group">
              <RestrictionPeriod
                title={
                  "tariff.advance_booking_restrictions.maximumAdvanceBooking.title"
                }
                field={this.state.$("max_days_before_check_in")}
              />
            </div>
          </div>
          <div className="col col-6 offset-1">
            <div className="form__fields-group">
              <RestrictionPeriodTips
                text={
                  "tariff.advance_booking_restrictions.maximumAdvanceBooking.example"
                }
              />
            </div>
          </div>
        </div>
        <FormActions withMargin>
          <Button onClick={this.updateHandler}>
            <FormattedMessage id="shared.confirm" />
          </Button>

          <Button variant="secondary" onClick={onClose}>
            <FormattedMessage id="shared.cancel" />
          </Button>
        </FormActions>
      </Wrapper>
    );
  }
}

AdvanceBookingRestrictionForm.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number,
  onClose: PropTypes.func,
};

export default AdvanceBookingRestrictionForm;
