import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'start_date',
  'end_date',
  'rates[].description',
  'rates[].price',
  'rates[].age_group',
  'rates[].age_period.from',
  'rates[].age_period.to',
  'rates[].occupation'
];

const labels = {
  start_date: formatMessage('periods.start_date'),
  rates: formatMessage('periods.rates')
};

const placeholders = {
  start_date: formatMessage('periods.date_not_defined'),
  end_date: formatMessage('periods.date_not_defined')
};

const rules = {
  start_date: 'required',
  end_date: 'required',
  'rates[].price': 'numeric|required'
};

export default {
  fields,
  rules,
  placeholders,
  labels
};
