import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class FullRefund extends Component {
  render() {
    return (
      <p className='value'>
        <FormattedMessage id='tariffs.cancellation_policy.has_full_refund' />
      </p>
    );
  }
}

export default FullRefund;
