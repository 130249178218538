import { types, destroy } from 'mobx-state-tree';
import instance from 'connection/instance';

import Message from './Message';

const Conversation = types.model('Conversation', {
  id: types.identifier(types.string),
  messages: types.optional(types.array(Message), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isPending() {
    return self.state === 'pending';
  },

  get isDone() {
    return self.state === 'done';
  },

  get isError() {
    return self.state === 'error';
  }
}, {
  sendMessage(values) {
    self.setState('pending');

    const data = { message: values };

    return instance.post(`/api/conversations/${self.id}/messages`, data)
      .then(response => self.addMessage(response))
      .then(() => self.setState('done'))
      .catch(self.errorHandler);
  },

  destroyMessage(message) {
    self.setState('pending');

    return instance.delete(`/api/conversations/${self.id}/messages/${message.id}`)
      .then(response => self.removeMessage(message))
      .then(() => self.setState('done'))
      .catch(self.errorHandler);
  },

  addMessage(response) {
    const { message } = response.data;
    self.messages.push(message);
  },

  removeMessage(message) {
    destroy(message);
  },

  errorHandler(error) {
    self.setState('error');
    return Promise.reject(error);
  },

  setState(state) {
    self.state = state;
  }
});

export default Conversation;
