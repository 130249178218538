import { useState } from 'react';
import instance from 'connection/instance';
import { AxiosResponse } from 'axios';

// eslint-disable-next-line no-unused-vars
enum STATUSES {
  // eslint-disable-next-line no-unused-vars
  IDLE = 'idle',

  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',

  // eslint-disable-next-line no-unused-vars
  FULLFILLED = 'fullfilled',

  // eslint-disable-next-line no-unused-vars
  REJECTED = 'rejected'
}

interface IDownloaderProps {
  getFileName: () => string
}

interface IDownloaderResponse {
  readonly status: STATUSES;
  readonly download: (path: string) => Promise<void>;
}

function useDownloader({ getFileName }: IDownloaderProps): IDownloaderResponse {
  const [status, setStatus] = useState<STATUSES>(STATUSES.IDLE);

  const download = async (path: string) => {
    setStatus(STATUSES.PENDING);

    const response: AxiosResponse<Blob> =
      await instance.get(path, { responseType: 'blob' });

    setStatus(STATUSES.FULLFILLED);

    const blob = new Blob([response.data]);
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', getFileName());

    document.body.appendChild(anchor);
    anchor.click();

    URL.revokeObjectURL(url);
  };

  return { status, download };
}

export default useDownloader;
