import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'tags[]',
  'tags[].type',
  'tags[].label',
  'tags[].value',
  'statuses',
  'statuses.created',
  'statuses.booked',
  'statuses.confirmed',
  'statuses.cancelled',
  'check_in',
  'check_out',
  'traveller',
  'travellers[]',
  'travellers[].id',
  'travellers[].name',
  'hotels[]',
  'hotels[].id',
  'hotels[].name',
  'managers[]',
  'managers[].id',
  'managers[].name',
  'state',
  'hotel',
  'manager',
  'types[]',
  'types[].id',
  'types[].name',
  'source'
];

const labels = {
  state: formatMessage('orders.status'),
  statuses: formatMessage('orders.status'),
  'statuses.booked': formatMessage('orders.statuses.booked'),
  'statuses.confirmed': formatMessage('orders.statuses.confirmed'),
  'statuses.cancelled': formatMessage('orders.statuses.cancelled'),
  check_in: formatMessage('orders.check_in'),
  check_out: formatMessage('orders.check_out'),
  hotel: formatMessage('orders.hotel'),
  hotels: formatMessage('orders.hotel'),
  manager: formatMessage('orders.manager'),
  managers: formatMessage('orders.manager'),
  types: formatMessage('orders.type'),
  source: formatMessage('orders.type'),
  traveller: formatMessage('orders.guestName')
};

const placeholders = {
  state: formatMessage('orders.status'),
  check_in: formatMessage('orders.check_in'),
  check_out: formatMessage('orders.check_out'),
  manager: formatMessage('orders.manager'),
  hotel: formatMessage('orders.hotel'),
  managers: formatMessage('orders.managers_placeholder'),
  hotels: formatMessage('orders.hotels_placeholder'),
  types: formatMessage('orders.type'),
  source: formatMessage('orders.type'),
  traveller: formatMessage('orders.guestName')
};

const types = {
  'statuses.booked': 'checkbox',
  'statuses.confirmed': 'checkbox',
  'statuses.cancelled': 'checkbox'
};

const extra = {
  state: [
    { value: 'booked', label: formatMessage('orders.statuses.booked') },
    { value: 'confirmed', label: formatMessage('orders.statuses.confirmed') },
    { value: 'cancelled', label: formatMessage('orders.statuses.cancelled') }
    // { value: 'booked', label: formatMessage('orders.statuses.booked') },
    // { value: 'confirmed', label: formatMessage('orders.statuses.confirmed') },
    // { value: 'cancelled', label: formatMessage('orders.statuses.cancelled') }
  ]
};

export default { fields, labels, placeholders, types, extra };
