import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Quota from './Quota';

@withRouter
@observer
class Quotas extends Component {
  render() {
    const { quotas, room_type, source, expand } = this.props;

    const listItems = quotas
      .map((quota) => {
        return (
          <Quota
            key={quota.day}
            quota={quota}
            room_type={room_type}
            source={source}
            expand={expand}
          />
        );
      });

    return (
      <div className='quotas'>
        {listItems}
      </div>
    );
  }
}

Quotas.propTypes = {
  quotas: PropTypes.object.isRequired,
  room_type: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired
};

export default Quotas;
