import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MdMailOutline from 'react-icons/lib/md/mail-outline';
import MdAdd from 'react-icons/lib/md/add';

import FormInput from 'Shared/form/FormInput';
import Checkbox from 'Shared/form/Checkbox';

@inject('form')
@observer
class Email extends Component {
  addHandler = (e) => {
    e.preventDefault();

    const { form } = this.props;
    form.$('contacts').add({ type: 'email' });
  }

  render() {
    const { field, showAddBtn, showRemoveBtn } = this.props;

    return (
      <div className='form__fields-group'>
        <div className='form__field grow'>
          <div className='prepend'>
            <span>
              <i className='icon'>
                <MdMailOutline />
              </i>
            </span>
            <FormInput field={field.$('value')} placeholder='booking@example.org' />
          </div>
        </div>

        <div className='form__field grow'>
          <FormInput field={field.$('text')} />
        </div>

        <Checkbox field={field.$('send_notification')} />

        <div className='form__field'>
          <div className='buttons'>
            {showRemoveBtn &&
              <button className='button gray' onClick={field.onDel}>
                <FormattedMessage id='shared.remove' />
              </button>}

            {showAddBtn &&
              <a href='' className='link' onClick={this.addHandler}>
                <FormattedMessage id='hotel.add_email' />
              </a>}
          </div>
        </div>
      </div>
    );
  }
}

Email.defaultProps = {
  showAddBtn: false,
  showRemoveBtn: false
};

Email.propTypes = {
  field: PropTypes.object.isRequired
};

export default Email;
