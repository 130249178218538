import { types } from 'mobx-state-tree';

export const Service = types.model('Service', {
  id: types.identifier(types.string),
  name: types.string,
  description: types.string,
  quantity: types.number,
  pace: types.string,
  pace_name: types.string
});
