import instance from 'connection/instance';
import { Dispatch } from 'react';
import { TTariffsAction, ACTIONS } from '../types';
import { sogazId } from 'pages/Sogaz/constants';

const PARAMS = {
  data: { source_id: sogazId }
};

const unblockTariffs = () => {
  return async (dispatch: Dispatch<TTariffsAction>): Promise<void> => {
    try {
      await instance.post('/api/tariffs/unblock', PARAMS);

      dispatch({ type: ACTIONS.TARIFFS_UNBLOCK });
    } catch (error) {
      console.log(error);
    }
  };
};

export default unblockTariffs;
