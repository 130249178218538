import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import DatesItem from './DatesItem';

import styled from 'styled-components';

const S = {
  Cell: styled.div.attrs(() => ({ className: 'cell' }))`
    display: flex;
    align-items: flex-end;
  `
};

@observer
class Dates extends Component {
  render() {
    const { dates } = this.props;

    const listItems = dates.map((date) => {
      return <DatesItem key={date.start_date} date={date} />;
    });

    return (
      <S.Cell>
        <div className='dates'>
          {listItems}
        </div>
      </S.Cell>
    );
  }
}

Dates.propTypes = {
  dates: PropTypes.object.isRequired
};

export default Dates;
