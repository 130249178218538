import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from 'Components/ui/Button';
import ConfirmModal from 'Components/ui/ConfirmModal';

import duplicateTariff from './utils/duplicateTariff';

import DuplicateIcon from 'react-icons/lib/md/control-point-duplicate';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
`;

const LeftContainer = styled.div``;

const Wrapper = styled.div``;

function TariffContentActions({ tariff, history, removeTariff, fetchTariffs }) {
  const [isPending, setIsPending] = useState();
  const [openedConfirmModal, setOpenedConfirmModal] = useState();

  const handleDestroy = async e => {
    e.preventDefault();

    await removeTariff({ tariff });
    navigateToList();
    fetchTariffs();
  };

  const handleExtend = async e => {
    e.preventDefault();

    setIsPending(true);
    const { data: { tariff: duplicatedTariff } } = await duplicateTariff({ id: tariff.id });
    history.push(`/tariffs/${duplicatedTariff.id}/edit`);
    setIsPending(false);
  };

  const navigateToList = () => {
    history.push('/tariffs');
  };

  const handleDuplicate = e => {
    e.preventDefault();

    history.push('/tariffs/new', { values: tariff });
  };

  const linkTo = ['/tariffs', tariff.id, 'edit'].join('/');

  return (
    <Wrapper className='actions'>
      <Container>
        <LeftContainer>
          <Link to={linkTo} className='button green'>
            <FormattedMessage id='shared.change' />
          </Link>

          <button className='button gray' onClick={() => setOpenedConfirmModal(true)}>
            <FormattedMessage id='shared.remove' />
          </button>
        </LeftContainer>

        <RightContainer>
          <Button
            loading={isPending}
            variant='secondary'
            onClick={handleExtend}
            disabled={!!tariff.duplicated_to}
          >
            <FormattedMessage id='tariff.extend' />
          </Button>

          <Button variant='secondary' onClick={handleDuplicate}>
            <i className='icon'>
              <DuplicateIcon />
            </i>
          </Button>
        </RightContainer>
      </Container>

      {openedConfirmModal && (
        <ConfirmModal
          title={<FormattedMessage id='shared.remove_tariff_ask' />}
          description={<FormattedMessage id='shared.remove_tariff_name_ask' values={{ name: tariff.name }} />}
          onApply={handleDestroy}
          onCancel={() => setOpenedConfirmModal(false)}
        />
      )}
    </Wrapper>
  );
}

TariffContentActions.propTypes = {
  tariff: PropTypes.object.isRequired,
  history: PropTypes.object,
  removeTariff: PropTypes.func,
  fetchTariffs: PropTypes.func
};

export default withRouter(TariffContentActions);
