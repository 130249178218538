import validatorjs from 'validatorjs';

import { formatMessage } from 'Utils/formatMessage';

validatorjs.register('not_null_if', function (value, requirement) {
  if (!requirement) return true;

  const [field, val] = requirement.split(',');

  if (!field || !val) return true;

  if (this.validator.input[field] === val) {
    return !!value;
  }

  return true;
}, formatMessage('periods.rate.priceError'));

validatorjs.register('min_max', function (value, requirement) {
  const [field, val] = requirement.split(',');

  if(Number(value) <= Number(this.validator.input[field]) && Number(value) >= 1 && Number(value) <= 365){
    return true
  }
}, formatMessage('tariff.restrictions_error.minMessage'));

validatorjs.register('max_min', function (value, requirement) {
  const [field, val] = requirement.split(',');

  if(Number(value) >= Number(this.validator.input[field]) && Number(value) >= 1 && Number(value) <= 365){
    return true
  }
}, formatMessage('tariff.restrictions_error.maxMessage'));

validatorjs.register('atLeastOneTrue', (value, requirement, attribute) => {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.some(item => item === true);
}, 'At least one checkbox must be selected');

export default validatorjs;
