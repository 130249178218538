import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import DiseaseTree from './DiseaseTree';

@observer
class MainSpecialization extends Component {
  render() {
    const { hotel } = this.props;
    const { specializations } = hotel;
    const treatmentProfiles = this.props.hotel.treatment_profiles.toJSON();

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.treatment_profiles' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {treatmentProfiles.length > 0
              ? <DiseaseTree hotel={hotel} />

              : <p className='muted'>
                <FormattedMessage id='hotel.treatment_profiles_didnt_choosed' />
                </p>}
          </div>
        </div>
      </div>
    );
  }
}

MainSpecialization.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default MainSpecialization;
