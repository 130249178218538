import { types } from 'mobx-state-tree';

export const OrdersPaginate = types.model('OrdersPaginate', {
  limit: types.number,
  page: types.number,
  total_pages: types.number
});

export const OrdersMeta = types.model('OrdersMeta', {
  count: types.number,
  paginate: OrdersPaginate
});
