import { types } from 'mobx-state-tree';
import { Source } from './Source';

import { Price } from 'Stores/models/Price';

export const Order = types.model('Order', {
  id: types.identifier(types.string),
  order_code: types.string,
  state: types.string,
  price: Price,
  source: Source
});
