import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class TopBarSearchBox extends Component {
  render() {
    return (
      <div className='search-box'>
        <div className='search-box__header'>
          <FormattedMessage id='menu.property_description' />
        </div>
      </div>
    );
  }
}

export default TopBarSearchBox;
