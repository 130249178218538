import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { AgeInputs } from 'Styles/styled';
import debounce from 'lodash/debounce';

const suffix = ' лет';
const prefixFrom = 'c ';
const prefixTo = 'до ';

const AgeInput = props => {
  const { agePeriod } = props;

  const handlerChangeAgeInput = debounce((e, type) => { onFromChange(e, type); }, 500);

  const onFromChange = (value, type) => {
    const x = parseInt(value.target.value.replace(/[^\d]/g, ''));

    agePeriod.set({ ...agePeriod.value, [type]: x });

    if (type === 'from') {
      if (x > agePeriod.value.from || x > 18) {
        return agePeriod.set({ [type]: 18, to: 18 });
      }

      if (x > agePeriod.value.to) {
        return agePeriod.set({ [type]: x, to: x });
      }
    }

    if (type === 'to') {
      if (x < agePeriod.value.from || !x) {
        return agePeriod.set({ ...agePeriod.value });
      }
    }

    if (x > 18) {
      agePeriod.set({ ...agePeriod.value, [type]: 18 });
    } else if (!x) {
      agePeriod.set({ ...agePeriod.value, [type]: 0 });
    }
  };

  const ageFrom = useMemo(() => agePeriod.value.from, [agePeriod.value.from]);
  const ageTo = useMemo(() => agePeriod.value.to, [agePeriod.value.to]);

  return (
    <>
      <AgeInputs>
        <NumberFormat
          value={ageFrom}
          className='age_input_from input'
          onChange={e => handlerChangeAgeInput(e, 'from')}
          prefix={prefixFrom}
          suffix={suffix}
          allowNegative={false}
          decimalSeparator={false}
        />

        <NumberFormat
          value={ageTo}
          className='age_input_to input'
          onChange={e => handlerChangeAgeInput(e, 'to')}
          prefix={prefixTo}
          suffix={suffix}
          allowNegative={false}
          decimalSeparator={false}
        />
      </AgeInputs>
    </>
  );
};

AgeInput.defaultProps = {
  placeholder: null,
  type: 'text',
  className: null,
  showLabel: false,
  withSuffix: true,
  decimalPrecision: 2
};

export default AgeInput;
