import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

import Info from '../Info';
import Actions from '../Actions';

@withRouter
@observer
class PageContent extends Component {
  render() {
    const { room } = this.props;

    return (
      <div className='info__content'>
        <div className='header'>
          Номер №{room.number}
        </div>

        <div className='content'>
          <Info room={room} />
          <Actions room={room} />
        </div>
      </div>
    );
  }
}

PageContent.propTypes = {
  room: PropTypes.object.isRequired
};

export default PageContent;
