import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

@withRouter
@inject('orderStore', 'dashboardStore', 'store', 'searchState')
@observer
class Control extends Component {
  submitHandler = (e) => {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.onSubmitSuccess,
      onError: this.onSubmitError
    });
  }

  onSubmitSuccess = (form) => {
    const { orderStore } = this.props;

    orderStore.saveOrder(form)
      .then(this.onSaveOrderSuccess)
      .catch(this.onSaveOrderError);
  }

  onSubmitError = (form) => {
    console.log(form.errors());
  }

  onSaveOrderSuccess = (order) => {
    const { history, dashboardStore, searchState } = this.props;
    const { check_in, check_out } = searchState.values();

    dashboardStore.fetch({ check_in, check_out });

    const reservation_id = order.reservations[0].id;
    history.push(`/dashboard/${reservation_id}`);
  }

  onSaveOrderError = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    const { orderStore } = this.props;

    return (
      <div className='buttons'>
        <button
          type='submit'
          className='button green'
          onClick={this.submitHandler}
        ><FormattedMessage id='shared.confirm' />
        </button>

        <Link
          to='/dashboard'
          className='button gray'
        ><FormattedMessage id='shared.cancel' />
        </Link>
      </div>
    );
  }
}

Control.propTypes = {
  form: PropTypes.object.isRequired
};

export default Control;
