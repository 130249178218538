import React from 'react';
import PropTypes from 'prop-types';

import RoomType from './RoomType';

function RoomTypes({ fetchTariffs, room_types }) {
  const listItems = room_types.map((item) => {
    return <RoomType key={item.id} item={item} fetchTariffs={fetchTariffs} />;
  });

  return (
    <div className='groups'>
      {listItems}
    </div>
  );
}

RoomTypes.propTypes = {
  room_types: PropTypes.array.isRequired,
  fetchTariffs: PropTypes.func
};

export default RoomTypes;
