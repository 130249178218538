import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router-dom';

import PriceList from './PriceList';

@withRouter
@observer
class PriceLists extends Component {
  render() {
    const { price_lists } = this.props;

    const listItems = price_lists.map((item) => {
      return <PriceList key={item.id} price_list={item} />;
    });

    return (
      <div className='price-lists'>
        <div className='price-lists__content'>
          {listItems}
        </div>
      </div>
    );
  }
}

export default PriceLists;
