import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import Buildings from '../Buildings';

@withRouter
@inject('buildingsStore')
@observer
class PageBody extends Component {
  render() {
    const { buildings } = this.props.buildingsStore;
    return (
      <div className='page__body'>
        <Buildings buildings={buildings} />
      </div>
    );
  }
}

export default PageBody;
