import React, { Component } from 'react';

import TopBarSearchBox from './TopBarSearchBox';
import TopBarActionBox from './TopBarActionBox';

export default class TopBarContent extends Component {
  render() {
    const { form } = this.props;
    return (
      <div className='layout__topbar-content'>
        <div className='layout__topbar-content-wrapper'>
          <TopBarSearchBox form={form} />
          <TopBarActionBox />
        </div>
      </div>
    );
  }
}
