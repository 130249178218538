import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import PriceList from './PriceList/index';
import TariffsList from './TariffsList';

@inject('priceListStore')
@observer
class PageBody extends Component {
  render() {
    const { priceListStore } = this.props;

    return (
      <div className='page__body wide'>
        <div className='groups'>
          <PriceList priceListStore={priceListStore} />
          <TariffsList priceListStore={priceListStore} />
        </div>
      </div>
    );
  }
}

export default PageBody;
