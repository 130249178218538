import styled from 'styled-components';
import Button from 'Components/ui/Button';

export const Footer = styled.div`
  width: 88%;
  background-color: #fff;
  padding: 24px 30px 24px 40px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -2px 4px 0px rgba(34, 60, 80, 0.2);
`;

export const FooterLeft = styled.div`
  display: flex;
  width: 50%;
  ${Button} + ${Button} {
    margin-left: 10px;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  width: 778px;
  display: flex;
`;

export const MessageWrapper = styled.div`
  width: 778px;
  margin-bottom: 20px;
`;
