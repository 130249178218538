import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class Source extends Component {
  constructor(props) {
    super(props);

    this.fieldEl = React.createRef();
  }

  componentDidMount() {
    const { field } = this.props;
    if (!field.$('selected').value) return;

    this.fieldEl.current.focus();
    this.fieldEl.current.select();
  }

  render() {
    const { field } = this.props;

    return (
      <div className='fields'>
        <div className='field'>
          <label htmlFor={field.$('selected').id}>
            <input
              className='input'
              {...field.$('selected').bind()}
            />

            {field.$('name').value}
          </label>
        </div>
        <div className='field'>
          <input
            ref={this.fieldEl}
            className='input'
            {...field.$('quantity').bind()}
          />
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  field: PropTypes.object.isRequired
};

export default Source;
