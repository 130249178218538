import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

ReactModal.setAppElement('#root');

const style = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  content: {
    position: 'absolute',
    top: '50%',
    bottom: 'auto',
    transform: 'translateY(-50%)',
    maxWidth: 915,
    maxHeight: 900,
    margin: '0 auto',
    boxShadow: '0 0 9px rgba(0,0,0, 0.13)',
    background: '#fff',
    borderRadius: 'none',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '25px 30px 35px'
  }
};

class Modal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    children: PropTypes.any
  }

  render() {
    return (
      <ReactModal
        style={style}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}

export default Modal;
