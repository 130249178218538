import * as React from 'react';
import styled from 'styled-components';
import { useSogaz } from 'pages/Sogaz/store/hooks';
import { servicesGroups } from 'pages/Sogaz/store/selectors';
import { compareSubmit, compareReset } from 'pages/Sogaz/store/actions';

import { Heading } from 'evergreen-ui';
import { SideRight } from 'components/v2/Layout';
import Services from '../Services';
import Actions from './Actions';

const Header = styled.div`
  padding: 16px 16px;
`;

const Body = styled.div`
  padding: 0 16px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.13);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 100vh;
  overflow: hidden;

  ${Header} {
    height: 76px;
  }

  ${Body} {
    flex-grow: 1;
    height: calc(100vh - 64px - 76px);
    overflow: auto;
  }

  ${Footer} {
    flex-grow: 0;
    flex-shrink: 0;
    height: 64px;
    z-index: 1;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function Compare({ className }: Props) {
  const { state, dispatch } = useSogaz();

  const services = React.useMemo(() => {
    return servicesGroups(state);
  }, [state.price_lists]);

  const { room_type, tariff, rate } = state.compare;

  if (!(rate && tariff && room_type)) {
    return null;
  }

  const handleSubmit = () => {
    const { room_type, tariff, rate, service } = state.compare;
    if (!(room_type && tariff && rate && service)) {
      return;
    }

    dispatch(compareSubmit({ room_type, tariff, rate, service }));
  };

  const handleReset = () => {
    dispatch(compareReset());
  };

  return (
    <SideRight>
      <Wrapper className={className}>
        <Header>
          <Heading>
            {rate.description.ru} / {rate.code}
          </Heading>

          <Heading
            size={200}
            marginTop={8}
          >
            {`${tariff.room_type.name.ru} - ${tariff.name.ru}`}
          </Heading>
        </Header>

        <Body>
          <Services
            services={services}
            onCompare={() => null}
          />
        </Body>

        <Footer>
          <Actions
            compare={state.compare}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </Footer>
      </Wrapper>
    </SideRight>
  );
}

export default Compare;
