import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import {
  RussiaIconInput,
  EnglandIconInput,
  InputMaskStyled,
  Wrapper
} from 'Styles/styled';

import { FormLanguageContext } from 'Context/FormLanguageContext';

import { Label } from './FormItemInput.styled';

@observer
class FormItemInput extends React.Component {
  static contextType = FormLanguageContext;

  static propTypes = {
    field: PropTypes.object.isRequired,
    number: PropTypes.number,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isLocalizeField: PropTypes.bool,
    mute: PropTypes.bool
  }

  static defaultProps = {
    type: 'text',
    placeholder: null,
    className: ''
  }

  render() {
    const {
      number,
      field,
      type,
      placeholder,
      className,
      isLocalizeField,
      mute,
      ...restProps
    } = this.props;

    const inputCls = classNames('input', {
      error: field.error && !field.focused
    });

    const currentLanguage = this.context.currentLanguage;

    const fieldCls = classNames('form__field', className);

    return (
      <div className={fieldCls}>
        {number && <span className='form__field-number'>{number}</span>}

        <Label mute={mute}>
          {field.label}
          {isLocalizeField && `(${currentLanguage.toUpperCase()})`}
          <span className='error'>{field.error && '*'}</span>
        </Label>

        <Wrapper>
          {isLocalizeField &&
            (currentLanguage === 'ru'
              ? (
                <RussiaIconInput
                  onClick={() => this.context.handleLanguageChange('en')}
                />
                )
              : (
                <EnglandIconInput
                  onClick={() => this.context.handleLanguageChange('ru')}
                />
                ))}

          <InputMaskStyled
            className={inputCls}
            isLocalizeField={isLocalizeField}
            // вернет объект который содержит все методы и значения
            {...field.bind({ type, placeholder })}
            {...restProps}
          />
        </Wrapper>
      </div>
    );
  }
}

export default FormItemInput;
