import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { DoneIcon } from 'Components/ui/Icons';

const Input = styled.input`
  position: absolute !important;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const PseudoInput = styled.div`
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  min-width: 17px;
  max-width: 17px;
  height: 17px;
  border: 2px solid #EAE9E4;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 8px;
  color: #FFFFFF;
  background: #fff;
`;

const Label = styled.label`
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;

  ${({ active }) => active && css`
    ${PseudoInput} {
      background-color: #52AB18;;
      border: 2px solid transparent;
    }
  `}
`;

function Checkbox({ className, label, name, value: initialValue, onChange }) {
  const [value, setValue] = useState();
  const inputRef = useRef();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    const newValue = !value;

    onChange && onChange(newValue);
    setValue(newValue);
  };

  return (
    <Wrapper
      className={className}
      active={!!value}
    >
      <Input
        ref={inputRef}
        name={name}
        onChange={handleChange}
        type='checkbox'
      />

      <PseudoInput>
        {!!value && (
          <DoneIcon />
        )}
      </PseudoInput>

      <Label>
        {label}
      </Label>
    </Wrapper>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

Checkbox.defaultProps = {
  value: false
};

export default styled(Checkbox)``;
