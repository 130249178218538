import _range from 'lodash/range';
import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'name_translations.ru',
  'name_translations.en',
  'floor_numbers'
];

const labels = {
  name: formatMessage('buildings.name'),
  'name_translations.ru': formatMessage('buildings.name'),
  'name_translations.en': formatMessage('buildings.name'),
  floor_numbers: formatMessage('buildings.floors')
};

const placeholders = {
};

const values = {
};

const rules = {
  'name_translations.ru': 'string|required',
  'name_translations.en': 'string',
  floor_numbers: 'integer|required'
};

const extra = {
  floor_numbers: _range(1, 32).map((i) => {
    return { value: i, label: i.toString() };
  })
};

const types = {
};

export default {
  fields,
  labels,
  extra,
  placeholders,
  values,
  rules,
  types
};
