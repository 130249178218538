import React, { Component } from 'react';
import propTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Control from './TopBarControl';
import Loader from './TopBarLoader';

@observer
class TopBar extends Component {
  render() {
    const { children, isLoading, topBarOrders } = this.props;

    return (
      <div className={topBarOrders ? 'layout__topbar__order' : 'layout__topbar'}>
        {isLoading && <Loader />}
        <Control />

        {children}
      </div>
    );
  }
}

TopBar.defaultProps = {
  isLoading: false
};

export default TopBar;
