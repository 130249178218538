import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { SourcesStore } from 'Stores/SourcesStore';
import SingleSelect from 'Shared/form/SingleSelectObj';
import { FormattedMessage } from 'react-intl';

@observer
class Source extends Component {
  componentDidMount() {
    const { sourcesStore } = this.props;
    sourcesStore.fetch();
  }

  @computed get isLoading() {
    const { sourcesStore } = this.props;
    return sourcesStore.isPending;
  }

  @computed get sourcesOptions() {
    const { sourcesStore } = this.props;

    return sourcesStore.isFetched
      ? sourcesStore.sources.toJSON()
      : [];
  }

  @computed get sourceDesc() {
    const { field } = this.props;

    return field.has('id') && field.$('id').value && (
      field.$('settings.should_be_confirmed').value
        ? <span className='error'><FormattedMessage id='hotel.settings.source_with_confirmation' /></span>
        : <span><FormattedMessage id='hotel.settings.source_without_confirmation' /></span>
    );
  }

  render() {
    const { field } = this.props;

    return (
      <SingleSelect
        field={field}
        simpleValue={false}
        valueKey='id'
        labelKey='name'
        searchable
        clearable
        isLoading={this.isLoading}
        options={this.sourcesOptions}
        description={this.sourceDesc}
      />
    );
  }
}

Source.defaultProps = {
  sourcesStore: SourcesStore.create()
};

Source.propTypes = {
  field: PropTypes.object.isRequired,
  sourcesStore: PropTypes.object.isRequired
};

export default Source;
