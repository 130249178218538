import { types } from 'mobx-state-tree';
import { fullName } from 'Utils/fullName';

export const Traveller = types.model('Traveller', {
  id: types.identifier(types.string),
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybe(types.string),
  date_of_birth: types.maybe(types.string),

  get fullName() {
    return [
      this.last_name,
      this.first_name,
      this.middle_name
    ].join(' ');
  },

  get shortName() {
    const { first_name, last_name, middle_name } = this;
    return fullName({ first_name, last_name, middle_name });
  }
});
