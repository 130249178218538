import { types } from 'mobx-state-tree';
import moment from 'Utils/moment';
import _pick from 'lodash/pick';
import _merge from 'lodash/merge';

import { Source } from './Source';
import { Tariff } from './../TariffsNewStore/Tariff';
import { TariffsMeta } from './../TariffsNewStore/TariffsMeta';

export const PriceList = types.model('PriceList', {
  id: types.identifier(types.string),
  number: types.string,
  start_date: types.maybe(types.string),
  end_date: types.maybe(types.string),
  name: types.maybe(types.string),
  doc_number: types.maybe(types.string),
  doc_date: types.maybe(types.string),
  source: types.maybe(Source),
  tariffs: types.optional(types.array(Tariff), []),
  meta: types.optional(TariffsMeta, {}),
  status: types.maybe(types.enumeration(['created', 'uploaded', 'confirmed', 'archived'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get startDate() {
    return this.start_date
      ? moment(this.start_date).format('DD.MM.YYYY')
      : 'не задан';
  },

  get endDate() {
    return this.end_date
      ? moment(this.end_date).format('DD.MM.YYYY')
      : 'не задан';
  },

  get isCreated() {
    return this.status === 'created';
  },

  get isUploaded() {
    return this.status === 'uploaded';
  },

  get isConfirmed() {
    return this.status === 'confirmed';
  },

  get autoConfirm() {
    return !this.source.settings.should_be_confirmed;
  }
}, {
  toForm() {
    const data = this.toJSON();
    const tariffs = data.tariffs.map((a) => ({ id: a.id, name: a.name }));

    let values = _pick(data,
      ['number', 'doc_number', 'doc_date', 'start_date', 'end_date', 'source']);
    values = _merge(values, { tariffs });

    return values;
  }
});
