import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FormItemInput from 'Shared/form/FormItemInput';
import Checkbox from 'Shared/form/Checkbox';

@observer
class Info extends Component {
  render() {
    const {
      name,
      floor_numbers
    } = this.props.building;

    return (
      <div className='options padded'>
        <div className='options__item'>
          <span className='key'>
            <FormattedMessage id='buildings.name' />
          </span>
          <span className='value'>
            {name}
          </span>
        </div>
        <div className='options__item'>
          <span className='key'>
            <FormattedMessage id='buildings.floors' />
          </span>
          <span className='value'>
            {floor_numbers}
          </span>
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  building: PropTypes.object.isRequired
};

export default Info;
