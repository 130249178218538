import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import moment from 'Utils/moment';

import { formatMessage } from 'Utils/formatMessage';

@withRouter
@inject('store')
@observer
class Show extends Component {
  render() {
    const {
      price_list,
      downloadHandler,
      destroyHandler,
      onEditHandler
    } = this.props;

    const date = moment(price_list.doc_date).format('DD.MM.YYYY');

    return (
      <div className='groups__item-block'>
        <div className='header'>
          {price_list.name}
        </div>

        <div className='content'>
          {formatMessage('price_list.successfully_created', {
            number: price_list.number,
            doc_number: price_list.doc_number,
            date: date
          })}

          <p>
            {formatMessage('price_list.to_start_downloading_press')}

            <a href='#' onClick={downloadHandler}>
              {formatMessage('price_list.link')}
            </a>

            {formatMessage('price_list.and_save_file')}
          </p>

          {price_list.isCreated && price_list.autoConfirm &&
            <p>
              {formatMessage('price_list.source_without_confirmation', {
                source: price_list.source.name
              })}
            </p>}

          {price_list.isCreated &&
            <p>
              {formatMessage('price_list.press_to_confirm')}
            </p>}

          {price_list.isUploaded &&
            <p>
              {formatMessage('price_list.uploaded')}
            </p>}

          {price_list.isConfirmed &&
            <p>
              {formatMessage('price_list.confirmed')}
            </p>}

          {price_list.isCreated &&
            <p>
              {formatMessage('price_list.could_be_destroy')}

              <a href='#' onClick={destroyHandler}>
                {formatMessage('price_list.destroy')}
              </a>
            </p>}

          {price_list.isUploaded &&
            <p>
              {formatMessage('price_list.could_be_change')}

              <a href='#' onClick={destroyHandler}>
                {formatMessage('price_list.revoke_confirmation')}
              </a>
            </p>}

          <div className='buttons'>
            <button
              type=''
              className='button default'
              onClick={onEditHandler}
            >
              {formatMessage('shared.change')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Show.defaultProps = {
  priceListStore: PropTypes.object.isRequired
};

export default Show;
