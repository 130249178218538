import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import Tree, { TreeNode } from 'rc-tree';
import store from 'store';

@observer
class AmenityTree extends Component {
  @observable checkedKeys = [];

  @computed get checkedValues() {
    return this.checkedKeys.toJS();
  }

  componentDidMount() {
    this.updateCheckedValuesHandler = autorun(() => {
      const values = this.props.form.$('amenities').value;

      this.updateCheckedValues(values);
    });
  }

  componentWillUnmount() {
    this.updateCheckedValuesHandler();
  }

  updateCheckedValues = (values) => {
    this.checkedKeys = values;
  }

  onCheck = (checkedKeys) => {
    const { form } = this.props;
    form.update({ amenities: checkedKeys });
  }

  render() {
    const { amenities } = this.props;
    const generalLanguage = store.get('language');

    const loop = (data) => {
      return data.map((item) => {
        return item.children
          ? <TreeNode
              key={item.tag}
              title={item.name_translations[generalLanguage]}
              className='rc_checkbox__field'
            >{loop(item.children)}
          </TreeNode>
          : <TreeNode key={item.tag} title={item.name_translations[generalLanguage]} />;
      });
    };
    const treeNodes = loop(amenities);

    return (
      <div className='form'>
        <Tree
          checkable
          onCheck={this.onCheck}
          checkedKeys={this.checkedValues}
          showIcon={false}
        >
          {treeNodes}
        </Tree>
      </div>
    );
  }
}

AmenityTree.propTypes = {
  amenities: PropTypes.array.isRequired,
  form: PropTypes.object
};

export default AmenityTree;
