import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { InsurantsStore } from 'Stores/InsurantsStore';
import SingleSelect from 'Shared/form/SingleSelectObj';

@observer
class Insurant extends Component {
  componentDidMount() {
    const { insurantsStore } = this.props;
    insurantsStore.fetch();
  }

  @computed get isLoading() {
    const { insurantsStore } = this.props;
    return insurantsStore.isPending;
  }

  @computed get insurantsOptions() {
    const { insurantsStore } = this.props;

    return insurantsStore.isFetched
      ? insurantsStore.insurants.toJSON()
      : [];
  }

  render() {
    const { field } = this.props;

    return (
      <SingleSelect
        field={field}
        simpleValue={false}
        valueKey='id'
        labelKey='short_name'
        searchable
        clearable
        isLoading={this.isLoading}
        options={this.insurantsOptions}
      />
    );
  }
}

Insurant.defaultProps = {
  insurantsStore: InsurantsStore.create()
};

Insurant.propTypes = {
  field: PropTypes.object.isRequired,
  insurantsStore: PropTypes.object.isRequired
};

export default Insurant;
