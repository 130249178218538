import { types, applySnapshot, destroy } from 'mobx-state-tree';
import instance from 'connection/instance';

import _pick from 'lodash/pick';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';

import { RoomType } from './RoomType';
import { Building } from './Building';

export const Room = types.model('Room', {
  id: types.identifier(types.string),
  number: types.string,
  floor_number: types.number,
  building: types.maybe(types.reference(Building)),
  room_type: types.maybe(types.reference(RoomType)),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  toForm() {
    let data = this.toJSON();
    data = _pick(data, ['id', 'name', 'number', 'floor_number']);

    const roomType = this.room_type && this.room_type.toJSON();

    const building = this.building && this.building.toJSON();

    return _merge(data, {
      room_type: roomType,
      building: building
    });
  },

  toClone() {
    let values = this.toForm();
    values = _omit(values, 'id');

    let current = values.number;
    current = current.toString();

    let currentNumber = current.match(/\d+/)[0];
    currentNumber = Number(currentNumber);

    const nextNumber = currentNumber + 1;
    const next = current.replace(currentNumber, nextNumber);

    values.number = next;
    return values;
  },

  update(values) {
    this.setState('pending');

    const params = { room: values };

    return instance.put(`/api/rooms/${values.id}`, params)
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  destroy() {
    this.setState('pending');

    return instance.delete(`/api/rooms/${this.id}`)
      .then((response) => this.destroySelf())
      .catch(error => this.errorHandler(error));
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { room } = response.data;
    applySnapshot(this, room);

    return response;
  },

  destroySelf() {
    destroy(this);
  }
});
