import styled from 'styled-components';
import { ReactComponent as EditQuotaIcon } from 'Icons/editQuota.svg';

export const ContentLeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const EditQuota = styled(EditQuotaIcon)`
  cursor: pointer;
`;

export const EditQuotaContainer = styled.div`
  flex-shrink: 0;
  margin-left: 20px;
`;
