import { types } from 'mobx-state-tree';

import Author from './Author';

const Message = types.model('Message', {
  id: types.identifier(types.string),
  created_at: types.string,
  text: types.string,
  is_owner: types.boolean,
  author: Author
});

export default Message;
