import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'Utils/moment';

import DateRangeInput from 'Shared/form/DateRangeInput';

@observer
class AvailablePeriod extends Component {
  leftBound = moment().subtract(24, 'month')

  rightBound = moment().add(2, 'years')

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) ||
      this.rightBound.isBefore(day);
  }

  render() {
    const { form } = this.props;

    return (
      <DateRangeInput
        startDate={form.$('available_period.from')}
        endDate={form.$('available_period.to')}
        enableOutsideDays
        isOutsideRange={this.isOutsideRange}
      />
    );
  }
}

AvailablePeriod.propTypes = {
  form: PropTypes.object.isRequired
};

export default AvailablePeriod;
