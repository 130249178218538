import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'type',
  'description',
  'price',
  'age_group',
  'age_period.from',
  'age_period.to',
  'occupation',
  'count',
  'id'
];

const labels = {
  price: formatMessage('rates.price'),
  age_group: formatMessage('age_group.self'),
  'age_period.from': formatMessage('age_period.from'),
  'age_period.to': formatMessage('age_period.to'),
  occupation: formatMessage('occupation.self'),
  count: formatMessage('rates.count')
};

const placeholders = {
  name: '',
  description: '',
  price: 'руб.'
};

const rules = {
  type: 'required|string',
  description: 'required|string',
  age_group: 'required|string|in:adult,child',
  'age_period.from': 'integer|required_if:age_group,child|min:0',
  'age_period.to': 'integer|required_if:age_group,child|max:18',
  occupation: 'required|string|in:main,extra,no',
  price: {
    amount: 'required|numeric|not_null_if:age_group,adult',
    currency: 'required|string'
  }
};

const extra = {
  age_group: [
    { value: 'adult', label: formatMessage('age_group.adult') },
    { value: 'child', label: formatMessage('age_group.child') }
  ],
  occupation: [
    { value: 'main', label: formatMessage('occupation.main') },
    { value: 'extra', label: formatMessage('occupation.extra') }
  ]
};

const values = {
  age_group: 'adult',
  occupation: 'main',
  count: 0,
  age_period: { from: 3, to: 14 },
  price: {
    amount: null,
    currency: 'RUB'
  }
};

const defaults = {
  age_period: { from: 3, to: 14 }
};

const types = {
  age_group: 'radio',
  occupation: 'radio'
};

export default {
  fields,
  labels,
  extra,
  placeholders,
  rules,
  values,
  defaults,
  types
};
