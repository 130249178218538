import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Type from './Type';
import Description from './Description';
import Info from './Info';

@inject('tariffsStore')
@observer
class Form extends Component {
  render() {
    const { form } = this.props;

    return (
      <form className='form'>
        <div className='form__body'>
          <div className='row'>
            <div className='col col-12'>
              <Type form={form} />
            </div>
          </div>

          <div className='row'>
            <div className='col col-5'>
              <Info form={form} />
            </div>

            <div className='col col-6 offset-1'>
              <Description form={form} />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
