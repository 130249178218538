import * as React from 'react';
import styled from 'styled-components';
import { NSSogaz } from '@types';

import PriceList from './PriceList';
import { Heading } from 'evergreen-ui';

const Header = styled.div`
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -16px;
  margin-right: -16px;

  ${PriceList} {
    margin-left: 16px;
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  priceLists: NSSogaz.TPriceList[],
  onSelect: (item: NSSogaz.TPriceList) => void
}>

function PriceLists({ priceLists, onSelect, className }: Props) {
  return (
    <Wrapper className={className}>
      <Header>
        <Heading size={700}>
          Прайс-листы 1С:УСК
        </Heading>
      </Header>

      <Body>
        {priceLists.map((priceList, idx) => (
          <PriceList
            key={idx}
            priceList={priceList}
          />
        ))}
      </Body>
    </Wrapper>
  );
}

export default PriceLists;
