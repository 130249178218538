import React, { Component } from 'react';
import { reaction } from 'mobx';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

@withRouter
@inject('servicesStore')
@observer
class Control extends Component {
  constructor(props) {
    super(props);

    this.submitHandler = this.submitHandler.bind(this);
    this.successHandler = this.successHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  submitHandler(e) {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successHandler,
      onError: this.errorHandler
    });
  }

  successHandler(form) {
    const { servicesStore } = this.props;

    servicesStore.saveService(form)
      .then(() => this.props.history.push('/services'));
  }

  errorHandler(form) {
    console.log(form.errors());
  }

  render() {
    return (
      <div className='form__control'>
        <button
          type='submit'
          className='button green'
          onClick={this.submitHandler}
        ><FormattedMessage id='shared.confirm' />
        </button>

        <Link
          to='/services'
          className='button gray'
        ><FormattedMessage id='shared.cancel' />
        </Link>
      </div>
    );
  }
}

Control.propTypes = {
  form: PropTypes.object.isRequired
};

export default Control;
