import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from './Form';

@inject('priceListState')
@observer
class GeneralInfo extends Component {
  render() {
    const { priceListState } = this.props;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='price_list.general' />
        </div>
        <div className='groups__item-content'>
          <div className='segment'>
            <Form form={priceListState} />
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralInfo;
