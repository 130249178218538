import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from 'Pages/Tariffs/components/Form/FeedTypeAndMealOption';
import { FormLanguageContext } from 'Context/FormLanguageContext';

@observer
class FeedTypeAndMealOption extends Component {
  static contextType = FormLanguageContext;
  render() {
    const { form } = this.props;
    const currentLanguage = this.context.currentLanguage;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='tariffs.feed_type_and_meal_option' />
        </div>
        <div className='groups__item-content'>
          <div className='segment'>
            <Form
              form={form}
              currentLanguage={currentLanguage}
              handleLanguageChange={this.context.handleLanguageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

FeedTypeAndMealOption.propTypes = {
  form: PropTypes.object.isRequired
};

export default FeedTypeAndMealOption;
