import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class PolicyRuleNotice extends Component {
  render() {
    return (
      <p className='muted'>
        {formatMessage('tariffs.cancellation_policy.policy_penalty_description')}
        {formatMessage('tariffs.cancellation_policy.non_refund_description')}
      </p>

    );
  }
}

export default PolicyRuleNotice;
