import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from './Button.theme';
import Loader from './Loader';

const Label = styled.div`
`;

const Wrapper = styled.button`
  ${(props) => theme(props)}
`;

function Button({ children, loading, className, ...rest }) {
  return (
    <Wrapper
      {...rest}
      loading={loading}
      className={className}
    >
      <Label>
        {children}
      </Label>

      {loading && (
        <Loader />
      )}
    </Wrapper>
  );
}

Button.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  size: 'medium',
  variant: 'primary'
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  children: PropTypes.any.isRequired
};

export default styled(Button)``;
