import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from 'Utils/formatMessage';
import Slider from 'rc-slider';
import _range from 'lodash/range';
import { getSliderMarks } from 'Utils/getSliderMarks';
import { SliderLabel, SliderLabelMute } from 'Styles/styled';

@observer
class ChildPermission extends Component {
  render() {
    const { value } = this.props;
    const marks = getSliderMarks(0, 19, value);

    return (
      <div className='form__field slider'>
        <SliderLabel>
          <SliderLabelMute>
            <FormattedMessage id='hotel.accommodation' />
          </SliderLabelMute>

          <label>
            {value > 0
              ? formatMessage('hotel.children_age_from', { years: value })
              : formatMessage('hotel.children_age_any')}
          </label>
        </SliderLabel>

        <Slider
          allowCross={false}
          min={0}
          max={18}
          step={1}
          marks={marks}
          value={value}
          trackStyle={{ backgroundColor: '#C9E6C8' }}
          railStyle={{ backgroundColor: '#EEEBE7' }}
          handleStyle={[{ borderColor: '#69AC66', backgroundColor: '#69AC66' }]}
        />
      </div>
    );
  }
}

ChildPermission.propTypes = {
  value: PropTypes.number.isRequired
};

export default ChildPermission;
