import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import { formatMessage } from 'Utils/formatMessage';

import TariffInfoBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffInfoBlock';
import TariffDescriptionBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffDescriptionBlock';
import TariffDateTimeAndRatesBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffDateTimeAndRatesBlock';
import TariffSeasonsBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffSeasonsBlock';
import TariffRulesBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffRulesBlock';
import TariffServicesBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffServicesBlock';
import TariffCancellationBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffCancellationBlock';
import TariffStayPeriodBlock from 'Pages/Tariffs/containers/List/components/Tariffs/TariffStayPeriodBlock';

@observer
class TariffContent extends Component {
  @computed get status() {
    const { status } = this.props.tariff;

    switch (status) {
      case 'created':
        return {
          label: 'status booked',
          text: formatMessage('tariffs.statuses.created')
        };

      case 'uploaded':
        return {
          label: 'status draft',
          text: formatMessage('tariffs.statuses.uploaded')
        };

      case 'confirmed':
        return {
          label: 'status confirmed',
          text: formatMessage('tariffs.statuses.confirmed')
        };

      default:
        return {
          label: 'status',
          text: formatMessage('tariffs.statuses.unknown')
        };
    }
  }

  @computed get labels() {
    const { tariff } = this.props;
    const tariff_type = tariff.isBase ? 'tariff' : tariff.type;

    const periodStr = [tariff_type, 'available_period'].join('.');
    const rateStr = [tariff_type, 'prices'].join('.');
    const seasonStr = [tariff_type, 'periods'].join('.');
    const typeStr = [tariff_type, 'type_name'].join('.');
    const stayPeriodStr = [tariff_type, 'stay_period'].join('.');
    const cancellationStr = [tariff_type, 'cancellation_policy'].join('.');

    return {
      available_period: formatMessage(periodStr),
      rates: formatMessage(rateStr, { billing_hour: tariff.billing_hour }),
      seasons: formatMessage(seasonStr, { billing_hour: tariff.billing_hour }),
      status: this.status,
      type: formatMessage(typeStr),
      stay_period: formatMessage(stayPeriodStr),
      cancellation_policy: formatMessage(cancellationStr),
      price_calculation_rule: formatMessage('tariffs.price_calculation_rules.self'),
      services_included: formatMessage('tariff.services_included')
    };
  }

  render() {
    const { tariff, onClickHandler } = this.props;
    const { name, room_type } = this.props.tariff;

    return (
      <div className='content'>
        <div className='header' onClick={onClickHandler(tariff)}>
          <b>{name}</b>
          <div className='sub_header'>{room_type.name}</div>
        </div>

        <div className='description'>
          <TariffInfoBlock tariff={tariff} labels={this.labels} />

          <TariffDescriptionBlock tariff={tariff} />

          <TariffDateTimeAndRatesBlock tariff={tariff} labels={this.labels} />

          <TariffSeasonsBlock tariff={tariff} labels={this.labels} />

          <TariffStayPeriodBlock tariff={tariff} labels={this.labels} />

          <TariffRulesBlock tariff={tariff} labels={this.labels} />

          <TariffCancellationBlock tariff={tariff} labels={this.labels} />

          <TariffServicesBlock tariff={tariff} labels={this.labels} />
        </div>
      </div>
    );
  }
}

TariffContent.propTypes = {
  tariff: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default TariffContent;
