import store from 'store';
import _includes from 'lodash/includes';

const defaultLocal = 'ru';

let language;
language = store.get('language');

const isDefined = _includes(['en', 'ru'], language);

if (!isDefined) {
  language = defaultLocal;
  store.set('language', language);
}

export default language;
