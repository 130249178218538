import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { computed, observable, toJS } from 'mobx';
import { formatMessage } from 'Utils/formatMessage';
import moment from 'Utils/moment';

// To avoid conflict with the dashboard, it is necessary the new instance
// instead of using (@inject) external QuotasStore
import { QuotasStore as LocalQuotasStore } from 'Stores/QuotasStore/List';

import Modal from 'Shared/ui/Modal';
import Switch from 'Shared/ui/Switch';
import Spinner from 'Shared/ui/Spinner';
import Calendar from './components/Calendar';
import Loader from './components/ContentLoader';
import FormState, { fields } from './components/FormState';
import SourceSelect from './components/SourceSelect';
import Form from './components/Form';
import Day from './components/Day';

import styled from 'styled-components';

const S = {
  NavSection: styled.div`
    margin-bottom: 30px;
    position: relative;
    z-index: 10000;
  `,

  NavSectionDate: styled.div`
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 25px;

    &:not(:empty) {
      margin-right: 30px;
    }    
  `,

  SourceSelect: styled(SourceSelect)`
    min-width: 200px;
    display: inline-block;
    vertical-align: middle;
  `,

  CalendarSection: styled.div`
    margin-bottom: 15px;
  `,

  EditSection: styled.div`
    margin-bottom: 27px;
    display: flex;
    justify-content: space-between;
  `,

  EditSectionColumn: styled.div`
    width: 47.7%;
  `,

  EditSectionHeader: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,

  EditSectionLabel: styled.div`
    font-size: 20px;
    line-height: 25px;
    display: inline;
  `,

  EditSectionDisclaimer: styled.div`
    font-size: 14px;
    line-height: 19px;  
  `,

  ActionsSection: styled.div`
    border-top: 1px solid #D7D3CC;
    padding-top: 32px;
    display: flex;
    justify-content: flex-end;
    
    button {
      margin-right: 13px;
    }
    ${({ unresponsive }) => unresponsive && 'pointer-events: none'}
  `,

  Error: styled.div`
    display: inline-block;
    vertical-align: middle;
    color: #ff7f7f;
    margin-left: 20px;
    max-width: 400px;
  `,

  Spinner: styled(Spinner)`
    margin-left: 5px;
  `
};

const QUOTA_EDIT_TYPE = 'quota';
const AVAILABILITY_EDIT_TYPE = 'availability';
const LOAD_UP_MONTHS = 5;
const TO_TRIGGER_MONTHS_COUNT = 2;
const NOW = Date.now();
const CHECK_IN = moment(NOW).subtract(LOAD_UP_MONTHS, 'month').startOf('month');
const CHECK_OUT = moment(NOW).add(LOAD_UP_MONTHS, 'month').endOf('month');

@inject('dashboardStore', 'quotasStore', 'searchState')
@withRouter
@observer
class Show extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { room_type_id } } } = props;
    this.roomTypeId = room_type_id;

    this.localQuotasStore = LocalQuotasStore.create();

    this.switchItems = [
      {
        label: formatMessage('dashboard.quota.edit.availability.self'),
        value: AVAILABILITY_EDIT_TYPE
      },
      {
        label: formatMessage('dashboard.quota.edit.quota.self'),
        value: QUOTA_EDIT_TYPE
      }
    ];

    this.state = {
      isOpen: true,
      rangeStartDate: null,
      rangeEndDate: null,
      selectedSourceId: null,
      error: null,
      calendarKey: Math.random(),
      editType: QUOTA_EDIT_TYPE
    };
  }

  @observable form = new FormState(fields);
  @observable roomType = null;
  @observable quotasByDays = null;
  @observable isPending = false;

  async componentDidMount() {
    await this.fetchLocalStoreQuotas(
      CHECK_IN.format('Y-MM-DD'),
      CHECK_OUT.format('Y-MM-DD')
    );

    this.roomType = this.localQuotasStore.findOne(this.roomTypeId);
    this.fillQuotasByDays();

    const sources = toJS(this.roomType.sources).map((source, index) => {
      return {
        id: source.insurance.id,
        name: source.insurance.name,
        selected: index === 0,
        quantity: 0
      };
    });

    this.setState({
      selectedSourceId: sources[0].id
    });

    this.form.update({
      sources,
      room_type: {
        id: this.roomType.id,
        name: this.roomType.name
      }
    });
  }

  componentDidUpdate() {
    if (this.isPending) {
      return false;
    }

    const { rangeStartDate, rangeEndDate, editType } = this.state;

    if (rangeStartDate && rangeEndDate) {
      const check_in = rangeStartDate.clone().format('Y-MM-DD');
      const check_out = rangeEndDate.clone().format('Y-MM-DD');

      let quantity;
      switch (editType) {
        case QUOTA_EDIT_TYPE:
          quantity = this.minRestrictionSelected;
          break;
        case AVAILABILITY_EDIT_TYPE:
          quantity = this.minAvailableSelected;
          break;
      }

      this.form.$('sources').fields.values().forEach(field => {
        field.$('quantity').set(quantity);
      });

      this.form.update({
        check_in,
        check_out,
        days: this.selectedDays
      });
    }

    // Синхронизация верхнего селектора источников с нижней формой с чекбоксами
    const sourcesFields = this.form.$('sources').fields.values();
    const selectedSources = sourcesFields.filter(field => field.value.selected);
    if (selectedSources.length === 1) {
      sourcesFields.forEach(field => {
        field.$('selected').set(
          this.state.selectedSourceId === field.value.id
        );
      });
    }
  }

  fillQuotasByDays() {
    /*
      Это хэш-таблица для быстрого доступа
      На выходе структура по типу такой:
      {
        '5a27ea5273e8b0489f2768cb': {
          '2019-11-01': {busy: 0, available: 2, in_all: 18, restriction: 2},
          '2019-11-02': {busy: 0, available: 2, in_all: 18, restriction: 2},
          ...
        },

        '5ba103be73e8b035cb40c8da': {
          '2019-11-01': {busy: 0, available: 10, in_all: 18, restriction: 10},
          '2019-11-02': {busy: 0, available: 10, in_all: 18, restriction: 10},
          ...
        },

        ...
      }
    */
    if (!this.roomType) return;

    const { sources } = this.roomType;

    if (!this.quotasByDays) {
      // Если не существует - инициализировать и заполнить первыми значениями
      this.quotasByDays = {};

      toJS(sources).forEach(source => {
        this.quotasByDays[source.insurance.id] = source.availabilities.reduce((acc, quota) => {
          const { day, busy, available, in_all, restriction } = quota;
          acc[day] = { busy, available, in_all, restriction };
          return acc;
        }, {});
      });
    } else {
      // Апдейтнуть
      toJS(sources).forEach(source => {
        source.availabilities.forEach(quota => {
          const { day, busy, available, in_all, restriction } = quota;

          if (this.quotasByDays[source.insurance.id][day]) return;
          this.quotasByDays[source.insurance.id][day] = { busy, available, in_all, restriction };
        });
      });
    }
  }

  checkTotalLimit() {
    const { capacity: { total } } = this.props.dashboardStore.room_types
      .find(item => item.id === this.roomTypeId);

    const { sources: formSources } = this.form.values();
    let error;
    formSources.forEach(({ quantity, name }) => {
      if (quantity > total) {
        error = formatMessage('dashboard.quota.edit.quota.limit_error', {
          sourceName: name,
          limit: total
        });
      }
    });
    return error;
  }

  @computed get minRestrictionSelected() {
    const source = this.quotasByDays[this.state.selectedSourceId];
    const restrictions = [];

    this.selectedDays.forEach(dateString => {
      restrictions.push(source[dateString].restriction);
    });

    return Math.min(...restrictions);
  }

  @computed get minAvailableSelected() {
    const source = this.quotasByDays[this.state.selectedSourceId];
    const available = [];

    this.selectedDays.forEach(dateString => {
      available.push(source[dateString].available);
    });

    return Math.min(...available);
  }

  async fetchLocalStoreQuotas(check_in, check_out) {
    return await this.localQuotasStore.fetch({ check_in, check_out });
  }

  reRenderCalendar() {
    this.setState({ calendarKey: Math.random() });
  }

  handleSourceSelectChange = val => {
    if (this.state.selectedSourceId === val) return;

    this.setState(
      { selectedSourceId: val },
      this.reRenderCalendar
    );
  };

  handleSwitchChange = selectedItem => {
    this.setState(
      { editType: selectedItem.value },
      this.reRenderCalendar
    );
  };

  @computed get selectedSource() {
    return this.quotasByDays[this.state.selectedSourceId];
  }

  handleChangeMonth = async activeDate => {
    /*
      5, 4, 3, 2, 1  |  1, 2, 3, 4, 5
      _  _                       _  _
    */
    if (activeDate.clone().subtract(TO_TRIGGER_MONTHS_COUNT, 'month').startOf('month').format('Y-MM-DD') === CHECK_IN.clone().format('Y-MM-DD')) {
      // Листаем в прошлое.
      // До границы осталоь TO_TRIGGER_MONTHS_COUNT месяцев.
      // Приращиваем кусок из LOAD_UP_MONTHS месяцев слева (подгружаем)
      await this.fetchLocalStoreQuotas(
        CHECK_IN.clone().subtract(LOAD_UP_MONTHS, 'month').startOf('month').format('Y-MM-DD'),
        CHECK_IN.format('Y-MM-DD')
      );
      CHECK_IN.subtract(LOAD_UP_MONTHS, 'month').startOf('month');
      this.fillQuotasByDays();
    } else if (activeDate.clone().add(TO_TRIGGER_MONTHS_COUNT, 'month').endOf('month').format('Y-MM-DD') === CHECK_OUT.clone().format('Y-MM-DD')) {
      // Листаем в будущее.
      // До границы осталоь TO_TRIGGER_MONTHS_COUNT месяцев.
      // Приращиваем кусок из LOAD_UP_MONTHS месяцев справа (подгружаем)
      await this.fetchLocalStoreQuotas(
        CHECK_OUT.format('Y-MM-DD'),
        CHECK_OUT.clone().add(LOAD_UP_MONTHS, 'month').endOf('month').format('Y-MM-DD')
      );
      CHECK_OUT.add(LOAD_UP_MONTHS, 'month').endOf('month');
      this.fillQuotasByDays();
    }
  };

  renderDay = day => {
    if (!day) return null;
    if (!this.quotasByDays) return day.format('D');

    const quota = this.selectedSource[day.format('Y-MM-DD')];

    const { available, busy, restriction, in_all } = quota;
    // const quantity = this.state.editType === QUOTA_EDIT_TYPE ? restriction : available;
    // const secondaryQuantity = this.state.editType === QUOTA_EDIT_TYPE ? available : restriction;

    const isNow = day.format('Y-MM-DD') === moment(Date.now()).format('Y-MM-DD');

    return (
      <Day
        day={day}
        quota={restriction}
        busy={busy}
        free={available}
        isNow={isNow}
      />
    );
  };

  @computed get sourcesOptions() {
    const { roomType } = this;
    if (!roomType) return [];

    return toJS(roomType.sources).map(({ insurance: { name, id } }) => ({
      label: name,
      value: id
    }));
  }

  @computed get selectedDays() {
    const { rangeStartDate, rangeEndDate } = this.state;
    if (!rangeStartDate || !rangeEndDate) return [];

    const now = rangeStartDate.clone(); const dates = [];

    while (now.isSameOrBefore(rangeEndDate)) {
      dates.push(now.format('Y-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  }

  @computed get formattedDate() {
    const { rangeStartDate, rangeEndDate } = this.state;

    if (!rangeStartDate || !rangeEndDate) return '';

    // TODO: to use moment
    const startDate = new Date(rangeStartDate.format());
    const endDate = new Date(rangeEndDate.format());

    const [startDay, startMonth, startYear] = [
      startDate.getDate(),
      formatMessage('shared.month', { month: startDate.getMonth() + 1 }),
      startDate.getFullYear()
    ];

    const [endDay, endMonth, endYear] = [
      endDate.getDate(),
      formatMessage('shared.month', { month: endDate.getMonth() + 1 }),
      endDate.getFullYear()
    ];

    return `${startDay} ${startMonth} – ${endDay} ${endMonth}, ${startYear}
      ${endYear > startYear ? ' / ' + endYear : ''}`;
  }

  onDatesChange = ({ startDate, endDate }) => {
    if (this.state.rangeEndDate) {
      this.setState({
        rangeStartDate: startDate,
        rangeEndDate: null
      });
    } else {
      this.setState({
        rangeStartDate: startDate,
        rangeEndDate: endDate
      });
    }

    if (startDate && endDate) {
      this.setState({ error: null });
    }
  };

  closeModal = () => {
    this.form.clear();
    this.setState({ isOpen: false });
    this.props.history.push('/dashboard');
  };

  onSubmitHandler = (e) => {
    e.preventDefault();

    const { rangeStartDate, rangeEndDate } = this.state;
    const { quotasStore: { ignore_room_type_capacity } } = this.props;

    if (!rangeStartDate && !rangeEndDate) {
      this.setState({ error: formatMessage('dashboard.quota.edit.range_error') });
      return;
    }

    if (rangeStartDate && !rangeEndDate) {
      this.setState({ error: formatMessage('dashboard.quota.edit.end_range_error') });
      return;
    }

    const quotaLimitError = this.checkTotalLimit();
    if (quotaLimitError && !ignore_room_type_capacity) {
      this.setState({ error: quotaLimitError });
      return;
    }

    this.isPending = true;

    this.form.onSubmit(e, {
      onSuccess: this.onSuccessHandler,
      onError: this.onErrorHandler
    });
  };

  onSuccessHandler = async form => {
    const {
      searchState,
      quotasStore
    } = this.props;

    const values = form.values();
    const { editType: special } = this.state;

    const { check_in, check_out } = searchState.values();

    const updateResult = await quotasStore.update({ ...values, check_in, check_out }, { special });
    const fetchResult = await quotasStore.fetch({ check_in, check_out });
    this.isPending = false;
    this.closeModal();
  };

  onErrorHandler = (form) => {
    this.isPending = false;
    console.log('error', form.errors());
  };

  render() {
    const {
      roomType,
      quotasByDays,
      isPending,
      state: { rangeStartDate, rangeEndDate, selectedSourceId }
    } = this;

    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
      >
        <S.NavSection>
          <S.NavSectionDate
            dangerouslySetInnerHTML={{ __html: this.formattedDate }}
          />
          <S.SourceSelect
            options={this.sourcesOptions}
            value={this.state.selectedSourceId}
            onChange={this.handleSourceSelectChange}
          />
          {this.state.error && <S.Error>{this.state.error}</S.Error>}
        </S.NavSection>

        <S.CalendarSection>
          {roomType && quotasByDays && selectedSourceId
            ? <Calendar
                key={this.state.calendarKey}
                startDate={rangeStartDate}
                endDate={rangeEndDate}
                onDatesChange={this.onDatesChange}
                renderDay={this.renderDay}
                onPrevMonthClick={this.handleChangeMonth}
                onNextMonthClick={this.handleChangeMonth}
                isLoading={this.localQuotasStore.isPending}
              />
            : <Loader />}
        </S.CalendarSection>

        <S.EditSection>
          <S.EditSectionColumn>
            <S.EditSectionHeader>
              <S.EditSectionLabel>
                <FormattedMessage id='shared.edit' />
              </S.EditSectionLabel>
              <Switch
                theme='green'
                items={this.switchItems}
                defaultSelected={this.state.editType}
                onChange={this.handleSwitchChange}
              />
            </S.EditSectionHeader>
            <Form form={this.form} />
          </S.EditSectionColumn>

          <S.EditSectionColumn>
            <S.EditSectionDisclaimer>
              <FormattedMessage id={`dashboard.quota.edit.${this.state.editType}.disclaimer`} />
              <br />
              <strong>
                <FormattedMessage id={`dashboard.quota.edit.${this.state.editType}.formula`} />
              </strong>
            </S.EditSectionDisclaimer>
          </S.EditSectionColumn>
        </S.EditSection>

        <S.ActionsSection unresponsive={isPending}>
          <button
            className='button green'
            onClick={this.onSubmitHandler}
          >
            <FormattedMessage id='shared.confirm' />
            {isPending && <S.Spinner />}
          </button>
          <button
            className='button gray'
            onClick={this.closeModal}
          >
            <FormattedMessage id='shared.cancel_do' />
          </button>
        </S.ActionsSection>
      </Modal>
    );
  }
}

export default Show;
