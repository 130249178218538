import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Upload from './Upload';

@observer
class Uploads extends Component {
  @observable currentModal = null;

  @computed get images() {
    return this.props.images.fields.values()
      .filter(image => !image.has('_destroy'));
  }

  @computed get imagesForCarousel() {
    return this.images.map((image) => {
      return { src: image.$('url').value };
    });
  }

  onZoomHandler = (idx, image) => {
    this.currentModal = idx;
  }

  onRemoveHandler = (idx, image) => {
    image.update({ _destroy: true });
  }

  render() {
    const listItems = this.images.map((image, i) => {
      return (
        <Upload
          key={image.id}
          image={image}
          imgIndex={i}
          onRemoveHandler={this.onRemoveHandler}
          onZoomHandler={this.onZoomHandler}
        />
      );
    });

    return (
      <div className='photos small'>
        {listItems}

        {Number.isInteger(this.currentModal) && (
          <ModalGateway>
            <Modal
              allowFullscreen={false}
              closeOnBackdropClick={false}
              onClose={this.onZoomHandler}
            >
              <Carousel
                currentIndex={this.currentModal}
                components={{ Footer: null }}
                views={this.imagesForCarousel}
              />
            </Modal>
          </ModalGateway>
        )}
      </div>
    );
  }
}

Uploads.propTypes = {
  images: PropTypes.object.isRequired
};

export default Uploads;
