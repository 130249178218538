import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Body from './Body';
import Control from './Control';
import state from './state';

@observer
class Form extends Component {
  componentDidMount() {
    const { form, service } = this.props;
    if (service) form.set(service.toJSON());
  }

  componentWillUnmount() {
    this.props.form.clear();
  }

  render() {
    const { form } = this.props;

    return (
      <form className='form'>
        <Body form={form} />
        <Control form={form} />
      </form>
    );
  }
}

Form.defaultProps = {
  form: state
};

export default Form;
