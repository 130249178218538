import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { DateRangePicker } from 'react-dates';
import moment from 'Utils/moment';
import { formatMessage } from 'Utils/formatMessage';

import ArrowLeftIcon from 'react-icons/lib/md/chevron-left';
import ArrowRightIcon from 'react-icons/lib/md/chevron-right';

const ArrowIcon = () => (<span>—</span>);

@observer
class DateRangeInput extends Component {
  @observable focusedInput = null;
  @observable startDate = this.props.startDate;
  @observable endDate = this.props.endDate;

  @computed get startDateObj() {
    const { startDate } = this;
    return startDate ? moment(startDate) : null;
  }

  @computed get endDateObj() {
    const { endDate } = this;
    return endDate ? moment(endDate) : null;
  }

  isOutsideRange(day) {
    return false;
  }

  handleDateChange = dates => {
    const { onDateChange } = this.props;
    onDateChange(dates);
    const { startDate, endDate } = dates;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  handleFocusChange = focusedInput => {
    this.focusedInput = focusedInput;
  }

  render() {
    const {
      className,
      startDate,
      endDate,
      disabled,
      enableOutsideDays,
      isOutsideRange,
      showClearDates
    } = this.props;

    const cs = classNames(
      'form__field',
      className
    );

    const isOutsideRangeFunc = isOutsideRange || this.isOutsideRange;
    const startDatePlaceholder = formatMessage('shared.start_date');
    const endDatePlaceholder = formatMessage('shared.end_date');

    return (
      <div className={cs}>
        <DateRangePicker
          startDate={this.startDateObj}
          endDate={this.endDateObj}
          onDatesChange={this.handleDateChange}
          focusedInput={this.focusedInput}
          onFocusChange={this.handleFocusChange}
          showDefaultInputIcon={false}
          hideKeyboardShortcutsPanel
          daySize={32}
          startDateId={startDate.id}
          startDatePlaceholderText={startDatePlaceholder}
          endDateId={endDate.id}
          endDatePlaceholderText={endDatePlaceholder}
          customArrowIcon={<ArrowIcon />}
          navPrev={<ArrowLeftIcon />}
          navNext={<ArrowRightIcon />}
          disabled={disabled}
          readOnly={disabled}
          enableOutsideDays={enableOutsideDays}
          isOutsideRange={isOutsideRangeFunc}
          showClearDates={showClearDates}
        />
      </div>
    );
  }
}

DateRangeInput.defaultProps = {
  className: '',
  showLabel: true,
  disabled: false,
  enableOutsideDays: false,
  isOutsideRange: undefined,
  showClearDates: false
};

DateRangeInput.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onDateChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  enableOutsideDays: PropTypes.bool.isRequired,
  isOutsideRange: PropTypes.func,
  showClearDates: PropTypes.bool.isRequired
};

export default DateRangeInput;
