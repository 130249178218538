import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'Utils/moment';

import Item from './Item';

@inject('store', 'dashboardStore')
@observer
class Grid extends Component {
  render() {
    const { check_in, check_out, dayWidth } = this.props;

    const listItems = [];
    let i = 0;
    let currentDay = moment(check_in);

    while (currentDay.isSameOrBefore(check_out)) {
      listItems.push(<Item key={i} item={currentDay} width={dayWidth} />);
      i = i + 1;
      currentDay = currentDay.clone().add(1, 'days');
    }

    return listItems;
  }
}

Grid.propTypes = {
  check_in: PropTypes.string.isRequired,
  check_out: PropTypes.string.isRequired,
  dayWidth: PropTypes.number.isRequired
};

export default Grid;
