import { types } from "mobx-state-tree";
import { Translation } from "./Translation";

export const Amenity = types.model("Amenity", {
  id: types.identifier(types.string),
  tag: types.string,
  name: types.string,
  parent_tag: types.maybe(types.string),
  icon: types.maybe(types.string),
  priority: types.maybe(types.number),
  name_translations: types.optional(Translation, {}),
  card_option_tags: types.maybe(types.array(types.string)),
});
