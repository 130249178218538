import styled, { css } from 'styled-components';

import { OpenRow, CloseRow } from 'Components/ui/Icons';

export const OrdersTableTr = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(8, 1fr);
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: #EAE9E4;
  padding-left: 24px;
  padding-right: 24px;
`;

export const OrdersTableRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #DCD8D2;

  ${props => props.isOpen && css`
    margin-top: -1px;
    cursor: auto;
    border: 1px solid #DCD8D2;
    background: rgba(234, 233, 228, 0.3);
  `}

  &:hover{
    background: rgba(234, 233, 228, 0.3);
  }
`;

export const OpenRowIcon = styled(OpenRow)`
  font-size: 20px;
`;

export const CloseRowIcon = styled(CloseRow)`
  cursor: pointer;
  font-size: 20px;
`;

export const Text = styled.span`
`;

export const OrdersTableTd = styled.div`
  display: flex;
  align-items: center;

  ${OpenRow} + ${Text} {
    margin-left: 10px;
  }

  ${CloseRow} + ${Text} {
    margin-left: 10px;
  }
`;

export const OrdersTableHeader = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(8, 1fr);
  padding: 16px 24px 16px 24px;

  ${props => props.isOpen && css`
    border-bottom: none;
  `}
`;

export const OrderTableRowHeader = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 2fr repeat(6, 1fr);
  margin-top: 12px;
  padding: 0 24px 12px 24px;
  color: #999999;
`;

export const RowHeaderItem = styled.div`

`;

export const RowFooterItem = styled.div`
  cursor: pointer;
  color: #1F6FCC;

  ${props => props.isOpen && css`
    color: #999999;
  `}
`;

export const OrderTableRowFooter = styled.div`
  ${RowFooterItem} + ${RowFooterItem} {
    margin-left: 10px;
  }
  display: flex;
  padding: 0px 24px 16px 24px;
`;

export const OrderTableRowContent = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 2fr repeat(6,1fr);
  border-top: 1px solid #EAE9E4;
  margin: 0px 24px 16px 24px;
  padding-top: 16px;
`;
