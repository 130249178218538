import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';

@inject('hotelStore')
@observer
class ObjectStars extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialStar = form.$('star').value;

        this.setState({
          selectedStar: initialStar
        });
      }
    );
  }

  state = {
    selectedStar: ''
  };

  onSelectRadio = e => {
    const { form } = this.props;
    const selectStar = Number(e.currentTarget.value);
    this.setState({
      selectedStar: selectStar
    });
    form.update({ star: selectStar });
  };

  render() {
    const starsCountArr = [0, 1, 2, 3, 4, 5];

    const radioList = starsCountArr.map((data, index) => {
      return (
        <div key={data}>
          <input
            type='radio'
            key={data}
            value={data}
            className='input radio_button'
            checked={this.state.selectedStar === data}
            onChange={this.onSelectRadio}
          />
          <span className='radio_button_name'>{data}</span>
        </div>
      );
    });

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.star' />
        </div>

        <div className='groups__item-content'>
          <div className='segment_star'>
            <span className='muted'>
              <FormattedMessage id='hotel.star_description' />
            </span>
            <div className='radio_list_star'>
              <>
                {radioList.map(radio => (
                  <div key={radio.key}>{radio}</div>
                ))}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ObjectStars;
