import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FormLanguageContext } from "Context/FormLanguageContext";
import {
  RussiaIconInput,
  EnglandIconInput,
  TextareaStyled,
  Wrapper,
} from "Styles/styled";
import { Label } from "./FormItemInput.styled";
import { ReactComponent as CloseIcon } from "Icons/close.svg";

@observer
class FormItemText extends Component {
  static contextType = FormLanguageContext;

  render() {
    const {
      field,
      type,
      placeholder,
      rows,
      cols,
      className,
      showLabel,
      isLocalizeField,
      size,
      mute,
      handleRemove,
    } = this.props;

    const inputCls = classNames({
      error: field.error && !field.focused,
    });

    const currentLanguage = this.context.currentLanguage;

    return (
      <div className={className}>
        {showLabel && (
          <Label mute={mute}>
            {field.label}
            {isLocalizeField && `(${currentLanguage.toUpperCase()})`}
            <span className="error">{field.error && "*"}</span>
          </Label>
        )}
        <Wrapper>
          {isLocalizeField &&
            (currentLanguage === "ru" ? (
              <RussiaIconInput
                size={size}
                onClick={() => this.context.handleLanguageChange("en")}
              />
            ) : (
              <EnglandIconInput
                size={size}
                onClick={() => this.context.handleLanguageChange("ru")}
              />
            ))}
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <TextareaStyled
              isLocalizeField={isLocalizeField}
              className={inputCls}
              minRows={rows}
              {...field.bind({ type, placeholder })}
            />
            {handleRemove && (
              <a className="action" href="#" onClick={handleRemove}>
                <i className="icon">
                  <CloseIcon />
                </i>
              </a>
            )}
          </div>
        </Wrapper>
      </div>
    );
  }
}

FormItemText.defaultProps = {
  rows: 3,
  cols: 3,
  placeholder: null,
  className: "form__field",
  showLabel: true,
};

FormItemText.propTypes = {
  field: PropTypes.object.isRequired,
};

export default FormItemText;
