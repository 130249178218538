import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction, observable } from 'mobx';
import GoogleMap from 'google-map-react';

import Marker from './Marker';

@observer
class Map extends Component {
  render() {
    const options = {
      draggable: false,
      scaleControl: true,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      styles: [{
        featureType: 'all',
        stylers: [{
          saturation: -80
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          { hue: '#00ffee' },
          { saturation: 50 }
        ]
      }, {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }]
    };

    const { location, coordinates } = this.props.address;

    return (
      <div className='map'>
        <GoogleMap
          options={options}
          bootstrapURLKeys={{ key: 'AIzaSyAXaiAVLHTskP3XBYbMObGbnY-al85kvgA' }}
          center={{ lat: coordinates[1], lng: coordinates[0] }}
          defaultZoom={13}
          yesIWantToUseGoogleMapApiInternals
        >
          <Marker lat={coordinates[1]} lng={coordinates[0]} />
        </GoogleMap>
      </div>
    );
  }
}

Map.propTypes = {
  address: PropTypes.object.isRequired
};

export default Map;
