import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Seasons from '../../Seasons';

@observer
class TariffSeasonsBlock extends Component {
  render() {
    const { tariff, labels } = this.props;
    return (
      tariff.seasons.length > 1 &&
        <div className='row'>
          <div className='col col-12'>
            <Seasons seasons={tariff.seasons} labels={labels} />
          </div>
        </div>
    );
  }
}

TariffSeasonsBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffSeasonsBlock;
