import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Emails from './Emails';
import Phones from './Phones';

@observer
class Contacts extends Component {
  render() {
    const { contacts } = this.props.hotel;

    const emails = contacts
      .filter(item => item.type === 'email');

    const phones = contacts
      .filter(item => item.type === 'phone');

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.contact_information' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div className='form'>
              <Emails emails={emails} />
              <Phones phones={phones} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contacts.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default Contacts;
