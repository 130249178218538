import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import ArrowDownIcon from 'react-icons/lib/md/keyboard-arrow-down';
import ArrowUpIcon from 'react-icons/lib/md/keyboard-arrow-up';

class SingleSelect extends Component {
  arrowRenderer({ onMouseDown, isOpen }) {
    return isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />;
  }

  render() {
    const { className, value, options, onChange } = this.props;
    const cn = classNames('form__field', className);

    return (
      <div className={cn}>
        <Select
          className={this.inputClsName}
          value={value}
          options={options}
          clearable={false}
          searchable={false}
          simpleValue
          onChange={onChange}
          arrowRenderer={this.arrowRenderer}
        />
      </div>
    );
  }
}

SingleSelect.propTypes = {
  // value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired
};

export default SingleSelect;
