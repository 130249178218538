import { types } from 'mobx-state-tree';

export const PayloadStore = types.model('PayloadStore', {
  exp: types.number,
  iat: types.number,
  jti: types.string,
  scp: types.string,
  sub: types.string,

  get expired() {
    return this.exp * 1000 <= new Date().getTime();
  }
});
