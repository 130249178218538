import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import FormItemText from "Shared/form/FormItemText";
import AutoComplete from "Shared/form/AutoComplete";

import { FormLanguageContext } from "Context/FormLanguageContext";

@observer
class Option extends Component {
  static propTypes = {
    field: PropTypes.object,
    number: PropTypes.number,
    dictionary: PropTypes.object,
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    onRemove: () => {},
  };

  static contextType = FormLanguageContext;

  handleRemove = (e) => {
    e.preventDefault();

    const { field, onRemove } = this.props;
    onRemove(field);
  };

  render() {
    const { field, number, dictionary } = this.props;
    const currentLanguage = this.context.currentLanguage;

    return (
      <div className="form__field manipulation_option">
        <div className="number">{number}</div>

        <AutoComplete
          field={field.$(`name_translations.${currentLanguage}`)}
          dictionary={dictionary}
        >
          <FormItemText
            field={field.$(`name_translations.${currentLanguage}`)}
            rows={1}
            showLabel={false}
            isLocalizeField
            size="small"
            handleRemove={this.handleRemove}
          />
        </AutoComplete>
      </div>
    );
  }
}

export default Option;
