import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Photos from './Photos';

@inject('hotelStore')
@observer
class List extends Component {
  render() {
    const { hotel } = this.props.hotelStore;

    return (
      <div className='page__body wide'>
        <div className='segment'>
          {hotel && <Photos />}
        </div>
      </div>
    );
  }
}

export default List;
