import { NSSogaz } from '@types';
import { IAdapter } from '../types';

import _groupBy from 'lodash/groupBy';
import _pick from 'lodash/pick';
import _min from 'lodash/min';
import _max from 'lodash/max';

export const servicesGroups = (state: IAdapter): NSSogaz.TServiceGroup[] => {
  let items: any = state.price_lists.data.flatMap(priceList => {
    return (priceList.services || []).map(service => ({
      ...service,
      price_list_id: priceList.id,
      lpu_id: priceList.lpu_id
    }));
  });

  items = _groupBy(items, 'service_code');

  const groups = Object.values(items).map((services: any) => {
    const baseValue = _pick(services[0], 'service_code', 'service_name', 'service_id', 'is_room', 'price_list_id', 'lpu_id');
    const periods = services.map((service: any) => _pick(service, 'begin_date', 'end_date', 'price', 'price_discount'));

    const begin_date = _min(services.map((s: any) => s.begin_date));
    const end_date = _max(services.map((s: any) => s.end_date));

    return { ...baseValue, begin_date, end_date, periods } as NSSogaz.TServiceGroup;
  });

  return groups;
};
