import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import Source from './Source';
import Status from './Status';
import Dates from './Dates';

@observer
class TopBarSearchBox extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className='search-box'>
        <form method='post' action='' className='form'>
          <div className='form__fields'>
            <Dates
              startDate={form.$('start_date')}
              endDate={form.$('end_date')}
            />
            <Source field={form.$('source')} />
            <Status field={form.$('status')} />
          </div>
        </form>
      </div>
    );
  }
}

export default TopBarSearchBox;
