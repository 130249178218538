import FormState from 'Shared/form/FormState';
import fields from './fields';
import { observable, computed, autorun, reaction } from 'mobx';
import _times from 'lodash/times';
import _range from 'lodash/range';

class OrderState extends FormState {
  constructor(props) {
    super(props);

    this.capacityHandler = autorun(() => {
      const capacity = this.rooms.length > 0 && this.rooms[0].capacity;
      this.setCapacity(capacity);
    });

    this.seatsHandler = autorun(() => {
      let beds = this.$('beds').value;
      let occupation = 'main';
      this.fillSlots({ beds, occupation });

      beds = this.$('extra_beds').value;
      occupation = 'extra';
      this.fillSlots({ beds, occupation });
    });
  }

  @observable rates = []
  @observable rooms = []
  @observable capacity = { beds: 0, extra_beds: 0 }

  @computed get bedsCount() {
    return this.capacity
      ? Number(this.capacity.beds)
      : 0;
  }

  @computed get isMainBedsOccupied() {
    let beds = this.$('beds').value;
    beds = Number(beds);

    return beds > 0 && beds === this.bedsCount;
  }

  @computed get bedsOptions() {
    return _range(1, this.bedsCount + 1).map((value) => {
      return { value: value, label: value.toString() };
    });
  }

  @computed get extraBedsCount() {
    return this.capacity
      ? this.capacity.extra_beds
      : 0;
  }

  @computed get extraBedsOptions() {
    return _range(0, this.extraBedsCount + 1).map((value) => {
      return { value: value, label: value.toString() };
    });
  }

  allMainAsRate(values) {
    // Ignore childs
    if (values.age_group === 'child') return;

    // Ignore extra beds
    if (values.occupation === 'extra') return;

    // Change main seats
    this.$('slots').fields.values()
      .filter(slot => slot.$('rate.occupation').value === 'main')
      .filter(slot => slot.$('rate.type').value != values.type)
      .filter(slot => slot.$('rate.age_group').value === 'adult')
      .forEach(slot => slot.set({ rate: values }));
  }

  setCapacity = (capacity) => {
    this.capacity = capacity;
  }

  fillSlots = ({ beds = 0, occupation = 'main' }) => {
    const slots = this.$('slots').fields.values()
      .filter(slot => slot.$('type').value == occupation);

    const diff = beds - slots.length;
    const count = Math.abs(diff);

    if (diff > 0) {
      _times(count).forEach(() => {
        const fields = this.getSlotFields({ occupation });

        this.$('slots').add(fields);
      });
    }

    if (diff < 0) {
      _times(count).forEach(() => {
        const slot = slots.pop();
        if (slot) this.del(slot.path);
      });
    }
  }

  getSlotFields = ({ occupation }) => {
    const slot = {
      type: occupation,
      cash: true,
      rate: {
        type: undefined,
        age_group: undefined,
        occupation: undefined
      },
      travellers: [{
        first_name: '',
        last_name: '',
        middle_name: '',
        gender: 'male',
        age_group: undefined
      }]
    };

    return slot;
  }
}

export { fields };

export default OrderState;
