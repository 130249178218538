import React from 'react';

import Edit from './Edit';

import useTariff from './hooks/useTariff';

function EditWrapper({ ...rest }) {
  const { match: { params: { id } } } = rest;

  const [data, { updateTariff, removeTariff }] = useTariff({ id });

  return (
    <Edit
      {...rest}
      {...data}
      removeTariff={removeTariff}
      updateTariff={updateTariff}
      key={data?.tariff?.id}
    />
  );
}

export default EditWrapper;
