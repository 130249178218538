import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import styled from 'styled-components';

const S = {
  Label: styled.label`
    [type="checkbox"] {
      display: none;
    }
    [type="checkbox"] + span {
      display: inline-block;
    }
    [type="checkbox"] + span:before {
      content: "";
      width: 15px;
      height: 15px;
      display: inline-block;
      background: rgba(255,255,255,0.4);
      border: 1px solid #C6C6C2;
      border-radius: 2px;
      margin-right: 7px;
      vertical-align: text-bottom;
    }
    [type="checkbox"]:checked + span:before {
      background-color: #52AB18;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-size: 60%;
      border: none;
    }
  `
};

@observer
class Source extends Component {
  render() {
    const { field } = this.props;

    return (
      <div className='fields'>
        <div className='field'>
          <S.Label htmlFor={field.$('selected').id}>
            <input
              className='input'
              {...field.$('selected').bind()}
            />

            <span>{field.$('name').value}</span>
          </S.Label>
        </div>
        <div className='field'>
          <input
            className='input'
            {...field.$('quantity').bind()}
          />
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  field: PropTypes.object.isRequired
};

export default Source;
