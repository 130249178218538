import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

@observer
class Bed extends Component {
  render() {
    const { number, type } = this.props.bed;

    const numberCls = classNames('number', {
      extra: type === 'extra'
    });

    return (
      <div className='bed'>
        <span className={numberCls}>
          {number}
        </span>
      </div>
    );
  }
}

Bed.propTypes = {
  bed: PropTypes.object.isRequired
};

export default Bed;
