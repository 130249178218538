import { computed } from 'mobx';
import moment from 'Utils/moment';
import qs from 'qs';

import FormState from 'Shared/form/FormState';
import fields from './fields';

class PriceListState extends FormState {
  options() {
    return {
      validateOnInit: true,
      validateOnChange: true,
      showErrorsOnReset: false,
      showErrorsOnClear: false
    };
  }

  @computed get tariffsMap() {
    const tariffs = this.$('tariffs').value || [];

    return tariffs.reduce((map, tariff) => {
      map[tariff.id] = tariff;
      return map;
    }, {});
  }

  @computed get hasTariffs() {
    return this.$('tariffs').size > 0;
  }

  includeTariff(tariff_id) {
    return !!this.tariffsMap[tariff_id];
  }

  toggleTariff({ id, name }) {
    this.includeTariff(id)
      ? this.deselectTariff({ id, name })
      : this.selectTariff({ id, name });
  }

  selectTariff(tariff) {
    this.$('tariffs').add(tariff);
  }

  deselectTariff(tariff) {
    const tariffs = this.$('tariffs').fields.values();

    const selected = tariffs
      .find(item => item.$('id').value === tariff.id);

    selected && this.$('tariffs').del(selected.path);
  }

  clearTariffs() {
    this.$('tariffs').set([]);
  }

  setFromSearchParams(locationSearch) {
    const queryParams = qs.parse(locationSearch, {
      ignoreQueryPrefix: true
    });

    let { start_date, end_date, source, status } = queryParams;

    // StartDate
    if (start_date === undefined) {
      start_date = moment().startOf('day').format('Y-MM-DD');
    }
    const startDate = moment(start_date);

    if (startDate.isValid) { this.$('start_date').set(startDate.format('Y-MM-DD')); }

    // EndDate
    if (end_date === undefined) {
      end_date = moment().endOf('day').add(1, 'years').format('Y-MM-DD');
    }
    const endDate = moment(end_date);

    if (endDate.isValid) { this.$('end_date').set(endDate.format('Y-MM-DD')); }

    // Status
    status = status || 'created';
    this.$('status').set(status);

    // Source
    if (source && source.id) { this.$('source').set(source); }
  }

  getQueryString() {
    const { start_date, end_date, source, status } = this.values();

    const isValid = start_date && end_date && source;
    if (!isValid) return undefined;

    return qs.stringify({ start_date, end_date, source, status }, { skipNulls: true });
  }
}

export { fields };
export default PriceListState;
