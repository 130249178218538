import * as React from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import { Text, Strong } from 'evergreen-ui';

const Header = styled.div`
  font-weight: bold;
  white-space: nowrap;
`;

const Body = styled.div`
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  diff: {
    start_date: string,
    end_date: string,
    price: number | [number, number]
  }
}>

function Tooltip({ className, diff }: Props) {
  const { start_date, end_date, price } = diff;

  const formatDate = (value: string): string => {
    return DateTime.fromISO(value).setLocale('ru').toLocaleString(DateTime.DATE_SHORT);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Strong size={300}>
          {formatDate(start_date)} - {formatDate(end_date)}
        </Strong>
      </Header>

      <Body>
        {Array.isArray(price) && (
          <>
            <Text size={300}>
              Sanatory.ru: <b>{price[0]}₽</b>
            </Text>

            <br />

            <Text size={300}>
              АО &quot;СОГАЗ&quot;: <b>{price[1]}₽</b>
            </Text>
          </>
        )}

        {!Array.isArray(price) && (
          <Text size={300}>
            Стоимость: <b>{price}₽</b>
          </Text>
        )}
      </Body>
    </Wrapper>
  );
}

export default Tooltip;
