import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Content = styled.div`
  position: fixed;
  z-index: 5;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  user-select: none;
`;

const ModalShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background: #3B4149;
  opacity: 0.4;
  z-index: 4;
`;

const Wrapper = styled.div`
  z-index: 9999;
  position: relative;
`;

const Modal = ({ children, onClose, className }) => {
  const ModalContainer = (
    <Wrapper>
      <ModalShadow />
      <Content>
        {children}
      </Content>
    </Wrapper>
  );
  return ReactDOM.createPortal(ModalContainer, document.querySelector('body'));
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

export default React.memo(Modal);
