import { ReactComponent as Smile } from 'Icons/Smile.svg';
import { formatMessage } from 'Utils/formatMessage';
import { Container, Title } from './styled';

const NoPhotoComponent = () => {
  return (
    <Container>
      <Smile />
      <Title> {formatMessage('room_types.no_photo')} </Title>
    </Container>

  );
};

export default NoPhotoComponent;
