import { formatMessage } from 'Utils/formatMessage';

const fields = {
  email: {
    label: formatMessage('shared.email'),
    placeholder: formatMessage('shared.email_placeholder'),
    rules: 'required|email|string'
  },
  password: {
    label: formatMessage('shared.password'),
    placeholder: formatMessage('shared.password_placeholder'),
    rules: 'required|string'
  }
};

export default { fields };
