import { observable, computed, toJS } from 'mobx';
import _first from 'lodash/first';
import _last from 'lodash/last';

class Registry {
  @observable items = new Map();
  @observable selected = new Map();
  @observable isSelecting = false;

  register(key, item) {
    this.items.set(key, item);
  }

  unregister(key) {
    this.items.delete(key);
  }

  selectItems(values) {
    this.selected.replace(values);
  }

  clear() {
    this.selected.clear();
  }

  isSelected(key) {
    return this.selected.has(key);
  }

  startSelecting() {
    this.isSelecting = true;
  }

  stopSelecting() {
    this.isSelecting = false;
  }

  @computed get bounds() {
    let data = toJS(this.selected);
    const border = 1;

    data = Object.values(data)
      .map(item => item.domNode.getBoundingClientRect());

    if (!data.length) return {};

    const xAttrs = data.map(rect => rect.x);
    const yAttrs = data.map(rect => rect.y);

    const xMin = Math.min(...xAttrs);
    const xMax = Math.max(...xAttrs);

    let width = data
      .map(item => item.width)
      .reduce((w, sum) => sum = sum + w, 0);

    width = width - border;

    const yMin = Math.min(...yAttrs);
    const yMax = Math.max(...yAttrs);
    const height = yMax - yMin + data[0].height;

    return { left: xMin, top: yMin, width: width, height: height, zIndex: 9000 };
  }

  @computed get firstSelected() {
    if (this.hasSelected) {
      const key = _first(this.selected.keys());
      return this.items.get(key);
    }
  }

  @computed get lastSelected() {
    if (this.hasSelected) {
      const key = _last(this.selected.keys());
      return this.items.get(key);
    }
  }

  @computed get hasSelected() {
    return this.selected.size > 0;
  }

  @computed get toQuotasForm() {
    if (!this.hasSelected) return;

    let data = toJS(this.selected);
    data = Object.values(data).map(item => item.data);

    const days = data.map(item => item.day);

    const values = data.map(item => item.quantity);
    const quantity = Math.min(...values);

    const { room_type, source } = data[0];

    return { days, quantity, room_type, source };
  }
}

export default Registry;
