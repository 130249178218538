import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS, reaction } from 'mobx';
import classNames from 'classnames';

import Modal from './Modal';
import Form from './Form';

import DoubleArrowIcon from 'Icons/double_arrow.svg';

import FormState, { fields } from './FormState';

@inject('quotasStore')
@observer
class QuotasSelector extends Component {
  constructor(props) {
    super(props);

    this.form = new FormState(fields);
  }

  componentDidMount() {
    const { registryStore } = this.props;

    this.changeSelectedHandler = reaction(
      () => registryStore.toQuotasForm,
      (selected) => this.populateForm(selected),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.changeSelectedHandler();
  }

  populateForm = (selected) => {
    if (!selected) {
      this.form.clear();
      return;
    }

    const { days, quantity, room_type, source } = selected;

    const { quotasStore: { insurances } } = this.props;

    const sources = toJS(insurances).map((item) => {
      const selected = item.id === source.id;
      return Object.assign({}, item, { selected, quantity });
    });

    this.form.update({ days, room_type, sources });
  }

  closeForm = () => {
    const { clickOutsideHandler } = this.props;

    this.form.clear();
    clickOutsideHandler();
  }

  onCloseHandler = (e) => {
    e.preventDefault();
    this.closeForm();
  }

  onSubmitHandler = (e) => {
    e.preventDefault();

    const hooks = {
      onSuccess: this.formSuccessHandler,
      onError: this.formErrorHandler
    };

    this.form.onSubmit(e, hooks);
  }

  formSuccessHandler = (form) => {
    const { quotasStore } = this.props;
    const values = form.values();

    quotasStore.update(values)
      .then(_ => this.closeForm());
  }

  formErrorHandler = (form) => {
    console.log('error', form.errors());
  }

  onClickContentHandler = (e) => {
    const { clickOutsideHandler } = this.props;
    clickOutsideHandler(e);
  }

  render() {
    const { portal, bounds } = this.props;

    const headerStyle = {
      height: bounds.top
    };

    const contentStyle = {
      height: bounds.height
    };

    const popoverStyle = {
      position: 'absolute',
      left: bounds.left,
      top: bounds.height
    };

    const popoverClass = classNames('quota-popover', {
      top: bounds.position === 'top',
      bottom: bounds.position === 'bottom'
    });

    return (
      <Modal modalRoot={portal}>
        <div
          className='dashboard__portal--header'
          style={headerStyle}
          onClick={this.onClickContentHandler}
        />

        <div
          className='dashboard__portal--content'
          style={contentStyle}
        >
          <div
            className={popoverClass}
            style={popoverStyle}
          >
            <div className='arrow' />

            <div className='content'>
              <Form
                form={this.form}
                onCloseHandler={this.onCloseHandler}
                onSubmitHandler={this.onSubmitHandler}
              />
            </div>
          </div>
        </div>

        <div
          className='dashboard__portal--footer'
          onClick={this.onClickContentHandler}
        />
      </Modal>
    );
  }
}

QuotasSelector.propTypes = {
  portal: PropTypes.object.isRequired,
  roomTypeId: PropTypes.string,
  sourceId: PropTypes.string,
  bounds: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  registryStore: PropTypes.object.isRequired,
  clickOutsideHandler: PropTypes.func.isRequired
};

export default QuotasSelector;
