import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Translation } from 'Stores/models/Translation';

export const Disease = types.model('Disease', {
  id: types.identifier(types.string),
  name: types.string,
  tag: types.string,
  short_name: types.string,
  short_name_translations: types.optional(Translation, {}),
  priority: types.optional(types.number, 0),
  name_translations: types.optional(Translation, {})
}, {
  fetch() {
    this.setState('pending');

    return instance.get(`/api/filters/treatment_profiles/${this.id}`)
      .then(response => this.resetStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  setState(state, response = undefined) {
    this.state = state;
    return response;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  resetStore(response) {
    const { disease } = response.data;

    applySnapshot(this, disease);
    return disease;
  }
});
