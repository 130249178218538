import { useState, useEffect } from "react";

import roomTypesMapper from "./utils/roomTypesMapper";
import instance from "connection/instance";

function useRoomTypes({ status, filters }) {
  const [roomTypes, setRoomTypes] = useState([]);
  const [isPending, setIsPending] = useState();

  const fetch = async () => {
    const options = { params: { data: { filters } } };
    setIsPending(true);
    const {
      data: { data: tariffs },
    } = await instance.get("/api/tariffs", options);

    setRoomTypes(roomTypesMapper({ tariffs }));
    setIsPending(false);
  };

  useEffect(() => {
    fetch();
  }, [status]);

  return [{ roomTypes, isPending }, { fetchTariffs: fetch }];
}

export default useRoomTypes;
