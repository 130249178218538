import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { withRouter, Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

import FormState, { fields } from 'Pages/Buildings/components/FormState';
import Loader from 'Pages/Buildings/components/Loader';
import Info from '../Info';
import Actions from '../Actions';

@withRouter
@observer
class PageContent extends Component {
  @computed get isLoading() {
    const { building } = this.props;
    return building.isPending;
  }

  render() {
    const { building } = this.props;

    return (
      <div className='info__content'>
        {this.isLoading && <Loader />}

        <div className='header'>
          {building.name}
        </div>

        <div className='content'>
          <Info building={building} />
          <Actions building={building} />
        </div>
      </div>
    );
  }
}

PageContent.propTypes = {
  building: PropTypes.object.isRequired
};

export default PageContent;
