import { types } from 'mobx-state-tree';

export const ImageVersion = types.model('ImageVersion', {
  url: types.string
});

export const Image = types.model('Image', {
  url: types.string,
  thumb: ImageVersion,
  portrait: ImageVersion
});
