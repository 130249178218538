import { types } from 'mobx-state-tree';
import { Translation } from 'Types/Translation';

const Option = types.model('Option', {
  name: types.string,
  name_translations: types.optional(Translation, {})
});

const Service = types.model('Service', {
  disclaimer: types.maybe(types.string),
  disclaimer_translations: types.maybe(Translation),
  options: types.optional(types.array(Option), [])
});

export default Service;
