import { types } from 'mobx-state-tree';

export const Quota = types.model('Quota', {
  day: types.string,
  in_all: types.number,
  available: types.number,
  available_beds: types.number,
  restriction: types.number,
  busy: types.number
});
