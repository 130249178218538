import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { ReactComponent as BedIcon } from 'Icons/bed.svg';

@observer
class Available extends Component {
  render() {
    const {
      quota: { available, available_beds },
      room_type: { beds }
    } = this.props;

    const restBeds = available_beds - available * beds;

    if (available === 0 && available_beds > 0) {
      return (
        <div className='item available'>
          <div className='badge'>
            <span className='count'>
              {available_beds}
            </span>
            <span className='icon'>
              <BedIcon />
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className='item available'>
        <div className='badge'>
          <span className='count'>
            {available}
          </span>

          {restBeds > 0 && (
            <span className='count_plus'>
              {restBeds}
            </span>
          )}
        </div>
      </div>
    );
  }
}

Available.propTypes = {
  quota: PropTypes.object.isRequired,
  room_type: PropTypes.object.isRequired
};

export default Available;
