import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class Manipulation extends Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired
  }

  render() {
    const { tariff: { service: { disclaimer, options } } } = this.props;

    return (
      <div className='row'>
        <div className='col col-12'>
          <div className='options'>
            <div className='options__item'>
              <span className='key'>
                <FormattedMessage id='tariffs.service.self' />
              </span>

              <span className='value'>
                <FormattedMessage
                  id='tariffs.service.options_quantity'
                  values={{ count: options.length }}
                />
              </span>

              {disclaimer && (
                <span className='value'>
                  {disclaimer}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Manipulation;
