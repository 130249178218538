import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, reaction } from 'mobx';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import SingleSelect from 'Shared/form/SingleSelect';
import FormFormattedInput from 'Shared/form/FormFormattedInput';
import { FormattedMessage } from 'react-intl';

@inject('tariffsStore')
@observer
class Rule extends Component {
  @computed get isDisabled() {
    const { rule } = this.props;
    const values = rule.values();

    // Non refund booking
    return values.penalty.unit === 'percent' &&
      values.penalty.amount === 100 &&
      values.deadline.amount === 0;
  }

  componentDidMount() {
    this.ruleReaction = reaction(
      () => {
        return this.props.rule.values();
      },
      (values) => this.setRuleRestrictions(values)
    );
  }

  componentWillUnmount() {
    this.ruleReaction();
  }

  setRuleRestrictions = (values) => {
    const { rule } = this.props;

    if (values.deadline.amount > 180) { rule.$('deadline.amount').set(180); }

    if (values.penalty.unit === 'percent' && values.penalty.amount > 100) {
      rule.$('penalty.amount').set(100);
    }
  }

  onDel = (e) => {
    e.preventDefault();
    const { rule } = this.props;

    rule.del();
  }

  render() {
    const { rule, form } = this.props;

    const className = classNames({
      deleted: rule.has('_destroy') && rule.$('_destroy').value
    });

    return (
      <tr className={className}>
        <td className='w22'>
          <FormattedMessage id='tariffs.cancellation_policy.rules.cancel_description' />
        </td>

        <td className='w8'>
          <FormFormattedInput
            field={rule.$('deadline.amount')}
            customError={form.hasDuplicateRules}
          />
        </td>

        <td className='w8'>
          <FormattedMessage
            id='tariffs.cancellation_policy.rules.deadline_description'
            values={{
              deadline_amount: rule.$('deadline.amount').value,
              billing_hour: form.$('billing_hour').value
            }}
          />
        </td>

        <td className='w34'>
          <FormattedMessage id='tariffs.cancellation_policy.rules.penalty_description' />
        </td>

        <td className='w12'>
          <FormFormattedInput field={rule.$('penalty.amount')} />
        </td>

        <td className='w12'>
          <SingleSelect field={rule.$('penalty.unit')} showLabel={false} />
        </td>

        <td className='w4'>
          <a
            href=''
            className='table__action-icon'
            onClick={this.onDel}
          >
            <CloseIcon />
          </a>
        </td>
      </tr>
    );
  }
}

Rule.propTypes = {
  rule: PropTypes.object.isRequired
};

export default Rule;
