import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  min-height: 52px;
  margin-bottom: 20px;
`;

export const Filters = styled.div`
  flex-grow: 1;
  display: flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding-right: 48px;
  border-radius: 4px;
`;
