import React from 'react';

import { FiltersControls, SelectedFilters } from '.';
import { Wrapper, Filters } from './FilterBar.styled';

class FilterBar extends React.PureComponent {
  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Filters>
          <SelectedFilters />
          <FiltersControls />
        </Filters>
      </Wrapper>
    );
  }
}

export default FilterBar;
