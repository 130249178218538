import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';

import { DeleteImagesBtn, GroupsItemHeader, Title } from './Photos.styled';
import Form from 'Pages/RoomTypes/components/Form/Photos';

@observer
class Photos extends Component {
  @computed get images() {
    const { form } = this.props;

    return form.$('images').fields.values().filter(image => !image.has('_destroy'));
  }

  onRemoveAllHandler = () => {
    this.images.forEach((image) => image.update({ _destroy: true }));
  }

  render() {
    const { form } = this.props;

    return (
      <>
        <GroupsItemHeader>
          <Title>
            <FormattedMessage id='room_types.photos' />
            ({form.$('images').value.length})
          </Title>

          <DeleteImagesBtn onClick={this.onRemoveAllHandler}>
            <FormattedMessage id='room_types.delete_photos' />
          </DeleteImagesBtn>
        </GroupsItemHeader>
        <div className='groups__item-content'>
          <Form field={form.$('uploads')} images={form.$('images')} />
        </div>
      </>
    );
  }
}

Photos.propTypes = {
  form: PropTypes.object.isRequired
};

export default Photos;
