import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import AccommodationFeaturesTree from './AccommodationFeaturesTree';

@observer
class AccommodationFeatures extends Component {
  render() {
    const { hotel } = this.props;
    const accommodationFeatures = this.props.hotel.accommodation_features.toJSON();

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.accommodation_features' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {accommodationFeatures.length > 0
              ? (<AccommodationFeaturesTree hotel={hotel} />)
              : (
                <p className='muted'>
                  <FormattedMessage id='hotel.accommodation_features_didnt_choosed' />
                </p>
                )}
          </div>
        </div>
      </div>
    );
  }
}

AccommodationFeatures.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default AccommodationFeatures;
