import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import fields from 'Pages/Hotel/components/FormState/fields';

@observer
class Currency extends Component {
  render() {
    const { value } = this.props;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='hotel.currency' />
        </div>
        <div className='value'>
          <span>{fields.extra.currency.find(i => i.value == value).label}</span>
        </div>
      </div>
    );
  }
}

Currency.propTypes = {
  value: PropTypes.string.isRequired
};

export default Currency;
