import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'chars'
];

const placeholders = {
  chars: formatMessage('shared.search_string')
};

export default { fields, placeholders };
