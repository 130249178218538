import * as React from 'react';
import styled from 'styled-components';
import { IAdapter } from 'pages/Sogaz/store/types';

import { Text, Strong, IconButton, TickIcon, CrossIcon } from 'evergreen-ui';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Left = styled(Row)`
`;

const Right = styled(Row)`
`;

const Buttons = styled(Row)`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  ${Row} + ${Row} {
    margin-left: 12px;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  compare: IAdapter['compare'],
  onSubmit: () => void,
  onReset: () => void
}>

function Actions({ compare, onSubmit, onReset, className }: Props) {
  const { rate, service } = compare;

  const isDisabled = React.useMemo(() => {
    const { tariff, rate, service } = compare;
    return !(tariff && rate && service);
  }, [compare]);

  return (
    <Wrapper className={className}>
      <Left>
        {rate
          ? <Text size={300}>
              {rate.description.ru}
              <br />
              <Strong size={300}>
                {rate.code}
              </Strong>
            </Text>

          : <Text size={300}>
              -
            </Text>
        }
      </Left>

      <Right>
        {service
          ? <Text size={300}>
              {service.service_name}
              <br />
              <Strong size={300}>
                {service.service_code}
              </Strong>
            </Text>

          : <Text size={300}>
              -
            </Text>
        }
      </Right>
      <Buttons>
        <IconButton
          icon={TickIcon}
          intent='success'
          size='small'
          marginRight={8}
          disabled={isDisabled}
          onClick={onSubmit}
        />

        <IconButton
          icon={CrossIcon}
          intent='danger'
          size='small'
          disabled={isDisabled}
          onClick={onReset}
        />
      </Buttons>
    </Wrapper>
  );
}

export default Actions;
