import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import Tariff from './Tariff';

@observer
class Tariffs extends Component {
  @observable selected = undefined

  onClickHandler = tariff => e => {
    e.preventDefault();

    this.selected === tariff.id
      ? this.selected = undefined
      : this.selected = tariff.id;
  }

  render() {
    const { tariffs } = this.props;

    const listItems = tariffs.map((item) => {
      const isActive = this.selected === item.id;

      return (
        <Tariff
          key={item.id}
          tariff={item}
          isActive={isActive}
          onClickHandler={this.onClickHandler}
        />
      );
    });

    return (
      <div className='tariffs'>
        <div className='tariffs__content'>
          {listItems}
        </div>
      </div>
    );
  }
}

Tariffs.propTypes = {
  tariffs: PropTypes.object.isRequired
};

export default Tariffs;
