import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autorun, computed, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import _pick from 'lodash/pick';

import ConversationStore from 'Stores/ConversationStore';
import Messages from './Messages';
import Loader from './Loader';
import Control from './Control';

@inject('chatStore')
@observer
class Conversations extends Component {
  @observable conference = undefined;

  @computed get isConnected() {
    return this.conference &&
      this.conference.isJoined;
  }

  componentDidMount() {
    this.connectionHandler = autorun(() => {
      const { order, chatStore } = this.props;

      if (chatStore.isConnected) {
        chatStore.getConference(order.id)
          .then((conference) => conference.connectToRoom())
          .then((conference) => this.conference = conference);
      }
    });
  }

  componentWillUnmount() {
    this.connectionHandler();

    if (this.isConnected) { this.conference.disconnectFromRoom(); }
  }

  render() {
    return (
      <div className='conversations'>
        <div className='conversations__body'>
          {this.isConnected
            ? <Messages conversation={this.conference} />
            : <Loader />}
        </div>

        {this.conference &&
          <Control conversation={this.conference} />}
      </div>
    );
  }
}

Conversations.propTypes = {
  order: PropTypes.object.isRequired
};

export default Conversations;
