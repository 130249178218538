import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class TariffType extends Component {
  render() {
    const { labels } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>{formatMessage('tariff.type')}</span>
          <p className='value'>
            {labels.type}
          </p>
        </div>
      </div>
    );
  }
}

TariffType.propTypes = {
  labels: PropTypes.object.isRequired
};

export default TariffType;
