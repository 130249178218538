import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FormFormattedInput from 'Shared/form/FormFormattedInput';

@observer
class StayPeriod extends Component {
  render() {
    const { stay_period, billing_hour, type } = this.props;
    const days = stay_period.$('min_days').value;
    const isBaseType = type === 'base';

    return (
      <div className='form__field stay-period'>
        <label>
          <FormattedMessage
            id={isBaseType ? 'tariff.stay_period' : 'package.stay_period'}
          />
          <span className='error'>{stay_period.$('min_days').error && '*'}</span>
        </label>

        <div className='stay-period__values'>
          <FormFormattedInput
            field={stay_period.$('min_days')}
          />

          <FormattedMessage
            id='stay_period.min_days_count'
            values={{ count: days, billing_hour: billing_hour }}
          />
        </div>
      </div>
    );
  }
}

StayPeriod.propTypes = {
  stay_period: PropTypes.object.isRequired,
  billing_hour: PropTypes.string,
  type: PropTypes.string
};

export default StayPeriod;
