import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class Service extends Component {
  render() {
    const { service } = this.props;

    return (
      <span className='value'>
        {service.name}
      </span>
    );
  }
}

Service.propTypes = {
  service: PropTypes.object.isRequired
};

export default Service;
