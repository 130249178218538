import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class TariffFeedTypeAndMealOptionBlock extends Component {
  render() {
    const { labels } = this.props;
    const { feed_type, meal_option, meal_disclaimer } = this.props.tariff;
    const detailStr = [meal_option && meal_option.name, feed_type && feed_type.name].join(' — ');

    return (
      <div className='row'>
        <div className='col col-12'>
          <div className='options'>
            <div className='options__item'>
              <span className='key'>
                {labels.feed_type_and_meal_option}
              </span>
              <span className='value'>
                {detailStr}
              </span>
              <span className='value'>
                {meal_disclaimer}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TariffFeedTypeAndMealOptionBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffFeedTypeAndMealOptionBlock;
