import { types, applySnapshot, applyPatch } from 'mobx-state-tree';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';

import { PriceList } from './PriceList';

const PriceListStore = types.model('PriceListStore', {
  price_list: types.maybe(PriceList),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  }

}, {
  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  toggle() {
    this.isActive = !this.isActive;
  },

  async fetchOne(id) {
    this.setState('pending');

    try {
      const [
        pResponse,
        tResponse
      ] = await Promise.all([
        instance.get(`/api/price_lists/${id}`, {}),
        instance.get('/api/tariffs', { params: { tariff: { price_list_id: id } } })
      ]);

      const { data: { price_list } } = pResponse;
      const { data: { tariffs, meta } } = tResponse;

      const response = {
        data: {
          price_list: { ...price_list, tariffs, meta }
        }
      };

      this.applySnapshot(response);
      this.setState('done', pResponse);
    } catch (error) {
      this.errorHandler(error);
    }
  },

  fetchTariffs() {
    return instance.get('/api/tariffs', { params: { tariff: { price_list_id: this.price_list.id } } })
      .then((response) => this.resetTariffStore(response))
      .catch(error => this.errorHandler(error));
  },

  create(params = {}) {
    this.setState('pending');

    return instance.post('/api/price_lists', { price_list: params })
      .then((response) => this.applySnapshot(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  upload(params = {}) {
    this.setState('pending');

    return instance.put(`/api/price_lists/${this.price_list.id}/upload`, { params: params })
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  update(params = {}) {
    this.setState('pending');

    return instance.put(`/api/price_lists/${this.price_list.id}`, { price_list: params })
      .then((response) => this.applySnapshot(response))
      .then((response) => this.fetchTariffs(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  download(params = {}) {
    return instanceBlob.get(`/api/price_lists/${this.price_list.id}/download`, { params: params })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'price_list.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .then(response => this.setState('done', response));
  },

  destroy(params = {}) {
    this.setState('pending');

    return instance.put(`/api/price_lists/${this.price_list.id}/cancel`, { params: params })
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { price_list } = response.data;
    applySnapshot(this, { price_list });

    return response;
  },

  resetTariffStore(response) {
    const { tariffs, meta } = response.data;
    const metaPatch = [{ op: 'replace', path: '/price_list/meta', value: meta }];

    const tariffsPatch = tariffs.map(tariff => {
      return { op: 'add', path: '/price_list/tariffs/-', value: tariff };
    });

    applyPatch(this, metaPatch.concat(tariffsPatch));
    return response;
  },

  clear() {
    const data = { price_list: null };
    applySnapshot(this, data);
  }

});

export default PriceListStore;
