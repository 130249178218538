import { types, destroy, getParent } from 'mobx-state-tree';
import moment from 'Utils/moment';
import instance from 'connection/instance';
import _uniqueId from 'lodash/uniqueId';
import _omit from 'lodash/omit';

export const TariffPrice = types.model('TariffPrice', {
  amount: types.number,
  currency: types.string
});

export const TariffRateRule = types.model('TariffRateRule', {
  date: types.maybe(types.string),
  start_date: types.maybe(types.string),
  end_date: types.maybe(types.string),
  price: types.maybe(TariffPrice)
});

export const TariffRateValue = types.model('TariffRateValue', {
  id: types.identifier(types.string),
  type: types.optional(types.string, 'TariffRateDailyRule'),
  price: types.maybe(TariffPrice),
  date: types.string,
  rate_id: types.string,
  tariff_id: types.string
});

export const TariffRateAgePeriod = types.model('TariffRateAgePeriod', {
  from: types.number,
  to: types.number
});

export const TariffRate = types.model('TariffRate', {
  id: types.identifier(types.string),
  type: types.string,
  description: types.string,
  price: types.maybe(TariffPrice),
  rules: types.optional(types.array(TariffRateRule), []),
  values: types.optional(types.array(TariffRateValue), []),
  age_group: types.maybe(types.string),
  age_period: types.maybe(TariffRateAgePeriod),
  occupation: types.maybe(types.string),

  get tariff() {
    return getParent(this, 2);
  },

  getRuleByDate(date) {
    return this.rules.filter(rule => date.isBetween(rule.start_date, rule.end_date, 'days', '[)'));
  }
}, {
  update(attrs) {
    const data = { tariff_rate: attrs };

    instance.put(`/api/tariffs/${this.tariff.id}/tariff_rates/${this.id}`, data)
      .then(response => this.updateRules(response));
  },

  updateRules(response) {
    const { status, data } = response;

    if (status === 200) { this.rules = data.tariff_rate.rules; }
  },

  resetValues(startDate, endDate) {
    if (!this.tariff) return [];
    const tariff = this.tariff;

    const data = [];
    const currentDate = startDate.clone();
    const { fromDateObj, toDateObj } = tariff.available_period;

    while (!currentDate.isSame(endDate, 'day')) {
      if (currentDate.isSameOrAfter(fromDateObj, 'day') &&
          currentDate.isSameOrBefore(toDateObj, 'day')) {
        const id = _uniqueId('value-');
        const dateStr = currentDate.format('YYYY-MM-DD');

        const rule = this.getRuleByDate(currentDate);
        const price = rule.length > 0 ? rule[0].price : this.price;

        data.push({
          id: id,
          date: dateStr,
          price: price.toJSON(),
          tariff_id: this.tariff.id,
          rate_id: this.id
        });
      }

      currentDate.add(1, 'days');
    }

    this.values = data;
  }
});

export const TariffAvailablePeriod = types.model('TariffAvailablePeriod', {
  from: types.maybe(types.string),
  to: types.maybe(types.string),

  get fromDate() {
    return this.from
      ? moment(this.from).format('DD.MM.YYYY')
      : 'неограничено';
  },

  get fromDateObj() {
    return this.from && moment(this.from);
  },

  get toDate() {
    return this.to
      ? moment(this.to).format('DD.MM.YYYY')
      : 'неограничено';
  },

  get toDateObj() {
    return this.to && moment(this.to);
  }
});

export const TariffVisiblePeriod = types.model('TariffVisiblePeriod', {
  from: types.maybe(types.string),
  to: types.maybe(types.string),

  get fromDate() {
    return this.from
      ? moment(this.from).format('DD.MM.YYYY')
      : 'не задан';
  },

  get toDate() {
    return this.to
      ? moment(this.to).format('DD.MM.YYYY')
      : 'не задан';
  }
});

export const TariffStopSalesPeriod = types.model('TariffStopSalesPeriod', {
  from: types.maybe(types.string),
  to: types.maybe(types.string),

  get fromDate() {
    return this.from
      ? moment(this.from).format('DD.MM.YYYY')
      : 'не задан';
  },

  get toDate() {
    return this.to
      ? moment(this.to).format('DD.MM.YYYY')
      : 'не задан';
  }
});

export const TariffSource = types.model('TariffSource', {
  id: types.identifier(types.string),
  name: types.string
});

export const TariffService = types.model('TariffService', {
  id: types.maybe(types.identifier(types.string)),
  name: types.string,
  description: types.string,
  quantity: types.number,
  pace: types.string,
  pace_name: types.string,
  service_id: types.string
});

export const TariffRatePeriod = types.model('TariffRatePeriod', {
  id: types.identifier(types.string),
  start_date: types.string,
  end_date: types.string,
  rates: types.array(TariffRate)
});

export const TariffStayPeriod = types.model('TariffStayPeriod', {
  min_days: types.number
});

export const TariffSeason = types.model('TariffSeason', {
  dates: types.array(types.frozen),
  rates: types.array(TariffRate)
});

export const TariffQuota = types.model('TariffQuota', {
  id: types.identifier(types.string),
  start_date: types.string,
  end_date: types.string,
  quantity: types.number
});

export const StopSales = types.model('StopSales', {
  from: types.maybe(types.string),
  to: types.maybe(types.string)
});

export const Tariff = types.model('Tariff', {
  id: types.identifier(types.string),
  name: types.string,
  description: types.maybe(types.string),
  rates: types.optional(types.array(TariffRate), []),
  room_type_id: types.string,
  available_period: types.maybe(TariffAvailablePeriod),
  visible_period: types.maybe(TariffVisiblePeriod),
  stop_sales: types.optional(types.array(TariffStopSalesPeriod), []),
  services: types.optional(types.array(TariffService), []),
  isActive: types.optional(types.boolean, false),
  selected_ids: types.optional(types.array(types.string), []),
  source: types.maybe(TariffSource),
  source_id: types.maybe(types.string),
  periods: types.optional(types.array(TariffRatePeriod), []),
  stay_period: types.maybe(TariffStayPeriod),
  seasons: types.optional(types.array(TariffSeason), []),
  quotas: types.optional(types.array(TariffQuota), []),

  get hasSelected() {
    return this.selected_ids.length > 0;
  }
}, {
  toggle() {
    this.isActive = !this.isActive;
  },

  destroy() {
    instance.delete(`/api/tariffs/${this.id}`)
      .then(response => this.removeFromCollection())
      .catch(error => console.log(error));
  },

  removeFromCollection() {
    destroy(this);
  },

  selectItems(ids) {
    this.selected_ids = ids;
  },

  selectValues(values) {
    this.selected_ids = values;
  },

  deselectValues() {
    this.selected_ids = [];
  },

  toClone() {
    const values = this.toJSON();
    return _omit(values, ['id', 'name']);
  }
});
