import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FullRefund from './FullRefund';
import NonRefund from './NonRefund';
import PolicyRules from './PolicyRules';

@observer
class TariffCancellationBlock extends Component {
  render() {
    const { labels } = this.props;
    const { cancellation_policy, billing_hour } = this.props.tariff;
    return (
      <div className='row'>
        <div className='col col-12'>
          <div className='options'>
            <div className='options__item'>
              <span className='key'>
                {labels.cancellation_policy}
              </span>

              {cancellation_policy.has_full_refund &&
                <FullRefund />}

              {cancellation_policy.has_non_refund &&
                <NonRefund />}

              {cancellation_policy.policy_rules.length > 0 &&
                <PolicyRules
                  cancellation_policy={cancellation_policy}
                  billing_hour={billing_hour}
                />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TariffCancellationBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffCancellationBlock;
