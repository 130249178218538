import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _defaultsDeep from 'lodash/defaultsDeep';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqueId from 'lodash/uniqueId';

import Blocks from '../Blocks';

const DEFAULT_BUILDING = {
  id: _uniqueId('building-'),
  name: 'Без принадлежности к корпусу',
  floor_numbers: 1,
  rooms: []
};

@withRouter
@inject('roomsStore')
@observer
class PageBody extends Component {
  @computed get buildings() {
    const { rooms } = this.props.roomsStore;

    let data = _groupBy(rooms, (room) => room.building);

    data = _map(data, (rooms) => {
      let building = rooms[0].building
        ? rooms[0].building.toJSON()
        : DEFAULT_BUILDING;

      building = _cloneDeep(building);

      return _defaultsDeep(building, { rooms });
    });

    return data;
  }

  render() {
    return (
      <div className='page__body'>
        <Blocks buildings={this.buildings} />
      </div>
    );
  }
}

export default PageBody;
