import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { when } from "mobx";
import { FormattedMessage } from "react-intl";
import { ObjectTypesStore } from "stores/ObjectTypesStore";
import Loader from "pages/Hotel/components/Loader";
import store from "store";

@inject("hotelStore")
@observer
class ObjectType extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    objectTypesStore: PropTypes.object.isRequired,
  };

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      (status) => {
        const { form } = this.props;
        const initialObjectType = form.$("card_options").value;

        this.setState({
          selectedType: initialObjectType,
        });
        this.fetchTypes();
      }
    );
  }

  state = {
    selectedType: [],
  };

  fetchTypes = () => {
    const { objectTypesStore, hotelStore } = this.props;
    const hotel_id = hotelStore.hotel.id;
    objectTypesStore.fetchChecked({ query: { hotel_id } });
  };

  onSelect = (currentTag) => {
    const { selectedType } = this.state;
    const { objectTypesStore, form } = this.props;
    const selectedObjectType = objectTypesStore
      .toJSON()
      .types.filter((type) => type.tag === currentTag)[0];
    const isTypeSelected = selectedType.some(
      (type) => type.tag === selectedObjectType.tag
    );
    let selectedData = [];

    if (isTypeSelected) {
      const index = selectedType.findIndex(
        (type) => type.tag === selectedObjectType.tag
      );
      const copyStateData = [...selectedType];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedType, selectedObjectType];
    }

    this.setState({
      selectedType: selectedData,
    });

    form.update({ card_options: selectedData });
  };

  render() {
    const { objectTypesStore } = this.props;
    const typesData = objectTypesStore.toJSON().types;
    const generalLanguage = store.get("language");

    const radioList = typesData.map((data) => {
      return (
        <div key={data.tag}>
          <input
            type="checkbox"
            key={data.tag}
            value={data.tag}
            className="input radio_button"
            checked={this.state.selectedType.some(
              (type) => type.tag === data.tag
            )}
          />

          <span className="radio_button_name">
            {data.name_translations[generalLanguage]}{" "}
          </span>
        </div>
      );
    });

    return (
      <div className="groups__item">
        <div className="groups__item-header">
          <FormattedMessage id="hotel.object_type" />
        </div>

        <div className="groups__item-content">
          <div className="segment">
            <div className="radio_list">
              {objectTypesStore.isFetched ? (
                <>
                  {radioList.map((radio, index) => (
                    <div
                      className={
                        index + 1 !== typesData.length
                          ? "radio_list_item"
                          : "radio_list_item_last"
                      }
                      onClick={() => this.onSelect(radio.key)}
                      key={radio.key}
                    >
                      {radio}
                    </div>
                  ))}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ObjectType.defaultProps = {
  form: PropTypes.object.isRequired,
  objectTypesStore: ObjectTypesStore.create(),
};

export default ObjectType;
