import { types } from 'mobx-state-tree';

export const RoomType = types.model('RoomType', {
  id: types.identifier(types.string),
  name: types.string,
  beds: types.number,
  extra_beds: types.number,

  get hasCapacity() {
    return self.capacity.beds_count > 0;
  }
}, {
  shiftCapacity(count) {
    const { capacity } = self;
    return capacity.shift(count);
  },

  unshiftCapacity(count) {
    const { capacity } = self;
    return capacity.unshift(count);
  }
});
