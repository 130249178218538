import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

export const Insurants = types.model('Insurants', {
  id: types.identifier(types.string),
  name: types.string,
  short_name: types.string,
  inn: types.string,
  kpp: types.string
}, {});

export const InsurantsStore = types.model('InsurantsStore', {
  insurants: types.optional(types.array(Insurants), []),
  state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get insurantsOptions() {
    return this.insurants.map((item) => {
      return { value: item.id, label: item.short_name };
    });
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    instance.get('/api/filters/insurants', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { sources: [] };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) {
      applySnapshot(this, data);
    }

    return data;
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  }
});
