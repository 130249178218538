import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Header from './Header';
import Form from 'Pages/Tariffs/components/Form/GeneralInfo';

const Content = styled.div`
  margin-top: 20px;
`;

@observer
class GeneralInfo extends Component {
  render() {
    const { form, tariff } = this.props;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='tariffs.general' />
        </div>
        <div className='groups__item-content'>
          <div className='segment'>
            <Header tariff={tariff} />

            <Content>
              <Form form={form} />
            </Content>
          </div>
        </div>
      </div>
    );
  }
}

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired,
  tariff: PropTypes.object
};

export default GeneralInfo;
