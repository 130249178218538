import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Services from './Services';

@observer
class Category extends Component {
  render() {
    const { name, services } = this.props.category;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          {name}
        </div>

        <div className='groups__item-content'>
          <Services services={services} />
        </div>
      </div>
    );
  }
}

Category.propTypes = {
  category: PropTypes.object.isRequired
};

export default Category;
