import { types } from 'mobx-state-tree';

import { TariffRate } from './TariffRate';

export const TariffRatePeriod = types.model('TariffRatePeriod', {
  id: types.identifier(types.string),
  start_date: types.string,
  end_date: types.string,
  rates: types.array(TariffRate)
});
