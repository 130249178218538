import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { dateRangeFormat } from 'Utils/dateFormat';

import Price from 'Shared/ui/Price';
import Slots from '../Slots';

class Reservation extends Component {
  render() {
    const { check_in, check_out, room_type, tariff, price, slots } = this.props.reservation;

    return (
      <div className='section'>
        <div className='section__header'>
          <div className='content'>
            {[room_type.name, tariff.name].join(' — ')}

            <div className='sub'>
              {dateRangeFormat(check_in, check_out)}
            </div>
          </div>

          <div className='price'>
            <Price value={price} />
          </div>
        </div>

        <div className='section__content'>
          <Slots
            tariff={tariff}
            slots={slots}
          />
        </div>
      </div>
    );
  }
}

Reservation.propTypes = {
  reservation: PropTypes.object.isRequired
};

export default Reservation;
