import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FormItemText from 'Shared/form/FormItemText';

import { FormLanguageContext } from 'Context/FormLanguageContext';

@observer
class Description extends Component {
  static contextType = FormLanguageContext;
  render() {
    const { form } = this.props;
    const currentLanguage = this.context.currentLanguage;
    return (
      <FormItemText
        field={form.$(`description_translations.${currentLanguage}`)}
        rows={9}
        isLocalizeField
        size='big'
      />
    );
  }
}

Description.propTypes = {
  form: PropTypes.object.isRequired
};

export default Description;
