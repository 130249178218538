import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import Sticky from 'react-stickynode';

import Content from './Content';

@inject('servicesStore')
@observer
class Edit extends Component {
  @computed get isFetched() {
    const { state } = this.props.servicesStore;
    return state == 'done';
  }

  @computed get service() {
    const { match, servicesStore } = this.props;
    const { id } = match.params;

    return this.isFetched && this.props.servicesStore.findOne(id);
  }

  render() {
    return (
      <div className='page__info wide'>
        <div className='page__info-wrapper'>
          <Sticky>
            {this.service && <Content service={this.service} />}
          </Sticky>
        </div>
      </div>
    );
  }
}

export default Edit;
