import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@observer
class Room extends Component {
  render() {
    const { id, number, isActive } = this.props.room;

    return (
      <div className='rooms__item'>
        <NavLink
          to={`/rooms/${id}`}
        >{number}
        </NavLink>
      </div>
    );
  }
}

Room.propTypes = {
  room: PropTypes.object.isRequired
};

export default Room;
