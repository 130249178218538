import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import instance from 'connection/instance';
import { Async } from 'react-select';

import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 200px;
`;

@observer
class Travellers extends Component {
  handleChange = ({ label, id }) => {
    const { onChange, traveller } = this.props;
    traveller.set(id);
    onChange('traveller', label, id);
  }

  getOptions = (input) => {
    const options = { params: { data: { chars: input } } };

    if (input.length < 3) {
      const promise = new Promise((resolve) => {
        resolve([]);
      });

      return promise.then(result => result);
    }

    return instance.get('/api/filters/travellers', options)
      .then(response => this.parseFilterData(response));
  }

  parseFilterData(response) {
    const { data } = response;
    return { options: data.data.map((item) => ({ id: item.id, label: item.name })) };
  }

  render() {
    const { traveller } = this.props;

    return (
      <Wrapper>
        <Async
          clearable={false}
          searchable
          simpleValue={false}
          placeholder={traveller.placeholder}
          noResultsText='По Вашему запросу ничего не найдено'
          searchPromptText='Введите для поиска'
          loadOptions={this.getOptions}
          arrowRenderer={() => null}
          onChange={this.handleChange}
        />
      </Wrapper>
    );
  }
}

Travellers.propTypes = {
  traveller: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Travellers;
