import React, { Component } from 'react';

export default class TopBarSearchBox extends Component {
  render() {
    return (
      <div className='search-box'>
        <form method='post' action='' className='form'>
          <div className='row gutters' />
        </form>
      </div>
    );
  }
}
