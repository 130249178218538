import { formatMessage } from 'Utils/formatMessage';

const fields = [
    'start_date',
    'end_date',
    'min_length_of_stay',
    'max_length_of_stay',
];

const labels = {
    'start_date': formatMessage('tariff.length_stay_restrictions.dateRangeInput'),
};

const rules = {
  min_length_of_stay: 'numeric|required|min:0|max:365',
  max_length_of_stay: 'numeric|required|min:0|max:365',
  start_date: "string|required",
  end_date: "string|required",
};

const defaults = {
    min_length_of_stay: 0,
    max_length_of_stay: 365
};

const values = {
    min_length_of_stay: 0,
    max_length_of_stay: 365
};

export default {
    fields,
    labels,
    rules,
    values,
    defaults,
};
