import { types } from 'mobx-state-tree';

const Source = types.model('Source', {
  id: types.maybe(types.identifier(types.string)),
  name: types.maybe(types.string),
  active: types.optional(types.boolean, false)
});

export const Integration = types.model('Integration', {
  id: types.maybe(types.identifier(types.string)),
  sources: types.optional(types.array(Source), []),
  connection_status: types.maybe(types.string),
  type: types.maybe(types.string),
  hotel_id: types.maybe(types.string),
  source_ids: types.maybe(types.array([]))
});
