import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ArrowIcon } from 'Components/ui/Icons';
import Element from './Element';

const DuplicateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
  user-select: none;

  ${ArrowIcon} + ${Element} {
    margin-left: 13px;
  }

  ${DuplicateWrapper} + ${Element}, ${Element} + ${DuplicateWrapper} {
    margin-left: 13px;
  }

  ${ArrowIcon} {
    align-self: flex-start;
    font-size: 15px;
    margin-top: 3px;
  }

  ${ArrowIcon} + ${Element} {
    margin-left: 12px;
  }

  ${Element} + ${ArrowIcon} {
    margin-left: 12px;
  }
`;

function TariffContentHeader({ className, tariff }) {
  return (
    <Wrapper className={className}>
      {tariff.duplicated_from && (
        <DuplicateWrapper>
          <Element
            tariff={tariff.duplicated_from}
          />

          <ArrowIcon />
        </DuplicateWrapper>
      )}

      <Element
        isActive
        tariff={tariff}
      />
    </Wrapper>
  );
}

TariffContentHeader.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object
};

export default styled(withRouter(TariffContentHeader))``;
