import { types, applySnapshot, detach } from 'mobx-state-tree';
import instance from 'connection/instance';
import moment from 'Utils/moment';

import { RoomType } from './RoomType';

export const DashboardStore = types.model('DashboardStore', {
  check_in: types.maybe(types.string),
  check_out: types.maybe(types.string),
  room_types: types.optional(types.array(RoomType), []),
  startPosition: types.optional(types.number, 0),
  startDay: types.optional(types.string, moment().startOf('day').toISOString()),
  currentOffset: types.optional(types.number, 0),
  dayStep: types.optional(types.number, 7),
  dayWidth: types.optional(types.number, 50),
  offset: types.maybe(types.number),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),
  leftPosition: types.optional(types.number, 0),
  topPosition: types.optional(types.number, 0),
  topOffsetStep: types.optional(types.number, 220),

  get scrollStep() {
    return this.dayStep * this.dayWidth;
  },

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get position() {
    return { left: this.leftPosition, top: this.topPosition };
  }
}, {
  setPosition(offset) {
    this.currentOffset = this.startPosition - offset;
  },

  setStartPosition(value) {
    this.startPosition = value;
  },

  setDayWith(value) {
    this.dayWidth = value;
  },

  setOffset(value) {
    this.offset = value;
  },

  setCurrentDate(value) {
    this.currentDate = value;
  },

  toLeft() {
    const offset = this.dayStep * this.dayWidth;
    this.setOffset(-offset);
  },

  toRight() {
    const offset = this.dayStep * this.dayWidth;
    this.setOffset(offset);
  },

  resetOffset() {
    this.offset = null;
  },

  convertMsToPx(diff) {
    return diff / 1000 * this.dayWidth / 86400;
  },

  calcEventOffset(eventDay) {
    eventDay = moment(eventDay, 'YYYY-MM-DD hh:mm:ss').utcOffset(eventDay);
    const check_in = moment(this.check_in);
    const diff = eventDay.diff(check_in);

    return this.convertMsToPx(diff);
  },

  calcDashboardPosition(event, bounding) {
    const hStep = this.scrollStep / 3;
    const hValue = this.calcEventOffset(event.check_in);
    const vStep = this.topOffsetStep;
    const vValue = bounding.top;

    this.leftPosition = hValue - hStep;
    this.topPosition = vValue - vStep;

    return this;
  },

  fetch(options = {}) {
    this.setState('pending');
    const params = { hotel: options };

    return instance.get('/api/hotel/events', { params })
      .then((response) => this.resetStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    detach(this);
  },

  toggleSelected(event) {
    event.isActive
      ? this.deselectEvent(event)
      : this.selectEvent(event);
  },

  deselectEvent(event) {
    this.selected = undefined;
    event.deselect();
  },

  selectEvent(event) {
    const currentSelected = this.selected;
    currentSelected && this.deselectEvent(currentSelected);

    this.selected = event;
    event.select();
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) { applySnapshot(this, data); }

    return this;
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  errorHandler(error) {
    this.state = 'error';
    return Promise.reject(error);
  }
});
