import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cleanDeep from 'clean-deep';

@withRouter
@inject('buildingsStore', 'store')
class Actions extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    buildingsStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  updateHandler = (e) => {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler
    });
  }

  successSubmitHandler = (form) => {
    const { buildingsStore } = this.props;
    const values = cleanDeep({ ...form.values() });

    buildingsStore.create(values)
      .then(building => this.navigateTo(`/buildings/${building.id}`))
      .catch(error => this.errorSubmitHandler(error));
  }

  errorSubmitHandler(error) {
    console.log(error.values());
  }

  navigateTo = (path) => {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    return (
      <div className='actions'>
        <div className='group'>
          <div className='float-left'>
            <button
              onClick={this.updateHandler}
              className='green button'
            ><FormattedMessage id='shared.confirm' />
            </button>
            <Link
              to='/buildings'
              className='gray button'
            ><FormattedMessage id='shared.cancel' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Actions;
