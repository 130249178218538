import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Toggle from 'Shared/form/Toggle';

@observer
class Type extends Component {
  render() {
    const { form } = this.props;

    return (
      <Toggle field={form.$('type')} className='padded' />
    );
  }
}

Type.propTypes = {
  form: PropTypes.object.isRequired
};

export default Type;
