import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

@withRouter
@inject('priceListStore', 'priceListState', 'store')
@observer
class TopBarActionBox extends Component {
  generateHandler = (e) => {
    const { priceListState } = this.props;
    priceListState.onSubmit(e, {
      onSuccess: this.onSuccessHandler,
      onError: this.onErrorHandler
    });
  }

  onSuccessHandler = (form) => {
    const { priceListStore } = this.props;
    const values = form.values();

    priceListStore.create(values)
      .then(this.navigateTo)
      .catch(this.errorSubmitHandler);
  }

  onErrorHandler = (form) => {
    console.log(form.errors());
  }

  navigateTo = () => {
    const { history, priceListStore } = this.props;

    const path = [
      '/price_lists',
      priceListStore.price_list.id
    ].join('/');

    history.push(path);
  }

  errorSubmitHandler = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    const { priceListState } = this.props;

    return (
      <div className='action-box'>
        <button
          type='submit'
          className='button green'
          disabled={!priceListState.isValid}
          onClick={this.generateHandler}
        >
          <FormattedMessage id='price_lists.generate' />
        </button>
      </div>
    );
  }
}

export default TopBarActionBox;
