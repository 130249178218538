import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

function roomTypesMapper({ tariffs }) {
  const data = _groupBy(tariffs, (tariff) => tariff.room_type.id);
  const roomTypes = _map(data, (tariffs) => ({
    roomType: tariffs[0].room_type,
    tariffs
  }));

  return roomTypes;
}

export default roomTypesMapper;
