import { types, applySnapshot, destroy } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Translation } from 'Types/Translation';

export const Building = types.model('Building', {
  id: types.identifier(types.string),
  name: types.string,
  name_translations: types.maybe(Translation),
  floor_numbers: types.number,
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  toForm() {
    return this.toJSON();
  },

  update(values) {
    this.setState('pending');

    const params = { building: values };

    return instance.put(`/api/buildings/${values.id}`, params)
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  destroy() {
    this.setState('pending');

    return instance.delete(`/api/buildings/${this.id}`)
      .then((response) => this.destroySelf())
      .catch(error => this.errorHandler(error));
  },

  destroySelf() {
    destroy(this);
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  applySnapshot(response) {
    const { building } = response.data;
    applySnapshot(this, building);

    return response;
  }
});
