import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default class TopBarContent extends Component {
  render() {
    return (
      <div className='layout__topbar-content'>
        <div className='layout__topbar-content-wrapper'>
          <div className='search-box'>
            <Link
              to='/tariffs'
              className='button gray'
            ><FormattedMessage id='tariff.all' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
