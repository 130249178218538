import arrayToTree from 'array-to-tree';

function tariffsMapper({ tariffs }) {
  const tariffsTree = arrayToTree(tariffs, {
    parentProperty: 'duplicated_from_id',
    customID: 'id'
  });

  return tariffsTree;
}

export default tariffsMapper;
