import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class TitleWithoutTraveller extends Component {
  render() {
    const { rate, contract } = this.props.slot;

    return (
      <div className='policies__column title'>
        <span>
          {rate.description}
        </span>

        {contract && (
          <span className='gray'>
            {contract.insured_name}
          </span>
        )}
      </div>
    );
  }
}

TitleWithoutTraveller.propTypes = {
  slot: PropTypes.object.isRequired
};

export default TitleWithoutTraveller;
