import _range from 'lodash/range';
import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'number',
  'floor_number',
  'building',
  'building.id',
  'building.name',
  'building.floor_numbers',
  'room_type',
  'room_type.id',
  'room_type.name'
];

const labels = {
  number: formatMessage('rooms.room_number'),
  room_type: formatMessage('rooms.room_type'),
  building: formatMessage('rooms.block'),
  floor_number: formatMessage('rooms.floor')
};

const placeholders = {
  number: '101А'
};

const rules = {
  number: 'string|required',
  floor_number: 'integer|required',
  'building.id': 'string|required',
  'room_type.id': 'string|required'
};

const extra = {
  floor_number: _range(1, 11).map((i) => {
    return { value: i, label: i.toString() };
  })
};

export default {
  fields,
  labels,
  extra,
  placeholders,
  rules
};
