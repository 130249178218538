import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Event from './Event';

@withRouter
@observer
class Events extends Component {
  render() {
    const listEvents = this.props.events.map((event) => {
      return <Event key={event.slot_id} event={event} />;
    });

    return listEvents;
  }
}

Events.propTypes = {
  events: PropTypes.object.isRequired
};

export default Events;
