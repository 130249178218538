import { types } from 'mobx-state-tree';
import { Translation } from 'Types/Translation';

const Promo = types.model('Promo', {
  target: types.maybe(types.string),
  active: types.boolean,
  applied_percent_off: types.number,
  disclaimer: types.maybe(types.string),
  disclaimer_translations: types.maybe(Translation),
  name: types.maybe(types.string),
  tag: types.maybe(types.string),
  priority: types.maybe(types.number)
});

export default Promo;
