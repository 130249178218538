import { duration } from 'Utils/helpers';
import moment from 'Utils/moment';
import _cloneDeep from 'lodash/cloneDeep';

function oldPricesPayment({ reservation, slot }) {
  const { check_in, check_out, tariff: { billing_hour } } = reservation;

  const total = {
    check_in: moment(check_in).format(),
    check_out: moment(check_out).format(),
    price: slot.price,
    duration: duration({ billing_hour, check_in, check_out })
  };

  return slot.cash
    ? { total, cash: _cloneDeep(total) }
    : { total, bank_transfer: _cloneDeep(total) };
}

export default oldPricesPayment;
