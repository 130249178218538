import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';
import { computed } from 'mobx';

import TariffAvailablePeriod from 'Pages/Tariffs/containers/List/components/Tariffs/TariffAvailablePeriod';

@observer
class TariffHeader extends Component {
  @computed get type() {
    const { type } = this.props.tariff;
    const typeStr = ['tariffs', type, 'name'].join('.');

    return formatMessage(typeStr);
  }

  toggleHandler = (e) => {
    e.preventDefault();

    const { isActive, tariff } = this.props;
    isActive.tariff = tariff.id;
  }

  render() {
    const { tariff, onClickHandler } = this.props;

    const {
      id,
      name,
      available_period,
      source,
      room_type
    } = tariff;

    return (
      <div className='caption' onClick={onClickHandler(tariff)}>
        <div className='item name'>
          {name}
          <div className='sub_header'>{room_type.name}</div>
        </div>

        <div className='item type'>
          {this.type}
        </div>

        <div className='item source'>
          {source
            ? source.name
            : formatMessage('tariff.not_specified')}
        </div>

        <div className='item period'>
          <TariffAvailablePeriod period={available_period} />
        </div>
      </div>
    );
  }
}

TariffHeader.propTypes = {
  tariff: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired
};

export default TariffHeader;
