import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@inject('store')
class LoginFormControl extends Component {
  constructor(props) {
    super(props);

    this.form = this.props.form;
    this.userStore = this.props.store.userStore;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(e) {
    this.form.onSubmit(e, {
      onSuccess: this.userStore.login,
      onError: this.userStore.logout
    });
  }

  handleReset(e) {
    this.form.onClear(e);
  }

  render() {
    return (
      <div className='form__control'>
        <button
          type='submit'
          className='button green'
          onClick={this.handleSubmit}
        ><FormattedMessage id='shared.login' />
        </button>

        <button
          className='button gray'
          onClick={this.handleReset}
        ><FormattedMessage id='shared.cancel' />
        </button>
      </div>
    );
  }
}

export default LoginFormControl;
