import { types } from 'mobx-state-tree';
import _range from 'lodash/range';

export const Building = types.model('Building', {
  id: types.identifier(types.string),
  name: types.string,
  floor_numbers: types.number
}, {
  getNumbersOptions() {
    const options = _range(1, this.floor_numbers + 1).map((i) => {
      return { value: i, label: i.toString() };
    });

    return options;
  }
});
