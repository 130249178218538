import jsonpatch from "fast-json-patch";
import _pick from "lodash/pick";
import _range from "lodash/range";
import _intersection from "lodash/intersection";
import { formatMessage } from "Utils/formatMessage";

const isEqualParent = (rateOne, rateTwo) => {
  let errors = [];

  // Check adults
  const attrs = ["age_group", "occupation", "count"];

  const objOne = _pick(rateOne, attrs);
  const objTwo = _pick(rateTwo, attrs);

  const patch = jsonpatch.compare(objOne, objTwo);

  if (patch.length === 0) {
    const data = formatMessage("rates.include_error");
    errors.push(data);
  }

  return errors;
};

const isEqualChild = (rateOne, rateTwo) => {
  let errors = [];
  // Check childs age period
  if (
    rateOne.age_group === rateTwo.age_group &&
    rateOne.age_group === "child"
  ) {
    const inter = isIntersect(rateOne, rateTwo);
    const data = formatMessage("rates.age_period_inter_error");

    errors = inter ? [data] : [];
  }

  return errors;
};

const isEqualFields = (rateOne, rateTwo) => {
  const errors = [];
  const attrs = [
    "age_group",
    "occupation",
    "count",
    "price.amount",
    "age_period",
    "description",
    "type",
  ];

  const objOne = _pick(rateOne, attrs);
  const objTwo = _pick(rateTwo, attrs);

  const patch = jsonpatch.compare(objOne, objTwo);

  if (patch.length) {
  } else {
    const data = formatMessage("rates.include_error");
    errors.push(data);
  }

  return patch;
};

const toPeriod = (rate) => {
  const period = rate.age_period;
  const values = [period.from, period.to].map((value) => Number(value));

  // возвращаем только число, так как если в range передать два одинаковых числа,
  // он вернет пустой массив, что приведет к ошибке
  if (period.from === period.to) {
    return period.from;
  }

  return _range(...values);
};

const compareAgePeriods = (periodOne, periodTwo) => {
  // проверяем, пересекаются ли периоды
  const index = periodOne.findIndex((number) => number === periodTwo);

  // если периоды пересекаются в самом первом элементе
  if (periodOne.includes(periodTwo)) {
    // разрешаем добавление
    if (index === 0) return false;
    return true;
  }

  // для всех остальных случаев, в которых периоды не пересекаются
  return false;
};

const isIntersect = (rateOne, rateTwo) => {
  const periodOne = toPeriod(rateOne);
  const periodTwo = toPeriod(rateTwo);

  // если оба периода - числа (у каждого периода значения from и to одинаковые)
  // см. toPeriod
  if (!Array.isArray(periodTwo) && !Array.isArray(periodOne)) {
    // если периоды совпали, запрещаем добавление категории
    if (periodTwo === periodOne) return true;
    return false;
  }

  // Если оба периода массивы
  if (Array.isArray(periodOne) && Array.isArray(periodTwo)) {
    return _intersection(periodOne, periodTwo).length > 0;
  }

  // если период первой или второй категории является массивом
  if (Array.isArray(periodOne)) {
    return compareAgePeriods(periodOne, periodTwo);
  } else {
    return compareAgePeriods(periodTwo, periodOne);
  }
};

const isSame = (rateOne, rateTwo, isChild = false) => {
  const equal = isEqual(rateOne, rateTwo);
  const inter = isIntersect(rateOne, rateTwo);

  return isChild ? equal && inter : equal;
};

export { isEqualChild, isEqualParent, isIntersect, isSame, isEqualFields };
