import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckboxLabel = styled.label`
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + span {
    display: inline-block;
    cursor: pointer;
  }

  input[type="checkbox"] + span:before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #c6c6c2;
    border-radius: 2px;
    margin-right: 7px;
    vertical-align: text-bottom;
  }

  input[type="checkbox"]:checked + span:before {
    background-color: #52ab18;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 60%;
    border: none;
  }
`;

const CustomCheckbox = ({ id, onChange, checked, label }) => {
  return (
    <CheckboxLabel htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        className="input"
        onChange={onChange}
        checked={checked}
      />
      <span>{label}</span>
    </CheckboxLabel>
  );
};

CustomCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default CustomCheckbox;
