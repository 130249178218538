import React, { Component } from 'react';
import Loader from 'react-loaders';

class PageLoader extends Component {
  render() {
    return (
      <div className='loader'>
        <Loader
          type='line-scale'
          active
          color='#999'
        />
      </div>
    );
  }
}

export default PageLoader;
