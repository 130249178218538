import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Rate from './Rate';
import _partition from 'lodash/partition';
import _flatten from 'lodash/flatten';

@observer
class Rates extends Component {
  render() {
    const { rates } = this.props;

    let parts = _partition(rates, { occupation: 'main' });
    parts.forEach(part => {
      part
        .sort((left, right) => {
          return left.count - right.count;
        })
        .sort((left, right) => {
          return left.price.amount - right.price.amount;
        });
    });
    parts = _flatten(parts);

    const listItems = parts
      .sort((prev, curr) => prev.price.amount - curr.price.amount)
      .map((item) => <Rate key={item.type} rate={item} />);

    return (
      <div className='cell'>
        <div className='price-block'>
          {listItems}
        </div>
      </div>
    );
  }
}

Rates.propTypes = {
  rates: PropTypes.object.isRequired
};

export default Rates;
