const fields = [
  'check_in',
  'check_out'
];

const labels = {};

const placeholders = {};

const rules = {
  check_in: 'string|required',
  check_out: 'string|required'
};

const values = {};

export default {
  fields,
  labels,
  values,
  placeholders,
  rules
};
