import { NSHotel } from '@types';
import * as React from 'react';
import styled from 'styled-components';

import Tariff from './Tariff';

const Wrapper = styled.div`
  ${Tariff} + ${Tariff} {
    margin-top: 48px;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  roomType: NSHotel.TRoomType,
  tariffs: NSHotel.TTariff[]
}>

function Tariffs({ roomType, tariffs, className }: Props) {
  return (
    <Wrapper className={className}>
      {tariffs.map(tariff => (
        <Tariff
          key={tariff.id}
          roomType={roomType}
          tariff={tariff}
        />
      ))}
    </Wrapper>
  );
}

export default Tariffs;
