import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Header from './Header';
import Room from './Room';
import Source from './Source';

@inject('quotasStore')
@observer
class Label extends React.Component {
  render() {
    const { room_type, sources } = this.props;
    const { hiddenRooms } = this.props.quotasStore;
    const currentIsHidden = hiddenRooms.get(room_type.id) ?? false;

    const { rooms, beds } = room_type;
    const hasRooms = rooms.length > 0;
    const filteredData = currentIsHidden ? [] : rooms;

    const listRooms = filteredData.map((r) => {
      return <Room key={r.id} room={r} beds_quantity={beds} />;
    });

    const listSources = sources.map((s) => <Source key={s.id} source={s} />);

    return (
      <div className="item">
        <div className="item__header">
          <Header
            room_type={room_type}
            hasRooms={hasRooms}
            currentIsHidden={currentIsHidden}
          />
        </div>
        <div className={'item__sources'}>{listSources}</div>

        <div className={'item__rooms'}>{listRooms}</div>
      </div>
    );
  }
}

Label.propTypes = {
  room_type: PropTypes.object.isRequired,
  sources: PropTypes.object.isRequired,
  quotasStore: PropTypes.object
};

export default Label;
