import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'Utils/moment';

import { observer, inject } from 'mobx-react';

import PeriodShow from '../PeriodShow';
import PeriodEdit from '../PeriodEdit';
import getSymbolFromCurrency from 'Utils/currencySymbolMap';

@inject('hotelStore')
@observer
class Period extends Component {
  leftBound = moment().subtract(1, 'years');
  rightBound = moment().add(2, 'years');

  isOutsideRange = day =>
    this.leftBound.isAfter(day) || this.rightBound.isBefore(day);

  render() {
    const {
      period,
      isEditable,
      onEdit,
      onDelete,
      onCancelEdit,
      onDateChange,
      onRateChange,
      onSave,
      dataRangeError,
      copyPeriod,
      isPeriodCopied,
      hotelStore: { hotel }
    } = this.props;

    const currentСurrency = hotel.toJSON().currency;
    const currentCurrencySymbol = getSymbolFromCurrency(currentСurrency);

    const startDate = period.start_date;
    const endDate = period.end_date;

    return (
      <>
        {isEditable
          ? (
            <PeriodEdit
              period={period}
              startDate={startDate}
              endDate={endDate}
              onDateChange={onDateChange}
              dataRangeError={dataRangeError}
              onRateChange={onRateChange}
              onSave={onSave}
              onCancelEdit={onCancelEdit}
              currencySymbol={currentCurrencySymbol}
            />
            )
          : (
            <PeriodShow
              period={period}
              startDate={startDate}
              endDate={endDate}
              onEdit={onEdit}
              onDelete={onDelete}
              copyPeriod={copyPeriod}
              isPeriodCopied={isPeriodCopied}
              currencySymbol={currentCurrencySymbol}
            />
            )}
      </>
    );
  }
}

Period.propTypes = {
  period: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onSave: PropTypes.func,
  hotelStore: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onDelete: PropTypes.func,
  onDateChange: PropTypes.func,
  onRateChange: PropTypes.func,
  dataRangeError: PropTypes.object,
  copyPeriod: PropTypes.func,
  isPeriodCopied: PropTypes.bool
};

export default Period;
