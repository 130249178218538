import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import RulesTable from './RulesTable';
import PolicyFullRefund from './PolicyFullRefund';
import PolicyRuleNotice from './PolicyRuleNotice';
import PolicyRuleDeadlineNotice from './PolicyRuleDeadlineNotice';
import { FormattedMessage } from 'react-intl';

@observer
class CancellationPolicy extends Component {
  componentDidMount() {
    const { cancellation_policy } = this.props;

    this.cancellationPolicyHandler = reaction(
      () => {
        return cancellation_policy.$('policy_rules').values();
      },
      (rules) => {
        this.setPolicyFullRefund(rules);
      },
      { fireImmediately: true }
    );

    if (cancellation_policy.$('has_non_refund').value) {
      cancellation_policy.$('policy_rules')
        .add(this.nonRefundRule());
    }
  }

  componentWillUnmount() {
    this.cancellationPolicyHandler();
  }

  setPolicyFullRefund = rules => {
    const { cancellation_policy } = this.props;
    const has_rules = rules.length === 0;

    cancellation_policy.$('has_full_refund').set(has_rules);
  }

  baseRule = () => {
    return {
      penalty: { amount: 50, unit: 'percent' },
      deadline: { amount: 7, unit: 'day' }
    };
  }

  nonRefundRule = () => {
    return {
      penalty: { amount: 100, unit: 'percent' },
      deadline: { amount: 0, unit: 'day' }
    };
  }

  handleAdd = (e) => {
    e.preventDefault();

    const { cancellation_policy } = this.props;
    cancellation_policy.$('policy_rules')
      .add(this.baseRule());
  }

  handleAddNoRefund = (e) => {
    e.preventDefault();

    const { cancellation_policy } = this.props;
    cancellation_policy.$('policy_rules')
      .add(this.nonRefundRule());
  }

  render() {
    const { cancellation_policy, form } = this.props;
    const has_full_refund = cancellation_policy.$('has_full_refund').value;

    return (
      <div className='form'>
        <div className='form__fields-wrapper'>
          {has_full_refund && <PolicyFullRefund />}
          {!has_full_refund && <PolicyRuleNotice />}
          {!has_full_refund && <PolicyRuleDeadlineNotice />}

          <div className='form__fields'>
            <RulesTable policy_rules={cancellation_policy.$('policy_rules')} form={form} />
          </div>

          <div className='form__fields'>
            <div className='form__field action'>
              <div className='buttons'>
                <button className='button gray' onClick={this.handleAdd}>
                  <FormattedMessage id='tariffs.cancellation_policy.rules.add' />
                </button>

                <button className='button gray' onClick={this.handleAddNoRefund}>
                  <FormattedMessage id='tariffs.cancellation_policy.rules.no_refund' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CancellationPolicy.propTypes = {
  cancellation_policy: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CancellationPolicy;
