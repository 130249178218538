import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { TTariffsAction, TTariffsListResponse, ACTIONS } from '../types';

const PARAMS = {
  data: {
    insurance_ids: ['5a27ea5273e8b0489f2768cb']
  }
};

const fetchTariffs = () => {
  return async (dispatch: Dispatch<TTariffsAction>): Promise<void> => {
    try {
      dispatch({ type: ACTIONS.TARIFFS_FETCH_PENDING });

      const { data }: AxiosResponse<TTariffsListResponse> = await instance.get('/api/sogaz/tariffs', { params: PARAMS });

      dispatch({ type: ACTIONS.TARIFFS_FETCH_FULLFILLED, payload: data.data });
    } catch (error) {
      dispatch({ type: ACTIONS.TARIFFS_FETCH_REJECTED });
    }
  };
};

export default fetchTariffs;
