import moment from 'Utils/moment';
import { types } from 'mobx-state-tree';

export const TariffLengthStayRestrictions = types.model('TariffLengthStayRestrictions', {
    start_date: types.maybe(types.string),
    end_date: types.maybe(types.string),
    min_length_of_stay: types.number,
    max_length_of_stay: types.number,

    get fromDate() {
        return this.start_date
            ? moment(this.start_date).format('DD.MM.YYYY')
            : 'не задан';
    },

    get toDate() {
        return this.end_date
            ? moment(this.end_date).format('DD.MM.YYYY')
            : 'не задан';
    }
});
