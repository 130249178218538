import React, { Component } from "react";
import { Tabs } from "components/ui/Tabs";

import TopBarActionBox from "./TopBarActionBox";

export default class TopBarContent extends Component {
  render() {
    const { TABS, currentTab, handleOnClickTab, isTariffList } = this.props;
    return (
      <div className="layout__topbar-content">
        <div className="layout__topbar-content-wrapper">
          {isTariffList && (
            <Tabs
              TABS={TABS}
              handleOnClickTab={handleOnClickTab}
              currentTab={currentTab}
            />
          )}
          <TopBarActionBox />
        </div>
      </div>
    );
  }
}
