import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observable } from 'mobx';
import DownIcon from 'react-icons/lib/md/keyboard-arrow-down';

import { formatMessage } from 'Utils/formatMessage';

import Popover from 'Shared/ui/Popover';

const IconContainer = styled.span`
  margin-left: 2px;
`;

@withRouter
@inject('filterForm', 'ordersStore', 'searchForm', 'sortForm')
@observer
class TopBarActionBox extends Component {
  @observable isDownload = false;

  setDownload = (type) => {
    this.isDownload = type;
  }

  unsetDownload = () => {
    this.isDownload = false;
  }

  handleDownload = (e, type) => {
    e.stopPropagation();
    e.preventDefault();

    this.setDownload(type);

    const { filterForm, ordersStore, searchForm, sortForm } = this.props;
    const filters = filterForm.values().tags;
    const sort = sortForm.values();
    const search = searchForm.values();

    ordersStore.download({ filters, search, sort }, type)
      .then(_r => this.unsetDownload())
      .catch(error => this.errorSubmitHandler(error));
  }

  errorSubmitHandler = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    const items = [
      {
        label: formatMessage('order.download.default'),
        onClick: e => this.handleDownload(e, 'orders'),
        loading: this.isDownload === 'orders'
      },
      {
        label: formatMessage('order.download.sogaz'),
        onClick: e => this.handleDownload(e, 'registries'),
        loading: this.isDownload === 'registries'
      }
    ];

    return (
      <div className='action-box'>
        <Popover
          items={items}
        >
          <button
            className='green button'
          >
            <FormattedMessage id='order.download.button' />

            <IconContainer>
              <DownIcon />
            </IconContainer>
          </button>
        </Popover>
      </div>
    );
  }
}

TopBarActionBox.propTypes = {
  ordersStore: PropTypes.object.isRequired,
  filterForm: PropTypes.object.isRequired,
  searchForm: PropTypes.object.isRequired,
  sortForm: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default TopBarActionBox;
