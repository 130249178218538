import { formatMessage } from "Utils/formatMessage";
import moment from "Utils/moment";

const fields = [
  "start_date",
  "end_date",
  "closed_to_arrival",
  "closed_to_departure",
];

const labels = {
  start_date: formatMessage(
    "tariff.closed_by_date_restrictions.dateRangeInput"
  ),
};

const rules = {
  closed_to_arrival: "boolean|required",
  closed_to_departure: "boolean|required",
  start_date: "string|required",
  end_date: "string|required",
};

const defaults = {
  closed_to_arrival: false,
  closed_to_departure: false,
};

const values = {
  closed_to_arrival: false,
  closed_to_departure: false,
};

export default {
  fields,
  labels,
  rules,
  values,
  defaults,
};
