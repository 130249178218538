import { css } from 'styled-components';

const variant = ({ variant }) => {
  if (variant === 'warning') {
    return css`
      background: #FBEFDB;
    `;
  }

  if (variant === 'accepted') {
    return css`
      background: #E4F2E3;
    `;
  }

  if (variant === 'danger') {
    return css`
      background: #F9E1E1;
    `;
  }

  return css``;
};

const defaults = (props) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0001);
  box-sizing: border-box;
`;

const theme = (props) => ([
  defaults(props),
  variant(props)
]);

export default theme;
