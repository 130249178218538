import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Slider from 'rc-slider';
import { getSliderMarks } from 'Utils/getSliderMarks';
import { SliderLabel, SliderLabelMute, Time, TimeLabel } from 'Styles/styled';

@observer
class CheckInTime extends Component {
  render() {
    const { value } = this.props;

    let rangeValue = value.split(':')[0];
    rangeValue = Number(rangeValue);

    const marks = getSliderMarks(1, 24, rangeValue, 'timeSlider');

    return (
      <div className='form__field slider'>
        <SliderLabel>
          <SliderLabelMute>
            <FormattedMessage id='hotel.checkin_time_after' />
          </SliderLabelMute>

          <TimeLabel>
            <FormattedMessage id='hotel.after' />
            <Time>{value}</Time>
          </TimeLabel>

        </SliderLabel>

        <Slider
          allowCross={false}
          min={1}
          max={23}
          step={1}
          marks={marks}
          value={rangeValue}
          railStyle={{ backgroundColor: '#C9E6C8' }}
          trackStyle={{ backgroundColor: '#EEEBE7' }}
          handleStyle={[{ borderColor: '#69AC66', backgroundColor: '#69AC66' }]}
        />
      </div>
    );
  }
}

CheckInTime.propTypes = {
  value: PropTypes.string.isRequired
};

export default CheckInTime;
