import React from 'react';
import { useThunkReducer } from '../hooks';
import reducer, { initialState } from '../reducer';
import SogazContext from '../Context';

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function SogazProvider({ children }: Props) {
  const [state, dispatch] = useThunkReducer(reducer, initialState, 'sogaz');

  return (
    <SogazContext.Provider value={{ state, dispatch }}>
      {children}
    </SogazContext.Provider>
  );
}

export default SogazProvider;
