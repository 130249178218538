import { types } from 'mobx-state-tree';

export const RoomType = types.model('RoomType', {
  id: types.identifier(types.string),
  name: types.string,
  beds: types.number,
  extra_beds: types.optional(types.number, 0)
});

export const Room = types.model('Room', {
  id: types.identifier(types.string),
  number: types.string,
  floor_number: types.number,
  room_type: types.maybe(RoomType),
  building_id: types.maybe(types.string),
  room_type_id: types.maybe(types.string)
});
