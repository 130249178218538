import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import CheckInTime from './CheckInTime';
import CheckOutTime from './CheckOutTime';
import ChildPermission from './ChildPermission';

import {
  SliderFormFields,
  SliderTitle,
  SliderBlock,
  SliderSegment,
  MuteText,
  SourceName,
  SliderWrapper
} from 'Styles/styled';

@observer
class BillingHour extends Component {
  render() {
    const { sources, settings } = this.props.hotel;

    const billingHourTitle = (billing_hour) => (
      billing_hour === 'night'
        ? <FormattedMessage id='hotel.night' />
        : <FormattedMessage id='hotel.day' />
    );

    return (
      <div className='groups__item'>
        <div className='groups__item-content'>
          <SliderSegment>
            <div className='form'>
              {sources.map((source, index) => (
                <SliderWrapper key={index}>
                  <SliderTitle>
                    <SourceName>{source.insurance.name}</SourceName>
                    <FormattedMessage id='hotel.calculate_period_in' />
                    <MuteText>{billingHourTitle(source.settings.billing_hour)}</MuteText>
                  </SliderTitle>

                  <SliderFormFields>
                    <CheckInTime value={source.settings.check_in_time} />
                  </SliderFormFields>

                  <SliderFormFields>
                    <CheckOutTime value={source.settings.check_out_time} />
                  </SliderFormFields>
                </SliderWrapper>
              ))}

              <SliderBlock>
                <SliderTitle>
                  <FormattedMessage id='hotel.age_limit' />
                </SliderTitle>

                <SliderFormFields>
                  <ChildPermission value={settings.children_from} />
                </SliderFormFields>
              </SliderBlock>
            </div>
          </SliderSegment>
        </div>
      </div>
    );
  }
}

BillingHour.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default BillingHour;
