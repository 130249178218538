import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { filters } from '../';
import { Tag } from './SelectedFilters.styled';

@inject('filterForm')
@observer
class SelectedFilters extends React.Component {
  static propTypes = {
    filterForm: PropTypes.object.isRequired
  };

  @computed get tags() {
    const { filterForm } = this.props;
    return Array.from(filterForm.$('tags').fields.values());
  }

  handleRemove = (tag) => {
    const { filterForm } = this.props;
    const { type } = tag.value;
    const filter = filters.find(item => item.type === type);

    filterForm.removeTag(tag);

    filter.fields.forEach(fieldName => {
      filterForm.$(fieldName).reset();
    });
  }

  render() {
    if (this.tags.length > 0) {
      return this.tags.map((tag, index) => (
        <Tag
          key={index}
          field={tag}
          onRemove={this.handleRemove}
        />
      ));
    }

    return null;
  }
}

export default SelectedFilters;
