import { types } from 'mobx-state-tree';

const Document = types.model('Document', {
  number: types.string
});

export const Traveller = types.model('Traveller', {
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybe(types.string),
  document: types.maybe(Document)
});
