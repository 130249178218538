import { NSHotel, NSSogaz } from '@types';
import { DispatcherThunk } from '../hooks/useThunkReducer';

export enum STATUSES {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected'
}

export enum ACTIONS {
  PRICE_LIST_FETCH_PENDING = 'price_list/fetch/pending',
  PRICE_LIST_FETCH_FULLFILLED = 'price_list/fetch/fullfilled',
  PRICE_LIST_FETCH_REJECTED = 'price_list/fetch/rejected',

  PRICE_LISTS_FETCH_PENDING = 'price_lists/fetch/pending',
  PRICE_LISTS_FETCH_FULLFILLED = 'price_lists/fetch/fullfilled',
  PRICE_LISTS_FETCH_REJECTED = 'price_lists/fetch/rejected',

  MAPPINGS_FETCH_PENDING = 'mappings/fetch/pending',
  MAPPINGS_FETCH_FULLFILLED = 'mappings/fetch/fullfilled',
  MAPPINGS_FETCH_REJECTED = 'mappings/fetch/rejected',

  TARIFFS_FETCH_PENDING = 'tariffs/fetch/pending',
  TARIFFS_FETCH_FULLFILLED = 'tariffs/fetch/fullfilled',
  TARIFFS_FETCH_REJECTED = 'tariffs/fetch/rejected',
  TARIFFS_BLOCK = 'tariffs/block',
  TARIFFS_UNBLOCK = 'tariffs/unblock',

  COMPARE_RATE_ADD = 'compare/rate/add',
  COMPARE_RATE_REMOVE = 'compare/rate/remove',
  COMPARE_SERVICE_ADD = 'compare/service/add',
  COMPARE_SERVICE_REMOVE = 'compare/service/remove',
  COMPARE_SUBMIT = 'compare/submit',
  COMPARE_RESET = 'compare/reset'
}

export type TService = {
  name: string
}

export interface IEntities<T> {
  status: STATUSES,
  data: T
}

export interface IAdapter {
  status: STATUSES,
  price_list: IEntities<NSSogaz.TPriceList | null>,
  price_lists: IEntities<NSSogaz.TPriceList[]>,
  mappings: IEntities<NSSogaz.TMapping[]>,
  tariffs: IEntities<NSHotel.TTariff[]>,
  compare: {
    room_type?: NSHotel.TRoomType,
    tariff?: NSHotel.TTariff,
    rate?: NSHotel.TRate,
    service?: NSSogaz.TServiceGroup
  }
}

// PRICE_LISTS
export interface IPriceListFetchPending {
  type: ACTIONS.PRICE_LIST_FETCH_PENDING
}

export interface IPriceListsFetchPending {
  type: ACTIONS.PRICE_LISTS_FETCH_PENDING
}

export interface IPriceListFetchFullfiled {
  type: ACTIONS.PRICE_LIST_FETCH_FULLFILLED,
  payload: NSSogaz.TPriceList
}

export interface IPriceListsFetchFullfiled {
  type: ACTIONS.PRICE_LISTS_FETCH_FULLFILLED,
  payload: NSSogaz.TPriceList[]
}

export interface IPriceListFetchRejected {
  type: ACTIONS.PRICE_LIST_FETCH_REJECTED
}
export interface IPriceListsFetchRejected {
  type: ACTIONS.PRICE_LISTS_FETCH_REJECTED
}

export type TPriceListAction =
  | IPriceListFetchPending
  | IPriceListFetchFullfiled
  | IPriceListFetchRejected

export type TPriceListsAction =
  | IPriceListsFetchPending
  | IPriceListsFetchFullfiled
  | IPriceListsFetchRejected

export type TPriceListsListResponse = {
  meta: {
    channel_manager: string,
    hotel: {
      inn: string,
      kpp: string
    }
  },
  data: NSSogaz.TPriceList[]
}

export type TPriceListsShowResponse = {
  meta: {
    channel_manager: string,
    hotel: {
      inn: string,
      kpp: string
    }
  },
  data: NSSogaz.TPriceList
}

// MAPPINGS
export interface IMappingsFetchPending {
  type: ACTIONS.MAPPINGS_FETCH_PENDING
}

export interface IMappingsFetchFullfiled {
  type: ACTIONS.MAPPINGS_FETCH_FULLFILLED,
  payload: NSSogaz.TMapping[]
}

export interface IMappingsFetchRejected {
  type: ACTIONS.MAPPINGS_FETCH_REJECTED
}

export type TMappingsAction =
  | IMappingsFetchPending
  | IMappingsFetchFullfiled
  | IMappingsFetchRejected

export type TMappingsListResponse = {
  meta: {
    channel_manager: string,
    hotel: {
      provider_id: string
    }
  },
  data: NSSogaz.TMapping[]
}

export type TMappingsOneResponse = {
  meta: {
    channel_manager: string,
    hotel: {
      provider_id: string
    }
  },
  data: NSSogaz.TMapping
}

// TARIFFS
export interface ITariffsFetchPending {
  type: ACTIONS.TARIFFS_FETCH_PENDING
}

export interface ITariffsFetchFullfiled {
  type: ACTIONS.TARIFFS_FETCH_FULLFILLED,
  payload: NSHotel.TTariff[]
}

export interface ITariffsFetchRejected {
  type: ACTIONS.TARIFFS_FETCH_REJECTED
}

export interface ITariffsBlock {
  type: ACTIONS.TARIFFS_BLOCK
}

export interface ITariffsUnblock {
  type: ACTIONS.TARIFFS_UNBLOCK
}

export type TTariffsAction =
  | ITariffsFetchPending
  | ITariffsFetchFullfiled
  | ITariffsFetchRejected
  | ITariffsBlock
  | ITariffsUnblock

export type TTariffsListResponse = {
  meta: {
    currency: string,
    locale: string
  },
  data: NSHotel.TTariff[]
}

// COMPARE
export interface ICompareRateAdd {
  type: ACTIONS.COMPARE_RATE_ADD,
  payload: {
    room_type: NSHotel.TRoomType,
    tariff: NSHotel.TTariff,
    rate: NSHotel.TRate
  }
}

export interface ICompareRateRemove {
  type: ACTIONS.COMPARE_RATE_REMOVE
}

export interface ICompareServiceAdd {
  type: ACTIONS.COMPARE_SERVICE_ADD,
  payload: {
    service: NSSogaz.TServiceGroup
  }
}

export interface ICompareServiceRemove {
  type: ACTIONS.COMPARE_SERVICE_REMOVE
}

export interface ICompareSubmit {
  type: ACTIONS.COMPARE_SUBMIT,
  payload: Required<IAdapter['compare']>
}

export interface ICompareReset {
  type: ACTIONS.COMPARE_RESET
}

export type TCompareAction =
  | ICompareRateAdd
  | ICompareRateRemove
  | ICompareServiceAdd
  | ICompareServiceRemove
  | ICompareSubmit
  | ICompareReset

export type IAction =
  | TPriceListAction
  | TPriceListsAction
  | TTariffsAction
  | TMappingsAction
  | TCompareAction

export interface IContext {
  state: IAdapter,
  dispatch: DispatcherThunk<(state: IAdapter, action: IAction) => void>
}
