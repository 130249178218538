import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TariffStatus from './TariffStatus';
import TariffType from './TariffType';
import TariffSource from './TariffSource';
import Insurant from './Insurant';

@observer
class TariffInfoBlock extends Component {
  render() {
    const { tariff: { source, insurant }, labels } = this.props;

    return (
      <div className='row'>
        <div className='col-3'>
          <TariffStatus labels={labels} />
        </div>
        <div className='col-3'>
          <TariffType labels={labels} />
        </div>
        <div className='col-3'>
          <TariffSource source={source} />
        </div>
        <div className='col-3'>
          <Insurant insurant={insurant} />
        </div>
      </div>
    );
  }
}

TariffInfoBlock.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default TariffInfoBlock;
