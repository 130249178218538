import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import instance from 'connection/instance';
import { Async } from 'react-select';

import styled from 'styled-components';

const Wrapper = styled.div`
  min-width: 200px;
`;

@observer
class Manager extends Component {
  handleChange = ({ label, id }) => {
    const { onChange, manager } = this.props;
    manager.set(id);
    onChange('manager', label, id);
  }

  getOptions = (input) => {
    const options = input.length < 3
      ? {}
      : { params: { data: { chars: input } } };

    return instance.get('/api/filters/managers', options)
      .then(response => this.parseFilterData(response));
  }

  parseFilterData(response) {
    const { data } = response;
    return { options: data.data.map((item) => ({ id: item.id, label: item.name })) };
  }

  render() {
    const { manager } = this.props;

    return (
      <Wrapper>
        <Async
          clearable={false}
          searchable
          simpleValue={false}
          placeholder={manager.placeholder}
          loadOptions={this.getOptions}
          arrowRenderer={() => null}
          onChange={this.handleChange}
        />
      </Wrapper>
    );
  }
}

Manager.propTypes = {
  manager: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Manager;
