import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react/index';
import PropTypes from 'prop-types';

import { FormLanguageContext } from 'Context/FormLanguageContext';

@withRouter
@observer
class TariffAddAction extends Component {
  static contextType = FormLanguageContext;

  handleAddTariff = (e) => {
    e.preventDefault();

    const currentLanguage = this.context.currentLanguage;
    const { history, tariff } = this.props;
    const room_type = tariff && tariff.room_type;

    history.push('/tariffs/new', {
      values: {
        room_type: {
          ...room_type,
          name: room_type.name[currentLanguage]
        }
      }
    });
  }

  render() {
    return (
      <div className='tariffs__actions'>
        <button className='button gray' onClick={this.handleAddTariff}>
          <FormattedMessage id='tariff.add' />
        </button>
      </div>
    );
  }
}

TariffAddAction.propTypes = {
  tariff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default TariffAddAction;
