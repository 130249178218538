import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FeedTypeTree from './FeedTypeTree';
import MealOptionsTree from './MealOptionsTree';

@observer
class Feed extends Component {
  render() {
    const { hotel } = this.props;
    const feedTypes = this.props.hotel.feed_types.toJSON();
    const mealOptions = this.props.hotel.meal_options.toJSON();

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.feed' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {feedTypes.length > 0
              ? (
                <>
                  <div className='key'>
                    <FormattedMessage
                      id='hotel.feedType'
                    />
                  </div>
                  <FeedTypeTree hotel={hotel} />
                </>
                )
              : (
                <p className='muted'>
                  <FormattedMessage id='hotel.feedType_didnt_choosed' />
                </p>
                )}

            {mealOptions.length > 0
              ? (
                <>
                  <div className='form__field--title--show'>
                    <div className='key'>
                      <FormattedMessage
                        id='hotel.meal_options'
                      />
                    </div>
                  </div>
                  <MealOptionsTree hotel={hotel} />
                </>
                )
              : (
                <p className='muted'>
                  <FormattedMessage id='hotel.meal_options_didnt_choosed' />
                </p>
                )}
          </div>
        </div>
      </div>
    );
  }
}

Feed.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default Feed;
