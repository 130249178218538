import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Feed } from 'Stores/models/Feed';

export const AccommodationFeaturesStore = types.model('AccommodationFeaturesStore', {
  features: types.optional(types.array(Feed), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  }
}, {
  fetchChecked() {
    this.setState('pending');

    return instance.get('/api/filters/accommodation_features')
      .then(response => this.resetStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  setState(state, response = undefined) {
    this.state = state;
    return response;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  resetStore(response) {
    applySnapshot(this, { features: response.data.data });
    return response.data.data;
  }
});
