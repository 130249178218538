import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, autorun, observable, reaction } from 'mobx';
import Collapsible from 'react-collapsible';
import _sortBy from 'lodash/sortBy';

import SingleSelect from 'Shared/form/SingleSelectObj';

import Header from './Header';
import Travellers from '../Travellers';
import { FormattedMessage } from 'react-intl';

@observer
class Slot extends Component {
  constructor(props) {
    super(props);

    this.rateChangeHandler = reaction(
      () => this.props.slot.$('rate').value,
      (values) => this.props.form.allMainAsRate(values)
    );
  }

  @computed get bedsCount() {
    const { form } = this.props;
    return form.$('room_type.beds').value;
  }

  @computed get asNoMultiSeat() {
    const { form } = this.props;
    const beds = (form.has('beds') && form.$('beds').value) || 0;

    return form.rates
      .filter(rate => rate.count === beds);
  }

  @computed get asMultiSeat() {
    const { rates } = this.props.form;

    return rates
      .filter(rate => rate.count === 0 && rate.age_group === 'adult' && rate.occupation === 'main');
  }

  @computed get asChild() {
    const { rates } = this.props.form;

    return rates
      .filter(rate => rate.age_group === 'child' && rate.occupation === 'main');
  }

  @computed get asExtra() {
    const { rates } = this.props.form;

    return rates
      .filter(rate => rate.occupation === 'extra');
  }

  @computed get ratesOptions() {
    const { slot, form } = this.props;
    const occupation = slot.$('type').value;

    const beds = (form.has('beds') && form.$('beds').value);
    const roomTypeBeds = form.$('room_type.beds').value;
    const room = form.rooms.length > 0 && form.rooms[0];
    const roomBeds = room ? room.capacity.beds : 0;

    let availableRates = [];

    if (occupation === 'extra') {
      availableRates = this.asExtra;
    } else {
      availableRates = this.bedsFilter(beds, roomBeds, roomTypeBeds);
    }

    return _sortBy(availableRates, ['description']);
  }

  bedsFilter = (beds, roomBeds, roomTypeBeds) => {
    let rates = [];
    if (roomBeds < roomTypeBeds) {
      rates = this.asMultiSeat;
    } else {
      rates = beds === roomTypeBeds
        ? (this.asNoMultiSeat.length === 0 ? this.asMultiSeat : this.asNoMultiSeat)
        : (this.asMultiSeat.concat(this.asNoMultiSeat));
    }

    return rates.concat(this.asChild);
  }

  handleTriggerClick = () => {
    const { slot, triggerHandler } = this.props;
    triggerHandler(slot);
  }

  onChange = (value) => {
    const { slot } = this.props;

    value
      ? slot.$('rate').update(value)
      : slot.$('rate').reset();
  }

  render() {
    const { number, slot, form, isOpenned } = this.props;

    return (
      <Collapsible
        trigger={<Header number={number} slot={slot} />}
        handleTriggerClick={this.handleTriggerClick}
        transitionTime={100}
        overflowWhenOpen='visible'
        easing='ease-in'
        open={isOpenned}
      >
        <div className='form__field'>
          {this.ratesOptions.length === 0 &&
            <p className='text error'>
              <FormattedMessage id='order.slot.rates_error' />
            </p>}

          <SingleSelect
            field={slot.$('rate')}
            valueKey='type'
            labelKey='description'
            simpleValue={false}
            selectFirst
            options={this.ratesOptions}
            isLoading={false}
            onChange={this.onChange}
          />

          {this.ratesOptions.length > 0 &&
            <Travellers slot={slot} travellers={slot.$('travellers')} />}

        </div>
      </Collapsible>
    );
  }
}

Slot.propTypes = {
  number: PropTypes.number.isRequired,
  slot: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isOpenned: PropTypes.bool.isRequired,
  triggerHandler: PropTypes.func.isRequired
};

export default Slot;
