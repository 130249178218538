import FormState from 'Shared/form/FormState';
import fields from './fields';
import moment from 'Utils/moment';
import qs from 'qs';

class SearchState extends FormState {
  toLeft() {
    let { check_in, check_out } = this.values();

    check_in = moment(check_in).subtract(1, 'months').startOf('month').format('Y-MM-DD');
    check_out = moment(check_out).subtract(1, 'months').endOf('month').format('Y-MM-DD');

    this.set({ check_in, check_out });

    return this.prepareSearchParams({ check_in, check_out });
  }

  toRight() {
    let { check_in, check_out } = this.values();

    check_in = moment(check_in).add(1, 'months').startOf('month').format('Y-MM-DD');
    check_out = moment(check_out).add(1, 'months').endOf('month').format('Y-MM-DD');

    this.set({ check_in, check_out });

    return this.prepareSearchParams({ check_in, check_out });
  }

  setFromSearchParams(locationSearch) {
    const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    let { check_in, check_out } = queryParams;

    if (check_in === undefined) {
      check_in = moment().startOf('day').format('Y-MM-DD');
    }

    if (check_out === undefined) {
      check_out = moment().endOf('day').add(2, 'months').format('Y-MM-DD');
    }

    const checkIn = moment(check_in);
    const checkOut = moment(check_out);

    if (checkIn.isValid() && checkOut.isValid()) {
      this.set({
        check_in: checkIn.format('Y-MM-DD'),
        check_out: checkOut.format('Y-MM-DD')
      });
    }
  }

  prepareSearchParams(params) {
    return qs.stringify(params);
  }

  gotoDate(date) {
    let { check_in, check_out } = this.values();
    check_in = moment(check_in);
    check_out = moment(check_out);

    if (moment(date).isBetween(check_in, check_out)) return;

    check_in = moment(date).startOf('month');
    check_out = moment(date).add(2, 'months').startOf('month');

    this.set({
      check_in: check_in.format('Y-MM-DD'),
      check_out: check_out.format('Y-MM-DD')
    });
  }

  setRange(check_in, check_out) {
    this.set({
      check_in: moment(check_in).format('Y-MM-DD'),
      check_out: moment(check_out).format('Y-MM-DD')
    });
  }
}

export { fields };
export default SearchState;
