import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { formatMessage } from 'Utils/formatMessage';

import RatePrice from 'components/v2/RatePrice';

const Value = styled.div`
  width: 30%;
`;

const Label = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding: 12px 0px 12px 0px;
`;

@observer
class Rate extends React.Component {
  handleChangePrice = val => {
    const { rate, onRateChange } = this.props;

    onRateChange(val, rate);
  }

  render() {
    const { rate, isEditable } = this.props;
    const error = rate.age_group === 'adult' && !rate.price.amount && formatMessage('periods.rate.priceError');

    return (
      <Row>
        <Label>{rate.description}</Label>
        <Value>
          {isEditable
            ? (
              <RatePrice
                price={rate.price}
                onChange={this.handleChangePrice}
                error={error}
              />
              )
            : (
              <span>{rate.price.amount}</span>
              )}
        </Value>
      </Row>
    );
  }
}

Rate.propTypes = {
  rate: PropTypes.object.isRequired,
  isEditable: PropTypes.any,
  onRateChange: PropTypes.object
};

export default Rate;
