import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class CalculationRule extends React.Component {
  render() {
    const { price_calculation_rule } = this.props;
    return (
      <div className='form__field price-calculation-rule'>
        <label htmlFor={price_calculation_rule.id}>
          <FormattedMessage id='tariffs.price_calculation_rules.self' />
          <span className='error'>{price_calculation_rule.error && '*'}</span>
        </label>

        <div className='price-calculation-rule__values'>
          <div className='price-calculation-rule__value'>
            <input
              className='input'
              {...price_calculation_rule.bind({
                type: 'checkbox',
                value: 'fixed',
                checked: price_calculation_rule.value === 'fixed'
              })}
            />
            <FormattedMessage id='tariffs.price_calculation_rules.fixed' />
          </div>

          <div className='price-calculation-rule__value'>
            <input
              className='input'
              {...price_calculation_rule.bind({
                type: 'checkbox',
                value: 'float',
                checked: price_calculation_rule.value === 'float'
              })}
            />
            <FormattedMessage id='tariffs.price_calculation_rules.float' />
          </div>
        </div>
      </div>
    );
  }
}

CalculationRule.propTypes = {
  price_calculation_rule: PropTypes.object.isRequired
};

export default CalculationRule;
