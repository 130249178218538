import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import ByCash from './ByCash';
import ByCard from './ByCard';
import Currency from './Currency';
import ByPrepayment from "./ByPrepayment";

@observer
class PaymentDetails extends Component {
  render() {
    const { currency, payment_details } = this.props.hotel;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.payment_details' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <div className='options'>
              <ByCash value={payment_details.by_cash} />
              <ByCard value={payment_details.by_card} />
              <ByPrepayment value={payment_details.by_prepayment} />
              <Currency value={currency} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentDetails.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default PaymentDetails;
