import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import Rooms from './Rooms';
import CustomCheckbox from '../CustomCheckbox';

const WrapperList = styled.div`
  max-height: 350px;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin-bottom: 0.5rem;
`;

const Input = styled.div`
  width: 3rem;
`;

@observer
class RoomsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allRooms: false,
      allInputValue: null
    };
  }

  handleCheckboxChange = () => {
    const { form } = this.props;
    const { allRooms } = this.state;
    const rooms = form.values().room_types;

    // Update allRooms state and toggle selected for all rooms
    this.setState({ allRooms: !allRooms }, () => {
      const newRooms = rooms.map((room) => ({
        ...room,
        selected: !allRooms
      }));

      form.update({
        room_types: newRooms
      });

      if (allRooms) {
        this.setState({ allInputValue: null }, () => {});
      }
    });
  };

  handleInputChange = (e) => {
    this.setState({
      allInputValue: e.target.value
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.props;
    const { allRooms, allInputValue } = this.state;

    if (
      allRooms !== prevState.allRooms ||
      allInputValue !== prevState.allInputValue
    ) {
      if (allRooms && allInputValue) {
        const rooms = form.values().room_types;

        const newRooms = rooms.map((room) => ({
          ...room,
          quantity: allInputValue
        }));

        form.update({
          room_types: newRooms
        });
      } else {
        const rooms = form.values().room_types;

        const newRooms = rooms.map((room) => ({
          ...room,
          quantity: null
        }));

        form.update({
          room_types: newRooms
        });

        this.setState({
          allInputValue: null
        });
      }
    }
  }

  render() {
    const { form } = this.props;
    const { allRooms, allInputValue } = this.state;

    const sources = form.$('room_types').fields.values();
    return (
      <WrapperList>
        <Wrapper>
          <CustomCheckbox
            id="allRooms"
            onChange={this.handleCheckboxChange}
            checked={allRooms}
            label="Заполнить все"
          />
          <Input className="field">
            <input
              className="input"
              onChange={this.handleInputChange}
              value={allInputValue === null ? '' : allInputValue}
            />
          </Input>
        </Wrapper>

        <div className="quotas-form__body">
          {sources.map((source) => (
            <Rooms
              setAllRooms={this.setState.bind(this)}
              key={source.id}
              field={source}
            />
          ))}
        </div>
      </WrapperList>
    );
  }
}

RoomsForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default RoomsForm;
