import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import SingleSelect from 'Shared/form/SingleSelect';

@inject('buildingsStore')
@observer
class Form extends React.Component {
  render() {
    const { form, currentLanguage } = this.props;

    return (
      <div className='form'>
        <div className='form__body'>
          <div className='form__fields'>
            <FormItemInput
              field={form.$(`name_translations.${currentLanguage}`)}
              className='grow'
              isLocalizeField
            />

            <SingleSelect
              className='form__field w20'
              field={form.$('floor_numbers')}
            />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

export default Form;
