import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const activeItemRules = css`
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.18);
  background: #D2D1CC; color: #333;

  ${({ theme }) => ({
    green: 'background: #52AB18; color: #fff'
  }[theme])}
`;

const S = {
  Container: styled.div`
    cursor: default;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCD8D2;
    border-radius: 4px;
    padding: 3px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: 0 1px 9px rgba(0,0,0,0.13);
  `,

  Item: styled.div`
    cursor: pointer;
    padding: 5px 19px 7px;
    ${props => props.active ? activeItemRules : ''};
  `
};

class Switch extends Component {
  state = {
    selected: this.props.defaultSelected || this.props.items?.[0]?.value
  };

  handleClick = (item) => (e) => {
    this.setState({ selected: item.value });
    this.props.onChange(item);
  };

  render() {
    const items = this.props.items.map(item => {
      const props = item.value === this.state.selected ? { active: true } : {};
      const { theme } = this.props;
      return (
        <S.Item
          key={item.value}
          theme={theme}
          onClick={this.handleClick(item)}
          {...props}
        >
          {item.label}
        </S.Item>
      );
    });
    if (items.length === 0) {
      return null;
    }
    return (
      <S.Container>
        {items}
      </S.Container>
    );
  }
}

Switch.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.required,
    value: PropTypes.string.required
  })),
  selected: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(['green', 'gray']),
  defaultSelected: PropTypes.object
};

Switch.defaultProps = {
  theme: 'gray',
  items: [],
  onChange: () => {}
};

export default Switch;
