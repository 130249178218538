import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import { formatMessage } from 'Utils/formatMessage';

@observer
class PolicyRules extends Component {
  render() {
    const { cancellation_policy, billing_hour } = this.props;

    const rules = _sortBy(cancellation_policy.policy_rules, [
      function (rule) {
        return rule.deadline.amount;
      }
    ]).map((rule) => {
      const currentText =
        rule.deadline.amount === 0
          ? formatMessage('tariffs.cancellation_policy.rule_0_days', {
            penalty_amount: rule.penalty.amount,
            penalty_unit: rule.penalty.unit,
            deadline_amount: rule.deadline.amount,
            deadline_unit: rule.deadline.unit,
            billing_hour: billing_hour
          })
          : formatMessage('tariffs.cancellation_policy.rule', {
            penalty_amount: rule.penalty.amount,
            penalty_unit: rule.penalty.unit,
            deadline_amount: rule.deadline.amount,
            deadline_unit: rule.deadline.unit,
            billing_hour: billing_hour
          });
      return (
        <span key={rule} className="value">
          {currentText}
        </span>
      );
    });
    return <span>{rules}</span>;
  }
}

PolicyRules.propTypes = {
  cancellation_policy: PropTypes.object.isRequired,
  billing_hour: PropTypes.string.isRequired
};

export default PolicyRules;
