import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { ACTIONS, TPriceListsShowResponse, TPriceListAction } from '../types';

const comparePriceList = (id: string) => {
  return async (dispatch: Dispatch<TPriceListAction>): Promise<void> => {
    try {
      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_PENDING });

      const query_params = {
        queue: 'pending'
      };

      const { data }: AxiosResponse<TPriceListsShowResponse> =
        await instance.put(`/api/sogaz/price_lists/${id}/queue`, { data: query_params });

      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_FULLFILLED, payload: data.data });
    } catch (error) {
      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_REJECTED });
    }
  };
};

export default comparePriceList;
