import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import enhanceWithClickOutside from 'react-click-outside';
import { formatMessage } from 'Utils/formatMessage';

import { filters } from '../../';

import {
  StyledPopover,
  MenuItem,
  Control,
  Label,
  AddIcon,
  ClearAllFiltersControl
} from './FilterPicker.styled';

@enhanceWithClickOutside
@observer
class FilterPicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable isOpened = false;

  handleClickOutside() {
    this.isOpened = false;
  }

  handleChange = (filter) => {
    const { onChange } = this.props;

    this.isOpened = false;

    if (filter) {
      onChange(filter);
    }
  }

  handleToggleOpened = () => {
    this.isOpened = !this.isOpened;
  }

  handleClose = () => {
    this.isOpened = false;
  }

  handleClearAllFilters = () => {
    const { filterForm } = this.props;
    filterForm.clearAll();
    this.filter = null;
  }

  render() {
    const { className, filterForm, ...rest } = this.props;

    const popoverItems = filters.map((filter) => {
      const selectedTags = filterForm.$('tags').value.map(tag => tag.type);
      if (!selectedTags.some(tag => (tag === 'check_in' || tag === 'check_out') && tag === filter.type)) {
        return (
          <MenuItem
            key={filter.type}
            onClick={this.handleChange.bind(this, filter)}
          >
            {filter.label}
          </MenuItem>
        );
      }
      return null;
    });

    return (
      <StyledPopover
        className={className}
        body={popoverItems}
        isOpen={this.isOpened}
        place='below'
        preferPlace='below'
        tipSize={0.01}
        enterExitTransitionDurationMs={200}
      >
        <div>
          <Control
            outline
            {...rest}
            onClick={this.handleToggleOpened}
          >
            <Label>
              <AddIcon />
              {formatMessage('orders.filter')}
            </Label>
          </Control>
        </div>

        {filterForm.$('tags').value.length > 0 && (
          <ClearAllFiltersControl onClick={this.handleClearAllFilters}>
            {formatMessage('orders.clear')}
          </ClearAllFiltersControl>
        )}
      </StyledPopover>
    );
  }
}

FilterPicker.propTypes = {
  filterForm: PropTypes.object.isRequired
};

export default styled(FilterPicker)``;
