import { useContext } from 'react';
import SogazContext from '../Context';

const useSogaz = () => {
  const context = useContext(SogazContext);

  if (context == null) {
    throw new Error(
      'No context provided: useSogaz() can only be used in a descendant of <SogazContextProvider>'
    );
  }

  return context;
};

export default useSogaz;
