import React from 'react';
import PropTypes from 'prop-types';

import { formatMessage } from 'Utils/formatMessage';

import {
  Wrapper,
  StyledClose,
  Caption,
  Label,
  Value
} from './Filter.styled';

const labels = {
  check_in: formatMessage('orders.check_in_data'),
  check_out: formatMessage('orders.check_out_data'),
  source: formatMessage('orders.source'),
  manager: formatMessage('orders.manager'),
  state: formatMessage('orders.status'),
  traveller: formatMessage('orders.guestName')
};

class Filter extends React.PureComponent {
  handleFilterRemove = () => {
    const { field, onRemove } = this.props;
    onRemove(field);
  }

  render() {
    const { field, onRemove, ...rest } = this.props;
    const { label, type } = field.value;
    const currentLabel = labels[type];
    return (
      <Wrapper {...rest}>
        <Caption>
          <Label>
            {currentLabel}:
          </Label>
          <Value>
            {label}
          </Value>
        </Caption>

        <StyledClose onClick={this.handleFilterRemove} />
      </Wrapper>
    );
  }
}

Filter.propTypes = {
  field: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default Filter;
