import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getIcon } from 'Utils/tariffsRateIcon';

@observer
class Rate extends Component {
  static propTypes = {
    rate: PropTypes.object.isRequired
  }

  render() {
    const { type, price } = this.props.rate;

    const icon = getIcon(type);

    const amount = price.amount > 0
      ? price.amount
      : '-';

    return (
      <div className='price-block__item alone'>
        <div className='icon'>
          {icon}
        </div>

        <div className='price'>
          {amount}
        </div>
      </div>
    );
  }
}

export default Rate;
