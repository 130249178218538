import { NSHotel } from '@types';
import * as React from 'react';
import styled from 'styled-components';

import RoomType from './RoomType';

const Wrapper = styled.div`
  ${RoomType} + ${RoomType} {
    margin-top: 64px;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  roomTypes: NSHotel.TRoomType[]
}>

function Tariffs({ roomTypes, className }: Props) {
  return (
    <Wrapper className={className}>
      {roomTypes.map(roomType => (
        <RoomType
          key={roomType.id}
          roomType={roomType}
        />
      ))}
    </Wrapper>
  );
}

export default Tariffs;
