import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import FormFormattedInput from "Shared/form/FormFormattedInput";

@observer
class RestrictionPeriod extends Component {
  render() {
    const { field, title } = this.props;

    return (
      <div className="form__field stay-period">
        <label>
          <FormattedMessage id={title} />
          <span className="error">{field.error && "*"}</span>
        </label>
          {field.error && <div className={'error'} style={{margin: '5px 0'}}>{field.error}</div>}
        <div className="stay-period__values">
          <FormFormattedInput field={field} />
        </div>
      </div>
    );
  }
}

RestrictionPeriod.propTypes = {
  title: PropTypes.string,
  field: PropTypes.string,
};

export default RestrictionPeriod;
