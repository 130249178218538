import React, { Component } from 'react';
import { formatMessage } from 'Utils/formatMessage';

class TopBarSearchBox extends Component {
  render() {
    return (
      <div className='search-box'>
        <form method='post' action='' className='form'>
          <div className='row gutters'>
            <div className='col col-6'>
              <div className='form-item'>
                <input placeholder={formatMessage('shared.search_string')} disabled />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default TopBarSearchBox;
