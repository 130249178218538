import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Status from './Status';
import ExpitedAt from './ExpitedAt';
import Source from './Source';
import CancellationPolicy from './../CancellationPolicy';

@observer
class Description extends Component {
  render() {
    const { reservation } = this.props;
    const { order, tariff, cancellation_policy } = reservation;

    return (
      <div className='section divided'>
        <div className='section__content'>
          <div className='options compact'>
            <Source order={order} />

            <Status order={order} />

            {reservation.isBooked &&
              <ExpitedAt reservation={reservation} />}

            <CancellationPolicy
              tariff={tariff}
              cancellation_policy={cancellation_policy}
            />
          </div>
        </div>
      </div>
    );
  }
}

Description.propTypes = {
  reservation: PropTypes.object.isRequired
};

export default Description;
