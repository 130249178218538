import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';

import Add from './containers/Add/index';
import Show from './containers/Show/index';
import List from './containers/List/index';

@withRouter
@observer
class PriceList extends Component {
  render() {
    return (
      <Switch>
        <Route
          path='/price_lists/new'
          component={Add}
        />

        <Route
          path='/price_lists/:id'
          component={Show}
        />

        <Route
          path='/price_lists'
          component={List}
        />
      </Switch>
    );
  }
}

export default PriceList;
