import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Grid from '../Grid';
import RoomTypes from './RoomTypes';

@withRouter
@inject('dashboardStore')
@observer
class Content extends Component {
  componentDidMount() {
    this.setContentSize();
  }

  componentDidUpdate() {
    this.setContentSize();
  }

  setContentSize = () => {
    const { width } = this.gridEl.getBoundingClientRect();
    this.contentEl.style.width = width + 'px';
  };

  render() {
    const {
      check_in,
      check_out,
      dayWidth,
      room_types
    } = this.props.dashboardStore;

    return (
      <div
        className="room_types"
        ref={(node) => {
          this.contentEl = node;
        }}
      >
        <div
          className="grid"
          ref={(node) => {
            this.gridEl = node;
          }}
        >
          <Grid check_in={check_in} check_out={check_out} dayWidth={dayWidth} />
        </div>

        <RoomTypes room_types={room_types} />
      </div>
    );
  }
}

Content.propTypes = {
  dashboardStore: PropTypes.object
};

export default Content;
