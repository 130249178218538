import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Quantity from './Quantity';
import IsManual from './IsManual';
import Disclaimer from 'Pages/Tariffs/components/Form/Promo/Disclaimer';

import { FormLanguageContext } from 'Context/FormLanguageContext';

@observer
class Promo extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired
  };

  static contextType = FormLanguageContext;

  render() {
    const { form } = this.props;
    const currentLanguage = this.context.currentLanguage;
    return (
      <form className="form">
        <div className="form__body">
          <div className="row">
            <div className="col col-5">
              <IsManual field={form.$('promo.target')} />
              <div className="row">
                <Quantity field={form.$('promo.applied_percent_off')} />
              </div>
            </div>
            <div className="col col-6 offset-1">
              <FormattedMessage id="tariffs.promo.prompt" />
            </div>
          </div>

          <div className="row">
            <div className="col col-7">
              <Disclaimer
                field={form.$(
                  `promo.disclaimer_translations.${currentLanguage}`
                )}
                ruField={form.$('promo.disclaimer_translations.ru')}
                enField={form.$('promo.disclaimer_translations.en')}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Promo;
