import _compact from 'lodash/compact';

export const fullName = ({ first_name, last_name, middle_name }) => {
  let initial = [first_name, middle_name];

  initial = _compact(initial)
    .map(str => str[0].toUpperCase() + '.')
    .join(' ');

  return [last_name, initial].join(' ');
};
