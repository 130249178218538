import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

import Floors from '../Floors';

@withRouter
@observer
class Block extends Component {
  @computed get floors() {
    const { rooms } = this.props.building;

    let data = _groupBy(rooms, (room) => room.floor_number);
    data = _map(data, (rooms, number) => {
      return { rooms, number };
    });

    return data;
  }

  render() {
    const { name } = this.props.building;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          {name}
        </div>

        <Floors floors={this.floors} />
      </div>
    );
  }
}

Block.propTypes = {
  building: PropTypes.object.isRequired
};

export default Block;
