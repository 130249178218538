import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';

import LoginForm from './components/LoginForm';

@inject('store')
@observer
class Login extends Component {
  static propTypes = {
    location: PropTypes.object,
    store: PropTypes.object
  }

  componentDidMount() {
    const { location, store } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (params.email) {
      store.userStore.logout();
    }
  }

  render() {
    const { location, store } = this.props;
    const { authenticated } = store.userStore;
    const refferer = (location.state && location.state.from) || '/';

    return (
      <div className='layout__page'>
        <div className='page__login'>
          <div className='container'>
            <div className='col col-4 push-middle push-center'>
              <LoginForm location={location} />
            </div>
          </div>
        </div>

        {authenticated && <Redirect to={refferer} />}
      </div>
    );
  }
}

export default Login;
