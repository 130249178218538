import { types } from 'mobx-state-tree';
import { Traveller } from './Traveller';

export const Capacity = types.model('Capacity', {
  beds: types.number,
  extra_beds: types.number,

  get totalCount() {
    return this.beds + this.extra_beds;
  }
});

export const Room = types.model('Room', {
  id: types.identifier(types.string),
  number: types.string,
  floor_number: types.number,
  capacity: Capacity,
  travellers: types.optional(types.array(Traveller), []),
  occupancy: types.optional(types.enumeration(['free', 'busy']), 'free'),

  get travellersCount() {
    return self.travellers.length;
  }
});
