import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _includes from 'lodash/includes';

import Card from './Card';

const allowedCards = ['visa_card', 'mastercard_card', 'mir_card'];

@observer
class Cards extends Component {
  render() {
    const { field } = this.props;

    const isSelectable = field.$('allow').value === true;

    const listItems = field.fields.values()
      .filter(item => _includes(allowedCards, item.name))
      .map(item => <Card key={item.id} card={item} isSelectable={isSelectable} />);

    return (
      <div className='content'>
        {listItems}
      </div>
    );
  }
}

Cards.propTypes = {
  field: PropTypes.object.isRequired
};

export default Cards;
