import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import _uniqBy from 'lodash/uniqBy';

import Previews from './Previews';
import Uploads from './Uploads';
import { AddButton } from './Photos.styled';

@observer
class Photos extends Component {
  selectHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.dropzoneRef.open();
  }

  onDrop = (files) => {
    const { field } = this.props;
    const currentFiles = (field.files && field.files[0]) || [];

    let data = currentFiles.concat(files);
    data = _uniqBy(data, (file) => file.name);

    field.onDrop(data);
  }

  render() {
    const { field, images } = this.props;

    const acceptedFiles = [
      'image/png',
      'image/jpeg',
      'image/jpg'
    ].join(', ');

    const className = classNames('field__dropzone__room-type', {
      compact: false
    });

    return (
      <div className='form'>
        <div className='form__body'>
          <div className='form__fields-wrapper'>
            <div className='form__field'>
              {field.files && field.files[0].length && <Previews files={field.files[0]} />}
            </div>

            <div className='form__field'>
              {images.size > 0 && <Uploads images={images} />}
            </div>

            <Dropzone
              accept={acceptedFiles}
              multiple
              className={className}
              activeClassName='field__dropzone--active'
              rejectClassName='field__dropzone--reject'
              ref={(node) => { this.dropzoneRef = node; }}
              onDrop={this.onDrop}
            >
              <div className='content'>
                <AddButton onClick={this.selectHandler}>
                  <FormattedMessage id='shared.choose_file' />
                </AddButton>

                <p>
                  <FormattedMessage id='shared.drop_image_here' />
                </p>
              </div>
            </Dropzone>
          </div>
        </div>
      </div>
    );
  }
}

Photos.propTypes = {
  field: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired
};

export default Photos;
