import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

import FeedType from "./FeedType";
import MealOption from "./MealOption";
import Disclaimer from "./Disclaimer";

@inject("tariffsStore")
@observer
class FeedTypeAndMealOption extends Component {
  render() {
    const { form, currentLanguage } = this.props;
    return (
      <form className="form">
        <div className="form__body">
          <div className="row">
            <div className="col col-5">
              <MealOption field={form.$("meal_option")} />
            </div>

            <div className="col col-6 offset-1">
              <FeedType field={form.$("feed_type")} />
            </div>
          </div>

          <div className="row">
            <div className="col col-5">
              <Disclaimer
                field={form.$(
                  `meal_disclaimer_translations.${currentLanguage}`
                )}
                ruField={form.$(`meal_disclaimer_translations.ru`)}
                enField={form.$(`meal_disclaimer_translations.en`)}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FeedTypeAndMealOption.propTypes = {
  form: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default FeedTypeAndMealOption;
