import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from './Form';

import LanguageControl from 'Shared/components/LanguageFormControl';
import { UserProvider } from 'Context/FormLanguageContext';

@observer
class GeneralInfo extends Component {
  state = {
    language: 'ru'
  };

  onClickHandler = (language) => {
    if (this.state.language === language) return;

    this.setState({ language: language });
  }

  render() {
    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className='groups__item'>
          <div className='groups__item-header'>
            <FormattedMessage id='hotel.general_information' />
          </div>

          <div className='layout__language-action'>
            <div className='content_hotel'>
              <div className='control'>
                <LanguageControl
                  onClick={this.onClickHandler}
                />
              </div>
            </div>
          </div>

          <div className='groups__item-content'>
            <div className='segment'>
              <Form currentLanguage={this.state.language} />
            </div>
          </div>
        </div>
      </UserProvider>
    );
  }
}

export default GeneralInfo;
