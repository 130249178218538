import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import LengthStayArrivalBasedRestrictionForm from "./LengthStayArrivalBasedRestrictionForm";
import {
  Wrapper,
  FormActions,
  ContentWrapper,
} from "./LengthStayArrivalBasedRestriction.styled";

@observer
class LengthStayArrivalBasedRestrictionList extends Component {
  render() {
    const { data, isOpened, form, onClose, onEdit, onDelete } = this.props;

    return (
      <Wrapper>
        {data.map((item, index) => (
          <Wrapper key={index}>
            <ContentWrapper>
              <Wrapper>
                <FormActions>
                  <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.dateRangeInput" />
                  <div>{item.start_date?.split('-')?.reverse()?.join('-')}</div>-<div>{item.end_date?.split('-')?.reverse()?.join('-')}</div>
                </FormActions>
                <FormActions>
                  <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.minLOSArrival.title" />
                  <div>{item.min_length_of_stay}</div>
                </FormActions>
                <FormActions>
                  <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.maxLOSArrival.title" />
                  <div>{item.max_length_of_stay}</div>
                </FormActions>
              </Wrapper>
              <FormActions>
                <div className="button gray" onClick={() => onEdit(index)}>
                  <FormattedMessage id="shared.edit" />
                </div>
                <div className="button red" onClick={() => onDelete(index)}>
                  <FormattedMessage id="shared.remove" />
                </div>
              </FormActions>
            </ContentWrapper>

            {isOpened === index + 1 && (
              <div className="row">
                <LengthStayArrivalBasedRestrictionForm
                  form={form}
                  onClose={onClose}
                  period={item}
                  index={index}
                />
              </div>
            )}
          </Wrapper>
        ))}
      </Wrapper>
    );
  }
}

LengthStayArrivalBasedRestrictionList.propTypes = {
  data: PropTypes.array,
  field: PropTypes.string,
  isOpened: PropTypes.any,
  form: PropTypes.object,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default LengthStayArrivalBasedRestrictionList;
