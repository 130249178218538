import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import GeneralInfo from 'Pages/PriceList/components/Form/GeneralInfo/index';
import TariffsInfo from 'Pages/PriceList/components/Form/TariffsInfo/index';
import SourceIsNotConfirmable from '../SourceIsNotConfirmable';

@inject('priceListState')
@withRouter
@observer
class List extends Component {
  @computed get sourceShouldBeConfirmed() {
    const { priceListState } = this.props;
    const { settings } = priceListState.$('source').value;

    return _isEmpty(settings) ? true : settings.should_be_confirmed;
  }

  render() {
    const source = this.props.priceListState.$('source').value;

    return (
      <div className='page__body wide'>
        {this.sourceShouldBeConfirmed
          ? <>
            <GeneralInfo />
            <TariffsInfo />
            </>

          : <>
            <SourceIsNotConfirmable source={source} />
            </>}
      </div>
    );
  }
}

export default List;
