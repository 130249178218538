import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import TariffHeader from './TariffHeader';
import TariffContent from './TariffContent';

@observer
class Tariff extends Component {
  render() {
    const { tariff, isActive } = this.props;

    return (
      <div className='tariffs__item'>
        {isActive
          ? <TariffContent {...this.props} />
          : <TariffHeader {...this.props} />}
      </div>
    );
  }
}

Tariff.defaultProps = {
  isActive: false
};

Tariff.propTypes = {
  tariff: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired
};

export default Tariff;
