import { types, applySnapshot, resolveIdentifier, destroy } from 'mobx-state-tree';
import instance from 'connection/instance';
import _groupBy from 'lodash/groupBy';
import { objectClean } from 'Utils/objectClean';
import { Service } from './models/Service';

export const ServicesStore = types.model('ServicesStore', {
  services: types.optional(types.array(Service), []),
  edited: types.maybe(types.reference(Service)),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get categories() {
    let data = _groupBy(this.services, 'category');

    data = Object.entries(data).map(([k, v]) => {
      return { id: k, name: v[0].category_name, services: v };
    });

    return data;
  },

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    return instance.get('/api/services', { params: params })
      .then(response => this.resetStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  findOne(service_id) {
    const service = resolveIdentifier(
      Service, this.services, service_id
    );

    return service;
  },

  destroyOne(service) {
    instance.delete(`/api/services/${service.id}`)
      .then(response => this.removeFromCollection(service))
      .catch(error => console.log(error));
  },

  saveService(form) {
    let data = form.values();
    data = objectClean(data);
    data = { service: data };

    const promise = form.$('id').isEmpty
      ? this.createService(data)
      : this.updateService(form.$('id').value, data);

    return promise
      .then(() => form.reset())
      .catch(error => console.log(error));
  },

  createService(data) {
    return instance.post('/api/services', data)
      .then(response => this.addToCollection(response));
  },

  updateService(id, data) {
    return instance.put(`/api/services/${id}`, data)
      .then(response => this.addToCollection(response));
  },

  clear() {
    const data = { services: [] };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { data } = response;

    applySnapshot(this, { services: data.services });
    return data;
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  parseResponse(response) {
    const { data } = response;

    let categories = _groupBy(data.services, 'category');
    categories = Object.entries(categories).map(([k, v]) => {
      return { id: k, services: v };
    });

    return { categories: categories };
  },

  addToCollection(response) {
    const { service } = response.data;
    const entry = this.findOne(service.id);

    entry
      ? applySnapshot(entry, service)
      : this.services.push(service);
  },

  removeFromCollection(service) {
    destroy(service);
  }
});
