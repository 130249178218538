import { types } from 'mobx-state-tree';
import moment from 'Utils/moment';

import { Traveller } from './Traveller';

export const Event = types.model('Event', {
  name: types.string,
  description: types.string,
  slot_id: types.identifier(types.string),
  reservation_id: types.string,
  check_in: types.string,
  check_out: types.string,
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  traveller: types.maybe(Traveller),

  get date_range() {
    const check_in = moment(this.check_in);
    const check_out = moment(this.check_out);

    const range = [
      check_in.format('D MMM'),
      check_out.format('D MMM YYYY')
    ];

    return range;
  },

  get nights() {
    return Math.ceil(this.diff / (86400 * 1000));
  },

  get diff() {
    const check_in = moment(this.check_in, 'YYYY-MM-DD hh:mm:ss');
    const check_out = moment(this.check_out, 'YYYY-MM-DD hh:mm:ss');

    return check_out.diff(check_in);
  }
});
