import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import store from 'store';

import Language from './Language';

const LANGUAGE = ['ru', 'en'];

@inject('store')
@observer
class MenuAction extends Component {
  onLogout = () => {
    const { userStore } = this.props.store;
    userStore.logout();
  }

  onClickHandler = (language) => {
    const currentLanguage = store.get('language');

    if (currentLanguage === language) return;

    store.set('language', language);
    window.location.reload();
  }

  render() {
    const currentLanguage = store.get('language');

    const listItems = LANGUAGE.map(language => {
      return (
        <Language
          key={language}
          language={language}
          isActive={language === currentLanguage}
          onClickHandler={this.onClickHandler}
        />
      );
    });

    return (
      <div className='control__language'>
        {listItems}
      </div>
    );
  }
}

export default MenuAction;
