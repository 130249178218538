import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import QuantitySelect from 'Shared/form/QuantitySelect';

@observer
class Quantity extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired
  }

  render() {
    const { field } = this.props;

    return (
      <QuantitySelect field={field} />
    );
  }
}

export default Quantity;
