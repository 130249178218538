import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ErrorIcon,
  AccesedIcon,
  WarningIcon,
  CloseIcon
} from 'Components/ui/Icons';

import theme from './Message.theme';

const Icons = {
  accepted: <AccesedIcon />,
  warning: <WarningIcon />,
  danger: <ErrorIcon />
};

const Text = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-left: 12px;
  white-space: wrap;

`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled.a`
  font-size: 20px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  ${(props) => theme(props)}

  ${Content} + ${Action} {
    margin-left: 12px;
  }
`;

const Message = ({ variant, children, onClose, className }) => {
  const hasClose = !!onClose;

  const handleClose = (e) => {
    if (onClose) onClose(e);
  };

  return (
    <Wrapper
      variant={variant}
      className={className}
    >
      <Content>
        <Icon>{Icons[variant]}</Icon>

        <Text>
          {children}
        </Text>
      </Content>

      {hasClose && (
        <Action onClick={handleClose}>
          <CloseIcon />
        </Action>
      )}
    </Wrapper>
  );
};

Message.defaultProps = {
  className: '',
  variant: 'accepted'
};

Message.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['warning', 'accepted', 'danger']),
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func
};

export default styled(Message)``;
