import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { computed, reaction } from 'mobx';
import { withRouter } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar/index';
import TopBarContent from './components/TopBarContent/index';
import PageBody from './components/PageBody/index';

import TariffsStore from 'Stores/TariffsNewStore/List';
import PriceListStore from 'Stores/PriceListStore';
import PriceListState, { fields } from 'Pages/PriceList/components/PriceListState';

@withRouter
@observer
class Search extends Component {
  priceListState = new PriceListState(fields);

  priceListStore = PriceListStore.create();

  tariffsStore = TariffsStore.create();

  @computed get isLoading() {
    return this.tariffsStore.isPending ||
      this.priceListStore.isPending;
  }

  componentDidMount() {
    this.datesHandler = reaction(
      () => {
        const start_date = this.priceListState.$('start_date').value;
        const end_date = this.priceListState.$('end_date').value;
        const source = this.priceListState.$('source').value;
        const status = this.priceListState.$('status').value;

        return { start_date, end_date, source, status };
      },
      (params) => {
        this.tariffsStore.fetch(params);
        this.priceListState.clearTariffs();
        this.replaceLocationParams();
      }
    );

    const { search } = this.props.location;
    this.priceListState.setFromSearchParams(search);
  }

  componentWillUnmount() {
    this.datesHandler();
    this.tariffsStore.clear();
    this.priceListStore.clear();
  }

  replaceLocationParams = () => {
    const { history } = this.props;

    const location = Object.assign({}, history.location, {
      search: this.priceListState.getQueryString()
    });

    history.push(location);
  }

  render() {
    return (
      <Provider
        tariffsStore={this.tariffsStore}
        priceListState={this.priceListState}
        priceListStore={this.priceListStore}
      >
        <div className='layout__page'>
          <TopBar isLoading={this.isLoading}>
            <TopBarContent form={this.priceListState} />
          </TopBar>

          <div className='layout__page-content'>
            <PageBody />
          </div>
        </div>
      </Provider>
    );
  }
}

export default Search;
