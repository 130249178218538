import _debounce from 'lodash/debounce';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, Provider } from 'mobx-react';
import { autorun } from 'mobx';
import { withRouter, Switch, Route } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageContent from './components/PageContent';
import Info from '../Info';
import Conversation from '../Conversation';

import searchForm from './components/SearchForm';
import filterForm from './components/FilterForm';
import sortForm from './components/SortForm';
import OrdersStore from 'Stores/OrdersStore/List';

@withRouter
@observer
class List extends Component {
  componentDidMount() {
    const { searchForm, filterForm, ordersStore } = this.props;
    const resetOrders = _debounce(ordersStore.reset, 500);

    this.formUpdater = autorun(() => {
      const filters = filterForm.values().tags;
      const sort = sortForm.values();
      const search = searchForm.values();

      resetOrders({ filters, sort, search });
    });
  }

  componentWillUnmount() {
    const { ordersStore } = this.props;
    ordersStore.clear();
    this.formUpdater();
  }

  render() {
    const { ordersStore, filterForm, sortForm, searchForm } = this.props;

    return (
      <Provider
        searchForm={searchForm}
        sortForm={sortForm}
        filterForm={filterForm}
        ordersStore={ordersStore}
      >
        <div className='layout__page'>
          <TopBar isLoading={ordersStore.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            {ordersStore.orders && <PageContent />}

            <Switch>
              <Route
                path='/orders/:id/info'
                component={Info}
              />

              <Route
                path='/orders/:id/conversation'
                component={Conversation}
              />
            </Switch>

          </div>
        </div>
      </Provider>
    );
  }
}

List.defaultProps = {
  filterForm: filterForm,
  sortForm: sortForm,
  searchForm: searchForm,
  ordersStore: OrdersStore.create()
};

List.propTypes = {
  filterForm: PropTypes.object.isRequired,
  sortForm: PropTypes.object.isRequired,
  searchForm: PropTypes.object.isRequired,
  ordersStore: PropTypes.object.isRequired
};

export default List;
