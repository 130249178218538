import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Service from './Service';

@observer
class Services extends Component {
  render() {
    const { labels, services } = this.props;
    const hasServices = services.length > 0;

    const listItems = services.map((item) => {
      return <Service key={item.name} service={item} />;
    });

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {labels.services_included}
          </span>

          {hasServices
            ? listItems
            : <span className='value'><FormattedMessage id='tariff.services_not_included' /></span>}
        </div>
      </div>
    );
  }
}

Services.propTypes = {
  services: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default Services;
