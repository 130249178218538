import React, { Component } from 'react';
import PropTypes from 'prop-types';

import currencySymbolMap from 'Utils/currencySymbolMap';
import accounting from 'accounting-js';

class Price extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired
  }

  render() {
    const {
      value: { amount, currency }
    } = this.props;

    const symbol = currencySymbolMap(currency);

    const format = '%v %s';

    const text = accounting.formatMoney(amount, {
      symbol,
      format,
      thousand: ' ',
      precision: 2
    });

    return <span className='price'>{text}</span>;
  }
}

export default Price;
