import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Form from 'Pages/Tariffs/components/Form/Quotas';

@observer
class QuotasEdit extends Component {
  render() {
    const { quotas } = this.props;

    return (
      <div className='quotas'>
        <Form quotas={quotas} />
      </div>
    );
  }
}

QuotasEdit.propTypes = {
  quotas: PropTypes.object.isRequired
};

export default QuotasEdit;
