import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { HotelFeedTypesStore } from 'stores/HotelFeedTypesStore';
import Loader from 'pages/Hotel/components/Loader';
import store from 'store';

@inject('hotelStore')
@observer
class FeedType extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    hotelFeedTypesStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialFeedType = form.$('feed_types').value;

        this.setState({
          selectedTypes: initialFeedType
        });
        this.fetchTypes();
      }
    );
  }

  state = {
    selectedTypes: []
  };

  fetchTypes = () => {
    const { hotelFeedTypesStore } = this.props;
    hotelFeedTypesStore.fetchChecked();
  };

  onSelect = currentTag => {
    const { selectedTypes } = this.state;
    const { hotelFeedTypesStore, form } = this.props;
    const selectedType = hotelFeedTypesStore
      .toJSON()
      .types.filter(type => type.tag === currentTag)[0];
    const isTypeSelected = this.state.selectedTypes.some(
      type => type.tag === selectedType.tag
    );
    let selectedData = [];

    if (isTypeSelected) {
      const index = selectedTypes.findIndex(
        type => type.tag === selectedType.tag
      );
      const copyStateData = [...selectedTypes];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedTypes, selectedType];
    }

    this.setState({
      selectedTypes: selectedData
    });

    form.update({ feed_types: selectedData });
  };

  render() {
    const { hotelFeedTypesStore } = this.props;
    const typesData = hotelFeedTypesStore.toJSON().types;
    const generalLanguage = store.get('language');

    const checkboxList = typesData.map((data, index) => {
      return (
        <div key={data.tag}>
          <input
            type='checkbox'
            key={data.tag}
            value={data.tag}
            className='input radio_button'
            checked={this.state.selectedTypes.some(
              type => type.tag === data.tag
            )}
          />
          <span className='radio_button_name'>
            {data.name_translations[generalLanguage]}
          </span>
        </div>
      );
    });

    return (
      <div>
        <div className='form__field--title'>
          <label>
            <FormattedMessage id='hotel.feedType' />
          </label>
        </div>
        <div>
          {hotelFeedTypesStore.isFetched
            ? (
              <>
                {checkboxList.map((checkbox, index) => (
                  <div
                    key={checkbox.key}
                    className={
                    index + 1 !== typesData.length ? 'radio_list_item' : 'radio_list_item_last'
                  }
                    onClick={() => this.onSelect(checkbox.key)}
                  >
                    {checkbox}
                  </div>
                ))}
              </>
              )
            : (
              <Loader />
              )}
        </div>
      </div>
    );
  }
}

FeedType.defaultProps = {
  form: PropTypes.object.isRequired,
  hotelFeedTypesStore: HotelFeedTypesStore.create()
};

export default FeedType;
