import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/ui/Button';
import { FormattedMessage } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import { observer } from 'mobx-react';
import { FormActions, DateRangePickerWrapper, FormContent } from './StopSales.styled';
import ArrowLeftIcon from 'react-icons/lib/md/chevron-left';
import ArrowRightIcon from 'react-icons/lib/md/chevron-right';
import CloseIcon from 'react-icons/lib/md/close';
import moment from 'Utils/moment';

class ArrowIcon extends Component {
  render() {
    return <span>—</span>;
  }
}

@observer
class Form extends Component {
  state = {
    availablePeriod: {}
  }

  componentDidMount() {
    const { form, index } = this.props;
    const values = form.$('stop_sales').values();
    const date = values[index];

    if (date) {
      form.startDateValue = moment(date.from);
      form.endDateValue = moment(date.to);
    } else {
      form.startDateValue = null;
      form.endDateValue = null;
    }

    this.setState({
      availablePeriod: {
        availableFrom: moment(form.values().available_period.from).format('YYYY-MM-DD'),
        availableTo: moment(form.values().available_period.to).format('YYYY-MM-DD')
      }
    });
  }

  handleFocusChange = (focusedInput) => {
    const { form } = this.props;
    form.focusedInput = focusedInput;
  }

  handleCancel = (e) => {
    const { form } = this.props;
    e.preventDefault();
    form.isAddStopSalePeriod = false;
    form.isOpenFormIndex = null;
  }

  handleDateChange = (dates) => {
    const { form } = this.props;
    const { startDate, endDate } = dates;

    if (startDate) {
      form.startDateValue = startDate;
    }

    if (endDate) {
      form.endDateValue = endDate;
    }
  }

  handleSaveNewPeriod = (e) => {
    e.preventDefault();
    const { form, index } = this.props;
    const isChange = index === 0 || Boolean(index);

    if (!isChange) {
      form.saveNewStopSalesPeriod();
    } else {
      form.changeStopSalesPeriod(index);
    }
  }

  isOutsideRange = (day) => {
    const {
      availablePeriod: {
        availableFrom,
        availableTo
      }
    } = this.state;
    return !moment(day.format('YYYY-MM-DD')).isBetween(availableFrom, availableTo, undefined, '[]');
  }

  render() {
    const { form } = this.props;
    return (
      <FormContent>
        <DateRangePickerWrapper>
          <DateRangePicker
            startDate={form.startDateValue}
            endDate={form.endDateValue}
            className='w40'
            focusedInput={form.focusedInput}
            startDateId='start-date'
            endDateId='end-date'
            onFocusChange={this.handleFocusChange}
            onDatesChange={this.handleDateChange}
            daySize={32}
            hideKeyboardShortcutsPanel
            disabled={false}
            readOnly={false}
            startDatePlaceholderText={<FormattedMessage id='shared.value' />}
            endDatePlaceholderText={<FormattedMessage id='shared.value' />}
            navPrev={<ArrowLeftIcon />}
            navNext={<ArrowRightIcon />}
            customCloseIcon={<CloseIcon />}
            customArrowIcon={<ArrowIcon />}
            isOutsideRange={this.isOutsideRange}
          />
        </DateRangePickerWrapper>

        <FormActions>
          <Button
            onClick={this.handleSaveNewPeriod}
            disabled={!(form.startDateValue && form.endDateValue)}
          > <FormattedMessage id='shared.confirm' />
          </Button>

          <Button
            variant='secondary'
            onClick={this.handleCancel}
          > <FormattedMessage id='shared.cancel' />
          </Button>
        </FormActions>
      </FormContent>
    );
  }
};

Form.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default Form;
