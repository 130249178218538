import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Item from './Item';

@inject('priceboardStore')
@observer
class Header extends Component {
  render() {
    const { startDay, endDay, dayWidth, grid } = this.props.priceboardStore;

    const listItems = grid.map((item) => {
      return (
        <Item key={item.id} item={item} width={dayWidth} />
      );
    });

    return listItems;
  }
}

export default Header;
