import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

@observer
class Preview extends Component {
  render() {
    const img_src = this.props.file.preview;

    const style = {
      backgroundImage: `url(${img_src})`
    };

    return (
      <div className='photo'>
        <div
          className='image'
          style={style}
        />
      </div>
    );
  }
}

Preview.propTypes = {
  file: PropTypes.object.isRequired
};

export default Preview;
