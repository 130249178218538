import React from 'react';
import PropTypes from 'prop-types';

import tariffsMapper from './utils/tariffsMapper';

import Tariff from './Tariff';
import TariffsCaption from './TariffsCaption';
import TariffAddAction from './TariffAddAction';
import useTariffs from './hooks/useTariffs';

function Tariffs({ tariffs, fetchTariffs }) {
  const mappedTariffs = tariffsMapper({ tariffs });
  const { getTariff, removeTariff, activateTariff, deactivateTariff } = useTariffs();

  const listItems = mappedTariffs.map((item) => {
    return (
      <Tariff
        key={item.id}
        getTariff={getTariff}
        tariff={item}
        removeTariff={removeTariff}
        fetchTariffs={fetchTariffs}
        activateTariff={activateTariff}
        deactivateTariff={deactivateTariff}
      />
    );
  });

  const hasTariffs = tariffs.length > 0;

  return (
    <div className='tariffs'>
      {hasTariffs && <TariffsCaption tariffs={tariffs} />}

      {hasTariffs &&
        <div className='tariffs__content'>
          {listItems}
        </div>}

      <TariffAddAction tariff={tariffs[0]} />
    </div>
  );
}

Tariffs.propTypes = {
  tariffs: PropTypes.array.isRequired,
  fetchTariffs: PropTypes.func
};

export default Tariffs;
