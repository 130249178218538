import styled from 'styled-components';
import PropTypes from 'prop-types';

import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';

import { FormattedMessage } from 'react-intl';

const Title = styled.div`
  font-size: 30px;
  line-height: 25px;
  color: #000000;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  max-width: 290px;
  color: #000000;
  margin-top: 12px;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  padding: 30px;
  width: 434px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;

  ${Button} + ${Button} {
    margin-left: 12px;
  }
`;

function ConfirmModal({ className, title, description, onApply, onCancel }) {
  return (
    <Modal>
      <Wrapper className={className}>
        <Title>{title}</Title>

        <Description>{description}</Description>

        <Buttons>
          <Button onClick={onApply} variant='danger'>
            <FormattedMessage id='shared.remove' />
          </Button>

          <Button onClick={onCancel} variant='secondary'>
            <FormattedMessage id='shared.cancel' />
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onApply: PropTypes.func,
  onCancel: PropTypes.func
};

export default styled(ConfirmModal)``;
