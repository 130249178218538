import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Tariffs from '../Tariffs';
import Loader from './../../../components/Loader';

@inject('tariffsStore')
@observer
class TariffsInfo extends Component {
  render() {
    const { tariffsStore } = this.props;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='tariffs.available' />
        </div>
        <div className='groups__item-content'>
          <div className='segment'>
            {tariffsStore.isPending && <Loader />}

            {tariffsStore.tariffs.length > 0
              ? <Tariffs tariffs={tariffsStore.tariffs} />
              : <p className='text gray'>
                <FormattedMessage id='price_lists.no_tariffs' />
              </p>}
          </div>
        </div>
      </div>
    );
  }
}

export default TariffsInfo;
