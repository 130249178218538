import axios from 'axios';
import Qs from 'qs';
import notAuthorized from './interceptors/notAuthorized';

const instanceBlob = axios.create({
  responseType: 'blob',
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' })
});

instanceBlob.interceptors.response.use(...notAuthorized);

export default instanceBlob;
