import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import store from "store";

@observer
class ObjectOptionsTree extends Component {
  render() {
    const typeOption = this.props.hotel.card_options;
    const generalLanguage = store.get("language");

    const loop = (data) => {
      return data.map((item, index) => {
        return (
          <div
            key={item.id}
            className={index + 1 !== typeOption.length ? "radio_list_item" : ""}
          >
            {item.name_translations[generalLanguage]}
          </div>
        );
      });
    };

    const treeNodes = loop(typeOption);

    return <div className="form">{treeNodes}</div>;
  }
}

ObjectOptionsTree.propTypes = {
  hotel: PropTypes.object.isRequired,
};

export default ObjectOptionsTree;
