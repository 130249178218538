import styled from 'styled-components';
import theme from './Icons.theme';

import { ReactComponent as AdultOnExtraBedSVG } from 'Icons/adult_on_extra_bed.svg';
import { ReactComponent as AdultOnMainBedSVG } from 'Icons/adult_on_main_bed.svg';
import { ReactComponent as AloneSVG } from 'Icons/alone.svg';
import { ReactComponent as ChildOnExtraBedSVG } from 'Icons/child_on_extra_bed.svg';
import { ReactComponent as ChildOnMainBedSVG } from 'Icons/child_on_main_bed.svg';
import { ReactComponent as BabySVG } from 'Icons/baby.svg';
import { ReactComponent as BedSVG } from 'Icons/bed.svg';
import { ReactComponent as CancelIconSVG } from 'Icons/cancel_icon.svg';
import { ReactComponent as CloseSVG } from 'Icons/close.svg';
import { ReactComponent as DoubleArrowSVG } from 'Icons/double_arrow.svg';
import { ReactComponent as DoubleOccupationSVG } from 'Icons/double_occupation.svg';
import { ReactComponent as EditIconSVG } from 'Icons/edit_icon.svg';
import { ReactComponent as MastercardSVG } from 'Icons/mastercard.svg';
import { ReactComponent as MenuBackSVG } from 'Icons/menu-back.svg';
import { ReactComponent as MenuBurgerSVG } from 'Icons/menu-burger.svg';
import { ReactComponent as MirSVG } from 'Icons/mir.svg';
import { ReactComponent as MoneySVG } from 'Icons/money.svg';
import { ReactComponent as OneMoreSVG } from 'Icons/one-more.svg';
import { ReactComponent as RussiaSVG } from 'Icons/russia.svg';
import { ReactComponent as SingleOccupationSVG } from 'Icons/single_occupation.svg';
import { ReactComponent as TwinsSVG } from 'Icons/twins.svg';
import { ReactComponent as UnitedKingdomSVG } from 'Icons/united-kingdom.svg';
import { ReactComponent as VisaSVG } from 'Icons/visa.svg';
import { ReactComponent as SelectArrowSVG } from 'Icons/select_arrow.svg';
import { ReactComponent as ErrorSVG } from 'Icons/error.svg';
import { ReactComponent as AccesedSVG } from 'Icons/accesed.svg';
import { ReactComponent as WarningSVG } from 'Icons/warning.svg';
import { ReactComponent as OpenRowIcon } from 'Icons/open_row.svg';
import { ReactComponent as CloseRowIcon } from 'Icons/close_row.svg';
import { ReactComponent as ArrowIconSVG } from 'Icons/arrow.svg';
import { ReactComponent as CopyIconSvg } from 'Icons/copy.svg';
import { ReactComponent as DoneSvg } from 'Icons/done.svg';
import { ReactComponent as StopSvg } from 'Icons/stop.svg';
import { ReactComponent as DocumentsCopySvg } from 'Icons/documents_copy.svg';

export const DoneIcon = styled(DoneSvg)`${theme}`;
export const CopyIcon = styled(CopyIconSvg)`${theme}`;
export const ArrowIcon = styled(ArrowIconSVG)`${theme}`;
export const AdultOnExtraBedIcon = styled(AdultOnExtraBedSVG)`${theme}`;
export const AdultOnMainBedIcon = styled(AdultOnMainBedSVG)`${theme}`;
export const AloneIcon = styled(AloneSVG)`${theme}`;
export const ChildOnExtraBedIcon = styled(ChildOnExtraBedSVG)`${theme}`;
export const ChildOnMainBedIcon = styled(ChildOnMainBedSVG)`${theme}`;
export const BabyIcon = styled(BabySVG)`${theme}`;
export const BedIcon = styled(BedSVG)`${theme}`;
export const CancelIconIcon = styled(CancelIconSVG)`${theme}`;
export const CloseIcon = styled(CloseSVG)`${theme}`;
export const DoubleArrowIcon = styled(DoubleArrowSVG)`${theme}`;
export const DoubleOccupationIcon = styled(DoubleOccupationSVG)`${theme}`;
export const EditIconIcon = styled(EditIconSVG)`${theme}`;
export const MastercardIcon = styled(MastercardSVG)`${theme}`;
export const MenuBackIcon = styled(MenuBackSVG)`${theme}`;
export const MenuBurgerIcon = styled(MenuBurgerSVG)`${theme}`;
export const MirIcon = styled(MirSVG)`${theme}`;
export const MoneyIcon = styled(MoneySVG)`${theme}`;
export const OneMoreIcon = styled(OneMoreSVG)`${theme}`;
export const RussiaIcon = styled(RussiaSVG)`${theme}`;
export const SingleOccupationIcon = styled(SingleOccupationSVG)`${theme}`;
export const TwinsIcon = styled(TwinsSVG)`${theme}`;
export const UnitedKingdomIcon = styled(UnitedKingdomSVG)`${theme}`;
export const VisaIcon = styled(VisaSVG)`${theme}`;
export const SelectArrowIcon = styled(SelectArrowSVG)`${theme}`;
export const ErrorIcon = styled(ErrorSVG)`${theme}`;
export const AccesedIcon = styled(AccesedSVG)`${theme}`;
export const WarningIcon = styled(WarningSVG)`${theme}`;
export const CloseRow = styled(CloseRowIcon)`${theme}`;
export const OpenRow = styled(OpenRowIcon)`${theme}`;
export const StopIcon = styled(StopSvg)`${theme}`;
export const DocumentsCopyIcon = styled(DocumentsCopySvg)`${theme}`;
