import { formatMessage } from 'Utils/formatMessage';

const getStatus = (status) => {
  switch (status) {
    case 'booked':
      return {
        label: 'status booked',
        text: formatMessage('orders.statuses.booked')
      };
    case 'confirmed':
      return {
        label: 'status confirmed',
        text: formatMessage('orders.statuses.confirmed')
      };
    case 'cancelled':
      return {
        label: 'status cancelled',
        text: formatMessage('orders.statuses.cancelled')
      };
    default:
      return {
        label: 'status',
        text: formatMessage('orders.statuses.unknown')
      };
  }
};

export default getStatus;
