import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SingleSelect from 'Shared/form/SingleSelect';

@observer
class Seats extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className='form__fields'>
        <SingleSelect
          field={form.$('beds')}
          options={form.bedsOptions}
          selectFirst
          className='w25'
        />

        <SingleSelect
          field={form.$('extra_beds')}
          options={form.extraBedsOptions}
          selectFirst
          disabled={!form.isMainBedsOccupied}
          className='w25'
        />
      </div>
    );
  }
}

Seats.propTypes = {
  form: PropTypes.object.isRequired
};

export default Seats;
