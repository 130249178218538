import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import RateName from './RateName';

@observer
class Rate extends Component {
  render() {
    const { rate } = this.props;

    return (
      <div className='rate'>
        <div className='rate__header'>
          <RateName rate={rate} />
        </div>
      </div>
    );
  }
}

Rate.propTypes = {
  rate: PropTypes.object.isRequired
};

export default Rate;
