import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  FilterInput,
  StyledCloseFilterIcon,
  StyledFilterPicker
} from './FiltersControls.styled';

@withRouter
@inject('filterForm')
@observer
class FiltersControls extends React.Component {
  static propTypes = {
    filterForm: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable filter = null;

  handleFiltersChange = (filter) => {
    this.filter = filter;
  }

  handleFilterCancel = () => {
    this.filter = null;
  }

  handleFilterAdd = (type, label, value) => {
    const { filterForm } = this.props;
    filterForm.addTag({ type, label, value });
    this.filter = null;
  }

  get renderComponent() {
    const { filterForm } = this.props;
    const Component = this.filter.component;

    const props = this.filter.fields.toJS().reduce((acc, cur) => {
      acc[cur] = filterForm.$(cur);
      return acc;
    }, {});

    return (
      <Component
        {...props}
        onChange={this.handleFilterAdd}
        tags={filterForm.$('tags').value}
      />
    );
  }

  render() {
    const { filterForm } = this.props;

    return (
      <>
        {this.filter
          ? (
            <FilterInput>
              {this.renderComponent}

              <StyledCloseFilterIcon onClick={this.handleFilterCancel} />
            </FilterInput>
            )
          : (
            <StyledFilterPicker onChange={this.handleFiltersChange} filterForm={filterForm} selectedTags={filterForm.$('tags').value} />
            )}

      </>
    );
  }
}

export default FiltersControls;
