const fields = [
  'check_in',
  'check_out',
  'periods',
  'action',
  'room_types[]',
  'room_types[].id',
  'room_types[].name',
  'room_types[].selected',
  'room_types[].quantity',
  'sources[]',
  'sources[].id',
  'sources[].name',
  'sources[].selected'
];

const rules = {
  periods: 'array|required|min:1',
  action: 'string|required'
};

const types = {
  'sources[].selected': 'checkbox',
  'room_types[].selected': 'checkbox'
};

export default {
  fields,
  rules,
  types
};
