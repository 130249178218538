import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
class Item extends Component {
  render() {
    const { item, width } = this.props;
    const date = item.date;

    const num = date.date();
    const day = date.format('dd');
    const month = date.format('MMMM');

    const dayOfWeek = date.day();
    const isDayOff = (dayOfWeek == 0 || dayOfWeek == 6);
    const isMonthStart = num == 1;

    const showNotification = false;

    const selfClassName = classNames('item', {
      'week-day': !isDayOff,
      'week-start': dayOfWeek == 1,
      'week-end': dayOfWeek == 5,
      'day-off': isDayOff
    });

    const selfStyle = {
      width: width
    };

    return (
      <div className={selfClassName} style={selfStyle}>
        <div className='header'>
          <div className='header__value'>
            <span className='num'>{num}</span>
            <span className='day'>{day}</span>
            {isMonthStart && <div className='month'>{month}</div>}
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
};

export default Item;
