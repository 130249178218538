import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import Slot from './Slot';

@observer
class Slots extends Component {
  componentDidMount() {
    const { slots } = this.props;
    if (!slots.size) return;

    const firstSlot = slots.fields.values()[0];
    this.toggleSelected(firstSlot);
  }

  @observable selected = undefined

  toggleSelected = (slot) => {
    if (this.selected === slot.id) {
      this.selected = undefined;
      return;
    }

    this.selected = slot.id;
  }

  render() {
    const { form, slots } = this.props;

    const listItems = slots.map((item, i) => {
      return (
        <Slot
          key={item.id}
          number={i + 1}
          slot={item}
          form={form}
          isOpenned={!!this.selected && this.selected === item.id}
          triggerHandler={this.toggleSelected}
        />
      );
    });

    return (
      <div className='form__fields-group'>
        {listItems}
      </div>
    );
  }
}

Slots.propTypes = {
  slots: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default Slots;
