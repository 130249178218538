import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from 'Utils/tariffsRateIcon';
import classNames from 'classnames';
import { formatMessage } from 'Utils/formatMessage';
import styled, { css } from 'styled-components';

const Price = styled.div`
  line-height: 0.9rem;
  vertical-align: middle;
  height: 1.2rem;
`;

const Icon = styled.div`
  margin-right: 0.25rem;
  height: 1.2rem;
`;

const Wrapper = styled.div`
  ${({ free }) => free && css`
    ${Price} {
      color: #CD5E5E;
    }

    ${Icon} {
      path {
        fill: #CD5E5E !important;
      }
    }
  `}
`;

function Rate({ rate: { price, type }, className }) {
  const getPriceDescription = () => {
    if (price.amount > 0) return price.amount;

    if (price.amount < 0) return '-';

    return formatMessage('shared.free');
  };

  const icon = getIcon(type);

  const wrapperClassName = classNames('price-block__item alone', className);

  return (
    <Wrapper className={wrapperClassName} free={price.amount === 0}>
      <Icon>
        {icon}
      </Icon>

      <Price>
        {getPriceDescription()}
      </Price>
    </Wrapper>
  );
}

Rate.propTypes = {
  className: PropTypes.string.isRequired,
  rate: PropTypes.object.isRequired
};

export default Rate;
