import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
class FormInput extends React.Component {
  static defaultProps = {
    type: 'text',
    placeholder: null,
    className: ''
  };

  static propTypes = {
    field: PropTypes.object.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    const { field, type, placeholder, className } = this.props;

    const inputCls = classNames(className, 'input', {
      error: field.error && !field.focused
    });

    return (
      <input
        className={inputCls}
        {...field.bind({ type, placeholder })}
      />
    );
  }
}

export default FormInput;
