import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

import Conversation from './Conversation';

const ConversationStore = types.model('ConversationStore', {
  conversation: types.maybe(Conversation),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isPending() {
    return self.state === 'pending';
  },

  get isDone() {
    return self.state === 'done';
  },

  get isError() {
    return self.state === 'error';
  }
}, {
  fetch(order_id) {
    self.setState('pending');

    return instance.get(`/api/orders/${order_id}/conversation`)
      .then(response => self.applySnapshot(response))
      .then(() => self.setState('done'))
      .catch(self.errorHandler);
  },

  setState(state) {
    self.state = state;
  },

  applySnapshot(response) {
    const { data } = response;

    applySnapshot(self, data);
    return self;
  },

  errorHandler(error) {
    self.setState('error');
    return Promise.reject(error);
  }
});

export default ConversationStore;
