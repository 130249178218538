import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

@inject('registryStore')
@observer
class Price extends Component {
  constructor(props) {
    super(props);

    this.editHandler = this.editHandler.bind(this);
  }

  componentDidMount() {
    const { registryStore, selectableKey } = this.props;
    registryStore.register(selectableKey, ReactDOM.findDOMNode(this));
  }

  componentWillUnmount() {
    const { registryStore, selectableKey } = this.props;
    registryStore.unregister(selectableKey);
  }

  editHandler(e) {
    e.preventDefault();

    const { item, registryStore } = this.props;
    registryStore.selectItems([[item.id, item]]);
  }

  @computed get selected() {
    const { selectableKey, registryStore } = this.props;
    return !registryStore.hasSelected && registryStore.isSelected(selectableKey);
  }

  render() {
    const { price } = this.props.item;

    const className = classNames('content', {
      selected: this.selected
    });

    return (
      <div
        className={className}
        onClick={this.editHandler}
      >
        {price.amount}
      </div>
    );
  }
}

Price.propTypes = {
  item: PropTypes.object.isRequired
};

export default Price;
