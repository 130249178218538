import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.el = document.createElement('div');
    this.el.className = 'dashboard__portal';
  }

  componentDidMount() {
    const { modalRoot } = this.props;
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    const { modalRoot } = this.props;
    modalRoot.removeChild(this.el);
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

Modal.propTypes = {
  modalRoot: PropTypes.object.isRequired
};

export default Modal;
