import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import RoomTypesStore from 'Stores/RoomTypesStore/List';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageBody from './components/PageBody';
import { FormattedMessage } from 'react-intl';
import { ListHeaderTitle } from './index.styled';

@withRouter
@observer
class List extends Component {
  componentDidMount() {
    const { roomTypesStore } = this.props;
    roomTypesStore.fetch();
  }

  @computed get isFetched() {
    const { roomTypesStore } = this.props;
    return roomTypesStore.room_types.length > 0;
  }

  render() {
    const { roomTypesStore } = this.props;
    return (
      <div className='layout__page'>
        <TopBar isLoading={roomTypesStore.isPending}>
          <ListHeaderTitle>
            <FormattedMessage id='room_types.self' />
          </ListHeaderTitle>
          <TopBarContent room_types={roomTypesStore.room_types} />
        </TopBar>

        <div className='layout__page-content'>
          {this.isFetched &&
            <PageBody room_types={roomTypesStore.room_types} />}
        </div>
      </div>
    );
  }
}

List.defaultProps = {
  roomTypesStore: RoomTypesStore.create()
};

List.propTypes = {
  roomTypesStore: PropTypes.object
};

export default List;
