import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  border-bottom: 2px solid #d2d1cc;
`;

export const TabItem = styled.div`
  padding-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;
  position: relative;
  color: #999;

  ${(p) =>
    p.$active &&
    css`
      color: #000;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 2px;
        background: #000;
        border-radius: 5px;
      }
    `}
`;

export const Tabs = ({ TABS, currentTab, handleOnClickTab }) => {
  return (
    <Wrapper>
      {!!TABS?.length &&
        TABS.map(({ title, value }) => (
          <TabItem
            key={value}
            $active={value === currentTab}
            onClick={handleOnClickTab(value)}
          >
            {title}
          </TabItem>
        ))}
    </Wrapper>
  );
};

Tabs.propTypes = {
  TABS: PropTypes.array,
  currentTab: PropTypes.string,
  handleOnClickTab: PropTypes.func
};
