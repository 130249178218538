import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

@withRouter
@observer
class Actions extends Component {
  render() {
    return (
      <div className='groups__item'>
        <div className='segment'>
          <div className='buttons'>
            <Link
              to='/hotel/edit'
              className='button green'
            >
              <FormattedMessage id='shared.change' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Actions;
