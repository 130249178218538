import React from 'react';
import styled, { AnyStyledComponent, css } from 'styled-components';

const baseStyle = css`
  font-style: normal;
  font-weight: normal;
  color: #000000;
  margin: 0;
  padding: 0;
`;

const H1 = styled.h1`
  ${baseStyle};

  margin-top: 8px;
  margin-bottom: 28px;

  font-size: 30px;
  line-height: 1.2;
`;

const H2 = styled.h2`
  ${baseStyle};

  font-size: 20px;
  line-height: 1.2;
`;

const H3 = styled.h3`
  ${baseStyle};

  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const ComponentMapper = {
  1: H1,
  2: H2,
  3: H3
};

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  level: 1 | 2 | 3
}>

function Heading({ level = 2, className, children }: Props) {
  const Component: AnyStyledComponent = ComponentMapper[level];

  return (
    <Component className={className}>
      {children}
    </Component>
  );
}

export default Heading;
