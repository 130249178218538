import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CustomCheckbox from '../CustomCheckbox';

const Source = ({ field, setAllSources }) => {
  const handleChange = (e) => {
    field.$('selected').value = e.target.checked;
    setAllSources({ allSources: false });
  };
  return (
    <div className="fields">
      <div className="field">
        <CustomCheckbox
          onChange={handleChange}
          id={field.$('selected').id}
          label={field.$('name').value}
          checked={field.$('selected').value}
        />
      </div>
    </div>
  );
};

Source.propTypes = {
  field: PropTypes.object.isRequired,
  setAllSources: PropTypes.func.isRequired
};

export default observer(Source);
