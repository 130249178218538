import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ArrowIcon, CloseIcon } from 'Components/ui/Icons';
import Element from './Element';

const DuplicateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  max-width: 50%;

  &:nth-last-child(1) {
    ${ArrowIcon} {
      display: none;
    }
  }
`;

const LeftContainer = styled.div`
  display: flex;
  width: 97%;
  justify-content: flex-start;
`;

const RightContainer = styled.div`
  cursor: pointer;
  font-size: 16px;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  position: relative;

  ${ArrowIcon} {
    margin-left: 13px;
    margin-right: 12px;
    align-self: flex-start;
    font-size: 15px;
    margin-top: 3px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    border-bottom: 2px solid #EAE9E4;
  }
`;

function TariffContentHeader({ className, history, tariffsList, match: { params: { id } } }) {
  const handleOpenPreview = (e) => {
    e.preventDefault();

    history.push('/tariffs');
  };

  return (
    <Wrapper className={className}>
      <LeftContainer>
        {tariffsList.map(element => (
          <DuplicateWrapper key={element.id}>
            <Element
              tariff={element}
              isActive={element.id === id}
            />

            <ArrowIcon />
          </DuplicateWrapper>
        ))}
      </LeftContainer>

      <RightContainer onClick={handleOpenPreview}>
        <CloseIcon />
      </RightContainer>
    </Wrapper>
  );
}

TariffContentHeader.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  tariffsList: PropTypes.array,
  match: PropTypes.object
};

export default styled(withRouter(TariffContentHeader))``;
