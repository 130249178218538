import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Building from './Building';

@withRouter
@observer
class Buildings extends Component {
  render() {
    const { buildings } = this.props;

    let number = 0;

    const listItems = buildings.map((item) => {
      number = number + 1;

      return (
        <Building
          key={item.id}
          number={number}
          building={item}
        />
      );
    });

    return (
      <div className='groups'>
        <div className='groups__item'>
          <div className='groups__item-header'>
            <FormattedMessage id='buildings.self' />
          </div>
          <div className='groups__item-content'>
            <div className='buildings'>
              {listItems}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Buildings.propTypes = {
  buildings: PropTypes.object.isRequired
};

export default Buildings;
