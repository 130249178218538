import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import FormItemText from "Shared/form/FormItemText";

@observer
class Disclaimer extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    ruField: PropTypes.object.isRequired,
    enField: PropTypes.object.isRequired,
  };

  handleRemove = (e) => {
    const { ruField, enField } = this.props;
    e.preventDefault();
    ruField.set("");
    enField.set("");
  };

  render() {
    const { field } = this.props;

    return (
      <FormItemText
        field={field}
        rows={3}
        isLocalizeField
        size="medium"
        handleRemove={this.handleRemove}
      />
    );
  }
}

export default Disclaimer;
