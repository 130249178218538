import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withRouter } from 'react-router-dom';

import Label from './Label';

@withRouter
@inject('dashboardStore', 'quotasStore')
@observer
class Labels extends Component {
  render() {
    const {
      quotasStore,
      dashboardStore: { room_types }
    } = this.props;

    const listItems = toJS(room_types).map((room_type) => {
      const rt = quotasStore.findOne(room_type.id);
      const sources = rt ? toJS(rt.sources) : [];

      return (
        <Label key={room_type.id} room_type={room_type} sources={sources} />
      );
    });

    return <div className="grid">{listItems}</div>;
  }
}

Labels.propTypes = {
  quotasStore: PropTypes.object,
  dashboardStore: PropTypes
};

export default Labels;
