import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "Utils/moment";

import TopBar from "Shared/components/TopBar";
import TopBarContent from "./components/TopBarContent";
import PageBody from "./components/PageBody";

import useRoomTypes from "./hooks/useRoomTypes";

const TABS = [
  {
    title: "Действующие тарифы",
    value: "default",
  },
  {
    title: "Архивные тарифы",
    value: "archive",
  },
];

function List(props) {
  const { history, location } = props;
  const isTariffList = location?.pathname === "/tariffs";

  const [currentTab, setCurrentTab] = useState(TABS[0].value);
  const operation = currentTab === "default" ? "gte" : "lt";

  const [{ isPending, roomTypes }, { fetchTariffs }] = useRoomTypes({
    status: currentTab,
    filters: [
      {
        field: "available_period.to",
        operation,
        value: moment().subtract(1, "months").format("YYYY-MM-DD"),
      },
    ],
  });

  const handleOnClickTab = (tab) => () => {
    if (tab !== currentTab && isTariffList) {
      setCurrentTab(tab);
      history.push(`${location.pathname}?status=${tab}`);
    }
  };

  return (
    <div className="layout__page">
      <TopBar isLoading={isPending}>
        <TopBarContent
          TABS={TABS}
          handleOnClickTab={handleOnClickTab}
          currentTab={currentTab}
          isTariffList={isTariffList}
        />
      </TopBar>

      <div className="layout__page-content">
        {(!isPending || roomTypes.length > 0) && (
          <PageBody roomTypes={roomTypes} fetchTariffs={fetchTariffs} />
        )}
      </div>
    </div>
  );
}

export default withRouter(List);
