import styled, { css } from 'styled-components';

import { Popover } from 'react-tiny-popover';

import MdAdd from 'react-icons/lib/md/add';

export const StyledPopover = styled(Popover)`
  border: 1px solid silver;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: 3px;
  padding: 10px;
  min-width: 160px;
  z-index: 10;
  
  && {
    margin: 0;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  color: #666;
  line-height: 30px;
  :hover {
    color: #000;
  }

  ${({ loading }) => loading && css`
    position: relative;
    cursor: default;
    pointer-events: none;
    border: none;

    &:before {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: calc(100%);
      height: calc(100%);
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: inherit;
    }

    &:after {
      position: absolute;
      content: '';
      border: 2px solid #95d795;
      border-top: 2px solid #41AB42;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      animation: spin 1s linear infinite;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: calc(50% - 0.5em);
    }
  `};
`;

export const Control = styled.div`

`;

export const AddIcon = styled(MdAdd)`
  font-size: 20px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearAllFiltersControl = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  color: silver;
  cursor: pointer;
  transition: color 0.25s;
  user-select: none;
  margin-left: 30px;
`;
