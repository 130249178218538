import { types } from 'mobx-state-tree';

export const TariffPrice = types.model('TariffPrice', {
  amount: types.number,
  currency: types.string
});

export const TariffRateRule = types.model('TariffRateRule', {
  id: types.identifier(types.string),
  date: types.maybe(types.string),
  start_date: types.maybe(types.string),
  end_date: types.maybe(types.string),
  price: types.maybe(TariffPrice)
});
