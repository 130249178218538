import styled from 'styled-components';
import Button from 'Components/ui/Button';

export const Footer = styled.div`
  height: 94px;
  width: 100%;
  background-color: #fff;
  padding: 24px 30px 24px 40px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
`;

export const FooterLeft = styled.div`
  display: flex;
  width: 50%;
  ${Button} + ${Button} {
    margin-left: 10px;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  width: 860px;
  display: flex;
`;
