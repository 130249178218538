import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

@withRouter
@inject('priceListStore', 'store')
@observer
class TopBarActionBox extends Component {
  confirmHandler = (e) => {
    e.preventDefault();
    const { priceListStore } = this.props;

    priceListStore.upload(priceListStore.price_list.id, {})
      .then(_response => this.navigateTo('/price_lists'))
      .catch(error => this.errorSubmitHandler(error));
  }

  navigateTo = (path) => {
    const { history } = this.props;
    history.push(path);
  }

  errorSubmitHandler(error) {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  render() {
    const { price_list } = this.props.priceListStore;
    const needConfirm = price_list && price_list.status == 'created';
    return (
      <div className='action-box'>
        {needConfirm && <button
          type='submit'
          className='button green'
          onClick={this.confirmHandler}
                        >
          <FormattedMessage id='price_lists.confirm' />
                        </button>}
      </div>
    );
  }
}

export default TopBarActionBox;
