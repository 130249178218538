import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import SingleSelectObj from 'Shared/form/SingleSelectObj';

const inputStyle = {
  minWidth: '16rem'
};

@observer
class Status extends Component {
  @computed get statusOptions() {
    return [{ id: 'created', name: 'Новый' }];
  }

  render() {
    const { field } = this.props;

    return (
      <SingleSelectObj
        field={field}
        valueKey='id'
        labelKey='name'
        simpleValue
        showLabel={false}
        options={this.statusOptions}
        className='w5'
        style={inputStyle}
      />
    );
  }
}

Status.propTypes = {
  field: PropTypes.object.isRequired
};

export default Status;
