import React from 'react';
import PropTypes from 'prop-types';
import moment from 'Utils/moment';

const format = 'DD.MM.YYYY';

function TariffAvailablePeriod({ className, period }) {
  if (!period) return null;

  const { from, to } = period;
  const fromDate = moment(from).format(format);
  const toDate = moment(to).format(format);

  return (
    <span className={className}>
      {fromDate} &mdash; {toDate}
    </span>
  );
}

TariffAvailablePeriod.defaultProps = {
  className: ''
};

TariffAvailablePeriod.propTypes = {
  period: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default TariffAvailablePeriod;
