import {
  types,
  applySnapshot,
  applyPatch,
  resolveIdentifier,
  detach
} from 'mobx-state-tree';
import instance from 'connection/instance';
import moment from 'Utils/moment';
import { Tariff, TariffRate, TariffRateValue } from './models/Tariff';
import _ from 'lodash';

export const PriceboardType = types.model('PriceboardType', {
  id: types.identifier(types.string),
  name: types.string,
  tariffs: types.optional(types.array(Tariff), [])
});

export const PriceboardGridItem = types.model('PriceboardGridItem', {
  id: types.identifier(types.string),
  date: types.frozen,

  get num() {
    return this.date.date();
  },

  get month() {
    return this.date.format('MMMM');
  },

  get dayOfWeek() {
    return this.date.day();
  },

  get isDayOff() {
    return (this.dayOfWeek === 0 || this.dayOfWeek === 6);
  },

  get isMonthStart() {
    return this.num === 1;
  }
});

export const PriceboardStore = types.model('PriceboardStore', {
  startPosition: types.optional(types.number, 0),
  startDay: types.optional(types.frozen, moment().startOf('day')),
  endDay: types.optional(types.frozen, moment().add(4, 'months').endOf('day')),
  currentOffset: types.optional(types.number, 0),
  dayStep: types.optional(types.number, 14),
  dayWidth: types.optional(types.number, 50),
  offset: types.maybe(types.number),
  room_types: types.optional(types.array(PriceboardType), []),
  grid: types.maybe(types.array(PriceboardGridItem)),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get scrollStep() {
    return this.dayStep * this.dayWidth;
  },

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  afterCreate() {
    const snapshot = this.gridData();
    applySnapshot(this, snapshot);
  },

  setEndDay(momentObj) {
    this.endDay = momentObj;
  },

  setStartDay(momentObj) {
    this.endDay = momentObj;
  },

  gridData() {
    const { startDay, endDay } = this;

    const currentDay = startDay.clone();
    const items = [];

    while (!currentDay.isSame(endDay, 'day')) {
      const date = currentDay.clone();
      currentDay.add(1, 'days');

      items.push({ id: date.format('DD-MM-YYYY'), date: date });
    }

    return { startDay: startDay, endDay: endDay, grid: items };
  },

  addGridItemsAfter() {
    const currentDay = this.endDay.clone();
    const endDay = currentDay.clone().add(this.dayStep, 'days');

    const patch = [];

    while (!currentDay.isSame(endDay, 'day')) {
      const date = currentDay.clone();
      currentDay.add(1, 'days');

      patch.push({
        op: 'add',
        path: '/grid/-',
        value: { id: date.format('DD-MM-YYYY'), date: date }
      });
    }

    this.setEndDay(currentDay);
    applyPatch(this, patch);
  },

  removeGridItemsAfter() {
    const count = this.grid.length;
    if (count - this.dayStep < 31) return false;

    const currentDay = this.endDay.clone();
    const endDay = currentDay.clone().subtract(this.dayStep, 'days');

    const patch = [];
    let i = 0;

    while (!currentDay.isSame(endDay, 'day')) {
      currentDay.subtract(1, 'days');
      i++;

      const item = count - i;
      patch.push({ op: 'remove', path: `/grid/${item}` });
    }

    setTimeout(() => {
      applyPatch(this, patch);
      this.setEndDay(currentDay);
    }, 1000);
  },

  setPosition(offset) {
    this.currentOffset = this.startPosition - offset;
  },

  setStartPosition(value) {
    this.startPosition = value;
  },

  setDayWith(value) {
    this.dayWidth = value;
  },

  setOffset(value) {
    this.offset = value;
  },

  toLeft() {
    const offset = this.dayStep * this.dayWidth;
    this.removeGridItemsAfter();
    this.setOffset(-offset);
  },

  toRight() {
    const offset = this.dayStep * this.dayWidth;
    this.addGridItemsAfter();
    this.setOffset(offset);
  },

  resetOffset() {
    this.offset = null;
  },

  convertMsToPx(diff) {
    return diff / 1000 * this.dayWidth / 86400;
  },

  calcEventOffset(eventDay) {
    const { startDay } = this;
    const diff = startDay.diff(eventDay);

    return this.convertMsToPx(diff);
  },

  fetch(params = { include: ['tariffs'] }) {
    this.setState('pending');

    instance.get('/api/tariffs', { params: params })
      .then((response) => this.updateStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  destroy() {
    detach(this);
  },

  updateStore(response) {
    const { status, data } = response;

    if (status === 200) {
      const value = data.room_types || [];
      const patch = { op: 'replace', path: '/room_types', value: value };

      applyPatch(this, patch);
    }
  },

  applyRuleChanges(changes) {
    _.chain(changes)
      .map(change => this.applyRulesChange(change))
      .groupBy(value => value.rate_id)
      .each((rules, rate_id) => this.updateRateRules(rate_id, rules))
      .value();
  },

  applyRulesChange(change) {
    const { id, patch } = change;

    const rateValue = this.resolveRuleIdentifier(id);
    applyPatch(rateValue, patch);

    return rateValue.toJSON();
  },

  updateRateRules(rate_id, rules) {
    const rate = resolveIdentifier(TariffRate, this, rate_id);
    if (rate) rate.update({ rules: rules });
  },

  resolveRuleIdentifier(id) {
    return resolveIdentifier(TariffRateValue, this, id);
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  errorHandler(error) {
    this.state = 'error';
    return Promise.reject(error);
  }
});
