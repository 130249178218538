import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Cards from './Cards';

@observer
class ByCard extends React.Component {
  render() {
    const { field } = this.props;

    const clsName = classNames('bank_cards', {
      disabled: !field.$('allow').value
    });

    return (
      <div className='checkbox__field' data-id='hotelPaymentByCard'>
        <div className='key'>
          <input
            className='input'
            {...field.$('allow').bind({
              type: 'checkbox',
              checked: field.$('allow').value
            })}
          />
        </div>
        <div className='value'>
          <label htmlFor={field.$('allow').id}>
            {field.label}
          </label>
          <div className={clsName}>
            <div className='header'>
              <FormattedMessage id='hotel.card_types_accepted' />
            </div>
            <Cards field={field} />
          </div>
        </div>
      </div>
    );
  }
}

ByCard.propTypes = {
  field: PropTypes.object.isRequired
};

export default ByCard;
