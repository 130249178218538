const fields = [
  'images[]',
  'images[].file'
];

const rules = {
  'images[]': 'array',
  'images[].file': 'required|string'
};

export default {
  fields,
  rules
};
