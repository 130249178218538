import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import CloseIcon from 'react-icons/lib/md/close';

@observer
class Navigation extends Component {
  render() {
    return (
      <div className='infoblock__navigation'>
        <div className='content'>
          <div className='navigation__item' />

          <div className='navigation__item'>
            <Link to='/dashboard'>
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
