import React from 'react';
import PropTypes from 'prop-types';

import {
  SliderMarkContainer,
  SelectedValue,
  SliderShortMark,
  ShortMarkValue
} from 'Styles/styled';

const ShortMarkSlider = ({ ...props }) => {
  const { count, value, type } = props;

  const getMark = (count, value, type) => {
    if (count === value) {
      return (
        <SelectedValue size='short'> {count} </SelectedValue>
      );
    }

    if (type && (count === 1 || count === 23)) {
      return <ShortMarkValue>{count}</ShortMarkValue>;
    }
  };

  return (
    <SliderMarkContainer>
      <SliderShortMark />
      {getMark(count, value, type)}
    </SliderMarkContainer>
  );
};

ShortMarkSlider.propTypes = {
  count: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired
};

export default ShortMarkSlider;
