import { computed, observable } from "mobx";

import _uniq from "lodash/uniq";
import _uniqueId from "lodash/uniqueId";
import _remove from "lodash/remove";
import _cloneDeep from "lodash/cloneDeep";

import { isEqualParent, isEqualChild } from "./ratesCompare";

import FormState from "Shared/form/FormState";
import baseFields from "./baseFields";
import tourFields from "./tourFields";
import packageFields from "./packageFields";

const childRates = {
  main: ["main", "no"],
  extra: ["extra", "no"],
  no: ["extra", "no", "main"],
};

class TariffState extends FormState {
  @computed get minOccupationCount() {
    return 0;
  }

  @computed get maxOccupationCount() {
    return this.bedsCount;
  }

  @computed get bedsCount() {
    const count = this.has("room_type") ? this.$("room_type.beds").value : 0;

    return Number(count);
  }

  @computed get extraBedsCount() {
    const count = this.has("room_type")
      ? this.$("room_type.extra_beds").value
      : 0;

    return Number(count);
  }

  @computed get hasExtraBeds() {
    return this.extraBedsCount > 0;
  }

  @computed get roomTypeName() {
    return this.has("room_type") ? this.$("room_type.name").value : null;
  }

  @computed get roomsCount() {
    return this.has("room_type") ? this.$("room_type.count").value : 0;
  }

  @computed get tariffType() {
    return this.has("type") && this.$("type").value;
  }

  @computed get getBillingHour() {
    return this.has("billing_hour") && this.$("billing_hour").value;
  }

  @computed get showCancellationPolicy() {
    return this.$("source.settings.has_cancellation_policy").value;
  }

  @computed get hasDuplicateRules() {
    let rules = this.$("cancellation_policy.policy_rules").values();
    rules = rules.map((d) => [d.deadline.amount, d.deadline.unit].join("-"));

    return _uniq(rules).length !== rules.length;
  }

  @computed get isTour() {
    return this.tariffType === "tour";
  }

  @computed get isBase() {
    return this.tariffType === "base";
  }

  @computed get isPackage() {
    return this.tariffType === "package";
  }

  isInclude(rValues) {
    let errors = [];
    const rates = this.$("rates")
      .values()
      .filter((rate) => !rate._destroy && rate.id !== rValues.id);

    if (rValues.age_group === "adult") {
      rates
        .filter((rate) => rate.age_group === "adult")
        .some((rate) => {
          const result = isEqualParent(rate, rValues);
          if (result.length) errors = result;
        });
    }

    if (rValues.age_group === "child") {
      rates
        .filter(
          (rate) =>
            rate.age_group === "child" &&
            rate.occupation === rValues.occupation
        )
        .some((rate) => {
          const result = isEqualChild(rate, rValues);
          if (result.length) errors = result;
        });
    }

    return errors;
  }

  @observable.ref periods = [];
  @observable copyPeriods = [];
  @observable editedPeriodId = null;
  @observable isAdding = false;
  @observable dataRangeError = null;
  @observable isPeriodCopied = false;
  @observable ratesIsOpenForm = [];
  @observable editedRateId = null;

  setPeriods(periods) {
    this.periods = periods.map((p) => ({
      ...p,
      id: _uniqueId("tariff-period"),
    }));
  }

  closeAllRatesForms(rates) {
    const changedRates = rates || this.ratesIsOpenForm;
    this.ratesIsOpenForm = [...changedRates].map((rate) => {
      return {
        id: rate.id,
        isOpenForm: false,
      };
    });

    this.editedRateId = null;
  }

  openRateForm(editedRate) {
    const newRatesIsOpenForm = [...this.ratesIsOpenForm].map((rate) => {
      const rateCopy = { ...rate };
      if (rateCopy.id === editedRate.id) {
        rateCopy.isOpenForm = !rateCopy.isOpenForm;
      } else {
        rateCopy.isOpenForm = false;
      }
      return rateCopy;
    });

    this.ratesIsOpenForm = [...newRatesIsOpenForm];
  }

  removePeriodById(id) {
    _remove(this.periods, (period) => period.id === id);
    this.periods = [...this.periods];
  }

  copyPeriod(periodId) {
    if (!this.isPeriodCopied) {
      this.isAdding = true;
      const copiedPeriod = _cloneDeep(this.getPeriodById(periodId));
      const lastPeriod = this.periods[this.periods.length - 1];
      const startDate = lastPeriod
        ? lastPeriod.end_date
        : this.$("available_period.from").value.split("T")[0];

      copiedPeriod.id = _uniqueId("tariff-period");
      copiedPeriod.start_date = startDate;
      copiedPeriod.end_date = "";
      copiedPeriod.rates = [...copiedPeriod.rates].map((rate) => ({
        ...rate,
        id: _uniqueId("rate-period"),
      }));

      this.periods = [...this.periods, copiedPeriod];
      this.editedPeriodId = copiedPeriod.id;
      this.isPeriodCopied = true;
    }
  }

  cancelEdit = () => {
    // Отмена редактирования добавляемого периода - удаляет его
    if (this.isAdding) this.removePeriodById(this.editedPeriodId);
    this.dataRangeError = null;
    this.isAdding = false;
    this.isPeriodCopied = false;
    this.editedPeriodId = null;
    this.copyPeriods = this.periods;
  };

  getPeriodById = (id) => this.periods.find((p) => p.id === id);

  editPeriod = (periodId) => {
    this.copyPeriods = this.periods;
    // Если есть добавляемый и редактируемый в данный момент, то удалить его
    if (this.isAdding) this.removePeriodById(this.editedPeriodId);
    this.isAdding = false;
    this.editedPeriodId = periodId;
    this.isPeriodCopied = true;
  };

  addPeriods() {
    this.copyPeriods = this.periods;
    this.isAdding = true;
    this.isPeriodCopied = true;
    const rates = this.$("rates").value;
    const ratesCopied = [...rates].map((rate) => ({
      ...rate,
      id: _uniqueId("rate-period"),
    }));
    const periodId = _uniqueId("tariff-period");
    const lastPeriod = this.periods[this.periods.length - 1];
    const startDate = lastPeriod
      ? lastPeriod.end_date
      : this.$("available_period.from").value.split("T")[0];

    const newPeriod = {
      id: periodId,
      start_date: startDate,
      end_date: "",
      rates: ratesCopied,
    };

    this.periods = [...this.periods, newPeriod];
    this.editedPeriodId = periodId;
    return periodId;
  }

  dateChange = (dates) => {
    let { startDate, endDate } = dates;
    const editedPeriod = this.getPeriodById(this.editedPeriodId);

    if (startDate && endDate) {
      this.dataRangeError = null;
    }

    if (startDate) startDate = startDate.format("Y-MM-DD");
    if (endDate) endDate = endDate.format("Y-MM-DD");
    editedPeriod.start_date = startDate || "";
    editedPeriod.end_date = endDate || "";
  };

  addRate(values) {
    let rates = this.$("rates").values();
    const newRate = _cloneDeep(values);

    const newPeriods = [...this.periods].map((period, index) => {
      const newRateCopy = _cloneDeep(values);
      newRateCopy.id = _uniqueId(`rate-period-${index}`);
      const newRates = [...period.rates, { ...newRateCopy }];
      return { ...period, rates: newRates };
    });

    const newRateOpenForm = {
      id: newRate.id,
      isOpenForm: false,
    };

    this.ratesIsOpenForm = [...this.ratesIsOpenForm, newRateOpenForm];
    this.periods = [...newPeriods];
    rates = [...rates, newRate];

    this.$("rates").add(values);
  }

  rateChange = (currentRate, newRate) => {
    const { price, ...otherAttr } = newRate;
    const newPeriods = [...this.periods].map((period) => {
      const newRates = [...period.rates].map((rate) => {
        return rate.type === currentRate.type
          ? { ...rate, ...otherAttr }
          : { ...rate };
      });

      return { ...period, rates: newRates.sort(this.sortRatesStore) };
    });

    this.periods = [...newPeriods];
  };

  ratePriceChange = (changedRate, value) => {
    const newPeriods = [...this.periods].map((period) => {
      const newRates = [...period.rates].map((rate) => {
        return rate.id === changedRate.id && period.id === this.editedPeriodId
          ? { ...rate, price: { ...rate.price, amount: Number(value) } }
          : { ...rate };
      });

      return { ...period, rates: [...newRates] };
    });

    this.periods = [...newPeriods];
  };

  removeRate = (rate) => {
    rate.del();
    const removeRateType = rate.$("type").value;
    const removeRateId = rate.$("id").value;

    const newPeriods = [...this.periods].map((period) => {
      const newRates = period.rates.filter(
        (rate) => rate.type !== removeRateType
      );

      const newRatesIsOpenForm = [...this.ratesIsOpenForm].filter(
        (newRate) => removeRateId !== newRate.id
      );

      this.ratesIsOpenForm = [...newRatesIsOpenForm];

      return { ...period, rates: newRates.sort(this.sortRatesStore) };
    });

    this.periods = [...newPeriods];
  };

  sortRatesStore = (p, n) => {
    if (p.type > n.type) return 1;
    if (p.type < n.type) return -1;
    return 0;
  };

  sortRates = (p, n) => {
    const pv = p.value;
    const nv = n.value;
    this.sortRatesStore(pv, nv);
  };

  savePeriod = () => {
    const editedPeriod = this.getPeriodById(this.editedPeriodId);
    const hasError = editedPeriod.rates.find(
      (r) => r.age_group === "adult" && r.price.amount === 0
    );

    if (hasError) return;

    const { start_date, end_date } = editedPeriod;

    if (!start_date || !end_date) {
      this.dataRangeError = "Необходимо выбрать диапозон";
      return;
    }

    this.dataRangeError = null;
    this.editedPeriodId = null;
    this.isAdding = false;
    this.isPeriodCopied = false;
  };

  @observable focusedInput = null;
  @observable startDateValue = null;
  @observable endDateValue = null;
  @observable isAddStopSalePeriod = false;
  @observable isOpenFormIndex = null;

  deleteStopSalesPeriods = () => {
    this.update({ stop_sales: [] });
  };

  saveNewStopSalesPeriod = () => {
    const values = this.$("stop_sales").values();
    this.update({
      stop_sales: [
        ...values,
        {
          from: this.startDateValue.format("Y-MM-DD"),
          to: this.endDateValue.format("Y-MM-DD"),
        },
      ],
    });
    this.isAddStopSalePeriod = false;
    this.startDateValue = null;
    this.endDateValue = null;
    this.isOpenFormIndex = null;
  };

  changeStopSalesPeriod = (index) => {
    const arr = this.$("stop_sales")
      .values()
      .map((period, perIndex) => {
        if (index === perIndex) {
          return {
            from: this.startDateValue.format("Y-MM-DD"),
            to: this.endDateValue.format("Y-MM-DD"),
          };
        }
        return period;
      });
    this.update({ stop_sales: [...arr] });
    this.startDateValue = null;
    this.endDateValue = null;
    this.isOpenFormIndex = null;
  };

  deleteStopSalePeriod = (index) => {
    const arr = this.$("stop_sales").values();
    _remove(arr, (period, perIndex) => perIndex === index);
    this.update({ stop_sales: [...arr] });
  };
}

export { baseFields, tourFields, packageFields };
export default TariffState;
