import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { computed } from "mobx";
import { FormattedMessage } from "react-intl";
import arrayToTree from "array-to-tree";

import AmenitiesStore from "Stores/AmenitiesStore";
import AmenityTree from "./AmenityTree";

@observer
class AmenitiesAndServices extends Component {
  @computed get rootTree() {
    const { isFetched, amenities } = this.props.amenitiesStore;

    return isFetched
      ? arrayToTree(amenities.toJSON(), {
          parentProperty: "parent_tag",
          customID: "tag",
        })
      : [];
  }

  componentDidMount() {
    const { amenitiesStore } = this.props;
    amenitiesStore.fetch();
  }

  render() {
    const { form } = this.props;

    return (
      <div className="groups__item">
        <div className="groups__item-header">
          <FormattedMessage id="hotel.amenities_and_services" />
        </div>

        <div className="groups__item-content">
          <div className="segment">
            <p className="muted">
              <FormattedMessage id="hotel.add_amenities_hint" />
            </p>

            {this.rootTree.length > 0 && (
              <AmenityTree form={form} amenities={this.rootTree} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AmenitiesAndServices.defaultProps = {
  amenitiesStore: AmenitiesStore.create(),
};

export default AmenitiesAndServices;
