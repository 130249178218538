import { IntlProvider, addLocaleData } from 'react-intl';
import { reaction } from 'mobx';
import AppStore from './stores/AppStore';
import ChatStore from './stores/ChatStore';
import { flattenMessages } from 'utils/flattenMessages';
import localeData from './locales/locales';

import ru from 'react-intl/locale-data/ru';
import en from 'react-intl/locale-data/en';
import { HotelStore } from './stores/HotelStore';

addLocaleData([...ru, ...en]);

const store = AppStore.create();
const chatStore = new ChatStore();
const hotelStore = HotelStore.create();

reaction(
  () => store.userStore.authenticated,
  value => value && hotelStore.fetch(),
  { fireImmediately: true }
);

reaction(
  () => store.userStore.authenticated,
  value => value ? chatStore.connect() : chatStore.disconnect(),
  { fireImmediately: true }
);

const { language } = store.userStore.setting;
let messages = localeData[language] || localeData.ru;
messages = flattenMessages(messages);

const { intl } = new IntlProvider({
  locale: language,
  messages: flattenMessages(messages)
}, {}).getChildContext();

const test = {};

export { store, chatStore, hotelStore, intl, test };
