import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RoomType from './RoomType';

@withRouter
@inject('priceboardStore')
@observer
class Labels extends Component {
  render() {
    const { room_types } = this.props.priceboardStore;

    const listItems = room_types.map((room_type) => {
      return <RoomType key={room_type.id} room_type={room_type} />;
    });

    return (
      <div className='room_types'>
        {listItems}
      </div>
    );
  }
}

export default Labels;
