import styled from 'styled-components';

export const PageBody = styled.div`
  background-color: #FFF;
  width: 100%;
  padding: 0.2rem 2.5rem;
  min-width: 53.5rem;
  min-height: 100%;
  box-sizing: border-box;
`;
