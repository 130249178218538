import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import MdZoomOutMap from 'react-icons/lib/md/zoom-out-map';
import MdDelete from 'react-icons/lib/md/delete';

@observer
class Upload extends Component {
  zoomPhoto = (e) => {
    e.preventDefault();

    const { imgIndex, image, onZoomHandler } = this.props;
    onZoomHandler(imgIndex, image);
  }

  removePhoto = (e) => {
    e.preventDefault();

    const { image, imgIndex, onRemoveHandler } = this.props;
    onRemoveHandler(imgIndex, image);
  }

  render() {
    const { image } = this.props;

    const style = {
      backgroundImage: `url(${image.$('thumb.url').value})`
    };

    return (
      <div className='photo'>
        <div
          style={style}
          className='image'
        />
        <div className='actions'>
          <a className='zoom' onClick={this.zoomPhoto}>
            <MdZoomOutMap />
          </a>
          <a className='remove' onClick={this.removePhoto}>
            <MdDelete />
          </a>
        </div>
      </div>
    );
  }
}

Upload.propTypes = {
  image: PropTypes.object.isRequired,
  imgIndex: PropTypes.number.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  onZoomHandler: PropTypes.func.isRequired
};

export default Upload;
