import React, { useEffect } from 'react';
import moment from 'Utils/moment';
import Rate from '../PeriodRates/Rate';
import classNames from 'classnames';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PeriodState, { periodFields } from 'Pages/Tariffs/components/FormState/PeriodState';
import { TopControls, DateRangeInputStyled, DataRangeError } from 'Styles/styled';

import PropTypes from 'prop-types';

const PeriodEdit = (props) => {
  const {
    period,
    startDate,
    endDate,
    dataRangeError,
    onDateChange,
    onRateChange,
    onSave,
    onCancelEdit,
    currencySymbol
  } = props;

  const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding-bottom: 13px;
  color: silver;
  
  > :last-child {
    width: 30%;
  }
`;

  useEffect(() => {
    const periodForm = new PeriodState(periodFields);
    periodForm.update(period);
  }, [period]);

  const leftBound = moment().subtract(24, 'month');
  const rightBound = moment().add(2, 'years');
  const isOutsideRange = day => leftBound.isAfter(day) || rightBound.isBefore(day);

  const cs = classNames('form__fields-wrapper');

  const listItems = period.rates.map(item => {
    return (
      <Rate
        key={item.age_group + '/' + item.type}
        rate={item}
        isEditable
        onRateChange={onRateChange}
      />
    );
  });

  return (
    <div>
      <TopControls>
        <DateRangeInputStyled
          startDate={startDate}
          endDate={endDate}
          enableOutsideDays
          isOutsideRange={isOutsideRange}
          onDateChange={onDateChange}
        />
        {dataRangeError &&
            (<DataRangeError>{dataRangeError}</DataRangeError>)}
      </TopControls>

      <div className={cs}>
        <ListHeader>
          <div>Тип размещения</div>
          <div>Цена, {currencySymbol}</div>
        </ListHeader>

        {listItems}
      </div>

      <div className='period_buttons'>
        <button className='button green' onClick={onSave}>
          <FormattedMessage id='shared.confirm' />
        </button>
        <button className='button gray' onClick={onCancelEdit}>
          <FormattedMessage id='shared.cancel_do' />
        </button>
      </div>
    </div>
  );
};

PeriodEdit.propTypes = {
  period: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  dataRangeError: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  onRateChange: PropTypes.func.isRequired
};

export default PeriodEdit;
