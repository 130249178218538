import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import Switch from "components/ui/Switch/Switch";

@observer
class SwitchRestrictionPeriod extends Component {
  handleSwitch = () => {
    const { field } = this.props;
    field.set(!field.value);
  };

  render() {
    const { field, title } = this.props;

    return (
      <div className="form__fields-group">
        <label>
          <FormattedMessage id={title} />
          <span className="error">{field.error && "*"}</span>
        </label>
        {field.error && <div className={'error'} style={{margin: '5px 0'}}>{field.error}</div>}
        <Switch active={field.value} onClick={this.handleSwitch} />
      </div>
    );
  }
}

SwitchRestrictionPeriod.propTypes = {
  title: PropTypes.string,
  field: PropTypes.string,
};

export default SwitchRestrictionPeriod;
