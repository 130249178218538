import _range from 'lodash/range';
import { formatMessage } from 'Utils/formatMessage';

const fields = [
  'id',
  'name',
  'name_translations.ru',
  'name_translations.en',
  'beds',
  'capacity',
  'capacity.total',
  'extra_beds',
  'area',
  'rm_count',
  'description',
  'description_translations.ru',
  'description_translations.en',
  'uploads',
  'images[]',
  'images[].id',
  'images[].url',
  'images[]._destroy',
  'images[].thumb',
  'images[].thumb.url',
  'amenities[]',
  'quotas[]',
  'quotas[].id',
  'quotas[].start_date',
  'quotas[].end_date',
  'quotas[].quantity',
  'quotas[]._destroy'
];

const labels = {
  name: formatMessage('room_types.name'),
  'name_translations.ru': formatMessage('room_types.name'),
  'name_translations.en': formatMessage('room_types.name'),
  beds: formatMessage('room_types.beds'),
  extra_beds: formatMessage('room_types.extra_beds'),
  area: formatMessage('room_types.area'),
  rm_count: formatMessage('room_types.rm_count'),
  description: formatMessage('room_types.description'),
  'description_translations.ru': formatMessage('room_types.description'),
  'description_translations.en': formatMessage('room_types.description')
};

const placeholders = {
  area: formatMessage('room_types.area_placeholder'),
  'quotas[].start_date': formatMessage('quotas.date_not_defined'),
  'quotas[].end_date': formatMessage('quotas.date_not_defined')
};

const values = {
  beds: 1,
  extra_beds: 0,
  rm_count: '1',
  capacity: {
    total: 0
  }
};

const rules = {
  'name_translations.ru': 'string|required',
  'name_translations.en': 'string',
  beds: 'integer|required',
  extra_beds: 'integer|required',
  area: 'numeric|required',
  rm_count: 'numeric|required',
  'images[]': 'array',
  'images[].file': 'required',
  'quotas[].start_date': 'string|required',
  'quotas[].end_date': 'string|required',
  'quotas[].quantity': 'integer|required'
};

const extra = {
  beds: _range(1, 13).map((i) => {
    return { value: i, label: i.toString() };
  }),

  extra_beds: _range(0, 3).map((i) => {
    return { value: i, label: i.toString() };
  })
};

const types = {};

export default {
  fields,
  labels,
  extra,
  placeholders,
  values,
  rules,
  types
};
