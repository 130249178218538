import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Loader from 'Pages/Hotel/components/Loader';

@withRouter
@inject('hotelStore')
@observer
class Actions extends Component {
  @computed get isLoading() {
    const { hotelStore } = this.props;
    return hotelStore.isPending;
  }

  @computed get isValid() {
    const { form } = this.props;

    if (form.changed && !form.validating) {
      return form.isValid;
    }

    if (form.touched && !form.validating) {
      return form.isValid;
    }

    return form.focused || form.isValid;
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successHandler,
      onError: this.errorHandler
    });
  }

  cancelHandler = (e) => {
    e.preventDefault();
    this.navigateToShow();
  }

  successHandler = (form) => {
    this.props.hotelStore.update(form)
      .then(_ => this.navigateToShow());
  }

  navigateToShow = () => {
    this.props.history.push('/hotel');
  }

  errorHandler = (form) => {
    console.log(form.errors());
  }

  render() {
    return (
      <div className='groups__item'>
        <div className='segment'>
          {!this.isValid &&
            <p className='text error'>
              <FormattedMessage id='shared.check_for_errors' />
            </p>}

          {this.isLoading && <Loader />}

          <div className='buttons'>
            <button
              type='submit'
              className='button green'
              onClick={this.submitHandler}
            ><FormattedMessage id='shared.confirm' />
            </button>

            <button
              type='submit'
              className='button gray'
              onClick={this.cancelHandler}
            ><FormattedMessage id='shared.cancel_do' />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Actions.propTypes = {
  form: PropTypes.object.isRequired
};

export default Actions;
