import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Tree, { TreeNode } from 'rc-tree';

import store from 'store';

@observer
class MealOptionsTree extends Component {
  render() {
    const mealOption = this.props.hotel.meal_options;
    const generalLanguage = store.get('language');

    const loop = (data) => {
      return data.map((item, index) => {
        return (
          <div
            key={item.id}
            className={index + 1 !== mealOption.length ? 'radio_list_item' : ''}
          >
            {item.name_translations[generalLanguage]}
          </div>
        );
      });
    };

    const treeNodes = loop(mealOption);

    return (
      <div className='form'>
        {treeNodes}
      </div>
    );
  }
}

MealOptionsTree.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default MealOptionsTree;
