import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, observable } from 'mobx';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';

import FormState from 'Shared/form/FormState';
import fields from './fields';

import Control from './Control';
import Previews from '../Previews';
import InfoLoader from '../InfoLoader';

import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import store from 'store';

const S = {
  Tag: styled.a`
    display: inline-block;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 7px;
    background: #fff;
    border-radius: 10px;
    color: #000;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    ${({ isActive }) => isActive ? 'font-weight: bold; box-shadow: 0 0 5px rgba(0,0,0,0.5); font-weight: bold; color: #000 !important;' : ''}
  `,

  TagsHeader: styled.header`
    font-size: 18px;
    margin-bottom: 10px;
  `
};

@inject('hotelStore')
@observer
class DropForm extends Component {
  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
      tag: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired
  }

  static defaultProps = {
    tags: [
      { tag: 'promo', name_translations: { ru: 'Акция', en: 'Stock' } },
      { tag: 'new_year', name_translations: { ru: 'Новый год', en: 'New year' } }
    ]
  }

  defaultTag = { tag: 'other', name_translations: { ru: 'Все остальные', en: 'Other' } }

  @observable selectedTag = this.defaultTag;

  @observable form = new FormState(fields)

  @observable files = [];

  @computed get isCompact() {
    return this.form.has('images') &&
      this.form.$('images').size > 0;
  }

  onDrop(files) {
    let data = this.files.concat(files);
    data = _uniqBy(data, (file) => file.name);

    this.files = data;
  }

  selectHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    this.dropzoneRef.open();
  }

  onTagClick = (tag) => {
    this.selectedTag = tag;
  }

  render() {
    const acceptedFiles = [
      'image/png',
      'image/jpeg',
      'image/jpg'
    ].join(', ');

    const generalLanguage = store.get('language');

    const tags = [...this.props.tags, this.defaultTag].map(tag => {
      const isActive = _isEqual(this.selectedTag, tag);
      return <S.Tag key={tag.tag} isActive={isActive} onClick={() => this.onTagClick(tag)}>{tag.name_translations[generalLanguage]}</S.Tag>;
    });

    const className = classNames('field__dropzone', {
      compact: this.isCompact
    });

    const selectedTag = _isEqual(this.selectedTag, this.defaultTag) ? null : this.selectedTag;

    const { isPending } = this.props.hotelStore;

    return (
      <div className='info__content'>
        <div className='form'>
          <div className='form__fields-wrapper'>
            <Dropzone
              accept={acceptedFiles}
              multiple
              className={className}
              activeClassName='field__dropzone--active'
              rejectClassName='field__dropzone--reject'
              ref={(node) => { this.dropzoneRef = node; }}
              onDrop={this.onDrop}
            >
              {isPending
                ? <InfoLoader />

                : <div className='content'>
                  <p>
                    <FormattedMessage id='shared.drop_image_here' />
                  </p>

                  <button className='button gray' onClick={this.selectHandler}>
                    <FormattedMessage id='shared.choose_file' />
                  </button>
                </div>}
            </Dropzone>

            {this.files.length > 0 &&
              <>
                <div className='form__field'>
                  <S.TagsHeader><FormattedMessage id='shared.promo' /></S.TagsHeader>
                  {tags}
                </div>
                <div className='form__field'>
                  <Previews files={this.files} />
                </div>
              </>}

            <Control files={this.files} selectedTag={selectedTag} />
          </div>
        </div>
      </div>
    );
  }
}

export default DropForm;
