import { types } from 'mobx-state-tree';

export const SourceSettings = types.model('SourceSettings', {
  booking_expiration_time: types.number,
  should_be_confirmed: types.boolean,
  has_cancellation_policy: types.boolean
}, {});

export const TariffSource = types.model('TariffSource', {
  id: types.identifier(types.string),
  name: types.string,
  settings: SourceSettings
});
