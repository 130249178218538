import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

@observer
class FormMaskedInput extends React.Component {
  render() {
    const {
      number,
      field,
      type,
      placeholder,
      className,
      ...restProps
    } = this.props;

    const inputCls = classNames(className, 'input', {
      error: field.error && !field.focused
    });

    return (
      <InputMask
        className={inputCls}
        {...field.bind({ type, placeholder })}
        {...restProps}
      />
    );
  }
}

FormMaskedInput.defaultProps = {
  type: 'text',
  placeholder: null,
  className: '',
  alwaysShowMask: true
};

FormMaskedInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  number: PropTypes.number
};

export default FormMaskedInput;
