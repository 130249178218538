import { types } from 'mobx-state-tree';

export const Source = types.model('Source', {
  type: types.string,
  name: types.string,

  get isHotel() {
    return this.type === 'hotel';
  },

  get isInsurance() {
    return this.type === 'insurance';
  }
});
