import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MdMailOutline from 'react-icons/lib/md/mail-outline';
import MdAdd from 'react-icons/lib/md/add';

import Email from './Email';

@observer
class Emails extends Component {
  render() {
    const { emails } = this.props;

    const showRemoveBtn = emails.length > 1;

    const listItems = emails.map((field, idx) => {
      const notLast = !!emails[idx + 1];

      return (
        <Email
          key={field.id}
          field={field}
          showAddBtn={!notLast}
          showRemoveBtn={showRemoveBtn}
        />
      );
    });

    return (
      <div className='form__section'>
        <div className='header'>
          <FormattedMessage id='hotel.email' />
        </div>
        <div className='content'>
          {listItems}
        </div>
      </div>
    );
  }
}

Emails.propTypes = {
  emails: PropTypes.array.isRequired
};

export default Emails;
