import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

@inject('servicesStore')
@observer
class ServiceActions extends Component {
  constructor(props) {
    super(props);

    this.destroyHandler = this.destroyHandler.bind(this);
  }

  destroyHandler(e) {
    e.preventDefault();

    const { servicesStore, service } = this.props;
    servicesStore.destroyOne(service);
  }

  render() {
    const { id } = this.props.service;
    const linkTo = ['/services', id, 'edit'].join('/');

    return (
      <div className='actions'>
        <Link
          to={linkTo}
          className='button gray'
        >
          <FormattedMessage id='shared.change' />
        </Link>

        <button
          className='button gray'
          onClick={this.destroyHandler}
        ><FormattedMessage id='shared.remove' />
        </button>
      </div>
    );
  }
}

ServiceActions.propTypes = {
  service: PropTypes.object.isRequired
};

export default ServiceActions;
