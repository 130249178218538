import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Title } from './PageCaption.styled';

@withRouter
@inject('ordersStore')
@observer
class PageCaption extends Component {
  render() {
    const { meta } = this.props.ordersStore;
    return (
      <Title>
        <FormattedMessage id='orders.list' />
        {'\u00A0'}({meta.count})
      </Title>
    );
  }
}

PageCaption.propTypes = {
  ordersStore: PropTypes.object.isRequired
};

export default PageCaption;
