import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'Utils/moment';

import DateRangeInput from 'Shared/form/DateRangeInput';

const inputStyle = {
  maxWidth: '16rem'
};

@observer
class Dates extends Component {
  leftBound = moment().subtract(1, 'years')

  rightBound = moment().add(2, 'years')

  isOutsideRange = (day) => {
    return this.leftBound.isAfter(day) ||
      this.rightBound.isBefore(day);
  }

  render() {
    const { startDate, endDate } = this.props;

    return (
      <DateRangeInput
        startDate={startDate}
        endDate={endDate}
        showLabel={false}
        enableOutsideDays
        isOutsideRange={this.isOutsideRange}
        className='w30'
        style={inputStyle}
      />
    );
  }
}

Dates.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired
};

export default Dates;
