import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import Rooms from '../Rooms';
import { FormattedMessage } from 'react-intl';

@withRouter
@observer
class Floor extends Component {
  render() {
    const { number, rooms } = this.props.floor;

    return (
      <div className='collapse__item active'>
        <div className='collapse__item-header'>
          <div className='description'>
            <span>{number} <FormattedMessage id='rooms.floor' /></span>
          </div>
        </div>

        <div className='collapse__item-content'>
          <Rooms rooms={rooms} />
        </div>
      </div>
    );
  }
}

Floor.propTypes = {
  floor: PropTypes.object.isRequired
};

export default Floor;
