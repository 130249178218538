import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { formatMessage } from "Utils/formatMessage";
import {
  StayRestrictionsLabel,
  Wrapper,
  FormActions,
  StopSalesElement,
  Span,
} from "./TariffLenghtStayRestrictions.styled";

const TariffLengthStayArrivalBasedRestrictions = (props) => {
  const { tariff, labels } = props;

  const listItems = tariff?.length_of_stay_arrival_based_restrictions?.map((item, index) => {
    return (
      <StopSalesElement key={index}>
        <FormActions>
          <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.dateRangeInput" />
          <Span>
            {item.start_date?.split('-')?.reverse()?.join('-')} - {item.end_date?.split('-')?.reverse()?.join('-')}
          </Span>
        </FormActions>
        <FormActions>
          <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.minLOSArrival.title" />
          <Span>{item.min_length_of_stay}</Span>
        </FormActions>
        <FormActions>
          <FormattedMessage id="tariff.length_of_stay_arrival_based_restrictions.maxLOSArrival.title" />
          <Span>{item.max_length_of_stay}</Span>
        </FormActions>
      </StopSalesElement>
    );
  });

  return (
    <div>
      <StayRestrictionsLabel className="key">
        {labels.length_of_stay_arrival_based_restrictions}
      </StayRestrictionsLabel>

      <Wrapper className="value">
        {tariff?.length_of_stay_arrival_based_restrictions?.length > 0 ? (
          listItems
        ) : (
          <div>{formatMessage("tariff.not_specified")}</div>
        )}
      </Wrapper>
    </div>
  );
};

TariffLengthStayArrivalBasedRestrictions.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default TariffLengthStayArrivalBasedRestrictions;
