import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { ReactComponent as DoubleArrowIcon } from 'Icons/double_arrow.svg';
import './Selector.scss';

import isNodeInRoot from './nodeInRoot';
import getBoundsForNode from './getBoundsForNode';
import doObjectsCollide from './doObjectsCollide';

import _throttle from 'lodash/throttle';

class SelectableGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoxSelecting: false,
      box: { width: 0, height: 0 },
      isPortalVisible: false,
      portal: { width: 0, height: 0 }
    };

    this.selectBox = React.createRef();

    this._movingDirection = null;
    this._mouseDownData = null;
    this._scrollOffsetData = null,
    this._rect = null;
    this._container = null;
    this._registry = this.props.store;
  }

  componentDidMount() {
    this._applyMousedown(this.props.enabled);
    this._rect = this._getInitialCoordinates();
    this.formPortal = this._setFormPortal();
    this._container = document.getElementsByClassName('dashboard__content')[0];
  }

  /**
   * Remove global event listeners
   */
  componentWillUnmount() {
    this._applyMousedown(false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.enabled !== this.props.enabled) {
      this._applyMousedown(nextProps.enabled);
    }
  }

  _registerSelectable = (key, domNode) => {
    this._registry.register(key, domNode);
  }

  _unregisterSelectable = (key) => {
    this._registry.unregister(key);
  }

  _setFormPortal = () => {
    const { quotasSelectorPortal } = this.props;
    return document.getElementsByClassName(quotasSelectorPortal)[0];
  }

  _applyMousedown(apply) {
    const funcName = apply ? 'addEventListener' : 'removeEventListener';
    ReactDOM.findDOMNode(this)[funcName]('mousedown', this._mouseDown);
  }

  _applyScrollEvents(apply) {
    const funcName = apply ? 'addEventListener' : 'removeEventListener';
    this._container[funcName]('scroll', this._setScrollOffset);
  }

  _setScrollOffset = (e) => {
    const offsetL = e.target.scrollLeft - this._scrollOffsetData.scrollLeft;
    const offsetT = e.target.scrollTop - this._scrollOffsetData.scrollTop;

    this._scrollOffsetData = {
      offsetLeft: offsetL,
      scrollLeft: this._scrollOffsetData.scrollLeft,
      offsetTop: offsetT,
      scrollTop: this._scrollOffsetData.scrollTop
    };
  }

  /**
   * Called while moving the mouse with the button down. Changes the boundaries
   * of the selection box
   */
  _openSelector = (e) => {
    const { offsetLeft, offsetTop } = this._scrollOffsetData;

    const w = Math.abs(this._mouseDownData.initialW - e.pageX - offsetLeft + this._rect.x);
    const h = Math.abs(this._mouseDownData.initialH - e.pageY + this._rect.y);

    this.setState({
      isBoxSelecting: true,
      box: {
        width: w,
        height: h,
        left: Math.min(e.pageX - this._rect.x + offsetLeft, this._mouseDownData.initialW),
        top: Math.min(e.pageY - this._rect.y, this._mouseDownData.initialH)
      }
    });

    this._handleScroll(e);

    this._throttledSelect(e);
  }

  _resizeSelector = (e, options) => {
    const { width, height, left, top } = this.state.box;
    let box = { width, height, left, top };

    const { offsetLeft } = this._scrollOffsetData;
    const value = e.pageX - this._rect.x - left + offsetLeft;

    if (this._movingDirection === 'right') {
      box = Object.assign({}, box, {
        width: Math.max(value, 0),
        left: value > 0 ? left : left + value
      });
    }

    if (this._movingDirection === 'left') {
      box = Object.assign({}, box, {
        width: width - value,
        left: e.pageX - this._rect.x + offsetLeft
      });
    }

    this.setState({ box });

    this._handleScroll(e);

    this._throttledSelect(e);
  }

  _handleScroll(e) {
    clearInterval(this.scroller);

    const containerRect = this._container.getBoundingClientRect();

    const offsetR = Math.abs(containerRect.right - e.pageX);
    const offsetL = Math.abs(containerRect.left - e.pageX);

    if (offsetR <= 100) {
      this.scroller = setInterval(() => this._scrollByOffset(5), 10);
    }

    if (offsetL <= 100) {
      this.scroller = setInterval(() => this._scrollByOffset(-5), 10);
    }
  }

  _scrollByOffset(offset) {
    const currentOffset = this._container.scrollLeft;
    this._container.scrollLeft = currentOffset + offset;
  }

  _getInitialCoordinates() {
    const style = window.getComputedStyle(document.body);

    const t = style.getPropertyValue('margin-top');
    const mTop = parseInt(t.slice(0, t.length - 2), 10);

    const l = style.getPropertyValue('margin-left');
    const mLeft = parseInt(l.slice(0, l.length - 2), 10);

    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = ReactDOM.findDOMNode(this).getBoundingClientRect();

    const coordinates = {
      x: Math.round(elemRect.left - bodyRect.left + mLeft),
      y: Math.round(elemRect.top - bodyRect.top + mTop)
    };

    return coordinates;
  }

  _getInitialScrollOffset() {
    const options = {
      offsetLeft: 0,
      scrollLeft: this._container.scrollLeft,
      offsetTop: 0,
      scrollTop: this._container.scrollTop
    };

    return options;
  }

  /**
   * Called when a user presses the mouse button. Determines if a select box should
   * be added, and if so, attach event listeners
   */
  _mouseDown = (e) => {
    // Initialize scroll listener
    this._applyScrollEvents(true);

    if (e.target.classList.contains('right')) {
      this._movingDirection = 'right';
      this._initMoving(e);
      return;
    }

    if (e.target.classList.contains('left')) {
      this._movingDirection = 'left';
      this._initMoving(e);
      return;
    }

    this._initSelecting(e);
  }

  _initMoving(e) {
    window.addEventListener('mouseup', this._mouseUp);

    this._rect = this._getInitialCoordinates();
    this._scrollOffsetData = this._getInitialScrollOffset();

    if (this.props.preventDefault) e.preventDefault();
    window.addEventListener('mousemove', this._resizeSelector);
  }

  _initSelecting(e) {
    const node = ReactDOM.findDOMNode(this);
    let collides, offsetData, distanceData;
    window.addEventListener('mouseup', this._mouseUp);

    // Right clicks
    if (e.which === 3 || e.button === 2) return;

    if (!isNodeInRoot(e.target, node)) {
      offsetData = getBoundsForNode(node);

      collides = doObjectsCollide({
        top: offsetData.top,
        left: offsetData.left,
        bottom: offsetData.offsetHeight,
        right: offsetData.offsetWidth
      }, {
        top: e.pageY - this._rect.y,
        left: e.pageX - this._rect.x,
        offsetWidth: 0,
        offsetHeight: 0
      });

      if (!collides) return;
    }

    this._rect = this._getInitialCoordinates();

    this._scrollOffsetData = this._getInitialScrollOffset();

    this._mouseDownData = {
      boxLeft: e.pageX - this._rect.x,
      boxTop: e.pageY - this._rect.y,
      initialW: e.pageX - this._rect.x,
      initialH: e.pageY - this._rect.y
    };

    if (this.props.preventDefault) e.preventDefault();

    window.addEventListener('mousemove', this._openSelector);
  }

  /**
   * Called when the user has completed selection
   */
  _mouseUp = (e) => {
    e.stopPropagation();

    // Remove scroll listener
    clearInterval(this.scroller);
    this._applyScrollEvents(false);

    window.removeEventListener('mousemove', this._openSelector);
    window.removeEventListener('mousemove', this._resizeSelector);
    window.removeEventListener('mouseup', this._mouseUp);

    this._selectElements(e);

    if (this._registry.hasSelected) {
      const bounds = this._registry.bounds;

      const { offsetLeft, offsetTop } = this._scrollOffsetData;
      const _formPortal = this.formPortal.getBoundingClientRect();

      const box = {
        left: bounds.left - this._rect.x + offsetLeft,
        top: bounds.top - this._rect.y + offsetTop,
        width: bounds.width,
        height: bounds.height
      };

      this.setState({ box });
    }

    this._mouseDownData = null;

    this._scrollOffsetData = null;

    this.setState({ isBoxSelecting: false });
  }

  /**
   * Selects multiple children given x/y coords of the mouse
   */
  _selectElements = (e) => {
    const currentItems = [];
    const selectbox = this.selectBox.current;
    const { tolerance } = this.props;

    if (!selectbox) return;

    this._registry.items.forEach(itemData => {
      if (itemData.domNode && doObjectsCollide(selectbox, itemData.domNode, tolerance)) {
        currentItems.push([itemData.key, itemData]);
      }
    });

    this._registry.selectItems(currentItems);

    currentItems.length > 0
      ? this._showSelectableView(e)
      : this._hideSelectableView(e);
  }

  _throttledSelect = _throttle(this._selectElements, 100);

  _showSelectableView = (e) => {
    const bounds = this._registry.bounds;
    const _portalRect = this.formPortal.getBoundingClientRect();

    const top = bounds.top - _portalRect.y;
    const left = e.pageX - _portalRect.x;

    const position = Math.abs(window.innerHeight - e.pageY) < 180 ? 'top' : 'bottom';

    const portalBounds = {
      width: bounds.width,
      height: bounds.height,
      left: e.pageX - _portalRect.x,
      top: top,
      position: position
    };

    this.setState({
      isPortalVisible: true,
      portal: portalBounds
    });
  }

  _hideSelectableView = () => {
    const bounds = { width: 0, height: 0 };

    this.setState({
      isPortalVisible: false,
      portal: bounds,
      box: bounds
    });

    this._registry.clear();
  }

  onClickContentHandler = (e) => {
    this._hideSelectableView();
  }

  /**
   * Renders the component
   * @return {ReactComponent}
   */
  render() {
    const Component = this.props.component;

    if (!this.props.enabled) {
      return (
        <Component className={this.props.className}>
          {this.props.children}
        </Component>
      );
    }

    const wrapperStyle = {
      position: 'relative',
      overflow: 'visible'
    };

    const QuotasSelector = this.props.quotasSelector;

    return (
      <Component className={this.props.className} style={wrapperStyle}>
        <div className='sc-quota-box' style={this.state.box} ref={this.selectBox}>
          {this.state.isPortalVisible &&
            <div className='sc-quota-selector'>
              <a className='sc-quota-selector__arrow right'>
                <DoubleArrowIcon />
              </a>
              <a className='sc-quota-selector__arrow left'>
                <DoubleArrowIcon />
              </a>
            </div>}
        </div>

        {this.state.isPortalVisible && (
          <QuotasSelector
            portal={this.formPortal}
            bounds={this.state.portal}
            registryStore={this._registry}
            clickOutsideHandler={this.onClickContentHandler}
          />
        )}

        {this.props.children}
      </Component>
    );
  }
}

SelectableGroup.propTypes = {
  // Store of registry nodes
  store: PropTypes.object.isRequired,

  // The component that will represent the Selectable DOM node
  component: PropTypes.node,

  // Amount of forgiveness an item will offer to the selectbox before registering
  tolerance: PropTypes.number,

  // Allows to enable/disable preventing the default action of the onmousedown event (with e.preventDefault).
  preventDefault: PropTypes.bool,

  // If false, all of the selectble features are turned off.
  enabled: PropTypes.bool,

  // A CSS class to add to the containing element
  className: PropTypes.string,

  // A CSS selector for portal
  quotasSelectorPortal: PropTypes.string.isRequired,

  // QuotasSelecter class
  quotasSelector: PropTypes.func.isRequired
};

SelectableGroup.defaultProps = {
  component: 'div',
  tolerance: 0,
  preventDefault: true,
  enabled: true
};

export default SelectableGroup;
