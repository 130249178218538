import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import arrayToTree from 'array-to-tree';
import PropTypes from 'prop-types';

import { RoomTypeAmenitiesStore } from 'Stores/RoomTypeAmenitiesStore';
import AmenityTree from 'Pages/RoomTypes/components/Amenities';
import {
  AmenitiesSegment,
  AmenitiesGroupsItem,
  GroupsItemContent,
  AmenitiesTitle
} from './Amenities.styled';

@observer
class AmenitiesAndServices extends Component {
  @computed get rootTree() {
    const { isFetched, amenities } = this.props.roomTypeAmenitiesStore;

    return isFetched
      ? arrayToTree(amenities.toJS(), {
        parentProperty: 'parent_tag',
        customID: 'tag'
      })
      : [];
  }

  componentDidMount() {
    const { roomTypeAmenitiesStore } = this.props;
    roomTypeAmenitiesStore.fetch();
  }

  render() {
    const { form } = this.props;
    return (
      <AmenitiesGroupsItem>
        <GroupsItemContent>
          <AmenitiesSegment>
            <AmenitiesTitle>
              <FormattedMessage id='hotel.amenities_and_services' />{' '}
            </AmenitiesTitle>
            {this.rootTree.length > 0 && (
              <AmenityTree form={form} amenities={this.rootTree} />
            )}
          </AmenitiesSegment>
        </GroupsItemContent>
      </AmenitiesGroupsItem>
    );
  }
}

AmenitiesAndServices.defaultProps = {
  roomTypeAmenitiesStore: RoomTypeAmenitiesStore.create()
};

AmenitiesAndServices.propTypes = {
  roomTypeAmenitiesStore: PropTypes.object,
  form: PropTypes.object
};

export default AmenitiesAndServices;
