import { autorun, reaction, computed } from 'mobx';
import { formatMessage } from 'Utils/formatMessage';
import _range from 'lodash/range';

import FormState from 'Shared/form/FormState';
import fields from './fields';

class RateState extends FormState {
  constructor(props) {
    super(props);

    autorun(() => {
      const occupation = this.$('occupation').value;
      const age_group = this.$('age_group').value;
      const age_period = this.$('age_period').value;
      const count = this.$('count').value;

      this.setTypeAndDescription({
        age_group,
        age_period,
        occupation,
        count
      });
    });

    reaction(
      () => this.$('age_group').value,
      ageGroup => this.$('count').set(0)
    );
  }

  options() {
    return {
      validateOnInit: false,
      showErrorsOnReset: false,
      showErrorsOnClear: false,
      showErrorsOnChange: true,
      validateOnChange: true
    };
  }

  setTypeAndDescription = ({ age_group, age_period, occupation, count }) => {
    // Set type
    let type = [];
    let data = null;

    type.push(age_group);

    if (this.isChild) {
      data = [age_period.from, age_period.to].join('_');
      type.push(data);
    }

    data = ['on', occupation, 'bed'].join('_');
    type.push(data);

    if (count > 0) {
      data = ['at', count].join('_');
      type.push(data);
    }

    type = type.join('_');

    this.$('type').set(type);

    // Set description
    const description = formatMessage('rates.description', {
      ageGroup: age_group,
      from: age_period.from,
      to: age_period.to,
      count: count,
      occupation: occupation
    }).replace(/^\s+|\s+$|\n+|\r+/g, '');

    this.$('description').set(description);
  };

  @computed get isMain() {
    return this.$('occupation').value === 'main';
  }

  @computed get isExtra() {
    return this.$('occupation').value === 'extra';
  }

  @computed get isChild() {
    return this.$('age_group').value === 'child';
  }

  @computed get isAdult() {
    return this.$('age_group').value === 'adult';
  }

  mainAdultOpts = room_type => {
    const bedsCount = (room_type.beds += 1);

    const value = { occupation: 'main', age_group: 'adult' };
    const description = formatMessage('rates.description_main', value);

    const options = _range(bedsCount).map(count => {
      const className = 'extra_option';
      const value = { count };
      const descriptionOption = formatMessage('rates.description_extra', value);
      return { ...value, descriptionOption, className };
    });

    return { ...value, options, description };
  };

  mainChildOpts = () => {
    const value = { occupation: 'main', age_group: 'child' };
    const description = formatMessage('rates.description_main', value);
    return { ...value, description, options: [] };
  };

  extraAdultOpts = () => {
    const value = { occupation: 'extra', age_group: 'adult' };
    const description = formatMessage('rates.description_main', value);
    return { ...value, description, options: [] };
  };

  extraChildOpts = () => {
    const value = { occupation: 'extra', age_group: 'child' };
    const description = formatMessage('rates.description_main', value);
    return { ...value, description, options: [] };
  };

  noPlaceChildOpts = (rates) => {
    const value = { occupation: 'no', age_group: 'child' };
    const description = formatMessage('rates.description_no_place_child', value);

    return { ...value, description, options: [] };
  }
}

export { fields };
export default RateState;
