import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from 'Pages/Tariffs/components/Form/Periods';

@observer
class RatesRules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

    togglePeriods = (flag) => {
      const { opened } = this.state;
      this.setState({ opened: !opened });
    }

    render() {
      const { form, form: { periods } } = this.props;
      const { opened } = this.state;

      return (
      <div className='groups__item'>
      <div className="groups__item_wrapper">
        <div className='groups__item-header'>
          <FormattedMessage id='periods.self' />
        </div>
          {periods && periods.length > 0 && <div className="showAll" onClick={this.togglePeriods}>
              {!opened ? 'Показать все периоды' : 'Показать актуальные периоды'}
          </div>}
      </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <Form form={form} opened={opened} />
          </div>
        </div>
      </div>
      );
    }
}

RatesRules.propTypes = {
  form: PropTypes.object.isRequired
};

export default RatesRules;
