import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import moment from 'Utils/moment';

import LocalizedLabel from 'Components/ui/LocalizedLabel';

const format = 'DD.MM.YYYY';

const NameContainer = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  font-weight: 600;
`;

const AvailablePeriod = styled.div`
  display: flex;
  margin-top: 2px;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  position: relative;
  white-space: nowrap;
  padding-bottom: 12px;
  border-bottom: 0;
  z-index: 1;

  &:after {
    content: '';
    width: calc(100% + 40px);
    height: 100%;
    top: 0;
    left: 0px;
    position: absolute;
    border-bottom: 2px solid #3B4149;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;

  &:hover {
    ${AvailablePeriod}, ${NameContainer} {
      color: #1F6FCC;
    }
  }

  ${({ isActive }) => isActive && css`
    cursor: default;
    ${AvailablePeriod} {
      color: #999999;
      &:after {
        border-bottom: 2px solid #EAE9E4;
        width: calc(100% + 80px);
        left: -40px;
      }

      &:before {
        border: 0;
      }
    }

    ${NameContainer} {
      color: #1F6FCC;
    }

    &:hover {
      ${AvailablePeriod} {
        color: #999999;
      }
    }
  `}
`;

function TariffContentHeader({ className, tariff, isActive, history }) {
  const { from, to } = tariff.available_period;
  const fromDate = moment(from).format(format);
  const toDate = moment(to).format(format);

  const handleClick = (e) => {
    if (isActive) return null;
    e.preventDefault();

    history.push(`/tariffs/${tariff.id}/`);
  };

  return (
    <Wrapper
      className={className}
      isActive={isActive}
      onClick={handleClick}
    >
      <NameContainer>
        <LocalizedLabel content={tariff.name} />
      </NameContainer>

      <AvailablePeriod>
        {fromDate} &mdash; {toDate}
      </AvailablePeriod>
    </Wrapper>
  );
}

TariffContentHeader.propTypes = {
  className: PropTypes.string,
  tariff: PropTypes.object,
  isActive: PropTypes.bool,
  history: PropTypes.object
};

export default styled(withRouter(TariffContentHeader))``;
