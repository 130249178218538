import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import _isEmpty from "lodash/isEmpty";

import ObjectOptionsTree from "./ObjectOptionsTree";

@observer
class MainSpecialization extends Component {
  render() {
    const { hotel } = this.props;
    const type_option = this.props.hotel.card_options.toJSON();

    return (
      <div className="groups__item">
        <div className="groups__item-header">
          <FormattedMessage id="hotel.object_type" />
        </div>

        <div className="groups__item-content">
          <div className="segment">
            {!_isEmpty(type_option) ? (
              <ObjectOptionsTree hotel={hotel} />
            ) : (
              <p className="muted">
                <FormattedMessage id="hotel.object_type_didnt_choosed" />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MainSpecialization.propTypes = {
  hotel: PropTypes.object.isRequired,
};

export default MainSpecialization;
