import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import TariffsStore from 'Stores/TariffsNewStore/List';
import PropTypes from 'prop-types';

import { FormLanguageContext } from 'Context/FormLanguageContext';

import List from './containers/List';
import Edit from './containers/Edit';
import Add from './containers/Add';

@withRouter
class Tariffs extends Component {
  static contextType = FormLanguageContext;

  render() {
    const { tariffsStore } = this.props;

    return (
      <Provider tariffsStore={tariffsStore}>
        <Switch>
          <Route
            exact
            path='/tariffs/:id/edit'
            component={Edit}
          />

          <Route
            exact
            path='/tariffs/new'
            component={Add}
          />

          <Route
            path='/tariffs/:id?'
            component={List}
          />
        </Switch>
      </Provider>
    );
  }
}

Tariffs.propTypes = {
  tariffsStore: PropTypes.object.isRequired
};

Tariffs.defaultProps = {
  tariffsStore: TariffsStore.create()
};

export default Tariffs;
