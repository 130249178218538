import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import _pickBy from 'lodash/pickBy';
import { FormattedMessage } from 'react-intl';

import Cards from './Cards';

@observer
class ByCard extends Component {
  render() {
    const { allow } = this.props.value;

    let items = this.props.value;
    items = _pickBy(items, (value, key) => key != 'allow');
    items = _pickBy(items, (value, key) => value === true);
    items = Object.keys(items);

    const clsName = classNames('bank_cards', {
      disabled: !allow
    });

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='hotel.payment_by_card' />
        </div>

        <div className='value'>
          {items.length > 0
            ? <Cards items={items} />
            : <FormattedMessage id='hotel.payment_by_card_not_accepted' />}
        </div>
      </div>
    );
  }
}

ByCard.propTypes = {
  value: PropTypes.object.isRequired
};

export default ByCard;
