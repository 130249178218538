import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RoomTypes from '../RoomTypes';

@withRouter
@observer
class PageBody extends Component {
  render() {
    const { room_types } = this.props;
    return (
      <div className='page__body'>
        <div className='groups'>
          <div className='groups__item'>
            <RoomTypes room_types={room_types} />
          </div>
        </div>
      </div>
    );
  }
}

PageBody.propTypes = {
  room_types: PropTypes.object.isRequired
};

export default PageBody;
