import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';
import { withRouter } from 'react-router-dom';

import Loader from '../../components/Loader';
import PageBody from './components/PageBody';

import { ReservationStore } from 'Stores/ReservationStore';

@inject('searchState')
@withRouter
@observer
class Show extends Component {
  constructor(props) {
    super(props);

    this.reservationStore = ReservationStore.create();

    this.reservationHandler = reaction(
      () => this.reservationStore.isFetched,
      (isFetched) => this.changeRange()
    );
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.reservationStore.fetch(id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    if (prevProps.match.params.id != id) { this.reservationStore.fetch(id); }
  }

  componentWillUnmount() {
    this.reservationHandler();
  }

  changeRange() {
    const { searchState } = this.props;
    const { reservation } = this.reservationStore;

    searchState.gotoDate(reservation.check_in);
  }

  render() {
    const { reservation, isPending } = this.reservationStore;

    return (
      <Provider reservationStore={this.reservationStore}>
        <div className='page__info'>
          <div className='page__info-wrapper'>
            {reservation && <PageBody />}
            {isPending && <Loader />}
          </div>
        </div>
      </Provider>
    );
  }
}

export default Show;
