import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default class TopBarActionBox extends Component {
  render() {
    return (
      <div className='action-box'>
        <form method='post' action='' className='form'>
          <Link
            to='/tariffs/new'
            className='button green'
          >
            <FormattedMessage id='tariff.add' />
          </Link>
        </form>
      </div>
    );
  }
}
