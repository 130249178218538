import { observable, computed } from 'mobx';

class QuotaTabsState {
  @observable items = new Map();

  select(item) {
    if (!item.id) return;

    const key = item.id;
    this.items.set(key, item);
  }

  deselect(item) {
    if (!item.id) return;

    const key = item.id;
    this.items.delete(key);
  }

  toggle(item) {
    this.isSelected(item)
      ? this.deselect(item)
      : this.select(item);
  }

  isSelected(item) {
    if (!item.id) return false;

    const key = item.id;
    return this.items.has(key);
  }
}

export default QuotaTabsState;
