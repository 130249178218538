import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from 'Utils/formatMessage';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Tooltip from 'Components/ui/Tooltip';

import LocalizedLabel from 'Components/ui/LocalizedLabel';

import { CopyIcon } from 'Components/ui/Icons';

import TariffAvailablePeriod from './TariffAvailablePeriod';

import Switch from 'Components/ui/Switch';

const S = {
  CaptionName: styled.span`
    font-weight: 600;
    ${props => props.withPromo ? 'color: red' : ''};
  `
};

const ItemName = styled.div`
  width: 35%;
  min-width: 35%;
`;

const ItemType = styled.div`
  width: 15%;
  min-width: 15%;
`;

const ItemSource = styled.div`
  width: 10%;
  min-width: 10%;
`;

const ItemPeriod = styled.div`
  width: 20%;
  min-width: 20%;
`;

const SwitchContainer = styled.div`
  width: 10%;
  min-width: 10%;
  display: flex;
`;

const ChildTariffName = styled.span`
  &&& {
    position: relative;
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    left: -6px;
    width: 10px;
    height: 31px;
    bottom: 8px;
    border-left: 1px solid #DCD8D2;
    border-bottom: 1px solid #DCD8D2;
  }
`;

const Copy = styled.div`
  position: absolute;
  display: none;
  font-size: 12px;
  top: 50%;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  right: -15px;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;

  &:hover {
    ${Copy} {
      display: flex;
    }
  }

  &:hover {
    ${ItemName} {
      color: #1F6FCC;
    }
  }
`;

const Wrapper = styled.div`
  color: #838383;
  padding-top: 0;
  margin-top: 20px;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  ${({ level }) => level && css`
    ${ChildTariffName} {
      margin-left: calc(16 * ${level}px);
    }
  `};
`;

function TariffHeader({ tariff, history, level, activateTariff, deactivateTariff, currentLanguage }) {
  const {
    name,
    available_period,
    source,
    promo,
    status,
    active
  } = tariff;

  const [activeTariff, setActiveTariff] = useState(active);
  const [overlayText, setOverlayText] = useState(formatMessage('shared.clipboard_copy'));
  const [pending, setPending] = useState();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    history.push(`/tariffs/${tariff.id}`);
  };

  const getStatus = () => {
    if (status === 'created') {
      return {
        label: 'status booked',
        text: formatMessage('tariffs.statuses.created')
      };
    } else if (status === 'uploaded') {
      return {
        label: 'status draft',
        text: formatMessage('tariffs.statuses.uploaded')
      };
    } else if (status === 'confirmed') {
      return {
        label: 'status confirmed',
        text: formatMessage('tariffs.statuses.confirmed')
      };
    } else {
      return {
        label: 'status',
        text: formatMessage('tariffs.statuses.unknown')
      };
    }
  };

  const handleCopy = e => {
    e.stopPropagation();
    setOverlayText(formatMessage('tariff.clipboard_success'));

    navigator.clipboard.writeText(name[currentLanguage]);

    setTimeout(() => setOverlayText(formatMessage('shared.clipboard_copy')), 2000);
  };

  const handleActive = async e => {
    try {
      setPending(true);
      setActiveTariff(!activeTariff);
      const func = activeTariff ? deactivateTariff : activateTariff;
      await func({ tariff });
      setPending(false);
    } catch (error) {
      setActiveTariff(!activeTariff);
    }
  };

  const type = formatMessage(['tariffs', tariff.type, 'name'].join('.'));

  return (
    <Wrapper onClick={handleClick} level={level}>
      <Container>
        <ItemName>
          <ChildTariffName>
            <S.CaptionName
              className={getStatus().label}
              withPromo={promo?.active}
            >
              <LocalizedLabel content={name} />
            </S.CaptionName>
          </ChildTariffName>
        </ItemName>

        <ItemType>
          {type}
        </ItemType>

        <ItemSource>
          {source
            ? source.name
            : formatMessage('tariff.not_specified')}
        </ItemSource>

        <ItemPeriod>
          <TariffAvailablePeriod period={available_period} />
        </ItemPeriod>

        <SwitchContainer>
          <Switch active={activeTariff} onClick={handleActive} disabled={pending} />
        </SwitchContainer>

        <Tooltip
          placement='bottom'
          overlay={overlayText}
        >
          <Copy onClick={handleCopy}>
            <CopyIcon />
          </Copy>
        </Tooltip>
      </Container>

      {tariff.children && (
        <TariffHeader
          tariff={tariff.children[0]}
          level={level + 1}
          currentLanguage={currentLanguage}
          history={history}
          activateTariff={activateTariff}
          deactivateTariff={deactivateTariff}
        />
      )}
    </Wrapper>
  );
}

TariffHeader.propTypes = {
  tariff: PropTypes.object.isRequired,
  level: PropTypes.number,
  history: PropTypes.object.isRequired,
  activateTariff: PropTypes.func,
  deactivateTariff: PropTypes.func,
  currentLanguage: PropTypes.string
};

export default withRouter(TariffHeader);
