import { types } from 'mobx-state-tree';

export const MenuStore = types.model('MenuStore', {
  openned: types.optional(types.boolean, true)
}, {
  open() {
    this.openned = true;
    return this.openned;
  },

  close() {
    this.openned = false;
    return this.openned;
  },

  toggle() {
    this.openned = !this.openned;
    return this.openned;
  }
});
