import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable, reaction } from 'mobx';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import PageBody from './components/PageBody';
import LanguageControl from 'Shared/components/LanguageFormControl';
import PropTypes from 'prop-types';

import FormState, {
  baseFields,
  tourFields,
  packageFields
} from 'Pages/Tariffs/components/FormState';

import _merge from 'lodash/merge';

import { UserProvider } from 'Context/FormLanguageContext';
import Buttons from './components/Actions/Buttons';

@inject('hotelStore')
@observer
class Edit extends Component {
  @observable form = undefined;

  @computed get hotel() {
    const { hotelStore } = this.props;
    return hotelStore.isFetched;
  }

  state = {
    language: 'ru'
  };

  @computed get tariff() {
    const { tariff } = this.props;

    return tariff;
  }

  componentDidMount() {
    this.changeTypeHandler = reaction(
      () => this.tariff && this.form && this.form.values().type,
      (type) => this.initForm(type),
      { fireImmediately: true }
    );

    this.scrollToTop();
  }

  componentWillUnmount() {
    this.changeTypeHandler();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  initForm = (type) => {
    const { tariff } = this.props;
    const tariffType = type || tariff?.type

    // Initialize form
    let fields;

    if (tariffType === 'tour') {
      fields = tourFields;
    } else if (tariffType === 'package') {
      fields = packageFields;
    } else {
      fields = baseFields;
    }

    this.form = new FormState(fields);

    // Prevent double update operation
    if (tariffType) {
      let values = tariff;

      const { periods, ...attrs } = values;

      values = _merge(attrs, { type: tariffType });

      this.form.update(values);
      this.form.setPeriods(periods);
    }
  }

  handleLanguageChange = language => {
    if (this.state.language === language) return;

    this.setState({ language });
  };

  render() {
    const { isPending, tariff, updateTariff, removeTariff, isFetched } = this.props;

    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.handleLanguageChange
        }}
      >
        <div className='layout__page'>
          <div className='layout__page-form-controls'>
            <TopBar isLoading={isPending}>
              <TopBarContent />
            </TopBar>

            <div className='layout__language-action'>
              <div className='language__control-content'>
                <div className='control'>
                  <LanguageControl onClick={this.handleLanguageChange} />
                </div>
              </div>
            </div>
          </div>

          {this.form && isFetched && this.hotel && (
            <>
              <div className='layout__page-content'>
                <PageBody
                  form={this.form}
                  tariff={tariff}
                />
              </div>

              <Buttons
                form={this.form}
                isPending={isPending}
                tariff={tariff}
                updateTariff={updateTariff}
                removeTariff={removeTariff}
              />
            </>
          )}
        </div>
      </UserProvider>
    );
  }
}

Edit.propTypes = {
  hotelStore: PropTypes.object.isRequired,
  tariff: PropTypes.object,
  isPending: PropTypes.bool,
  updateTariff: PropTypes.func,
  removeTariff: PropTypes.func,
  isFetched: PropTypes.bool
};

export default Edit;
