import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Switch from 'Components/ui/Switch/Switch';

const IntegretionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const SubTitle = styled.span`
  color: #999;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

@inject('integrationsStore')
@observer
class SourceItem extends Component {
  render() {
    const { name, subtitle, active, id, sourceId, hasCookie } = this.props;
    const handleChangeSwitch = () => {
      this.props.integrationsStore.activate(id, sourceId);
    };
    return (
      <IntegretionWrapper>
        <TitleWrapper>
          <Title>{name}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TitleWrapper>

        <Switch active={active} onClick={handleChangeSwitch} disabled={!hasCookie}/>
      </IntegretionWrapper>
    );
  }
}

SourceItem.propTypes = {
  name: PropTypes.string.isRequired,
  hasCookie: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  sourceId: PropTypes.string.isRequired,
  integrationsStore: PropTypes.integrationsStore
};

export default SourceItem;
