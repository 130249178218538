import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

class ExternalLink extends Component {
  render() {
    const path = this.props.data.path || '#';

    const className = classNames('infinity-menu-node-container', {
      active: !this.props.data.path && this.props.isOpen
    });

    return (
      <div className={className}>
        <a href='/docs' target='_blank'>
          <FormattedMessage id={this.props.name} />
        </a>
      </div>
    );
  }
}

export default ExternalLink;
