import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';

import classNames from 'classnames';

import ArrowLeftIcon from 'react-icons/lib/md/chevron-left';
import ArrowRightIcon from 'react-icons/lib/md/chevron-right';

import('./override.scss');

@observer
class RangeCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: START_DATE
    };
  }

  onFocusChange = (focusedInput) => {
    this.setState({
      focusedInput: focusedInput || START_DATE
    });
  };

  render() {
    const className = classNames('quota-modal-calendar', {
      'is-month-change-locked': this.props.isLoading,
      'groups-modal-calendar': this.props.isGroups
    });

    return (
      <div className={className}>
        <DayPickerRangeController
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          onDatesChange={this.props.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={this.state.focusedInput}
          navPrev={<ArrowLeftIcon />}
          navNext={<ArrowRightIcon />}
          onPrevMonthClick={this.props.onPrevMonthClick}
          onNextMonthClick={this.props.onNextMonthClick}
          numberOfMonths={2}
          hideKeyboardShortcutsPanel
          enableOutsideDays={false}
          daySize={55}
          minimumNights={0}
          renderDay={this.props.renderDay}
        />
      </div>
    );
  }
}

RangeCalendar.defaultProps = {
  renderDay: day => day.format('D'),
  onDatesChange: () => {}
};

RangeCalendar.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDatesChange: PropTypes.func,
  renderDay: PropTypes.func
};

export default RangeCalendar;
