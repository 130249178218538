import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';

const rateToClone = (rate) => {
  const values = _omit(rate, ['id']);
  return values;
};

const tariffToClone = (tariff) => {
  let values = _cloneDeep(tariff.toJSON());

  const rates = values.rates.map((rate) => {
    return rateToClone(rate);
  });

  values = _omit(values, ['id', 'name', 'rates']);
  values = _merge(values, { rates: rates });

  values = _merge(values, {
    room_type: tariff.room_type && tariff.room_type.toJSON(),
    source: tariff.source && tariff.source.toJSON()
  });

  return values;
};

const tariffToForm = (tariff) => {
  let values = _cloneDeep(tariff.toJSON());

  values = _merge(values, {
    room_type: tariff.room_type && tariff.room_type.toJSON(),
    source: tariff.source && tariff.source.toJSON()
  });

  return values;
};

export { rateToClone, tariffToClone, tariffToForm };
