import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Form from 'Pages/Tariffs/components/Form/GeneralInfo';

@observer
class GeneralInfo extends Component {
  render() {
    const { form } = this.props;

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='tariffs.general' />
        </div>
        <div className='groups__item-content'>
          <div className='segment'>
            <Form form={form} />
          </div>
        </div>
      </div>
    );
  }
}

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired
};

export default GeneralInfo;
