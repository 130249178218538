import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class PolicyFullRefund extends Component {
  render() {
    return (
      <p className='value'>
        {formatMessage('tariffs.cancellation_policy.has_full_refund')}
      </p>

    );
  }
}

export default PolicyFullRefund;
