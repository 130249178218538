import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Tariffs from '../Tariffs/index';

@withRouter
@inject('priceListStore')
@observer
class TariffsList extends Component {
  render() {
    const { price_list } = this.props.priceListStore;

    return (
      <div className='groups__item-content'>
        <div className='groups__item-blocks'>
          <div className='groups__item-block'>
            <div className='header'>
              <FormattedMessage id='tariffs.available' />
            </div>

            <div className='content'>
              {price_list.tariffs.length > 0 &&
                <Tariffs tariffs={price_list.tariffs} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TariffsList;
