import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';

import PageBody from './components/PageBody';
import Loader from '../../components/Loader';
import OrderStore from 'Stores/OrdStore';

@observer
class Add extends Component {
  render() {
    const { orderStore } = this.props;

    return (
      <Provider
        orderStore={orderStore}
      >
        <div className='page__info'>
          <div className='page__info-wrapper'>
            <PageBody />
            {orderStore.isPending && <Loader />}
          </div>
        </div>
      </Provider>
    );
  }
}

Add.defaultProps = {
  orderStore: OrderStore.create()
};

export default Add;
