import React, { Component } from 'react';

import TopBarSearchBox from './TopBarSearchBox';
import PropTypes from 'prop-types';

class TopBarContent extends Component {
  render() {
    const { adminModeHandler } = this.props;
    return (
      <div className='layout__topbar-content'>
        <div className='layout__topbar-content-wrapper'>
          <TopBarSearchBox adminModeHandler={adminModeHandler}/>
        </div>
      </div>
    );
  }
}

TopBarContent.propTypes = {
  adminModeHandler: PropTypes.func
};

export default TopBarContent;
