import styled from 'styled-components';

export const Groups = styled.div`
  width: 850px;
  margin-bottom: 100px;
`;

export const ContentBlock = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const Title = styled.span`
  font-size: 30px;
  line-height: 25px;
  margin-bottom: 28px;
`;
