import React, { Component } from 'react';
import { computed, reaction } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';

import List from './containers/List';
import Show from './containers/Show';
import Add from './containers/Add';
import EditQuotaModal from './containers/EditQuotaModal';

import { DashboardStore } from 'Stores/DashboardStore/List';
import { QuotasStore } from 'Stores/QuotasStore/List';
import SearchState, { fields } from './components/SearchState';
import QuotaTabsState from './components/QuotaTabsState';
import GroupsQuotasModal from './components/GroupsQuotasModal';

@withRouter
@observer
class Index extends Component {
  constructor(props) {
    super(props);

    this.searchState = new SearchState(fields);
    this.dashboardStore = DashboardStore.create();
    this.quotasStore = QuotasStore.create();
    this.quotaTabsState = new QuotaTabsState();

    this.state = {
      isOpenGroupsQuotas: false
    };
  }

  @computed get isLoading() {
    return this.dashboardStore.isPending || this.quotasStore.isPending;
  }

  @computed get isFetched() {
    return (
      this.dashboardStore.room_types.length > 0 &&
      this.quotasStore.room_types.length > 0
    );
  }

  componentDidMount() {
    this.searchState.setFromSearchParams(this.props.location.search);

    this.datesHandler = reaction(
      () => {
        const { check_in, check_out } = this.searchState.values();
        return { check_in, check_out };
      },
      ({ check_in, check_out }) => {
        if (check_in && check_out) {
          this.dashboardStore.fetch({ check_in, check_out });
          this.quotasStore.fetch({ check_in, check_out });
        }
      },
      { fireImmediately: true }
    );
  }

  toggleModalOpen = () => {
    const { isOpenGroupsQuotas } = this.state;
    this.setState({ isOpenGroupsQuotas: !isOpenGroupsQuotas });
  };

  closeQuotasModal = () => {
    this.setState({ isOpenGroupsQuotas: false });
  };

  componentWillUnmount() {
    this.datesHandler();
  }

  render() {
    const { isOpenGroupsQuotas } = this.state;
    return (
      <Provider
        dashboardStore={this.dashboardStore}
        quotasStore={this.quotasStore}
        searchState={this.searchState}
        quotaTabsState={this.quotaTabsState}
      >
        <div className="layout__page">
          <TopBar isLoading={this.isLoading}>
            <TopBarContent
              form={this.searchState}
              toggleModalOpen={this.toggleModalOpen}
            />
          </TopBar>

          <div className="layout__page-content">
            {this.isFetched && <List form={this.searchState} />}

            <Switch>
              <Route exact path="/dashboard/new" component={Add} />

              <Route exact path="/dashboard/:id" component={Show} />

              <Route
                exact
                path="/dashboard/quota/:room_type_id"
                component={EditQuotaModal}
              />
            </Switch>
            <GroupsQuotasModal
              isOpenGroupsQuotas={isOpenGroupsQuotas}
              closeQuotasModal={this.closeQuotasModal}
            />
          </div>
        </div>
      </Provider>
    );
  }
}

export default Index;
