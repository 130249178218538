import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Services from '../../Services/index';

@observer
class Index extends Component {
  render() {
    const { labels, tariff } = this.props;

    return (
      <div className='row'>
        <div className='col col-8'>
          <Services services={tariff.services} labels={labels} />
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  tariff: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default Index;
