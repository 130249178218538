import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class Checkbox extends React.Component {
  static defaultProps = {
    className: 'form__field checkbox',
    style: {}
  }

  static propTypes = {
    field: PropTypes.object.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
  }

  render() {
    const { field, style, className } = this.props;

    const inputProps = field.bind({
      type: 'checkbox',
      checked: field.value
    });

    return (
      <div className={className} style={style}>
        <label htmlFor={field.id}>
          {field.label}
        </label>

        <input
          className='input'
          {...inputProps}
        />
      </div>
    );
  }
}

export default Checkbox;
