import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import _range from 'lodash/range';

import FormItemInput from 'Shared/form/FormItemInput';
import SingleSelectObj from 'Shared/form/SingleSelectObj';
import SingleSelect from 'Shared/form/SingleSelect';

@inject('roomsStore')
@observer
class Form extends Component {
  @computed get roomTypes() {
    const { room_types } = this.props.roomsStore.meta;
    return room_types.toJSON();
  }

  @computed get buildingOptions() {
    const { buildings } = this.props.roomsStore.meta;
    return buildings.toJSON();
  }

  @computed get floorOptions() {
    const { form } = this.props;

    const building_id = form.$('building.id').value;
    if (!building_id) return [];

    const numbers = form.$('building.floor_numbers').value;

    return _range(1, numbers + 1).map((i) => {
      return { value: i, label: i.toString() };
    });
  }

  render() {
    const { form } = this.props;

    return (
      <div className='form'>
        <div className='form__body'>
          <FormItemInput
            field={form.$('number')}
            className='w25'
          />

          <SingleSelectObj
            field={form.$('room_type')}
            valueKey='id'
            labelKey='name'
            simpleValue={false}
            options={this.roomTypes}
          />

          <div className='form__fields'>
            <SingleSelectObj
              className='grow'
              field={form.$('building')}
              valueKey='id'
              labelKey='name'
              simpleValue={false}
              options={this.buildingOptions}
            />

            <SingleSelect
              field={form.$('floor_number')}
              options={this.floorOptions}
              className='w25'
            />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
