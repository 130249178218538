import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Source from './Source';

@observer
class Form extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { form } = this.props;

    const sources = Array.from(form.$('sources').fields.values());

    const listSources = sources
      .map(source => {
        return <Source key={source.id} field={source} />;
      });

    return (
      <form className='form' onSubmit={this.handleSubmit}>
        <div className='quotas-form__body'>
          {listSources}
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form;
