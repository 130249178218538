import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MdLocalPhone from 'react-icons/lib/md/local-phone';

@observer
class Phones extends React.Component {
  render() {
    return (
      <div className='form__fields-group'>
        <div className='form__field'>
          <label>
            <FormattedMessage id='hotel.additional_communication_channels' />
          </label>
        </div>
        <div className='form__fields'>
          <div className='form__field grow'>
            <div className='prepend'>
              <span>
                <i className='icon'>
                  <MdLocalPhone />
                </i>
              </span>
              <input
                className='input'
                type='text'
                value='alexander.kotov@overteam.ru'
              />
            </div>
          </div>
          <div className='form__field w25'>
            <input
              className='input'
              type='text'
              placeholder='Доб.'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Phones;
