import * as React from 'react';
import styled, { css } from 'styled-components';
import { useSogaz } from 'pages/Sogaz/store/hooks';
import { compareRateAdd, compareRateRemove } from 'pages/Sogaz/store/actions';
import { DateTime } from 'luxon';
import { NSHotel } from '@types';

import { Table, Small } from 'evergreen-ui';
import Diff from './Diff';

interface ITableRow {
  $status: 'success' | 'warning' | 'danger'
}

const TableRow = styled(Table.Row)<ITableRow>`
  ${props => props.$status === 'success' && css`
    background-color: #DCF2EA;
  `}

  ${props => props.$status === 'warning' && css`
    background-color: #FFEFD2;
  `}

  ${props => props.$status === 'danger' && css`
    background-color: #F9DADA;
  `}
`;

const Wrapper = styled.div`
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  roomType: NSHotel.TRoomType,
  tariff: NSHotel.TTariff
}>

function Tariff({ roomType, tariff, className }: Props) {
  const { state, dispatch } = useSogaz();

  const { name, available_period, code, rates } = tariff;

  const selectedId = React.useMemo(() => {
    return state.compare.rate?.code;
  }, [state.compare.rate]);

  const formatDate = (value: string): string => {
    return DateTime.fromISO(value).setLocale('ru').toLocaleString(DateTime.DATE_SHORT);
  };

  const rateIntent = (rate: NSHotel.TRate) => {
    const { compare_status } = rate;

    if (compare_status) {
      return compare_status.status;
    }

    return 'danger';
  };

  const showDiff = (rate: NSHotel.TRate): boolean => {
    if (!rate.compare_status) {
      return false;
    }

    if (rate.compare_status.status === 'success') {
      return false;
    }

    return true;
  };

  const handleSelect = (rate: NSHotel.TRate) => {
    dispatch(compareRateAdd({ room_type: roomType, tariff, rate }));
  };

  const handleDeselect = (rate: NSHotel.TRate) => {
    dispatch(compareRateRemove());
  };

  return (
    <Wrapper className={className}>
      <Table.Head>
        <Table.TextHeaderCell>
          {name.ru} / {code}
          <br />
          <Small color='muted'>
            {formatDate(available_period.from)} - {formatDate(available_period.to)}
          </Small>
        </Table.TextHeaderCell>

        <Table.TextHeaderCell />
      </Table.Head>

      <Table.Body>
        {rates.map(rate => (
          <React.Fragment key={rate.code}>
            <TableRow
              height={48}
              isSelectable
              isSelected={!!(selectedId && selectedId === rate.code)}
              onSelect={() => handleSelect(rate)}
              onDeselect={() => handleDeselect(rate)}
              $status={rateIntent(rate)}
            >
              <Table.TextCell>
                {rate.description.ru}
                <br />
                <Small color='muted'>
                  {rate.code}
                </Small>
              </Table.TextCell>

              {rate.services && rate.services.length > 0
                ? <Table.TextCell>
                    {rate.services[0].service_name}
                    <br />
                    <Small color='muted'>
                      {rate.services[0].service_code}
                    </Small>
                  </Table.TextCell>

                : <Table.TextCell>
                    -
                  </Table.TextCell>
              }
            </TableRow>

            {rate.compare_status && showDiff(rate) && (
              <TableRow height='auto'>
                <Diff compareStatus={rate.compare_status} />
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </Table.Body>
    </Wrapper>
  );
}

export default styled(Tariff)``;
