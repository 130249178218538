import styled from "styled-components";

export const StopSalesElement = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  display: grid;
  grid-template-columns: 200px 300px 300px;
  gap: 30px;

`;

export const StayRestrictionsLabel = styled.div`
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Span = styled.span`
display: flex;
`
