import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import GeneralInfo from './components/GeneralInfo';
import Contacts from './components/Contacts';
import BillingHour from './components/BillingHour';
import MainSpecialization from './components/MainSpecialization';
import AmenitiesAndServices from './components/AmenitiesAndServices';
import PaymentDetails from './components/PaymentDetails';
import Actions from './components/Actions';
import ObjectOptions from './components/ObjectOptions';
import Feed from './components/Feed';
import TreatmentFactors from './components/TreatmentFactors';
import LocationTypes from './components/LocationTypes';
import AccommodationFeatures from './components/AccommodationFeatures';

@inject('hotelStore')
@observer
class Show extends Component {
  componentDidMount() {
    const { hotelStore } = this.props;
    hotelStore.fetch({ presenter: 'show_view' });
  }

  componentWillUnmount() {
    const { hotelStore } = this.props;
    hotelStore.clear();
  }

  render() {
    const { hotel } = this.props.hotelStore;

    return (
      <div className='page__body'>
        {hotel && (
          <div className='groups'>
            <GeneralInfo hotel={hotel} />
            <Contacts hotel={hotel} />
            <BillingHour hotel={hotel} />
            <MainSpecialization hotel={hotel} />
            <ObjectOptions hotel={hotel} />
            <Feed hotel={hotel} />
            <TreatmentFactors hotel={hotel} />
            <LocationTypes hotel={hotel} />
            <AccommodationFeatures hotel={hotel} />
            <AmenitiesAndServices hotel={hotel} />
            <PaymentDetails hotel={hotel} />
            <Actions hotel={hotel} />
          </div>
        )}
      </div>
    );
  }
}

export default Show;
