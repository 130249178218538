import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class TariffStatus extends Component {
  render() {
    const { labels } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {formatMessage('tariff.status')}
          </span>
          <p className='value'>
            <span className={labels.status.label}>{labels.status.text}</span>
          </p>
        </div>
      </div>
    );
  }
}

TariffStatus.propTypes = {
  labels: PropTypes.object.isRequired
};

export default TariffStatus;
