import React, { Component } from 'react';
import { inject, computed, observer, Provider } from 'mobx-react';
import { when } from 'mobx';

import FormState, { fields } from 'Pages/Hotel/components/FormState';

import GeneralInfo from './components/GeneralInfo';
import Contacts from './components/Contacts';
import BillingHour from './components/BillingHour';
import Diseases from './components/Diseases';
import AmenitiesAndServices from './components/AmenitiesAndServices';
import PaymentDetails from './components/PaymentDetails';
import Actions from './components/Actions';
import ObjectOptions from './components/ObjectOptions';
import ObjectStars from './components/ObjectStars';
import Feed from './components/Feed';
import TreatmentFactors from './components/TreatmentFactors';
import LocationTypes from './components/LocationTypes';
import AccommodiationFeatures from './components/AccommodiationFeatures';

@inject('hotelStore')
@observer
class Edit extends Component {
  componentDidMount() {
    const { hotelStore } = this.props;
    hotelStore.fetch({ presenter: 'edit_view' });

    when(
      () => hotelStore.isFetched,
      (status) => this.formPopulate()
    );
  }

  componentWillUnmount() {
    const { hotelStore } = this.props;
    hotelStore.clear();
  }

  formPopulate = () => {
    const { form, hotelStore } = this.props;
    const values = hotelStore.hotel.toForm();

    form.update(values);
  }

  render() {
    const { form } = this.props;

    return (
      <Provider form={form}>
        <div className='page__body'>
          <div className='groups'>
            <GeneralInfo />
            <ObjectStars form={form} />
            <Contacts form={form} />
            <BillingHour form={form} />
            <Diseases form={form} />
            <ObjectOptions form={form} />
            <Feed form={form} />
            <TreatmentFactors form={form} />
            <LocationTypes form={form} />
            <AccommodiationFeatures form={form} />
            <AmenitiesAndServices form={form} />
            <PaymentDetails form={form} />
            <Actions form={form} />
          </div>
        </div>
      </Provider>
    );
  }
}

Edit.defaultProps = {
  form: new FormState(fields)
};

export default Edit;
