import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { computed } from 'mobx';

import PriceListsStore from 'Stores/PriceListStore/List';
import TopBar from 'Shared/components/TopBar/index';
import TopBarContent from './components/TopBarContent/index';
import PageBody from './components/PageBody/index';

@observer
class List extends Component {
  priceListsStore = PriceListsStore.create()

  @computed get isLoading() {
    return this.priceListsStore.isPending;
  }

  componentDidMount() {
    this.priceListsStore.fetch();
  }

  componentWillUnmount() {
    this.priceListsStore.clear();
  }

  render() {
    return (
      <Provider priceListsStore={this.priceListsStore}>
        <div className='layout__page'>
          <TopBar isLoading={this.isLoading}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            {this.priceListsStore.isFetched && <PageBody />}
          </div>
        </div>
      </Provider>
    );
  }
}

export default List;
