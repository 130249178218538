import styled from 'styled-components';

import { ReactComponent as RussiaIcon } from 'Icons/russia.svg';
import { ReactComponent as EnglandIcon } from 'Icons/united-kingdom.svg';
import CloseIcon from 'react-icons/lib/md/close';
import { ReactComponent as ChangeIcon } from 'Icons/edit_icon.svg';

import InputMask from 'react-input-mask';
import Textarea from 'react-textarea-autosize';
import PeriodRates from 'Pages/Tariffs/components/Form/PeriodRates';
import DateRangeInput from 'Pages/Tariffs/components/Form/Periods/DateRangeInput';
import Period from '../pages/Tariffs/components/Form/Periods/Period';
import Select from 'react-select';

const positionValue = 'absolute';
const topValue = '7px';
const leftValue = '5px';

export const RussiaIconInput = styled(RussiaIcon)`
  position: ${positionValue};
  top: ${topValue};
  left: ${leftValue};
`;

export const RatesChangeIcon = styled(ChangeIcon)`
  cursor: pointer;
`;

export const EnglandIconInput = styled(EnglandIcon)`
  position: ${positionValue};
  top: ${topValue};
  left: ${leftValue};
`;

export const InputMaskStyled = styled(InputMask)`
  padding-left: ${props => props.isLocalizeField && '25px'};
`;

export const TextareaStyled = styled(Textarea)`
  padding-left: ${props => props.isLocalizeField && '25px'};
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const RowGutters = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid #d7d3cc;
`;

export const RatesFormBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 15px 0px;
`;
export const TopControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PeriodStyled = styled(Period)`
  margin-top: 50px;
  &:first-child {
    margin-top: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  height: 47px;
`;

export const DateRangeInputStyled = styled(DateRangeInput)`
  max-width: 220px;
`;

export const ReadableDateRange = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding-bottom: 13px;
  color: silver;
  > :last-child {
    width: 15%;
  }
`;

export const Label = styled.div``;

export const Value = styled.div`
  width: 15%;
  input {
    width: 100% !important;
  }
`;

export const PeriodRatesStyled = styled(PeriodRates)`
  && {
    margin-bottom: 18px;
  }
`;

export const DataRangeError = styled.div`
  color: red;
`;

export const ListHeaderLeft = styled.div`
  width: 70%;
`;

export const ListHeaderRate = styled.div`
  display: flex;
  border-bottom: 1px solid silver;
  padding-bottom: 13px;
  color: silver;
  > :last-child {
    width: 15%;
  }
`;

export const RatesItem = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid #d7d3cc;
`;

export const RatesContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemDesctription = styled.div`
  width: 68%;
`;

export const ItemPrice = styled.div`
  width: 20%;
`;

export const ItemAction = styled.div`
  width: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RatesCloseIcon = styled(CloseIcon)`
  font-size: 20px;
  cursor: pointer;
`;

export const RatesFormButtons = styled.div`
  margin-top: 15px;
`;

export const RatesFormLeft = styled.div`
  width: 70%;
  display: flex;
`;

export const RatesFormRight = styled.div`
  width: 30%;
`;

export const FormFieldsGroupLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const FormFieldsGroupRight = styled.div`
  width: 60%;
  margin-right: 12px;
`;
export const AgeInputs = styled.div`
  display: flex;
`;

export const AgeGroupSelect = styled(Select)`
  width: ${props => (props.isChild ? '100%' : '60%')};
  margin-right: 12px;
`;

export const OccupationSelect = styled(Select)`
  width: 40%;
  margin-right: 12px;
`;

export const SliderLongMark = styled.div`
  border-left: 1px solid #e5e1dc;
  height: 16px;
`;

export const SliderShortMark = styled.div`
  border-left: 1px solid #e5e1dc;
  height: 10px;
`;

export const SliderMarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectedValue = styled.span`
  display: flex;
  position: absolute;
  padding-top: ${props => (props.size === 'long' ? '36px' : '40px')};
  font-size: 14px;
`;

export const MarkValue = styled.span`
  font-size: 11px;
`;

export const ShortMarkValue = styled.span`
  font-size: 11px;
  padding-top: 6px;
`;

export const SliderLabel = styled.div`
  display: flex;
`;

export const SliderFormFields = styled.div`
  margin-bottom: 44px;
`;

export const SliderLabelMute = styled.p`
  margin-right: 15px;
  opacity: 0.55;
`;

export const MuteText = styled.span`
  color: #999999;
  font-size: 16px;
`;

export const SourceName = styled.div``;

export const SliderTitle = styled.div`
  font-size: 18px;
  margin-bottom: 18px;
  ${SourceName} {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  ${MuteText} {
    margin-left: 8px;
  }
`;

export const SliderBlock = styled.div`
  padding-top: 15px;
`;

export const SliderSegment = styled.div`
  padding: 1.875rem 1.875rem 1.875rem;
`;

export const SliderWrapper = styled.div``;

export const TimeLabel = styled.div`
  display: flex;
`;

export const Time = styled.div`
  margin-left: 6px;
`;
