import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import GeneralInfo from '../GeneralInfo';
import RatesAndPrices from '../RatesAndPrices';
import RatesRules from '../RatesRules';
import Service from '../Service';
import CancellationPolicy from '../CancellationPolicy';
import PriceCalculationRule from '../PriceCalculationRule';
// import ReleasePeriod from '../ReleasePeriod';
import ManipulationPrograms from '../ManipulationPrograms';
import Promo from '../Promo';
import FeedTypesAndMealOptions from '../FeedTypeAndMealOption';
import StopSales from '../StopSales';
import LengthOfStayRestrictions from '../LengthStayRestrictions';
import AdvanceBookingRestrictions from '../AdvanceBookingRestrictions';
import LengthStayArrivalBasedRestrictions from '../LengthStayArrivalBasedRestrictions';
import ClosedByDateRestrictions from '../ClosedByDateRestrictions';
// import ClosedByWeekdayRestrictions from '../ClosedByWeekdayRestrictions';

// TODO hide restriction by weekday
// TODO hide ReleasePeriod
@withRouter
@observer
class PageBody extends Component {
  @computed get showRules() {
    return !this.props.form.isTour;
  }

  @computed get showCancellationPolicy() {
    return this.props.form.showCancellationPolicy;
  }

  render() {
    const { form } = this.props;
    if (!form) return null;
    return (
      <div className='page__body wide'>
        <GeneralInfo form={form} />

        {this.showRules && (
          <PriceCalculationRule form={form} />
        )}

        {/*<ReleasePeriod form={form} />*/}

        <RatesAndPrices form={form} />

        {this.showRules && (
          <RatesRules form={form} />
        )}

        <StopSales form={form} />

        <LengthOfStayRestrictions form={form} />

        <AdvanceBookingRestrictions form={form} />

        <LengthStayArrivalBasedRestrictions form={form} />

        <ClosedByDateRestrictions form={form} />

        {/*<ClosedByWeekdayRestrictions form={form} />*/}

        <FeedTypesAndMealOptions form={form} />

        <ManipulationPrograms form={form} />

        <Service form={form} />

        {this.showCancellationPolicy && (
          <CancellationPolicy form={form} />
        )}

        <Promo form={form} />
      </div>
    );
  }
}

PageBody.propTypes = {
  form: PropTypes.object.isRequired
};

export default PageBody;
