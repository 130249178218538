import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';

import GeneralInfo from '../GeneralInfo';
import AmenitiesAndServices from '../AmenitiesAndServices';
import { Groups, ContentBlock, Title } from './PageContent.styled';
import { FormattedMessage } from 'react-intl';

@withRouter
@observer
class PageContent extends Component {
  @computed get isLoading() {
    const { room_type } = this.props;
    return room_type.isPending;
  }

  render() {
    const { form } = this.props;
    return (
      <Groups>
        <Title>
          <FormattedMessage id='room_types.room_description' />
        </Title>
        <ContentBlock>
          <GeneralInfo form={form} />
          <AmenitiesAndServices form={form} />
        </ContentBlock>
      </Groups>
    );
  }
}

PageContent.propTypes = {
  room_type: PropTypes.object.isRequired,
  form: PropTypes.object
};

export default PageContent;
