import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from 'Utils/formatMessage';

@observer
class Manipulation extends Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired
  }

  @computed get valueString() {
    const {
      tariff: { manipulation: { quantity } }
    } = this.props;

    const currentValue = (quantity || []).sort();

    if (currentValue.length === 0) {
      return formatMessage(
        'tariffs.manipulation.quantity_string',
        { count: 0 }
      );
    }

    if (currentValue.length === 1) {
      return formatMessage(
        'tariffs.manipulation.quantity_string',
        { count: currentValue[0] }
      );
    }

    if (currentValue[0] === 0) {
      return formatMessage(
        'tariffs.manipulation.quantity_to',
        { count: currentValue.reverse()[0] }
      );
    }

    return formatMessage(
      'tariffs.manipulation.quantity_between',
      { from_count: currentValue[0], to_count: currentValue.reverse()[0] }
    );
  }

  render() {
    const { tariff: { manipulation: { disclaimer } } } = this.props;

    return (
      <div className='row'>
        <div className='col col-12'>
          <div className='options'>
            <div className='options__item'>
              <span className='key'>
                <FormattedMessage id='tariffs.manipulation.self' />
              </span>

              <span className='value'>
                {this.valueString}
              </span>

              {disclaimer && (
                <span className='value'>
                  {disclaimer}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Manipulation;
