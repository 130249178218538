import {
  Dates,
  Type,
  Manager,
  Status,
  Travellers
} from './Filter';

import { formatMessage } from 'Utils/formatMessage';

const filters = [
  {
    type: 'check_in',
    label: formatMessage('orders.check_in'),
    component: Dates,
    fields: ['check_in']
  },
  {
    type: 'check_out',
    label: formatMessage('orders.check_out'),
    component: Dates,
    fields: ['check_out']
  },
  {
    type: 'source',
    label: formatMessage('orders.type'),
    component: Type,
    fields: ['source']
  },
  {
    type: 'manager',
    label: formatMessage('orders.manager'),
    component: Manager,
    fields: ['manager']
  },
  {
    type: 'state',
    label: formatMessage('orders.status'),
    component: Status,
    fields: ['state']
  },
  {
    type: 'traveller',
    label: formatMessage('orders.guestName'),
    component: Travellers,
    fields: ['traveller']
  }
];

export default filters;
