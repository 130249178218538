import * as React from 'react';
import styled from 'styled-components';
import { filter } from 'fuzzaldrin-plus';

import { NSSogaz } from '@types';
import { useSogaz } from 'pages/Sogaz/store/hooks';
import { compareServiceAdd, compareServiceRemove } from 'pages/Sogaz/store/actions';

import { Table, Small } from 'evergreen-ui';
import EmptyAlert from './EmptyAlert';

const Wrapper = styled.div`
  height: 100%;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  services: NSSogaz.TServiceGroup[],
  onCompare: (item: NSSogaz.TServiceGroup) => void
}>

type TQuery = {
  chars: string
}

function Services({ services, onCompare, className }: Props) {
  const [query, setQuery] = React.useState<TQuery>({ chars: '' });

  const { state, dispatch } = useSogaz();

  if (services.length === 0) {
    return <EmptyAlert />;
  }

  const handleChange = (chars: string): void => {
    setQuery({ ...query, chars });
  };

  const selectedService = React.useMemo(() => {
    return state.compare.service;
  }, [state.compare.service]);

  const filteredServices = React.useMemo(() => {
    const chars = String(query.chars).trim();

    if (chars.length === 0) {
      return services;
    }

    return services.filter(service => {
      const result = filter([service.service_name], chars);
      return result.length === 1;
    });
  }, [services, query.chars]);

  const handleSelect = (service: NSSogaz.TServiceGroup) => () => {
    dispatch(compareServiceAdd({ service }));
  };

  const handleDeselect = (service: NSSogaz.TServiceGroup) => () => {
    dispatch(compareServiceRemove());
  };

  return (
    <Wrapper className={className}>
      <Table.Head>
        <Table.SearchHeaderCell
          placeholder='Название услуги...'
          onChange={handleChange}
        />
      </Table.Head>

      <Table.Body height={'calc(100% - 55px)'}>
        {filteredServices.map(service => (
          <Table.Row
            key={service.service_id}
            height={48}
            isSelectable
            isSelected={selectedService && selectedService.service_id === service.service_id}
            onSelect={handleSelect(service)}
            onDeselect={handleDeselect(service)}
          >
            <Table.TextCell>
              {service.service_name}
              <br />
              <Small>
                {service.service_code}
              </Small>
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Wrapper>
  );
}

export default Services;
