import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';

import { DocumentsCopyIcon } from 'Components/ui/Icons';
import Button from 'components/ui/Button/Button';
import SourceItem from '../Source';
import ConfirmModal from '../ConfirmModal';
import { formatMessage } from 'Utils/formatMessage';

const GroupItem = styled.div`
  margin-bottom: 2rem;
`;

const GroupItemContent = styled.div`
  width: auto;
  height: auto;
  background-color: #fff;
`;

const IntegrationsSegment = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  padding: 30px;
  border: 1px solid #dcd8d2;
  box-sizing: border-box;
  background-color: #fff;
  min-width: 319px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
`;

const TypeTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

const Status = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  color: ${(props) => (props.isConnected ? '#58CA97' : '#D2D1CC')};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;

  &:last-child {
    gap: 0;
  }
`;

const CopyText = styled.div`
  position: absolute;
  //display: none;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  right: -15px;
`;

const IntegretionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const SubTitle = styled.span`
  color: #999;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const IconWrapper = styled.div`
  position: relative;
  cursor: pointer;

  ${({ hasCookie }) =>
    !hasCookie &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

@inject('integrationsStore')
@observer
class Integration extends Component {
  state = {
    openModal: false,
    showCopy: false
  };

  closeModalHandler = () => {
    this.setState({ openModal: false });
  };

  render() {
    const {
      connection_status,
      id,
      sources,
      type,
      hotel_id
    } = this.props.integration;
    const integrationType = type === 'travel_line' && 'TravelLine';
    const isConnected = connection_status === 'active' && true;
    const status =
      connection_status === 'active' ? 'Подключено' : 'Неподключено';
    const hasCookie = Cookies.get('restriction');

    const handleClick = () => {
      if (isConnected) {
        this.setState({ openModal: true });
      }
      if (!isConnected) {
        this.props.integrationsStore.activate(id);
      }
    };

    const deactivateIntegration = () => {
      this.props.integrationsStore.deactivate(id, []);
      this.setState({ openModal: false });
    };

    const handleCopy = (e) => {
      this.setState({ showCopy: true });
      e.stopPropagation();
      navigator.clipboard.writeText(hotel_id);
      setTimeout(() => this.setState({ showCopy: false }), 2000);
    };

    return (
      <>
        <GroupItem>
          <GroupItemContent>
            <IntegrationsSegment>
              <Header>
                <TypeTitle>{integrationType}</TypeTitle>
                <Status isConnected={isConnected}>{status}</Status>
              </Header>
              <Content>
                {sources.map(({ name, active, id: sourceId }) => {
                  const subtitle = active ? 'Активен' : 'Неактивен';
                  return (
                    <SourceItem
                      name={name}
                      id={id}
                      active={active}
                      subtitle={subtitle}
                      key={sourceId}
                      sourceId={sourceId}
                      hasCookie={hasCookie}
                    />
                  );
                })}

                <IntegretionWrapper>
                  <TitleWrapper>
                    <Title>Идентификатор</Title>
                    <SubTitle>{hotel_id}</SubTitle>
                  </TitleWrapper>

                  <IconWrapper hasCookie={hasCookie} onClick={handleCopy}>
                    <DocumentsCopyIcon fontSize="24px" />
                    {this.state.showCopy && (
                      <CopyText>{formatMessage('shared.copy')}</CopyText>
                    )}
                  </IconWrapper>
                </IntegretionWrapper>
              </Content>
              <Button
                size="large"
                variant={isConnected ? 'danger' : 'primary'}
                loading={this.props.integrationsStore.isPending}
                onClick={handleClick}
                disabled={!hasCookie}
              >
                <FormattedMessage
                  id={!isConnected ? 'shared.switch' : 'shared.switch_off'}
                />
              </Button>
            </IntegrationsSegment>
          </GroupItemContent>
        </GroupItem>
        {this.state.openModal && (
          <ConfirmModal
            title={<FormattedMessage id="shared.integration_title" />}
            description={
              <FormattedMessage id="shared.integration_description" />
            }
            onApply={deactivateIntegration}
            onCancel={this.closeModalHandler}
          />
        )}
      </>
    );
  }
}

Integration.propTypes = {
  integration: PropTypes.object.isRequired,
  integrationsStore: PropTypes.integrationsStore
};

export default Integration;
