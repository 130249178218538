import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CloseIcon from 'react-icons/lib/md/close';

@withRouter
@observer
class Navigation extends Component {
  handleClose = () => {
    this.props.history.push('/orders');
  }

  render() {
    return (
      <div className='navigation'>
        <div className='content'>
          <div className='navigation__item' />

          <div className='navigation__item'>
            <CloseIcon onClick={this.handleClose} />
          </div>
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  history: PropTypes.func.isRequired
};

export default Navigation;
