import { PropTypes } from 'mobx-react';
import React from 'react';

export default class SearchInput extends React.Component {
  static propTypes = {
    startSearching: PropTypes.func,
    searchInput: PropTypes.any,
    setSearchInput: PropTypes.func
  }

  render() {
    return (
      <input
        className='input react-infinity-menu-default-search-input'
        onClick={this.props.startSearching}
        value={this.props.searchInput}
        onChange={this.props.setSearchInput}
      />
    );
  }
}
