import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';

@inject('registryStore')
@observer
class WritingValue extends Component {
  @computed get isSelected() {
    const { selectableKey, registryStore } = this.props;
    return registryStore.isSelected(selectableKey);
  }

  componentDidMount() {
    const { registryStore, selectableKey, day, room_type, source, value } = this.props;

    const item = {
      key: selectableKey,
      domNode: ReactDOM.findDOMNode(this),
      data: {
        day: day,
        room_type: {
          id: room_type.id,
          name: room_type.name
        },
        source: {
          id: source.id,
          name: source.name
        },
        quantity: value
      }
    };

    registryStore.register(selectableKey, item);
  }

  componentWillUnmount() {
    const { registryStore, selectableKey } = this.props;
    registryStore.unregister(selectableKey);
  }

  render() {
    const restrictionKlass = classNames('text', {
      selected: this.isSelected
    });

    return (
      <span className={restrictionKlass}>
        {this.props.value}
      </span>
    );
  }
}

WritingValue.propTypes = {
  value: PropTypes.number.isRequired,
  room_type: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  selectableKey: PropTypes.string.isRequired
};

export default WritingValue;
