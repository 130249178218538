import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates';
import moment from 'Utils/moment';

import ArrowLeftIcon from 'react-icons/lib/md/chevron-left';
import ArrowRightIcon from 'react-icons/lib/md/chevron-right';
import CloseIcon from 'react-icons/lib/md/close';

@observer
class SingleDateInput extends Component {
  constructor(props) {
    super(props);

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  @observable focusedInput = false;

  @computed get dateObjs() {
    const { value } = this.props.date;
    return value ? moment(value) : null;
  }

  isOutsideRange(day) {
    return false;
  }

  onDateChange(newDate) {
    const { date, customValidation } = this.props;
    if (newDate) newDate = newDate.format('Y-MM-DD');

    if (customValidation && newDate == null) {
      date.set('');
      date.invalidate("date cant't be blank");
    } else {
      date.set(newDate);
      date.resetValidation();
    }
  }

  onFocusChange(focusedInput) {
    this.focusedInput = focusedInput.focused;
  }

  render() {
    const {
      className,
      showLabel,
      date,
      disabled,
      enableOutsideDays,
      isOutsideRange
    } = this.props;

    const klass = classNames('form__field', className, {
      error: date.error
    });

    const isOutsideRangeFunc = isOutsideRange || this.isOutsideRange;

    return (
      <div className={klass}>
        {showLabel &&
          <label htmlFor={date.id}>
            {date.label}
            <span className='error'>{date.error && '*'}</span>
          </label>}

        <SingleDatePicker
          date={this.dateObjs}
          onDateChange={this.onDateChange}
          focused={this.focusedInput}
          onFocusChange={this.onFocusChange}
          showDefaultInputIcon={false}
          hideKeyboardShortcutsPanel
          daySize={32}
          id={date.id}
          placeholder={date.placeholder}
          navPrev={<ArrowLeftIcon />}
          navNext={<ArrowRightIcon />}
          disabled={disabled}
          readOnly={disabled}
          enableOutsideDays={enableOutsideDays}
          isOutsideRange={enableOutsideDays ? isOutsideRangeFunc : undefined}
          customCloseIcon={<CloseIcon />}
        />
      </div>
    );
  }
}

SingleDateInput.defaultProps = {
  className: '',
  showLabel: true,
  disabled: false,
  enableOutsideDays: false,
  isOutsideRange: false,
  customValidation: false
};

SingleDateInput.propTypes = {
  date: PropTypes.object.isRequired,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  enableOutsideDays: PropTypes.bool,
  isOutsideRange: PropTypes.bool,
  customValidation: PropTypes.bool
};

export default SingleDateInput;
