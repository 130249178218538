import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction, computed } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Select from 'react-select';

import ArrowDownIcon from 'react-icons/lib/md/keyboard-arrow-down';
import ArrowUpIcon from 'react-icons/lib/md/keyboard-arrow-up';

@observer
class SingleSelectObj extends Component {
  componentDidMount() {
    this.selectFirstHandler = reaction(
      () => this.selectOptions[0],
      (selected) => !this.props.isLoading && selected && this.selectFirstValue(selected),
      { fireImmediately: true }
    );

    this.unselectSelectedHandler = reaction(
      () => this.selectOptions.length === 0 && !this.props.isLoading,
      (selected) => selected && this.unselectSelected()
    );
  }

  componentWillUnmount() {
    this.selectFirstHandler();
    this.unselectSelectedHandler();
  }

  @computed get selectOptions() {
    const { field, options } = this.props;

    return options || field.extra || [];
  }

  selectFirstValue = (selected) => {
    const { selectFirst, onChange } = this.props;
    const onChangeFunc = onChange || this.onChange;

    if (selectFirst) onChangeFunc(selected);
  }

  unselectSelected = () => {
    const { onChange } = this.props;
    const onChangeFunc = onChange || this.onChange;

    onChangeFunc(null);
  }

  arrowRenderer = ({ onMouseDown, isOpen }) => {
    return isOpen
      ? <ArrowUpIcon />
      : <ArrowDownIcon />;
  }

  onChange = (value) => {
    const { field, simpleValue, clearable } = this.props;

    if (!value && !!clearable) {
      field.reset();
      return;
    }

    simpleValue
      ? field.set(value)
      : field.update(value);
  }

  @computed get hasErrors() {
    const { field, valueKey } = this.props;

    return field.has(valueKey) && field.$(valueKey).error;
  }

  @computed get inputClsName() {
    const { field } = this.props;

    const klass = classNames({
      error: this.hasErrors && !field.focused
    });

    return klass;
  }

  render() {
    const {
      className,
      style,
      field,
      isLoading,
      showLabel,
      clearable,
      searchable,
      valueKey,
      labelKey,
      simpleValue,
      onChange,
      description
    } = this.props;

    const klass = classNames('form__field', className);

    const changeHandler = onChange || this.onChange;

    return (
      <div className={klass} style={style}>
        {showLabel &&
          <label htmlFor={field.id}>
            {field.label}
            <span className='error'>{this.hasErrors && '*'}</span>
          </label>}

        <Select
          {...field.bind({ onChange: changeHandler })}
          isLoading={isLoading}
          className={this.inputClsName}
          valueKey={valueKey}
          labelKey={labelKey}
          options={this.selectOptions}
          clearable={clearable}
          searchable={searchable}
          simpleValue={simpleValue}
          disabled={!this.selectOptions.length}
          arrowRenderer={this.arrowRenderer}
        />

        {!!description &&
          <div className='desc'>
            {description}
          </div>}
      </div>
    );
  }
}

SingleSelectObj.defaultProps = {
  isLoading: false,
  clearable: false,
  showLabel: true,
  searchable: false,
  valueKey: 'value',
  labelKey: 'label',
  simpleValue: true,
  onChange: undefined,
  selectFirst: false,
  className: 'form__field',
  style: {}
};

SingleSelectObj.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  description: PropTypes.node,
  isLoading: PropTypes.bool,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  showLabel: PropTypes.bool,
  simpleValue: PropTypes.bool,
  selectFirst: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
};

export default SingleSelectObj;
