import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import LanguageControl from './LanguageControl';

@inject('store')
@observer
class MenuAction extends Component {
  onLogout = () => {
    const { userStore } = this.props.store;
    userStore.logout();
  }

  render() {
    const { userStore } = this.props.store;
    const { email } = userStore;

    return (
      <div className='layout__menu-action'>
        <div className='content'>
          <div className='description'>
            <span>{email}</span>
          </div>

          <div className='control'>
            <div className='control__exit'>
              <button className='button dark' onClick={this.onLogout}>
                <FormattedMessage id='shared.logout' />
              </button>
            </div>

            <LanguageControl />
          </div>
        </div>
      </div>
    );
  }
}

export default MenuAction;
