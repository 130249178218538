import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import { Amenity } from '../models/Amenity';

export const RoomTypeAmenitiesStore = types.model('RoomTypeAmenitiesStore', {
  amenities: types.optional(types.array(Amenity), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    instance.get('/api/filters/room_type_amenities', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { amenities: [] };
    applySnapshot(this, data);
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  resetStore(response) {
    const { data } = response;

    applySnapshot(this, data);
    return data;
  }
});
