import { types } from 'mobx-state-tree';

import { Traveller } from './Traveller';
import { Rate } from './Rate';
import { Price } from 'Stores/models/Price';

export const Slot = types.model('Slot', {
  id: types.identifier(types.string),
  count: types.number,
  price: Price,
  cash: types.boolean,
  rate: Rate,
  contract: types.frozen,
  prices: types.frozen,
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  travellers: types.optional(types.array(Traveller), []),

  get isBooked() {
    return this.state === 'booked';
  },

  get isConfirmed() {
    return this.state === 'confirmed';
  },

  get isCancelled() {
    return this.state === 'cancelled';
  }
});
