import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class ServicesCaption extends Component {
  render() {
    const { tariffs } = this.props;

    return (
      <div className='caption'>
        <div className='item'>
          Услуга
        </div>
        <div className='item'>
          Стоимость
        </div>
      </div>
    );
  }
}

ServicesCaption.propTypes = {
  tariffs: PropTypes.object.isRequired
};

export default ServicesCaption;
