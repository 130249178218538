import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loaders';
import styled from 'styled-components';

import TariffHeader from './TariffHeader';
import TariffContent from './TariffContent';

import getTariffsList from './utils/getTariffsList';

const LoaderContainer = styled.div``;

function Tariff({
  getTariff,
  tariff,
  fetchTariffs,
  removeTariff,
  activateTariff,
  deactivateTariff,
  match: {
    params: {
      id
    }
  }
}) {
  const wrapperRef = useRef();
  const findTariff = ({ tariff, id }) => {
    if (id === tariff.id) {
      return tariff;
    }

    if (!tariff.children) return null;

    return findTariff({ id, tariff: tariff.children[0] });
  };

  const isActiveTariff = findTariff({ tariff, id });

  const [pending, setPending] = useState();
  const [tariffsList, setTariffsList] = useState([]);
  const [activeTariff, setActiveTariff] = useState();

  const fetch = async () => {
    setPending(true);
    const data = await getTariff({ tariff: isActiveTariff });
    setPending(false);
    scrollTo();

    setActiveTariff(data);
  };

  useEffect(() => {
    if (isActiveTariff) {
      fetch();
      const list = getTariffsList({ tariff, id });
      setTariffsList(list);
    }
  }, [isActiveTariff]);

  const scrollTo = () => {
    const rect = wrapperRef.current.getBoundingClientRect();
    window.scrollTo({ top: rect.top + window.pageYOffset - 48 });
  };

  return (
    <div className='tariffs__item' ref={wrapperRef}>
      {isActiveTariff && activeTariff
        ? (
          <TariffContent
            tariff={activeTariff}
            tariffsList={tariffsList}
            removeTariff={removeTariff}
            fetchTariffs={fetchTariffs}
          />
          )
        : (
          <TariffHeader
            tariff={tariff}
            pending={pending}
            activateTariff={activateTariff}
            deactivateTariff={deactivateTariff}
          />
          )}

      {pending && (
        <LoaderContainer>
          <Loader
            className='loading'
            type='line-scale'
            active
            color='#999'
          />
        </LoaderContainer>
      )}
    </div>
  );
}

Tariff.propTypes = {
  tariff: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getTariff: PropTypes.func,
  removeTariff: PropTypes.func,
  fetchTariffs: PropTypes.func,
  activateTariff: PropTypes.func,
  deactivateTariff: PropTypes.func
};

export default withRouter(Tariff);
