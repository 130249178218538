import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';

export const isEmpty = (value) => {
  return (_isString(value) && _isEmpty(value)) ||
  (_isObject(value) && _isEmpty(value));
};

export const objectClean = (obj) => {
  Object.keys(obj).forEach(key =>
    (obj[key] && typeof obj[key] === 'object') &&
      objectClean(obj[key]) ||
      (_isString(obj[key]) && _isEmpty(obj[key])) && delete obj[key]
  );
  return obj;
};
