import styled from "styled-components";

export const Description = styled.div`
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: ${props => props.withMargin && '20px'};
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
`;


