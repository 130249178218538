import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getIcon } from 'Utils/tariffsRateIcon';

@observer
class RateName extends Component {
  get icon() {
    const { type } = this.props.rate;
    return getIcon(type);
  }

  render() {
    return this.icon;
  }
}

RateName.propTypes = {
  rate: PropTypes.object.isRequired
};

export default RateName;
