import { types, destroy } from 'mobx-state-tree';
import _uniqueId from 'lodash/uniqueId';
import _extend from 'lodash/extend';

import { Notification } from './Notification';

export const NotifyStore = types.model('NotifyStore', {
  notifications: types.optional(types.array(Notification), [])
}, {
  create(data) {
    const msg = _extend(data, {
      id: _uniqueId('notify_')
    });

    this.notifications.push(msg);
  },

  prepareDummy() {
    const msg = {
      id: _uniqueId('notify_'),
      type: 'error',
      header: 'Новое сообщение',
      messages: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      ]
    };

    this.notifications.push(msg);
  },

  removeNotification(rel) {
    destroy(rel);
  }
});
