import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import MdExpandMore from 'react-icons/lib/md/expand-more';

@inject('quotaTabsState')
@observer
class Source extends Component {
  @computed get isSelected() {
    const { quotaTabsState, source } = this.props;
    return quotaTabsState.isSelected(source);
  }

  toggleHandler = (e) => {
    e.preventDefault();

    const { quotaTabsState, source } = this.props;
    quotaTabsState.toggle(source);
  }

  render() {
    const { id, insurance } = this.props.source;

    const klass = classNames('quota actionable', {
      active: this.isSelected
    });

    return (
      <div className='source'>
        <div className='item__quotas'>
          <div className={klass} onClick={this.toggleHandler}>
            <div className='text'>
              <b>
                <FormattedMessage id='dashboard.quota.available' />・{insurance.name}
              </b>
            </div>
            <span className='icon'>
              <MdExpandMore />
            </span>
          </div>

          {this.isSelected &&
            <>
              <div className='quota'>
                <div className='text'>
                  <FormattedMessage id='dashboard.quota.booked' />
                </div>
              </div>

              <div className='quota'>
                <div className='text'>
                  <FormattedMessage id='dashboard.quota.allotment' />
                </div>
              </div>
            </>}
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  source: PropTypes.object.isRequired
};

export default Source;
