import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import List from './containers/List';
import Add from './containers/Add';
import Edit from './containers/Edit';

@withRouter
@observer
class RoomTypes extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path='/room_types/new'
          component={Add}
        />

        <Route
          exact
          path='/room_types/:id/edit'
          component={Edit}
        />

        <Route
          exact
          path='/room_types'
          component={List}
        />
      </Switch>
    );
  }
}

export default RoomTypes;
