import styled from 'styled-components';
import Button from 'Components/ui/Button';

export const DateRangePickerWrapper = styled.div`
  width: 230px;
`;

export const Description = styled.div`
  margin-bottom: 30px;
`;

export const FormContent = styled.div`
  margin-bottom: 20px;
  border-top: 1px solid #DCD8D2;
  border-bottom: 1px solid #DCD8D2;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const Footer = styled.div`
  border-top: ${props => props.showBorder ? '1px solid #DCD8D2' : 'none'};
  padding-top: ${props => props.showBorder ? '34px' : 'none'};
  display: flex;
  ${Button} + ${Button} {
    margin-left: 10px;
  }
`;

export const FormActions = styled.div`
  display: flex;
  ${Button} + ${Button} {
    margin-left: 10px;
  }
  margin-top: ${props => !props.isEditPeriod ? '20px' : 'none'};
`;

export const StopSalesPeriodWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const StopSalesPeriodTitle = styled.span`
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`;
