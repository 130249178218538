import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import { MealOptionsStore } from 'Stores/MealOptionsStore';
import SingleSelect from 'Shared/form/SingleSelectObj';

@observer
class MealOption extends Component {
  componentDidMount() {
    const { mealOptionsStore } = this.props;
    mealOptionsStore.fetch();
  }

  @computed get isLoading() {
    const { mealOptionsStore } = this.props;
    return mealOptionsStore.isPending;
  }

  @computed get options() {
    const { mealOptionsStore } = this.props;

    return mealOptionsStore.isFetched
      ? mealOptionsStore.meal_options.toJSON()
      : [];
  }

  render() {
    const { field } = this.props;
    return (
      <SingleSelect
        field={field}
        simpleValue={false}
        valueKey='tag'
        labelKey='name'
        searchable={false}
        clearable
        isLoading={this.isLoading}
        options={this.options}
      />
    );
  }
}

MealOption.defaultProps = {
  mealOptionsStore: MealOptionsStore.create()
};

MealOption.propTypes = {
  field: PropTypes.object.isRequired,
  mealOptionsStore: PropTypes.object.isRequired
};

export default MealOption;
