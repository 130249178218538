import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';

import PageContent from './components/PageContent';

@withRouter
@inject('buildingsStore')
@observer
class Add extends Component {
  render() {
    const { buildingsStore } = this.props;

    return (
      <div className='page__info very wide'>
        <div className='page__info-wrapper'>
          <Sticky>
            <PageContent />
          </Sticky>
        </div>
      </div>
    );
  }
}

export default Add;
