import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

@withRouter
@observer
class Info extends Component {
  render() {
    const {
      id,
      number,
      floor_number,
      room_type,
      building
    } = this.props.room;

    return (
      <div className='options padded'>
        <div className='options__item'>
          <span className='key'>
            <FormattedMessage id='rooms.block' />
          </span>
          {building
            ? <span className='value'>{building.name}</span>
            : <span className='value'><FormattedMessage id='rooms.not_specified' /></span>}
        </div>
        <div className='options__item'>
          <span className='key'>
            <FormattedMessage id='rooms.floor' />
          </span>
          <span className='value'>
            {floor_number}
          </span>
        </div>
        <div className='options__item'>
          <span className='key'>
            <FormattedMessage id='rooms.room_type' />
          </span>
          <span className='value'>
            {room_type
              ? room_type.name
              : <span><FormattedMessage id='rooms.not_specified' /></span>}
          </span>
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  room: PropTypes.object.isRequired
};

export default Info;
