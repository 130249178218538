import styled from 'styled-components';

import Popover from 'react-popover';
import Button from 'Components/ui/Button';
import MdAdd from 'react-icons/lib/md/add';

export const StyledPopover = styled(Popover)`
  border: 1px solid silver;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: 3px;
  padding: 10px;
  min-width: 160px;
  
  && {
    margin: 0;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  color: #666;
  line-height: 30px;
  :hover {
    color: #000;
  }
`;

export const Control = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 8px 0px 4px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition:
    padding-left 0.25s,
    padding-right 0.25s;

  :hover {
    background: #FFFF;
  }

  :not(:first-child) {
    margin-left: 4px;
  }

  :not(:last-child) {
    margin-right: 4px;
  }
`;

export const AddIcon = styled(MdAdd)`
  font-size: 20px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearAllFiltersControl = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  color: silver;
  cursor: pointer;
  transition: color 0.25s;
  user-select: none;
  margin-left: 30px;
`;
