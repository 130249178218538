import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const anim1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const anim3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const anim2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(16px, 0);
  }
`;

const Item = styled.div`
  position: absolute;
  top: 0;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 0px;
    animation: ${anim1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 0px;
    animation: ${anim2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 16px;
    animation: ${anim2} 0.6s infinite;
  }

  &:nth-child(4) {
    left: 32px;
    animation: ${anim3} 0.6s infinite;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  width: 44px;
  height: 8px;
  opacity: 1 !important;
`;

function Loader({ className }) {
  return (
    <Wrapper className={className}>
      <Item />
      <Item />
      <Item />
      <Item />
    </Wrapper>
  );
}

Loader.propTypes = {
  className: PropTypes.string
};

export default styled(Loader)``;
