import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Tariff from './Tariff';

@observer
class Tariffs extends Component {
  render() {
    const { tariffs } = this.props;

    const listItems = tariffs.map((item) => {
      return <Tariff key={item.id} tariff={item} />;
    });

    return (
      <div className='tariffs'>
        {listItems}
      </div>
    );
  }
}

Tariffs.propTypes = {
  tariffs: PropTypes.object.isRequired
};

export default Tariffs;
