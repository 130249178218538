import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

@withRouter
@inject('dashboardStore', 'reservationStore', 'searchState')
@observer
class Actions extends Component {
  @computed get canConfirm() {
    const { reservation } = this.props.reservationStore;
    return reservation.isBooked;
  }

  @computed get canCancel() {
    const { reservation } = this.props.reservationStore;
    return !reservation.isCancelled;
  }

  confirmHandler = (e) => {
    e.preventDefault();

    this.props.reservationStore.confirmOrder()
      .then(() => this.props.history.push('/dashboard'))
      .then(() => this.fetchDashboardStore());
  }

  cancelHandler = (e) => {
    e.preventDefault();

    this.props.reservationStore.cancelOrder()
      .then(() => this.props.history.push('/dashboard'))
      .then(() => this.fetchDashboardStore());
  }

  fetchDashboardStore() {
    const { dashboardStore, searchState } = this.props;
    const { check_in, check_out } = searchState.values();

    dashboardStore.fetch({ check_in, check_out });
  }

  render() {
    return (
      <div className='buttons'>
        {this.canConfirm &&
          <button
            className='button gray'
            onClick={this.confirmHandler}
          >
            <FormattedMessage id='dashboard.confirm_booking' />
          </button>}

        {this.canCancel &&
          <button
            className='button red'
            onClick={this.cancelHandler}
          >
            <FormattedMessage id='dashboard.cancel_booking' />
          </button>}
      </div>
    );
  }
}

export default Actions;
