import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import FeedType from './FeedType';
import MealOptions from './MealOptions';

class Feed extends Component {
  render() {
    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.feed' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <FeedType form={this.props.form} />
            <MealOptions form={this.props.form} />
          </div>
        </div>
      </div>
    );
  }
}

Feed.defaultProps = {
  form: PropTypes.object.isRequired
};

export default Feed;
