import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import TopBarSearchBox from './TopBarSearchBox';

@observer
class TopBarContent extends Component {
  render() {
    const { form, toggleModalOpen } = this.props;

    return (
      <div className="layout__topbar-content">
        <div className="layout__topbar-content-wrapper">
          <TopBarSearchBox form={form} toggleModalOpen={toggleModalOpen} />
        </div>
      </div>
    );
  }
}

TopBarContent.propTypes = {
  form: PropTypes.object.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  toggleHideAllRooms: PropTypes.func.isRequired,
  hideAllRooms: PropTypes.bool.isRequired
};

export default TopBarContent;
