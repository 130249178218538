import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from './components/TopBarContent';
import List from './containers/List';
import Add from './containers/Add';

import { HotelStore } from 'Stores/HotelStore';

@inject('store')
@observer
class Photo extends Component {
  componentDidMount() {
    this.props.hotelStore.fetch();
  }

  componentWillUnmount() {
    this.props.hotelStore.clear();
  }

  render() {
    const { hotelStore } = this.props;

    return (
      <Provider hotelStore={this.props.hotelStore}>
        <div className='layout__page'>
          <TopBar isLoading={hotelStore.isPending}>
            <TopBarContent />
          </TopBar>

          <div className='layout__page-content'>
            <List />

            <Switch>
              <Route
                exact
                path='/photo/add'
                component={Add}
              />
            </Switch>
          </div>
        </div>
      </Provider>
    );
  }
}

Photo.defaultProps = {
  hotelStore: HotelStore.create()
};

export default Photo;
