import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
class Item extends Component {
  render() {
    const { item, width } = this.props;

    const num = item.date();
    const day = item.format('dd');
    const month = item.format('MMMM');

    const dayOfWeek = item.day();
    const isDayOff = (dayOfWeek == 0 || dayOfWeek == 6);
    const isMonthStart = num == 1;

    const showNotification = false;

    const selfClassName = classNames('item', {
      'week-day': !isDayOff,
      'week-start': dayOfWeek == 1,
      'week-end': dayOfWeek == 5,
      'day-off': isDayOff
    });

    const selfStyle = {
      width: width
    };

    return (
      <div
        className={selfClassName}
        style={selfStyle}
      />
    );
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
};

export default Item;
