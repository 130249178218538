import { types } from 'mobx-state-tree';

import { Address } from './Address';
import { Image } from './Image';
import { Price } from './Price';
import { Room } from './Room';
import { RoomType } from './RoomType';
import { Tariff } from './Tariff';
import { Availability } from './Availability';

export const Hotel = types.model('Hotel', {
  id: types.identifier(types.string),
  address: Address,
  name: types.string,
  description: types.string,
  email: types.string,
  check_in_time: types.optional(types.string, '12:00'),
  check_out_time: types.optional(types.string, '10:00'),
  images: types.optional(types.array(Image), []),
  price: Price,
  phone: types.string,
  web_site: types.maybe(types.string),
  rooms: types.optional(types.array(Room), []),
  room_types: types.optional(types.array(RoomType), []),
  tariffs: types.optional(types.array(Tariff), []),
  availabilities: types.optional(types.array(Availability), []),
  tariffSelected: types.maybe(types.reference(Tariff))
});
