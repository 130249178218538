import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { TPriceListAction, TPriceListsShowResponse, ACTIONS } from '../types';
import { NSSogaz } from '@types';

const setPriceList = (price_list: NSSogaz.TPriceList) => {
  return async (dispatch: Dispatch<TPriceListAction>): Promise<void> => {
    try {
      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_PENDING });

      const { data }: AxiosResponse<TPriceListsShowResponse> =
        await instance.get(`/api/sogaz/price_lists/${price_list.id}`);

      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_FULLFILLED, payload: data.data });
    } catch (error) {
      dispatch({ type: ACTIONS.PRICE_LIST_FETCH_REJECTED });
    }
  };
};

export default setPriceList;
