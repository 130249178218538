const fields = [
  'check_in',
  'check_out',
  'days',
  'room_type',
  'room_type.id',
  'room_type.name',
  'sources[]',
  'sources[].id',
  'sources[].name',
  'sources[].quantity',
  'sources[].availability',
  'sources[].selected'
];

const defaults = {
  'sources[].quantity': 0,
  'sources[].availability': 0,
  'sources[].selected': false
};

const rules = {
  check_in: 'string|required',
  check_out: 'string|required',
  days: 'array|required|min:1',
  'room_type.id': 'string|required',
  'room_type.name': 'string|required',
  'sources[].id': 'string|required',
  'sources[].name': 'string|required',
  'sources[].quantity': 'integer|required',
  'sources[].availability': 'integer|required',
  'sources[].selected': 'boolean|required'
};

const types = {
  'sources[].selected': 'checkbox'
};

export default {
  fields,
  rules,
  defaults,
  types
};
