import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cleanDeep from 'clean-deep';
import DeleteIcon from 'react-icons/lib/md/delete';

@withRouter
@inject('store')
class Actions extends Component {
  updateHandler = (e) => {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler
    });
  }

  destroyHandler = (e) => {
    e.preventDefault();

    this.props.building.destroy()
      .then(_ => this.navigateTo('/buildings'));
  }

  successSubmitHandler = (form) => {
    const { building } = this.props;
    const values = cleanDeep({ ...form.values() });

    building.update(values)
      .then(_ => this.navigateTo(`/buildings/${building.id}`))
      .catch(error => this.errorSubmitHandler(error));
  }

  errorSubmitHandler(error) {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  navigateTo = (path) => {
    const { building, history } = this.props;
    history.push(path);
  }

  render() {
    return (
      <div className='actions'>
        <div className='group'>
          <div className='float-left'>
            <button
              onClick={this.updateHandler}
              className='green button'
            ><FormattedMessage id='shared.confirm' />
            </button>
            <Link
              to='/buildings'
              className='gray button'
            ><FormattedMessage id='shared.cancel' />
            </Link>
          </div>
          <div className='float-right'>
            <button
              className='button gray'
              onClick={this.destroyHandler}
            >
              <i className='icon'>
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Actions.propTypes = {
  form: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired
};

export default Actions;
