import styled from 'styled-components';

export const GeneralInfoHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  justify-content: space-between;
`;

export const GeneralInfoTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
`;

export const LanguageAction = styled.div`
  display: flex;
  align-items: center;
`;

export const FormFields = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
