import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from 'Utils/formatMessage';

@observer
class Status extends Component {
  @computed get status() {
    const { draft, state } = this.props.order;

    if (state == 'booked') {
      return {
        label: 'status booked',
        text: formatMessage('orders.statuses.booked')
      };
    } else if (state == 'confirmed' && draft) {
      return {
        label: 'status draft',
        text: formatMessage('orders.statuses.confirmed'),
        description: formatMessage('orders.statuses.confirmed_description')
      };
    } else if (state == 'confirmed' && !draft) {
      return {
        label: 'status confirmed',
        text: formatMessage('orders.statuses.confirmed')
      };
    } else if (state == 'cancelled') {
      return {
        label: 'status cancelled',
        text: formatMessage('orders.statuses.cancelled')
      };
    } else {
      return {
        label: 'status',
        text: formatMessage('orders.statuses.unknown')
      };
    }
  }

  render() {
    const { state } = this.props.order;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='orders.status' />
        </div>
        <div className='value'>
          <span className={this.status.label}>
            {this.status.text}
          </span>
        </div>
      </div>
    );
  }
}

Status.propTypes = {
  order: PropTypes.object.isRequired
};

export default Status;
