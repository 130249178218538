import styled from 'styled-components';
import Close from 'react-icons/lib/md/close';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  max-width: 360px;
  height: 32px;
  padding: 0 12px;
  background: #D2D1CC;
  border-radius: 4px;
  font-size: 0.8rem;
`;

export const StyledClose = styled(Close)`
  height: 16px;
  width: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

export const Caption = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Label = styled.span`
  opacity: 0.6;
`;

export const Value = styled.span`
  margin-left: 4px;
`;
