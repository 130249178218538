import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

export const MealOptions = types.model('MealOptions', {
  id: types.identifier(types.string),
  name: types.string,
  tag: types.string
}, {});

export const MealOptionsStore = types.model('MealOptionsStore', {
  meal_options: types.optional(types.array(MealOptions), []),
  state: types.optional(types.enumeration(['pending', 'done', 'error']), 'done'),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    instance.get('/api/tariffs/meal_options', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { sources: [] };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) {
      applySnapshot(this, data);
    }

    return data;
  },

  setState(state) {
    this.state = state;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  }
});
