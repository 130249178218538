import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Dates from './Dates';
import Availabilities from './Availabilities';
import Seats from './Seats';
import Slots from './Slots';
import Control from './Control';

@observer
class Form extends Component {
  render() {
    const { form } = this.props;

    return (
      <form className='form order__form'>
        <div className='order__form--header'>
          <div className='form__fields-group'>
            <Dates form={form} />
            <Availabilities form={form} />
            <Seats form={form} />
          </div>
        </div>

        <div className='order__form--content'>
          {form.bedsCount > 0
            ? <div className='form__fields-group'>
              <Slots slots={form.$('slots')} form={form} />
              </div>

            : <div className='form__fields-group'>
              <p className='text muted'>
                Для продолжения выберите даты заезда/выезда и один из доступных тарифов
              </p>
              </div>}
        </div>

        <div className='order__form--actions'>
          <Control form={form} />
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  form: PropTypes.object.isRequired
};

export default Form;
