import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { HotelMealOptionsStore } from 'stores/HotelMealOptionsStore';
import Loader from 'pages/Hotel/components/Loader';
import store from 'store';

@inject('hotelStore')
@observer
class MealOptions extends React.Component {
  propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    hotelMealOptionsStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialFeedType = form.$('meal_options').value;

        this.setState({
          selectedOptions: initialFeedType
        });
        this.fetchTypes();
      }
    );
  }

  state = {
    selectedOptions: []
  };

  fetchTypes = () => {
    const { hotelMealOptionsStore } = this.props;
    hotelMealOptionsStore.fetchChecked();
  };

  onSelect = currentTag => {
    const { selectedOptions } = this.state;
    const { hotelMealOptionsStore, form } = this.props;
    const selectedOption = hotelMealOptionsStore
      .toJSON()
      .options.filter(type => type.tag === currentTag)[0];
    const isOptionSelected = this.state.selectedOptions.some(
      type => type.tag === selectedOption.tag
    );
    let selectedData = [];

    if (isOptionSelected) {
      const index = selectedOptions.findIndex(
        type => type.tag === selectedOption.tag
      );
      const copyStateData = [...selectedOptions];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedOptions, selectedOption];
    }

    this.setState({
      selectedOptions: selectedData
    });

    form.update({ meal_options: selectedData });
  };

  render() {
    const { hotelMealOptionsStore } = this.props;
    const optionsData = hotelMealOptionsStore.toJSON().options;
    const generalLanguage = store.get('language');

    const checkboxList = optionsData.map((data, index) => {
      return (
        <div key={data.tag}>
          <input
            type='checkbox'
            key={data.tag}
            value={data.tag}
            className='input radio_button'
            checked={this.state.selectedOptions.some(
              type => type.tag === data.tag
            )}
          />
          <span className='radio_button_name'>
            {data.name_translations[generalLanguage]}
          </span>
        </div>
      );
    });

    return (
      <div>
        <div className='form__field--title--meal'>
          <label>
            <FormattedMessage id='hotel.meal_options' />
          </label>
        </div>
        <div>
          {hotelMealOptionsStore.isFetched
            ? (
              <>
                {checkboxList.map((checkbox, index) => (
                  <div
                    key={checkbox.key}
                    onClick={() => this.onSelect(checkbox.key)}
                    className={
                    index + 1 !== optionsData.length
                      ? 'radio_list_item'
                      : 'radio_list_item_last'
                  }
                  >
                    {checkbox}
                  </div>
                ))}
              </>
              )
            : (
              <Loader />
              )}
        </div>
      </div>
    );
  }
}

MealOptions.defaultProps = {
  form: PropTypes.object.isRequired,
  hotelMealOptionsStore: HotelMealOptionsStore.create()
};

export default MealOptions;
