import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

import { PriceList } from './PriceList';

const PriceListsStore = types.model('PriceListsStore', {
  price_lists: types.optional(types.array(PriceList), []),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  }

}, {
  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  toggle() {
    this.isActive = !this.isActive;
  },

  fetch(params = {}) {
    this.setState('pending');

    return instance.get('/api/price_lists', params)
      .then((response) => this.applySnapshot(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { price_lists: [] };
    applySnapshot(this, data);
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { data } = response;
    applySnapshot(this, data);

    return response;
  }
});

export default PriceListsStore;
