import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class TariffsCaption extends Component {
  render() {
    return (
      <div className='caption'>
        <div className='item name'>
          <FormattedMessage id='tariff.rate' />
        </div>
        <div className='item type'>
          <FormattedMessage id='tariff.type' />
        </div>
        <div className='item source'>
          <FormattedMessage id='tariff.source' />
        </div>
        <div className='item period'>
          <FormattedMessage id='tariff.available_period' />
        </div>

        <div className='item tariff-active' />
      </div>
    );
  }
}

export default TariffsCaption;
