import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import Value from './Value';

@inject('priceboardStore')
@observer
class Values extends Component {
  componentDidMount() {
    this.endDayReaction = reaction(
      () => this.props.priceboardStore.endDay,
      () => this.resetRateValues()
    );

    this.resetRateValues();
  }

  componentWillUnmount() {
    this.endDayReaction();
  }

  resetRateValues() {
    const { rate, priceboardStore } = this.props;
    const { startDay, endDay, grid } = priceboardStore;

    if (grid) { rate.resetValues(startDay, endDay); }
  }

  render() {
    const { rate } = this.props;
    const className = classNames(['rate__values', rate.type]);

    const listItems = rate.values
      .map(value => <Value key={value.id} value={value} />);

    return (
      <div className={className}>
        {listItems}
      </div>
    );
  }
}

Values.propTypes = {
  rate: PropTypes.object.isRequired
};

export default Values;
