import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { NSSogaz } from '@types';
import { Dispatch } from 'react';
import { ACTIONS, IAction, IAdapter, ICompareSubmit, TMappingsOneResponse } from '../types';

interface INewValue {
  currentMappings: NSSogaz.TMapping[],
  newMapping: NSSogaz.TMapping
}

const newValue = ({ currentMappings, newMapping }: INewValue): NSSogaz.TMapping[] => {
  const value = currentMappings
    .filter(m => m.rate.provider_id !== newMapping.rate.provider_id);

  return [...value, newMapping];
};

const compareSubmit = (payload: ICompareSubmit['payload']) => {
  return async (dispatch: Dispatch<IAction>, getState: () => IAdapter): Promise<void> => {
    dispatch({ type: ACTIONS.MAPPINGS_FETCH_PENDING });

    const { room_type, tariff, rate, service } = payload;

    const mapping: Omit<NSSogaz.TMapping, 'compare_status'> = {
      hotel: {
        platform_id: service.price_list_id
      },
      room_type: {
        provider_id: room_type.id,
        platform_id: service.lpu_id
      },
      tariff: {
        provider_id: tariff.id,
        platform_id: service.service_id
      },
      rate: {
        provider_id: rate.code,
        platform_id: service.service_code
      }
    };

    const response: AxiosResponse<TMappingsOneResponse> =
      await instance.post('/api/sogaz/mappings', { data: mapping });

    const { mappings: { data } } = getState();
    const mappingsValue = newValue({ currentMappings: data, newMapping: response.data.data });
    dispatch({ type: ACTIONS.MAPPINGS_FETCH_FULLFILLED, payload: mappingsValue });

    dispatch({ type: ACTIONS.COMPARE_RESET });
  };
};

export default compareSubmit;
