import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as MenuBackIcon } from 'Icons/menu-back.svg';

@inject('store')
class MenuHeader extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store.menuStore;
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(e) {
    e.preventDefault();
    this.store.toggle();
  }

  render() {
    return (
      <div className='layout-menu__header'>
        <FormattedMessage id='shared.menu_header' />

        <a href='' onClick={this.clickHandler}>
          <MenuBackIcon />
        </a>
      </div>
    );
  }
}

export default MenuHeader;
