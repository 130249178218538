import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import { Scrollbars } from 'react-custom-scrollbars';

import Navigation from '../Navigation';
import Header from '../Header';
import Actions from '../Actions';
import Description from '../Description';
import Reservation from '../Reservation';

@inject('reservationStore')
@observer
class PageBody extends Component {
  @computed get isHotelSource() {
    const { reservation } = this.props.reservationStore;
    return reservation.order.source.isHotel;
  }

  render() {
    let { reservation } = this.props.reservationStore;
    reservation = toJS(reservation);

    return (
      <div className='infoblock'>
        <Navigation reservation={reservation} />

        <div className='infoblock__body'>
          <div className='content'>
            <div className='order__form'>
              <div className='order__form--content'>
                <Header reservation={reservation} />

                <div className='reservation-group'>
                  <div className='reservation-group__content'>
                    <Description reservation={reservation} />
                    <Reservation reservation={reservation} />
                  </div>
                </div>
              </div>

              <div className='order__form--actions'>
                {this.isHotelSource && <Actions />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageBody;
