import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import { formatMessage } from 'Utils/formatMessage';
import { FormattedMessage } from 'react-intl';

import Switch from 'Components/ui/Switch';
import Checkbox from 'Components/v2/Checkbox';

const NumberFormatComponent = styled(NumberFormat)`
  width: 100px;
  min-width: 100px;

  ${({ error }) => error && css`
    border: 1px solid #FF5656;
  `}
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorLabel = styled.div`
  color: #FF5656;
  font-size: 12px;  
`;

const Wrapper = styled.div`
  ${Checkbox} {
    margin-top: 12px;
  }
`;

function PriceInput({ price, onChange, className, decimalPrecision, placeholder, error }) {
  const inputRef = useRef();
  const [prevValue, setPrevValue] = useState(null);
  const inputCls = classNames(className, 'input');

  const handleChangeSwitch = () => {
    if (price.amount === -1) {
      onChange(prevValue);
    } else {
      setPrevValue(cleanValue(inputRef.current.state.value));
      onChange(-1);
    }
  };

  const cleanValue = val => {
    return Number(val.replace(' ', ''));
  };

  const handleChangeInput = (val, { floatValue }) => {
    onChange(floatValue);
  };

  const handleChangeCheckbox = () => {
    if (price.amount === 0) {
      onChange(prevValue);
    } else {
      setPrevValue(cleanValue(inputRef.current.state.value));
      onChange(0);
    }
  };

  const getPlaceholder = () => {
    if (price.amount == 0) {
      return formatMessage('shared.unset');
    }

    if (price.amount < 0) {
      return formatMessage('shared.unactive');
    }

    return placeholder;
  };

  return (
    <Wrapper>
      <TopContent>
        <NumberFormatComponent
          ref={inputRef}
          placeholder={getPlaceholder()}
          value={price.amount}
          disabled={price.amount < 0}
          className={inputCls}
          onChange={handleChangeInput}
          error={error}
          thousandSeparator={' '}
          allowNegative={true}
          decimalPrecision={decimalPrecision}
        />

        <Switch active={price.amount >= 0} onClick={handleChangeSwitch} />
      </TopContent>

      <Checkbox
        value={price.amount === 0}
        onChange={handleChangeCheckbox}
        label={<FormattedMessage id='shared.free' />}
      />

      {error && (
        <ErrorLabel>
          {error}
        </ErrorLabel>
      )}
    </Wrapper>
  );
}

PriceInput.defaultProps = {
  placeholder: null,
  type: 'text',
  className: null,
  showLabel: false,
  decimalPrecision: 2
};

PriceInput.propTypes = {
  price: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  decimalPrecision: PropTypes.number,
  onChange: PropTypes.func
};

export default PriceInput;
