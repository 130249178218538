import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';

import PageContent from './components/PageContent';

@withRouter
@inject('roomsStore')
@observer
class Edit extends Component {
  @computed get room() {
    const { match, roomsStore } = this.props;
    if (!roomsStore.isFetched) return undefined;

    const { id } = match.params;
    return roomsStore.find(id);
  }

  render() {
    const { roomsStore } = this.props;

    return (
      <div className='page__info very wide'>
        <div className='page__info-wrapper'>
          <Sticky>
            {this.room && <PageContent room={this.room} />}
          </Sticky>
        </div>
      </div>
    );
  }
}

export default Edit;
