import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';

import Item from './Item';

@inject('store', 'priceboardStore')
@observer
class RowsGrid extends Component {
  render() {
    const { dayWidth, grid } = this.props.priceboardStore;

    const listItems = grid.map((item) => {
      return (
        <Item key={item.id} item={item} width={dayWidth} />
      );
    });

    return listItems;
  }
}

export default RowsGrid;
