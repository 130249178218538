import * as React from 'react';
import styled from 'styled-components';
import { useDownloader } from './hooks';
import { DateTime } from 'luxon';

import { NSSogaz } from '@types';
import { comparePriceList } from 'pages/Sogaz/store/actions';
import { useSogaz } from 'pages/Sogaz/store/hooks';

import {
  Heading, Text, Small, Popover, Menu, IconButton, MoreIcon, Position, Pane, Badge
} from 'evergreen-ui';

const Button = styled(IconButton)`
  transform: rotate(-90deg);
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${Button} {
    margin-left: 16px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 0 1px rgb(67 90 111 / 30%);
  box-sizing: border-box;
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1px rgb(67 90 111 / 30%), 0 2px 4px -2px rgb(67 90 111 / 47%);
  }

  ${Header} + ${Body} {
    margin-top: 8px;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  priceList: NSSogaz.TPriceList
}>

function PriceList({ priceList, className }: Props) {
  const {
    id,
    lpu_name,
    price_date,
    lpu_dog_number,
    lpu_dog_days_nights,
    services_count,
    queue,
    queue_at
  } = priceList;

  const { dispatch } = useSogaz();

  const { download } = useDownloader({
    getFileName: () => {
      const currentTime = (new Date()).getTime();
      return `${id}_${currentTime}.xlsx`;
    }
  });

  const formatDate = (value: string): string => {
    return DateTime.fromISO(value).setLocale('ru').toLocaleString(DateTime.DATETIME_SHORT);
  };

  const billingHour = lpu_dog_days_nights > 0 ? 'день' : 'ночь';

  const handleReportClick = async (callback: () => void) => {
    await download(`/api/sogaz/price_lists/${id}/report`);
    callback();
  };

  const handreCompareClick = async (callback: () => void) => {
    dispatch(comparePriceList(id));
    callback();
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Heading size={300}>
          {lpu_name}
          <br />
          <Small>
            {lpu_dog_number} от {price_date}
          </Small>
        </Heading>

        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                <Menu.Item onSelect={() => handleReportClick(close)}>
                  Скачать отчет .XSLX
                </Menu.Item>
                <Menu.Item onSelect={() => handreCompareClick(close)}>
                  Сопоставить с 1С:УСК
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        >
          <Button
            size='small'
            appearance='minimal'
            icon={MoreIcon}
          />
        </Popover>
      </Header>

      <Body>
        <Pane display="flex" alignItems="center" marginBottom={4}>
          <Pane flexBasis={100}>
            <Text size={300}>Тип расчета</Text>
          </Pane>
          <Pane>
            <Text size={300}>
            {billingHour}
            </Text>
          </Pane>
        </Pane>

        <Pane display="flex" alignItems="center" marginBottom={4}>
          <Pane flexBasis={100}>
            <Text size={300}>Кол-во услуг</Text>
          </Pane>
          <Pane>
            <Text size={300}>{services_count}</Text>
          </Pane>
        </Pane>

        <Pane display="flex" alignItems="center">
          <Pane flexBasis={100}>
            {queue === 'idle' && (
              <Badge color="yellow">Загружен</Badge>
            )}

            {queue === 'pending' && (
              <Badge color="blue">В очереди</Badge>
            )}

            {queue === 'fullfilled' && (
              <Badge color="green">обработан</Badge>
            )}

            {queue === 'rejected' && (
              <Badge color="red">ошибка</Badge>
            )}
          </Pane>
          <Pane>
            <Text size={300}>{formatDate(queue_at)}</Text>
          </Pane>
        </Pane>
      </Body>
    </Wrapper>
  );
}

export default styled(PriceList)``;
