import styled from 'styled-components';

export const RoomTypeView = styled.div`
  padding: 30px;
  margin-bottom: 15px;
  background-color: #fff;
  width: 672px;
  height: 223px;
`;

export const RoomTypeContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RoomTypeContentLeft = styled.div`
  width: 100%;
`;

export const RoomTypeContentRigth = styled.div`

`;

export const RoomTypeTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RoomTypeTitle = styled.div`
  font-size: 20px;
`;

export const RoomTypeCount = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: #58CA97;
  font-size: 16px;
  width: 24%;
`;

export const RoomTypeDescriptionLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 60px;
  justify-content: space-between;
`;

export const RoomTypeDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DescriptionItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionItemText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
`;

export const RoomTypeDescriptionRigth = styled.div`
  width: 50%;
`;

export const RoomTypeButtons = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const RoomTypeImage = styled.img`
  width: 170px;
  max-width: 170px;
  height: 100px;
  border-radius: 4px;
`;
