import React from 'react';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #EAE9E4;
`;

export const Body = styled.div`
  flex-grow: 1;
`;

export const Panel = styled.div`
  padding: 26px 30px;
  background: #FFFFFF;
  border: 1px solid #DCD8D2;
  box-sizing: border-box;
  border-radius: 4px;
`;

interface IContainer {
  $fullWidth?: boolean
}

// eslint-disable-next-line space-infix-ops
export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  box-sizing: border-box;
  flex-grow: 1;

  ${props => props.$fullWidth && css`
    width: calc(100% - 80px);
  `}

  ${props => !props.$fullWidth && css`
    min-width: 960px;
    max-width: 960px;
  `}
`;

export const SideRight = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 480px;
  max-width: 720px;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.13);
  overflow: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  background-color: #FFFFFF;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function Layout({ children, className }: Props) {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  );
}

export default Layout;
