import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import CloseIcon from 'react-icons/lib/md/close';

@observer
class Navigation extends Component {
  render() {
    const { order } = this.props.reservation;

    return (
      <div className='infoblock__navigation'>
        <div className='content'>
          <div className='navigation__item'>
            <Link to={`/orders/${order.id}/info`}>
              <span><FormattedMessage id='dashboard.all_orders' /></span>
            </Link>
          </div>

          <div className='navigation__item'>
            <Link to='/dashboard'>
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  reservation: PropTypes.object.isRequired
};

export default Navigation;
