import React from 'react';
import PropTypes from 'prop-types';
import Form from './RateForm';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import CloseIcon from 'react-icons/lib/md/close';
import { ReactComponent as ChangeIcon } from 'Icons/edit_icon.svg';

const RatesChangeIcon = styled(ChangeIcon)`
  cursor: pointer;
  color: #333333;
`;

const RatesItem = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid #d7d3cc;
`;

const RatesContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RatesCloseIcon = styled(CloseIcon)`
  font-size: 20px;
  cursor: pointer;
  color: #333333;
`;

const ItemDesctription = styled.div`
  width: 60%;
`;

const ItemPrice = styled.div`
  width: 15%;
`;

const ItemStatus = styled.div`
  width: 15%;
`;

const ItemAction = styled.div`
  width: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  ${({ inactive }) => inactive && css`
    color: #999999;
  `}
`;

const Rate = props => {
  const {
    rate,
    form,
    isOpenForm,
    rateForm,
    addHandler,
    isAdding,
    control
  } = props;

  const editHandler = e => {
    e.preventDefault();
    const { editHandler, rate } = props;
    editHandler(rate);
  };

  const price = rate.$('price').value.amount;

  const getPrice = () => {
    if (price == 0) {
      return <FormattedMessage id='shared.free' />;
    }

    if (price < 0) {
      return null;
    }

    return price;
  };

  return (
    <Wrapper inactive={price < 0}>
      <RatesItem>
        <RatesContent>
          <ItemDesctription>
            <div className='description'>{rate.$('description').value}</div>
          </ItemDesctription>

          <ItemPrice>
            {getPrice()}
          </ItemPrice>

          <ItemStatus>
            {price < 0 && <FormattedMessage id='shared.anavailable' />}
          </ItemStatus>

          <ItemAction>
            <RatesChangeIcon onClick={editHandler} />

            <RatesCloseIcon
              onClick={() => {
                form.removeRate(rate);
              }}
            />
          </ItemAction>
        </RatesContent>
      </RatesItem>

      {isOpenForm && (
        <Form
          form={form}
          control={control}
          rateForm={rateForm}
          addHandler={addHandler}
          isAdding={isAdding}
        />
      )}
    </Wrapper>
  );
};

Rate.propTypes = {
  rate: PropTypes.object.isRequired,
  editHandler: PropTypes.func.isRequired,
  isOpenForm: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  rateForm: PropTypes.object.isRequired,
  addHandler: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired
};

export default Rate;
