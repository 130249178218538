import { types, applySnapshot, destroy } from 'mobx-state-tree';
import instance from 'connection/instance';
import { tariffToClone, tariffToForm } from './tariffToClone';

import { RoomType } from './RoomType';
import { TariffRate } from './TariffRate';
import { TariffAvailablePeriod } from './TariffAvailablePeriod';
import { TariffVisiblePeriod } from './TariffVisiblePeriod';
import { TariffStopSalesPeriod } from './TariffStopSalesPeriod';
import { TariffSource } from './TariffSource';
import { TariffInsurant } from './TariffInsurant';
import { TariffRatePeriod } from './TariffRatePeriod';
import { TariffStayPeriod } from './TariffStayPeriod';
import { TariffSeason } from './TariffSeason';
import { TariffCancellationPolicy } from './TariffCancellationPolicy';
import { TariffReleasePeriod } from './TariffReleasePeriod';
import { TariffFeedType } from './TariffFeedType';
import { TariffMealOption } from './TariffMealOption';
import { Service, Manipulation, Promo } from './models';
import { Translation } from 'Types/Translation';
import { Duplicate } from './Duplicate';
import { TariffLengthStayRestrictions } from './TariffLengthStayRestrictions';
import {TariffAdvanceBookingRestrictions} from "./TariffAdvanceBookingRestrictions";
import {TariffClosedByWeekdayRestrictions} from "./TariffClosedByWeekdayRestrictions";
import {TariffClosedByDateRestrictions} from "./TariffClosedByDateRestrictions";
import {TariffLengthOfStayArrivalBasedRestrictions} from "./TariffLengthOfStayArrivalBasedRestrictions";

export const Tariff = types.model('Tariff', {
  id: types.identifier(types.string),
  name: types.string,
  name_translations: types.maybe(Translation),
  description: types.maybe(types.string),
  description_translations: types.maybe(Translation),
  room_type_id: types.string,
  duplicated_to: types.maybe(Duplicate),
  duplicated_from: types.maybe(Duplicate),
  room_type: types.reference(RoomType),
  available_period: types.maybe(TariffAvailablePeriod),
  visible_period: types.maybe(TariffVisiblePeriod),
  stop_sales: types.optional(types.array(TariffStopSalesPeriod), []),
  isActive: types.optional(types.boolean, false),
  selected_ids: types.optional(types.array(types.string), []),
  source: types.maybe(TariffSource),
  source_id: types.maybe(types.string),
  insurant: types.maybe(TariffInsurant),
  rates: types.optional(types.array(TariffRate), []),
  periods: types.optional(types.array(TariffRatePeriod), []),
  stay_period: types.maybe(TariffStayPeriod),
  seasons: types.optional(types.array(TariffSeason), []),
  status: types.maybe(types.enumeration(['created', 'uploaded', 'confirmed', 'archived'])),
  type: types.maybe(types.enumeration(['base', 'tour', 'package'])),
  billing_hour: types.maybe(types.enumeration(['night', 'day'])),
  price_calculation_rule: types.maybe(types.enumeration(['fixed', 'float'])),
  cancellation_policy: types.maybe(TariffCancellationPolicy),
  release_period: TariffReleasePeriod,
  feed_type: types.maybe(TariffFeedType),
  meal_option: types.maybe(TariffMealOption),
  meal_disclaimer: types.maybe(types.string),
  meal_disclaimer_translations: types.maybe(Translation),
  manipulation: Manipulation,
  service: Service,
  promo: Promo,
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),
  length_of_stay_restrictions: types.optional(types.array(TariffLengthStayRestrictions), []),
  closed_by_date_restrictions: types.optional(types.array(TariffClosedByDateRestrictions), []),
  closed_by_week_day_restrictions: types.optional(types.array(TariffClosedByWeekdayRestrictions), []),
  advance_booking_restrictions: types.optional(types.array(TariffAdvanceBookingRestrictions), []),
  length_of_stay_arrival_based_restrictions: types.optional(types.array(TariffLengthOfStayArrivalBasedRestrictions), []),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  },

  get hasSelected() {
    return this.selected_ids.length > 0;
  },

  get isBase() {
    return this.type === 'base';
  },

  get isTour() {
    return this.type === 'tour';
  },

  get isPackage() {
    return this.type === 'package';
  }
}, {
  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  toggle() {
    this.isActive = !this.isActive;
  },

  update(values) {
    this.setState('pending');

    const params = { tariff: values };

    return instance.put(`/api/tariffs/${values.id}`, params)
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  destroy() {
    return instance.delete(`/api/tariffs/${this.id}`)
      .then((response) => this.removeFromCollection())
      .catch(error => console.log(error));
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { tariff } = response.data;
    applySnapshot(this, tariff);

    return response;
  },

  removeFromCollection() {
    destroy(this);
  },

  selectItems(ids) {
    this.selected_ids = ids;
  },

  selectValues(values) {
    this.selected_ids = values;
  },

  deselectValues() {
    this.selected_ids = [];
  },

  toClone() {
    return tariffToClone(this);
  },

  toForm() {
    return tariffToForm(this);
  }
});
