import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import FormState, { fields } from 'Pages/Buildings/components/FormState';
import Form from 'Pages/Buildings/components/Form';
import Loader from 'Pages/Buildings/components/Loader';
import Actions from '../Actions';

import LanguageControl from 'Shared/components/LanguageFormControl';
import { UserProvider } from 'Context/FormLanguageContext';

@withRouter
@inject('buildingsStore')
@observer
class PageContent extends Component {
  static propTypes = {
    buildingsStore: PropTypes.object.isRequired,
    building: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  @observable form = new FormState(fields);

  @computed get isLoading() {
    const { buildingsStore } = this.props;
    return buildingsStore.isPending;
  }

  state = {
    language: 'ru'
  };

  updateHandler = (e) => {
    e.preventDefault();

    this.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler,
      onError: this.errorSubmitHandler
    });
  }

  onClickHandler = (language) => {
    if (this.state.language === language) return;

    this.setState({ language: language });
  }

  successSubmitHandler = (form) => {
    const { building } = this.props;
    const values = form.values();

    building.update(values)
      .then(this.navigateToShow);
  }

  navigateToShow = () => {
    const { building, history } = this.props;
    history.push(`/buildings/${building.id}`);
  }

  render() {
    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className='info__content'>
          {this.isLoading && <Loader />}

          <div className='layout__language-action'>
            <div className='content'>
              <div className='control'>
                <LanguageControl
                  onClick={this.onClickHandler}
                />
              </div>
            </div>
          </div>

          <div className='header'>
            <FormattedMessage id='buildings.add' />
          </div>

          <div className='content'>
            <Form form={this.form} currentLanguage={this.state.language} />
            <Actions form={this.form} />
          </div>
        </div>
      </UserProvider>
    );
  }
}

export default PageContent;
