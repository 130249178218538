import instance from 'connection/instance';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { TMappingsAction, TMappingsListResponse, ACTIONS } from '../types';

const fetchMappings = () => {
  return async (dispatch: Dispatch<TMappingsAction>): Promise<void> => {
    try {
      dispatch({ type: ACTIONS.MAPPINGS_FETCH_PENDING });

      const { data }: AxiosResponse<TMappingsListResponse> = await instance.get('/api/sogaz/mappings');

      dispatch({ type: ACTIONS.MAPPINGS_FETCH_FULLFILLED, payload: data.data });
    } catch (error) {
      dispatch({ type: ACTIONS.MAPPINGS_FETCH_REJECTED });
    }
  };
};

export default fetchMappings;
