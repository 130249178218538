import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FormItemInput from 'Shared/form/FormItemInput';
import Source from './Source';
import RoomType from './RoomType';
import AvailablePeriod from './AvailablePeriod';
import Insurant from './Insurant';

import { FormLanguageContext } from 'Context/FormLanguageContext';

@observer
class Info extends Component {
  static contextType = FormLanguageContext;
  render() {
    const currentLanguage = this.context.currentLanguage;
    const { form } = this.props;
    return (
      <div className='form__fields-wrapper'>
        <div className='form__fields-group'>
          <FormItemInput field={form.$(`name_translations.${currentLanguage}`)} isLocalizeField />
          <RoomType form={form} />
          <AvailablePeriod form={form} />
          <Insurant field={form.$('insurant')} />
          <Source field={form.$('source')} />
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  form: PropTypes.object.isRequired
};

export default Info;
