import instance from 'connection/instance';
import { useState } from 'react';

function useTariffs() {
  const [cachedTariffs, setCachedTariffs] = useState([]);

  const removeTariff = async ({ tariff }) => {
    return instance.delete(`/api/tariffs/${tariff.id}`);
  };

  const activateTariff = async ({ tariff }) => {
    return instance.put(`/api/tariffs/${tariff.id}/activate`);
  };

  const deactivateTariff = async ({ tariff }) => {
    return instance.put(`/api/tariffs/${tariff.id}/deactivate`);
  };

  const getTariff = async ({ tariff }) => {
    const currentTariff = checkTariffInCache({ tariff });
    const data = currentTariff || await fetchTariff({ tariff });

    return data;
  };

  const fetchTariff = async ({ tariff }) => {
    const { data: { tariff: data } } = await instance.get(`/api/tariffs/${tariff.id}`);
    setCachedTariffs([
      ...cachedTariffs,
      data
    ]);

    return data;
  };

  const checkTariffInCache = ({ tariff }) => {
    return cachedTariffs.find(cf => cf.id === tariff.id);
  };

  return { getTariff, removeTariff, activateTariff, deactivateTariff };
}

export default useTariffs;
