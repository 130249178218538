import { formatMessage } from "Utils/formatMessage";

const fields = [
  "name",
  "name_translations.ru",
  "name_translations.en",
  "description",
  "description_translations.ru",
  "description_translations.en",
  "web_site",
  "address",
  "address.location",
  "address.coordinates",
  "address.location_translations",
  "address.location_translations.ru",
  "address.location_translations.en",
  "treatment_profiles[]",
  "treatment_profiles[].id",
  "treatment_profiles[].name",
  "treatment_profiles[].name_translations",
  "treatment_profiles[].short_name",
  "treatment_profiles[].tag",
  "contacts[]",
  "contacts[].type",
  "contacts[].value",
  "contacts[].text",
  "contacts[].suffix",
  "contacts[].send_notification",
  "settings",
  "settings.check_in_time",
  "settings.check_out_time",
  "settings.children_from",
  "settings.billing_hour",
  "payment_details",
  "payment_details.by_cash.allow",
  "payment_details.by_card.allow",
  "payment_details.by_card.visa_card",
  "payment_details.by_card.mastercard_card",
  "payment_details.by_card.mir_card",
  "payment_details.by_prepayment",
  "payment_details.by_prepayment.allow",
  "payment_details.by_prepayment.percent",
  "amenities[]",
  "currency",
  "sources[]",
  "sources[].id",
  "sources[].searchable",
  "sources[].settings",
  "sources[].settings.billing_hour",
  "sources[].settings.check_in_time",
  "sources[].settings.check_out_time",
  "sources[].insurance",
  "sources[].insurance.name",
  "card_options[]",
  "card_options[].id",
  "card_options[].name",
  "card_options[].name_translations",
  "card_options[].tag",
  "star",
  "feed_types[]",
  "feed_types[].id",
  "feed_types[].name",
  "feed_types[].name_translations",
  "feed_types[].tag",
  "meal_options[]",
  "meal_options[].id",
  "meal_options[].name",
  "meal_options[].name_translations",
  "meal_options[].tag",
  "treatment_factors[]",
  "treatment_factors[].id",
  "treatment_factors[].name",
  "treatment_factors[].name_translations",
  "treatment_factors[].tag",
  "location_types[]",
  "location_types[].id",
  "location_types[].name",
  "location_types[].name_translations",
  "location_types[].tag",
  "accommodation_features[]",
  "accommodation_features[].id",
  "accommodation_features[].name",
  "accommodation_features[].name_translations",
  "accommodation_features[].tag",
];

const labels = {
  name: formatMessage("hotel.name"),
  "name_translations.ru": formatMessage("hotel.name"),
  "name_translations.en": formatMessage("hotel.name"),
  description: formatMessage("hotel.description"),
  "description_translations.ru": formatMessage("hotel.description"),
  "description_translations.en": formatMessage("hotel.description"),
  web_site: formatMessage("hotel.website"),
  email: formatMessage("hotel.email"),
  phone: formatMessage("hotel.phone"),
  address: formatMessage("hotel.address"),
  "address.location": formatMessage("hotel.address"),
  "address.coordinates": formatMessage("hotel.coordinates"),
  "address.location_translations.ru": formatMessage("hotel.address"),
  "address.location_translations.en": formatMessage("hotel.address"),
  "contacts[].send_notification": formatMessage("hotel.use_for_notification"),
  "payment_details.by_cash": formatMessage("hotel.payment_by_cash"),
  "payment_details.by_card": formatMessage("hotel.payment_by_card"),
  "payment_details.by_prepayment": formatMessage("hotel.payment_by_prepayment"),
  "settings.billing_hour": formatMessage("hotel.settings.billing_hour"),
  "settings.check_in_time": formatMessage("hotel.checkin_time_after"),
  "settings.check_out_time": formatMessage("hotel.checkout_time_before"),
  currency: formatMessage("hotel.currency"),
};

const placeholders = {
  name: "",
  description: "",
  web_site: "",
  email: "",
  phone: "",
  address: "",
  "address.location": "",
  "address.coordinates": "",
  "contacts[].text": "Пример: Отдел бронирования",
  "contacts[].suffix": "Доб.",
  currency: "",
};

const rules = {
  name: "required",
  "name_translations.ru": "string|required",
  "name_translations.en": "string",
  description: "string",
  "description_translations.ru": "string",
  "description_translations.en": "string",
  web_site: "string",
  address: "required",
  "address.location": "required|string",
  "address.coordinates": "required|array",
  "address.location_translations.ru": "required|string",
  "address.location_translations.en": "string",
  "contacts[]": "required|min:1",
  "contacts[].type": "required|in:email,phone",
  "contacts[].value": "required|min:3",
  "contacts[].text": "required|string",
  "contacts[].suffix": "numeric",
  "contacts[].send_notification": "boolean",
  settings: "required",
  "settings.check_in_time": "required|string",
  "settings.check_out_time": "required|string",
  "settings.children_from": "required|numeric",
  "settings.billing_hour": "required|string",
  "payment_details.by_cash": "required",
  "payment_details.by_cash.allow": "required|boolean",
  "payment_details.by_card": "required",
  "payment_details.by_card.allow": "required|boolean",
  "payment_details.by_prepayment": "required",
  "payment_details.by_prepayment.allow": "required|boolean",
  currency: "required|string",
};

const types = {
  "contacts[].send_notification": "checkbox",
};

const extra = {
  settings: {
    billing_hour: [
      {
        key: "night",
        name: formatMessage("hotel.settings.night"),
      },
      {
        key: "day",
        name: formatMessage("hotel.settings.day"),
      },
    ],
  },
  currency: [
    { label: "Российский рубль", value: "RUB" },
    { label: "Доллар США", value: "USD" },
    { label: "Евро", value: "EUR" },
    { label: "Казахский тенге", value: "KZT" },
    { label: "Китайский юань женьминьби", value: "CNY" },
    { label: "Грузинский лари", value: "GEL" },
    { label: "Украинская гривна", value: "UAH" },
    { label: "Швейцарский франк", value: "CHF" },
    { label: "Фунт стерлингов Велико­британии", value: "GBP" },
    { label: "Японская йена", value: "JPY" },
    { label: "Дирхам ОАЭ", value: "AED" },
    { label: "Афганский афгани", value: "AFN" },
    { label: "Албанский лек", value: "ALL" },
    { label: "Армянский драм", value: "AMD" },
    { label: "Ангольская кванза", value: "AOA" },
    { label: "Аргентинский песо", value: "ARS" },
    { label: "Австралийский доллар", value: "AUD" },
    { label: "Азербайджанский манат", value: "AZN" },
    { label: "Бангладешская така", value: "BDT" },
    { label: "Болгарский лев", value: "BGN" },
    { label: "Бахрейнский динар", value: "BHD" },
    { label: "Бурундийский франк", value: "BIF" },
    { label: "Брунейский доллар", value: "BND" },
    { label: "Боливийский боливиано", value: "BOB" },
    { label: "Бразильский реал", value: "BRL" },
    { label: "Ботсванская пула", value: "BWP" },
    { label: "Белорусский рубль", value: "BYN" },
    { label: "Канадский доллар", value: "CAD" },
    { label: "Конголезский франк", value: "CDF" },
    { label: "Чилийский песо", value: "CLP" },
    { label: "Колумбийский песо", value: "COP" },
    { label: "Костариканский колон", value: "CRC" },
    { label: "Кубинский песо", value: "CUP" },
    { label: "Чешская крона", value: "CZK" },
    { label: "Джибутийский франк", value: "DJF" },
    { label: "Датская крона", value: "DKK" },
    { label: "Алжирский динар", value: "DZD" },
    { label: "Египетский фунт", value: "EGP" },
    { label: "Эфиопский быр", value: "ETB" },
    { label: "Ганский седи", value: "GHS" },
    { label: "Гамбийский даласи", value: "GMD" },
    { label: "Гвинейский франк", value: "GNF" },
    { label: "Гонконгский доллар", value: "HKD" },
    { label: "Хорватская куна", value: "HRK" },
    { label: "Венгерский форинт", value: "HUF" },
    { label: "Индонезийская рупия", value: "IDR" },
    { label: "Израильский шекель", value: "ILS" },
    { label: "Индийская рупия", value: "INR" },
    { label: "Иракский динар", value: "IQD" },
    { label: "Иранский риал", value: "IRR" },
    { label: "Исландская крона", value: "ISK" },
    { label: "Иорданский динар", value: "JOD" },
    { label: "Кенийский шиллинг", value: "KES" },
    { label: "Киргизский сом", value: "KGS" },
    { label: "Камбоджийский риель", value: "KHR" },
    { label: "Северо-корейская вона (КНДР)", value: "KPW" },
    { label: "Южно-корейская вона (Корея)", value: "KRW" },
    { label: "Кувейтский динар", value: "KWD" },
    { label: "Лаосский кип", value: "LAK" },
    { label: "Ливанский фунт", value: "LBP" },
    { label: "Шри-ланкийская рупия", value: "LKR" },
    { label: "Ливийский динар", value: "LYD" },
    { label: "Марокканский дирхам", value: "MAD" },
    { label: "Молдовский лей", value: "MDL" },
    { label: "Малагасийский ариари", value: "MGA" },
    { label: "Македонский денар", value: "MKD" },
    { label: "Монгольский тугрик", value: "MNT" },
    { label: "Мавританская угия", value: "MRO" },
    { label: "Маврикийская рупия", value: "MUR" },
    { label: "Малавийская квача", value: "MWK" },
    { label: "Мексиканский песо", value: "MXN" },
    { label: "Малайзийский ринггит", value: "MYR" },
    { label: "Мозамбикский метикал", value: "MZN" },
    { label: "Намибийский доллар", value: "NAD" },
    { label: "Нигерийская наира", value: "NGN" },
    { label: "Никарагуанская кордоба", value: "NIO" },
    { label: "Норвежская крона", value: "NOK" },
    { label: "Непальская рупия", value: "NPR" },
    { label: "Ново­зеландский доллар", value: "NZD" },
    { label: "Оманский риал", value: "OMR" },
    { label: "Перуанский соль", value: "PEN" },
    { label: "Филиппинский песо", value: "PHP" },
    { label: "Пакистанская рупия", value: "PKR" },
    { label: "Польский злотый", value: "PLN" },
    { label: "Парагвайский гуарани", value: "PYG" },
    { label: "Катарский риал", value: "QAR" },
    { label: "Новый румынский лей", value: "RON" },
    { label: "Сербский динар", value: "RSD" },
    { label: "Саудовский риял", value: "SAR" },
    { label: "Сейшельская рупия", value: "SCR" },
    { label: "Суданский фунт", value: "SDG" },
    { label: "Шведская крона", value: "SEK" },
    { label: "Сингапурский доллар", value: "SGD" },
    { label: "Сьерра-леонский леоне", value: "SLL" },
    { label: "Сомалийский шиллинг", value: "SOS" },
    { label: "Суринамский доллар", value: "SRD" },
    { label: "Сирийский фунт", value: "SYP" },
    { label: "Свазилендский лилангени", value: "SZL" },
    { label: "Таиландский бат", value: "THB" },
    { label: "Таджикский сомони", value: "TJS" },
    { label: "Туркменский манат", value: "TMT" },
    { label: "Тунисский динар", value: "TND" },
    { label: "Новая турецкая лира", value: "TRY" },
    { label: "Тайваньский доллар", value: "TWD" },
    { label: "Танзанийский шиллинг", value: "TZS" },
    { label: "Угандийский шиллинг", value: "UGX" },
    { label: "Уругвайский песо", value: "UYU" },
    { label: "Узбекский сум", value: "UZS" },
    { label: "Венесуэльский боливар", value: "VEF" },
    { label: "Вьетнамский донг", value: "VND" },
    { label: "Франк КФА (Центральная Африка)", value: "XAF" },
    { label: "СПЗ", value: "XDR" },
    { label: "Франк КФА (Западная Африка)", value: "XOF" },
    { label: "Йеменский риал", value: "YER" },
    { label: "Южно-африканский рэнд", value: "ZAR" },
    { label: "Замбийская квача", value: "ZMK" },
  ],
};

const values = {
  payment_details: {
    by_cash: {
      allow: false,
    },
    by_card: {
      allow: false,
      visa_card: true,
      mastercard_card: true,
      mir_card: true,
    },
    by_prepayment: {
      allow: false,
      percent: 10,
    },
  },
  settings: {
    check_in_time: "14:00",
    check_out_time: "10:00",
    children_from: 3,
  },
  currency: "RUB",
  contacts: [
    { type: "email", value: null, text: null, send_notification: true },
    { type: "phone", value: null, suffix: null, text: null },
  ],
};

export default { fields, labels, placeholders, rules, values, types, extra };
