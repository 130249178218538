import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import { formatMessage } from 'Utils/formatMessage';

@withRouter
@observer
class PriceList extends Component {
  @computed get status() {
    const { status } = this.props.price_list;

    if (status === 'created') {
      return {
        label: 'status booked',
        text: formatMessage('tariffs.statuses.created')
      };
    } else if (status === 'uploaded') {
      return {
        label: 'status draft',
        text: formatMessage('tariffs.statuses.uploaded')
      };
    } else if (status === 'confirmed') {
      return {
        label: 'status confirmed',
        text: formatMessage('tariffs.statuses.confirmed')
      };
    } else {
      return {
        label: 'status',
        text: formatMessage('tariffs.statuses.unknown')
      };
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    const { history, price_list } = this.props;

    history.push(`/price_lists/${price_list.id}`);
  }

  render() {
    const { price_list } = this.props;

    return (
      <div className='price-lists__item'>
        <div className='caption' onClick={this.handleClick}>
          <div className='item name'>
            {price_list.number}
          </div>

          <div className='item source'>
            <span className={this.status.label}>{this.status.text}</span>
          </div>

          <div className='item period'>
            {price_list.startDate} - {price_list.endDate}
          </div>
        </div>
      </div>
    );
  }
}

PriceList.propTypes = {
  price_list: PropTypes.object.isRequired
};

export default PriceList;
