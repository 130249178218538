import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
class Email extends Component {
  render() {
    const { email } = this.props;

    return (
      <div className='options__item'>
        <div className='key'>
          {email.text}
        </div>
        <div className='value'>
          {email.value}
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  email: PropTypes.object.isRequired
};

export default Email;
