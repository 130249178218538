import { types } from 'mobx-state-tree';
import moment from 'Utils/moment';

export const TariffAvailablePeriod = types.model('TariffAvailablePeriod', {
  from: types.maybe(types.string),
  to: types.maybe(types.string),

  get fromDate() {
    return this.from
      ? moment(this.from).format('DD.MM.YYYY')
      : 'неограничено';
  },

  get fromDateObj() {
    return this.from && moment(this.from);
  },

  get toDate() {
    return this.to
      ? moment(this.to).format('DD.MM.YYYY')
      : 'неограничено';
  },

  get toDateObj() {
    return this.to && moment(this.to);
  }
});
