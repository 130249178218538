import React from "react";

import { Wrapper, TabItem } from "./Tabs.styled";

export const Tabs = ({ TABS, currentTab, handleOnClickTab }) => {
  return (
    <Wrapper>
      {!!TABS?.length &&
        TABS.map(({ title, value }) => (
          <TabItem
            key={value}
            $active={value === currentTab}
            onClick={handleOnClickTab(value)}
          >
            {title}
          </TabItem>
        ))}
    </Wrapper>
  );
};
