import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _zipObject from 'lodash/zipObject';
import _first from 'lodash/first';
import _last from 'lodash/last';

import pricesToNights from './pricesToNights';
import pricesToDays from './pricesToDays';

function calcFloatPrice(items) {
  return items.reduce((sum, i) => {
    const amount = sum.amount + i.price.amount;
    const currency = i.price.currency;

    return { amount, currency };
  }, { amount: 0, currency: 'RUB' });
}

function calcFixedPrice(items) {
  const { price: { amount, currency } } = items[0];
  return { amount: amount * items.length, currency };
}

function calcPrice(items, { price_calculation_rule, type }) {
  if (type === 'package' || type === 'tour') { return { amount: items[0].price.amount, currency: items[0].price.currency }; }

  const price = price_calculation_rule === 'float'
    ? calcFloatPrice(items)
    : calcFixedPrice(items);

  return price;
}

function pricesPayment({ tariff, prices }) {
  const { billing_hour, price_calculation_rule, type } = tariff;
  const isDay = billing_hour === 'day';

  const items = isDay
    ? pricesToDays(prices)
    : pricesToNights(prices);

  if (items.length === 0) return { total: 0 };

  const total = {
    price: calcPrice(items, { price_calculation_rule, type }),
    check_in: isDay ? _first(items).day : _first(items).night[0],
    check_out: isDay ? _last(items).day : _last(items).night[1],
    duration: { value: items.length, unit: billing_hour }
  };

  let methods = _groupBy(items, 'payment_method.type');

  methods = _map(methods, (items, type) => ({
    type,
    value: {
      price: calcPrice(items, { price_calculation_rule, type }),
      check_in: isDay ? _first(items).day : _first(items).night[0],
      check_out: isDay ? _last(items).day : _last(items).night[1],
      duration: { value: items.length, unit: billing_hour }
    }
  }));

  methods = _zipObject(
    methods.map(item => item.type),
    methods.map(item => item.value)
  );

  return { total, ...methods };
}

export default pricesPayment;
