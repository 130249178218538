import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';

import Quota from './Quota';

@observer
class Quotas extends Component {
  handleAdd = (e) => {
    e.preventDefault();

    const { quotas } = this.props;
    quotas.add({ quantity: 0 });
  }

  @computed get hasQuotas() {
    const { quotas } = this.props;
    return quotas.size > 0;
  }

  render() {
    const { quotas } = this.props;

    const listItems = quotas.fields.values()
      .filter(item => !(item.has('_destroy') && item.$('_destroy').value))
      .map(item => <Quota key={item.id} quota={item} />);

    return (
      <div className='form quotas'>
        {this.hasQuotas
          ? (
            <div className='quotas__items'>
              {listItems}
            </div>
            )
          : (
            <div className='quotas_empty'>
              <p> <FormattedMessage id='quotas.empty' /></p>
            </div>
            )}

        <div className='quotas__footer'>
          <button className='button gray' onClick={this.handleAdd}>
            <FormattedMessage id='quotas.add' />
          </button>
        </div>
      </div>
    );
  }
}

Quotas.propTypes = {
  quotas: PropTypes.object.isRequired
};

export default Quotas;
