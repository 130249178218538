import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Room from './Room';

@withRouter
@observer
class Rooms extends Component {
  render() {
    const listItems = this.props.rooms.map((item) => {
      return <Room key={item.id} room={item} />;
    });

    return (
      <div className='rooms'>
        {listItems}
      </div>
    );
  }
}

Rooms.propTypes = {
  rooms: PropTypes.array.isRequired
};

export default Rooms;
