import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Notification from './Notification';

@inject('store')
@observer
class Notifications extends Component {
  render() {
    const { notifyStore } = this.props.store;

    const listItems = notifyStore.notifications.map((item) => {
      return <Notification key={item.id} notification={item} />;
    });

    return (
      <div className='notifications'>
        {listItems}
      </div>
    );
  }
}

export default Notifications;
