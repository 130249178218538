import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formatMessage } from 'Utils/formatMessage';

@observer
class FullRefund extends Component {
  render() {
    return (
      <span className='value'>
        {formatMessage('tariffs.cancellation_policy.has_full_refund')}
      </span>
    );
  }
}

export default FullRefund;
