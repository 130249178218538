import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class TopBarSearchBox extends Component {
  render() {
    const { adminModeHandler } = this.props;
    return (
      <div className='search-box' onDoubleClick={adminModeHandler}>
        <div className='search-box__header'>
          <FormattedMessage id='menu.settings' />
        </div>
      </div>
    );
  }
}

TopBarSearchBox.propTypes = {
  adminModeHandler: PropTypes.func
};

export default TopBarSearchBox;
