import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MdLocalPhone from 'react-icons/lib/md/local-phone';
import MdAdd from 'react-icons/lib/md/add';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import ru from 'react-phone-input-2/lang/ru.json';

import FormMaskedInput from 'Shared/form/FormMaskedInput';
import FormInput from 'Shared/form/FormInput';

@inject('form')
@observer
class Phone extends Component {
  addHandler = (e) => {
    e.preventDefault();

    const { form } = this.props;
    form.$('contacts').add({ type: 'phone' });
  }

  setPhoneNumber = (value) => {
    const { field } = this.props;
    field.set(value);
  }

  render() {
    const { field, showAddBtn, showRemoveBtn } = this.props;

    return (
      <div className='form__fields-group'>
        <div className='form__fields'>
          <div className='form__field grow'>
            <PhoneInput
              localization={ru}
              value={field.$('value').value}
              onChange={value => this.setPhoneNumber({ value })}
              inputClass='form_input_phone'
            />
          </div>
          <div className='form__field w25'>
            <FormMaskedInput
              field={field.$('suffix')}
              placeholder='Доб.'
            />
          </div>
        </div>
        <div className='form__field grow'>
          <FormInput field={field.$('text')} />
        </div>
        <div className='form__field'>
          <div className='buttons'>
            {showRemoveBtn &&
              <button className='button gray' onClick={field.onDel}>
                <FormattedMessage id='shared.remove' />
              </button>}

            {showAddBtn &&
              <a href='' className='link' onClick={this.addHandler}>
                <FormattedMessage id='hotel.add_phone' />
              </a>}
          </div>
        </div>
      </div>
    );
  }
}

Phone.defaultProps = {
  showRemoveBtn: false,
  showAddBtn: false
};

Phone.propTypes = {
  field: PropTypes.object.isRequired
};

export default Phone;
