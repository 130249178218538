import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import ManipulationClassifier from "Stores/ManipulationClassifier";

import Quantity from "./Quantity";
import IsManual from "./IsManual";
import Options from "./Options";
import Disclaimer from "./Disclaimer";

import { FormLanguageContext } from "Context/FormLanguageContext";

@observer
class ManipulationPrograms extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
  };

  static contextType = FormLanguageContext;

  dictionary = ManipulationClassifier.create();

  render() {
    const { form } = this.props;
    const currentLanguage = this.context.currentLanguage;

    return (
      <form className="form">
        <div className="form__body">
          <div className="row">
            <div className="col col-4">
              <Quantity field={form.$("manipulation.quantity")} />
            </div>

            <div className="col col-8">
              <IsManual field={form.$("manipulation.is_manual")} />
            </div>
          </div>

          <div className="row">
            <div className="col col-7">
              <Options
                field={form.$("manipulation.options")}
                dictionary={this.dictionary}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-7">
              <Disclaimer
                field={form.$(
                  `manipulation.disclaimer_translations.${currentLanguage}`
                )}
                ruField={form.$(`manipulation.disclaimer_translations.ru`)}
                enField={form.$(`manipulation.disclaimer_translations.en`)}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ManipulationPrograms;
