import { types, applySnapshot, applyPatch, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';
import jsonpatch from 'fast-json-patch';
import { objectClean } from 'Utils/objectClean';

import { Hotel } from './models/Hotel';
import { Building } from './models/Building';

export const HotelStore = types.model('HotelStore', {
  hotel: types.maybe(Hotel),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get hasBuildings() {
    return this.hotel && this.hotel.hasBuildings;
  },

  get hasRoomTypes() {
    return this.hotel && this.hotel.hasRoomTypes;
  },

  get hasRooms() {
    return this.hotel && this.hotel.hasRooms;
  },

  get isWizardCompleted() {
    return this.hotel && this.hotel.isWizardCompleted;
  },

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    instance.get('/api/hotel', { params: params })
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  update(form) {
    this.setState('pending');
    const data = { hotel: objectClean(form.values()) };

    return instance.put('/api/hotel', data)
      .then(response => this.resetStore(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  updateRaw(data) {
    this.setState('pending');
    return instance.put('/api/hotel', data)
      .then(response => {
        response.data.hotel.images.sort((left, right) => left.priority - right.priority);
        return this.resetStore(response);
      })
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  forward() {
    return instance.put('/api/hotel/forward', {})
      .then((response) => this.updateStore(response))
      .catch(error => this.errorHandler(error));
  },

  back() {
    return instance.put('/api/hotel/back', {})
      .then((response) => this.updateStore(response))
      .catch(error => this.errorHandler(error));
  },

  updateAndForward(form) {
    let promise = this.update(form);
    promise = promise.then(() => this.forward());
    return promise;
  },

  removeImages(images) {
    const data = {
      hotel: {
        images: images.map(({ id }) => ({ id, _destroy: true }))
      }
    };

    return instance.put('/api/hotel', data)
      .then(response => this.resetStore(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  removeAllImages() {
    this.setImages([]);
    return instance.delete('/api/hotel/images');
  },

  clear() {
    const data = { hotel: undefined, state: undefined };
    applySnapshot(this, data);
  },

  resetStore(response) {
    const { status, data } = response;

    if (status === 200) { applySnapshot(this, data); }

    return data;
  },

  updateStore(response) {
    const { status, data } = response;

    if (status === 200) {
      const patch = jsonpatch
        .compare(this.toJSON(), data)
        .filter((action) => action.op === 'replace' || action.op === 'add');

      applyPatch(this, patch);
    }

    return data;
  },

  setState(state, data = undefined) {
    this.state = state;
    return data;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  getBuldingFloorsOptions(building_id) {
    const building = resolveIdentifier(
      Building, this.hotel, building_id
    );

    return building ? building.getNumbersOptions() : [];
  },

  setImages(images) {
    this.hotel.images = images;
  },

  updateImagesSorting() {
    const data = {
      hotel: {
        images: this.hotel.images.map(
          ({ id, priority, promo, alt_translations }, index) => {
            if (!promo) {
              return {
                id,
                priority: priority,
                alt_translations
              };
            } else {
              return {
                id,
                priority: priority,
                promo: promo.toJSON(),
                alt_translations
              };
            }
          }
        )
      }
    };

    return this.updateRaw(data); // => promise
  }
}
);
