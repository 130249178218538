import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import Rates from './Rates';

@inject('priceboardStore')
@observer
class Tariff extends Component {
  render() {
    const { name, rates } = this.props.tariff;

    return (
      <div className='tariff'>
        <div className='tariff__header'>
          <b>{name}</b>
        </div>

        <div className='tariff__content'>
          <Rates rates={rates} />
        </div>
      </div>
    );
  }
}

Tariff.propTypes = {
  tariff: PropTypes.object.isRequired
};

export default Tariff;
