import { NSHotel } from '@types';
import { IAdapter } from '../types';

import _groupBy from 'lodash/groupBy';

export const roomTypesSelector = (state: IAdapter): NSHotel.TRoomType[] => {
  const roomTypeMappings = _groupBy((state.mappings.data || []), 'room_type.provider_id');
  const items = _groupBy((state.tariffs.data || []), 'room_type.id');
  const priceListServices = state.price_lists.data.flatMap(pl => pl.services || []);

  const roomTypes = Object.values(items).map((tariffsItems) => {
    const baseValue = tariffsItems[0].room_type;
    const tariffsMappings = _groupBy((roomTypeMappings[baseValue.id] || []), 'tariff.provider_id');

    const tariffs = tariffsItems.map(tariffItem => {
      const ratesMapping = _groupBy((tariffsMappings[tariffItem.id] || []), 'rate.provider_id');

      const rates = tariffItem.rates.map(rate => {
        const rateMappings = ratesMapping[rate.code] || [];
        if (!rateMappings.length) {
          return { ...rate, services: [] };
        }

        const rateMapping = rateMappings[0];

        const services = priceListServices
          .filter(s => s.service_code === rateMapping.rate.platform_id);

        return { ...rate, services, compare_status: rateMapping.compare_status };
      });

      return { ...tariffItem, rates };
    });

    return { ...baseValue, tariffs };
  });

  return roomTypes;
};
