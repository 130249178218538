import React from 'react';
import { formatMessage } from 'Utils/formatMessage';

export default class TopBarSearchBox extends React.Component {
  render() {
    return (
      <div className='search-box'>
        <form method='post' action='' className='form'>
          <div className='row gutters'>
            <div className='col col-6'>
              <div className='form-item'>
                <input
                  className='input'
                  placeholder={formatMessage('shared.search_string')}
                  disabled
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
