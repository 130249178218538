import { types, applySnapshot, detach } from 'mobx-state-tree';
import instance from 'connection/instance';
import _cloneDeep from 'lodash/cloneDeep';

import Image from './Image';
import { Amenity } from 'Stores/models/Amenity';
import { Quota } from './Quota';
import arrayToTree from 'array-to-tree';
import { Translation } from 'Types/Translation';
import { Capacity } from '../models/Capacity';

export const RoomType = types.model('RoomType', {
  id: types.identifier(types.string),
  name: types.string,
  name_translations: types.maybe(Translation),
  description: types.maybe(types.string),
  description_translations: types.maybe(Translation),
  area: types.maybe(types.number),
  rm_count: types.optional(types.number, 1),
  count: types.optional(types.number, 0),
  beds: types.number,
  extra_beds: types.number,
  images: types.optional(types.array(Image), []),
  amenities: types.optional(types.array(Amenity), []),
  quotas: types.optional(types.array(Quota), []),
  capacity: types.optional(Capacity, { total: 0 }),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  toForm() {
    let data = this.toJSON();
    data = _cloneDeep(data);

    const selectedNodes = [];

    const loop = (data) => {
      return data.map((item) => {
        return item.children
          ? loop(item.children)
          : selectedNodes.push(item.tag);
      });
    };

    loop(arrayToTree(data.amenities, { parentProperty: 'parent_tag', customID: 'tag' }));
    data.amenities = selectedNodes;

    return data;
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  update(formData) {
    this.setState('pending');

    return instance.put(`/api/room_types/${this.id}`, formData)
      .then((response) => this.applySnapshot(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  destroy() {
    this.setState('pending');

    return instance.delete(`/api/room_types/${this.id}`)
      .then((response) => this.destroySelf())
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { room_type } = response.data;
    applySnapshot(this, room_type);

    return response;
  },

  destroySelf() {
    detach(this);
  }
});
