import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { DiseasesStore } from 'stores/DiseasesStore/index';
import Loader from 'pages/Hotel/components/Loader';
import store from 'store';

@inject('hotelStore')
@observer
class Diseases extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    hotelStore: PropTypes.object.isRequired,
    diseasesStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    when(
      () => this.props.hotelStore.isFetched,
      status => {
        const { form } = this.props;
        const initialDiseases = form.$('treatment_profiles').value;

        this.setState({
          selectedDiseases: initialDiseases
        });
        this.fetchDiseases();
      }
    );
  }

  state = {
    selectedDiseases: []
  };

  fetchDiseases = () => {
    const { diseasesStore, hotelStore } = this.props;
    const hotel_id = hotelStore.hotel.id;

    diseasesStore.fetchChecked({ query: { hotel_id } });
  };

  onSelect = currentTag => {
    const { selectedDiseases } = this.state;
    const { diseasesStore, form } = this.props;
    const selectedDisease = diseasesStore
      .toJSON()
      .diseases.filter(type => type.tag === currentTag)[0];
    const isDiseaseSelected = selectedDiseases.some(
      type => type.tag === selectedDisease.tag
    );
    let selectedData = [];

    if (isDiseaseSelected) {
      const index = selectedDiseases.findIndex(
        type => type.tag === selectedDisease.tag
      );
      const copyStateData = [...selectedDiseases];
      copyStateData.splice(index, 1);
      selectedData = copyStateData;
    } else {
      selectedData = [...selectedDiseases, selectedDisease];
    }

    this.setState({
      selectedDiseases: selectedData
    });

    form.update({ treatment_profiles: selectedData });
  };

  render() {
    const { diseasesStore } = this.props;
    const diseasesData = diseasesStore.toJSON().diseases;

    const generalLanguage = store.get('language');

    const checkboxList = diseasesData.map((data, index) => {
      return (
        <div key={data.tag} style={{ display: 'flex' }}>
          <input
            type='checkbox'
            key={data.tag}
            value={data.tag}
            className='input radio_button'
            checked={this.state.selectedDiseases.some(
              type => type.tag === data.tag
            )}
          />
          <span className='radio_button_name'>
            {data.name_translations[generalLanguage]}
          </span>
        </div>
      );
    });
    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.treatment_profiles' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            <p className='muted'>
              <FormattedMessage id='hotel.add_specialization_hint' />
            </p>

            {diseasesStore.isFetched
              ? (
                <>
                  {checkboxList.map((checkbox, index) => (
                    <div
                      className={
                      index + 1 !== diseasesData.length
                        ? 'radio_list_item'
                        : 'radio_list_item_last'
                    }
                      key={checkbox.key}
                      onClick={() => this.onSelect(checkbox.key)}
                    >
                      {checkbox}
                    </div>
                  ))}
                </>
                )
              : (
                <Loader />
                )}
          </div>
        </div>
      </div>
    );
  }
}

Diseases.defaultProps = {
  form: PropTypes.object.isRequired,
  diseasesStore: DiseasesStore.create()
};

export default Diseases;
