import { Dispatch } from 'react';
import { ACTIONS, ICompareReset } from '../types';

const compareReset = () => {
  return async (dispatch: Dispatch<ICompareReset>): Promise<void> => {
    dispatch({ type: ACTIONS.COMPARE_RESET });
  };
};

export default compareReset;
