import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Rate from './Rate';
import { ListHeader } from 'Styles/styled';

@observer
class PeriodRates extends Component {
  render() {
    const {
      periodRates,
      className,
      isEditable,
      onRateChange
    } = this.props;

    const listItems = periodRates.map(item => {
      return (
        <Rate
          key={item.age_group + '/' + item.type}
          rate={item}
          isEditable={isEditable}
          onRateChange={onRateChange}
        />
      );
    });

    const cs = classNames('form__fields-wrapper', className);

    return (
      <div className={cs}>
        <ListHeader>
          <div>Тип размещения</div>
          <div>Цена, руб</div>
        </ListHeader>

        {listItems}
      </div>
    );
  }
}

PeriodRates.propTypes = {
  periodRates: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onRateChange: PropTypes.func.isRequired
};

export default PeriodRates;
