import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
class ByPrepayment extends Component {
  render() {
    const { allow } = this.props.value;

    return (
      <div className='options__item'>
        <div className='key'>
          <FormattedMessage id='hotel.by_prepayment' />
        </div>

        <div className='value'>
          {allow
            ? <FormattedMessage id='hotel.payment_alowed' />
            : <FormattedMessage id='hotel.payment_not_alowed' />}
        </div>
      </div>
    );
  }
}

ByPrepayment.propTypes = {
  value: PropTypes.object.isRequired
};

export default ByPrepayment;
