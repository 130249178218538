const fields = [
  'text'
];

const placeholders = {
  text: 'Сообщение...'
};

const rules = {
  text: 'required|string'
};

export default {
  fields,
  placeholders,
  rules
};
