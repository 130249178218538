import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import Quotas from '../Quotas';

@inject('quotaTabsState')
@observer
class Source extends Component {
  @computed get isSelected() {
    const { quotaTabsState, source } = this.props;
    return quotaTabsState.isSelected(source);
  }

  render() {
    const { room_type, source } = this.props;

    return (
      <div className='source'>
        <Quotas
          quotas={source.availabilities}
          room_type={room_type}
          source={source}
          expand={this.isSelected}
        />
      </div>
    );
  }
}

Source.propTypes = {
  room_type: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired
};

export default Source;
