import React, { Component } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import FormInput from 'Shared/form/FormInput';
import state from './state';

@inject('priceboardStore', 'registryStore')
@observer
class Form extends Component {
  constructor(props) {
    super(props);

    this.submitHandler = this.submitHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);

    this.successHandler = this.successHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  componentDidMount() {
    this.updateForm();
    this.autoFocus();
  }

  updateForm() {
    const { registryStore, priceboardStore, form } = this.props;
    const key = registryStore.firstSelected.key;
    const rule = priceboardStore.resolveRuleIdentifier(key);

    if (rule) form.update(rule.toJSON());
  }

  autoFocus() {
    const { form } = this.props;
    const field = form.fields.values()[0];

    if (field) field.focus();
  }

  submitHandler(e) {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successHandler,
      onError: this.errorHandler
    });
  }

  cancelHandler(e) {
    e.preventDefault();
    this.props.registryStore.selectItems([]);
  }

  successHandler(form) {
    const { priceboardStore, registryStore } = this.props;
    const { price, type } = form.values();

    const changes = registryStore.selected.keys().map((key) => {
      return {
        id: key,
        patch: [
          { op: 'replace', path: '/price', value: Number(price) },
          { op: 'replace', path: '/type', value: type }
        ]
      };
    });

    priceboardStore.applyRuleChanges(changes);
    registryStore.selectItems([]);
  }

  errorHandler(form) {
    console.log(form.errors());
  }

  render() {
    const { form } = this.props;

    return (
      <form className='form'>
        <div className='fields'>
          <div className='field'>
            <FormInput field={form.$('price.amount')} />
          </div>

          <div className='field' onClick={this.submitHandler}>
            <button className='button gray small'>
              <FormattedMessage id='shared.confirm' />
            </button>
          </div>

          <div className='field' onClick={this.cancelHandler}>
            <button className='button gray small'>
              <FormattedMessage id='shared.cancel' />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  form: state
};

export default Form;
