import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from 'Utils/formatMessage';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FormLanguageContext } from 'Context/FormLanguageContext';

import LocalizedLabel from 'Components/ui/LocalizedLabel';

import Tooltip from 'Components/ui/Tooltip';

import TariffAvailablePeriod from './TariffAvailablePeriod';
import TariffChildHeader from './TariffChildHeader';

import { CopyIcon } from 'Components/ui/Icons';
import Switch from 'Components/ui/Switch';

const S = {
  CaptionName: styled.span`
    font-weight: 600;
    ${props => props.withPromo ? 'color: red' : ''};
  `
};

const ItemName = styled.div`
  width: 35%;
  min-width: 35%;
`;

const ItemType = styled.div`
  width: 15%;
  min-width: 15%;
`;

const ItemSource = styled.div`
  width: 10%;
  min-width: 10%;
`;

const ItemPeriod = styled.div`
  width: 20%;
  min-width: 20%;
`;

const SwitchContainer = styled.div`
  width: 10%;
  min-width: 10%;
`;

const Copy = styled.div`
  position: absolute;
  display: none;
  font-size: 12px;
  top: 50%;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  right: -15px;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  &:hover {
    ${ItemName} {
      color: #1F6FCC;
    }
  }

  &:hover {
    ${Copy} {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  color: #838383;
  padding-top: 0;
  padding: 0.75rem 0;
  border-bottom: 1px solid #DCD8D2;
`;

function TariffHeader({ tariff, history, activateTariff, deactivateTariff }) {
  const { currentLanguage } = useContext(FormLanguageContext);
  const {
    name,
    available_period,
    source,
    promo,
    status,
    children,
    active
  } = tariff;

  const [activeTariff, setActiveTariff] = useState(active);
  const [overlayText, setOverlayText] = useState(formatMessage('shared.clipboard_copy'));
  const [pending, setPending] = useState();

  const handleClick = (e) => {
    e.preventDefault();

    history.push(`/tariffs/${tariff.id}`);
  };

  const handleCopy = e => {
    e.stopPropagation();
    setOverlayText(formatMessage('tariff.clipboard_success'));

    navigator.clipboard.writeText(name[currentLanguage]);

    setTimeout(() => setOverlayText(formatMessage('shared.clipboard_copy')), 2000);
  };

  const getStatus = () => {
    if (status === 'created') {
      return {
        label: 'status booked',
        text: formatMessage('tariffs.statuses.created')
      };
    } else if (status === 'uploaded') {
      return {
        label: 'status draft',
        text: formatMessage('tariffs.statuses.uploaded')
      };
    } else if (status === 'confirmed') {
      return {
        label: 'status confirmed',
        text: formatMessage('tariffs.statuses.confirmed')
      };
    } else {
      return {
        label: 'status',
        text: formatMessage('tariffs.statuses.unknown')
      };
    }
  };

  const type = formatMessage(['tariffs', tariff.type, 'name'].join('.'));
  const childTariff = children && children[0];

  const handleActive = async e => {
    try {
      setPending(true);
      setActiveTariff(!activeTariff);
      const func = activeTariff ? deactivateTariff : activateTariff;
      await func({ tariff });
      setPending(false);
    } catch (error) {
      setActiveTariff(!activeTariff);
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <Container>
        <ItemName>
          <S.CaptionName
            className={getStatus().label}
            withPromo={promo?.active}
          >
            <LocalizedLabel content={name} />
          </S.CaptionName>
        </ItemName>

        <ItemType>
          {type}
        </ItemType>

        <ItemSource>
          {source
            ? source.name
            : formatMessage('tariff.not_specified')}
        </ItemSource>

        <ItemPeriod>
          <TariffAvailablePeriod period={available_period} />
        </ItemPeriod>

        <SwitchContainer>
          <Switch active={activeTariff} onClick={handleActive} disabled={pending} />
        </SwitchContainer>

        <Tooltip
          placement='bottom'
          overlay={overlayText}
        >
          <Copy onClick={handleCopy}>
            <CopyIcon />
          </Copy>
        </Tooltip>
      </Container>

      {childTariff && (
        <TariffChildHeader
          tariff={childTariff}
          currentLanguage={currentLanguage}
          level={1}
          activateTariff={activateTariff}
          deactivateTariff={deactivateTariff}
        />
      )}
    </Wrapper>
  );
}

TariffHeader.propTypes = {
  tariff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activateTariff: PropTypes.func,
  deactivateTariff: PropTypes.func
};

export default withRouter(TariffHeader);
