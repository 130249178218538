import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Source from './Source';

@observer
class Form extends Component {
  formSubmitHandler = (e) => {
    e.preventDefault();

    const { onSubmitHandler } = this.props;
    onSubmitHandler(e);
  }

  formResetHandler = (e) => {
    e.preventDefault();

    const { onCloseHandler } = this.props;
    onCloseHandler(e);
  }

  handleKeyDown = (e) => {
    // Submit form by Enter
    if (e.key === 'Enter') this.formSubmitHandler(e);

    // Reset form by Escape
    if (e.key === 'Escape') this.formResetHandler(e);
  }

  render() {
    const { form } = this.props;

    const sources = Array.from(form.$('sources').fields.values());

    const listSources = sources
      .map(source => <Source key={source.id} field={source} />);

    return (
      <form
        className='form quotas-form'
        onSubmit={this.formSubmitHandler}
        onKeyDown={this.handleKeyDown}
      >
        <div className='quotas-form__header'>
          <b><FormattedMessage id='dashboard.quota.self' /></b>
        </div>

        <div className='quotas-form__body'>
          {listSources}
        </div>

        <div className='quotas-form__footer'>
          <div className='buttons'>
            <button className='button gray small' onClick={this.formResetHandler}>
              <FormattedMessage id='shared.cancel' />
            </button>
            <button className='green button small' onClick={this.formSubmitHandler}>
              <FormattedMessage id='shared.confirm' />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired
};

export default Form;
