import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Checkbox from 'Shared/form/Checkbox';

@observer
class IsManual extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired
  }

  render() {
    const { field } = this.props;

    const style = {
      marginTop: '28px',
      marginLeft: '30px'
    };

    return (
      <Checkbox field={field} style={style} />
    );
  }
}

export default IsManual;
