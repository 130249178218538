import { types } from 'mobx-state-tree';

export const Penalty = types.model('Penalty', {
  amount: types.number,
  unit: types.maybe(types.enumeration(['rub', 'percent', 'first_day']))
});

export const Deadline = types.model('Deadline', {
  amount: types.number,
  unit: types.maybe(types.enumeration(['day', 'month']))
});

export const PolicyRule = types.model('PolicyRule', {
  penalty: Penalty,
  deadline: Deadline
});

export const TariffCancellationPolicy = types.model('TariffCancellationPolicy', {
  has_full_refund: types.boolean,
  has_non_refund: types.boolean,
  policy_rules: types.optional(types.array(PolicyRule), [])
});
