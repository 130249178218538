import { types } from 'mobx-state-tree';
import instance from 'connection/instance';

export const Specialization = types.model('Specialization', {
  id: types.identifier(types.string),
  code: types.string,
  name: types.string,
  parent: types.maybe(types.reference(types.late(() => Specialization))),
  children: types.optional(types.array(types.late(() => Specialization)), []),
  isRoot: types.boolean,
  isNode: types.boolean,
  isLeaf: types.boolean
}, {
  fetchChildren() {
    this.setState('pending');

    return instance.get('/api/hotel/diseases', { params: { hotel: { disease_id: this.id } } })
      .then(response => this.extendStore(response))
      .then(response => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  setState(state, response = undefined) {
    this.state = state;
    return response;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  extendStore(response) {
    const { specializations } = response.data;

    this.children = specializations;
    return this;
  }
});
