import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Tariffs from './Tariffs';

@withRouter
@observer
class Label extends Component {
  render() {
    const { name, tariffs } = this.props.room_type;

    return (
      <div className='room-type'>
        <div className='room-type__header'>
          <div className='content'>
            <b>{name}</b>
          </div>
        </div>

        <Tariffs tariffs={tariffs} />
      </div>
    );
  }
}

Label.propTypes = {
  room_type: PropTypes.object.isRequired
};

export default Label;
