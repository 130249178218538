import { types } from 'mobx-state-tree';

import { MenuStore } from './MenuStore';
import { UserStore } from './UserStore';
import { NotifyStore } from './NotifyStore';

const AppStore = types.model('AppStore', {
  userStore: types.optional(UserStore, { setting: { locale: 'ru' } }),
  menuStore: types.optional(MenuStore, {}),
  notifyStore: types.optional(NotifyStore, {})
});

export default AppStore;
