import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import CloseIcon from 'react-icons/lib/md/close';

class Notification extends Component {
  constructor(props) {
    super(props);

    this.removeHandler = this.removeHandler.bind(this);
  }

  removeHandler(e) {
    e.preventDefault();

    const { notification } = this.props;
    notification.remove();
  }

  render() {
    const { type, header, messages } = this.props.notification;

    const description = messages.map((msg, num) => {
      return <p key={num}>{msg}</p>;
    });

    const className = classNames('notification', type);

    return (
      <div className={className}>
        <div className='content'>
          <div className='header'>
            <div className='action'>
              <a
                href=''
                onClick={this.removeHandler}
              ><CloseIcon />
              </a>
            </div>
            {header}
          </div>
          <div className='description'>
            <Scrollbars
              autoHeight
              autoHeightMin={40}
              autoHeightMax={180}
            >
              {description}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired
};

export default Notification;
