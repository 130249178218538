
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: inline-block;
  width: 37px; /* ширина переключателя */
  height: 17px; /* высота переключателя */
  border-radius: 12px; /* радиус скругления */
  background: #EAE9E4; /* цвет фона */
  z-index: 0;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  transition-duration: 300ms;

  &:after {
    content: "";
    height: 22px; /* высота кнопки */
    width: 22px; /* ширина кнопки */
    border-radius: 18px; /* радиус кнопки */
    background: #D2D1CC; /* цвет кнопки */
    top: -2px; /* положение кнопки по вертикали относительно основы */
    left: -2px; /* положение кнопки по горизонтали относительно основы */
    transition-duration: 300ms; /* анимация */
    position: absolute;
    z-index: 1;
  }

  ${({ active }) => active && css`
    background: rgba(82,171,24,0.2);

    &:after {
      left: 20px;
      background: #52AB18;
    }
  `}

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

function Switch({ className, active, onClick, disabled }) {
  const handleClick = e => {
    e.stopPropagation();

    if (disabled) return;

    onClick && onClick(e);
  };

  return (
    <Wrapper className={className} active={!!active} disabled={disabled} onClick={handleClick} />
  );
}

Switch.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default styled(Switch)``;
