import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { FormLanguageContext } from "Context/FormLanguageContext";
import { formatMessage } from 'Utils/formatMessage';
import {
    RussiaIconInput,
    EnglandIconInput,
} from 'Styles/styled';
import store from 'store';

@observer
class AltInput extends Component {
    static contextType = FormLanguageContext;
    state = {
        language: store.get('language')
    }

    handleBlur = (e) => {
        this.props.onBlur(e.target.value, this.state.language);
    }

    handleLanguageChange = language => {
        if (this.state.language === language) return;

        this.setState({ language });
    };

    render() {
        const {
            alt_translations,
            onBlur,
        } = this.props;
        const {language} = this.state

        return (
            <div className="alt_input" key={language}>
                <div className="alt_input_languages">
                    {(language === 'ru'
                        ? (
                            <RussiaIconInput
                                onClick={()=>this.handleLanguageChange('en')}
                            />
                        )
                        : (
                            <EnglandIconInput
                                onClick={() => this.handleLanguageChange('ru')}
                            />
                        ))}
                </div>
                <input
                    className="input"
                    onBlur={this.handleBlur}
                    placeholder={formatMessage("shared.alt_string")}
                    defaultValue={alt_translations.toJSON()[language]}
                />
            </div>
        )
    }
}

AltInput.propTypes = {
    alt_translations: PropTypes.object.isRequired,
    onBlur: PropTypes.func.isRequired,
};

export default AltInput
