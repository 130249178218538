import moment from 'Utils/moment';

function datesRange(date1, date2, format = 'D MMM') {
  const from = moment(date1);
  const to = moment(date2);

  const delimiter = ' – ';

  if (from.isSame(to)) {
    return from.format(format);
  }

  if (from.month() === to.month()) {
    return [from.format('D'), to.format(format)].join(delimiter);
  }

  if (from.year() === to.year()) {
    const formatTo = format;
    const formatFrom = format.replace(/Y/g, '').trim();

    return [from.format(formatFrom), to.format(formatTo)].join(delimiter);
  }

  return [from.format(format), to.format(format)].join(delimiter);
}

export default datesRange;
