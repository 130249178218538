import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Phone from './Phone';

@observer
class Phones extends Component {
  render() {
    const listItems = this.props.phones.map((item) => {
      return <Phone key={item.value} phone={item} />;
    });

    return (
      <div className='form__section'>
        <div className='header'>
          <FormattedMessage id='hotel.phone' />
        </div>
        <div className='content'>
          <div className='options'>
            {listItems}
          </div>
        </div>
      </div>
    );
  }
}

Phones.propTypes = {
  phones: PropTypes.array.isRequired
};

export default Phones;
