import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import DeleteIcon from 'react-icons/lib/md/delete';
import { FormattedMessage } from 'react-intl';

@withRouter
class Actions extends Component {
  destroyHandler = (e) => {
    e.preventDefault();

    this.props.building.destroy()
      .then(_ => this.navigateTo('/buildings'));
  }

  navigateTo = (path) => {
    const { building, history } = this.props;
    history.push(path);
  }

  render() {
    const { id } = this.props.building;

    return (
      <div className='actions'>
        <div className='group'>
          <div className='float-left'>
            <Link
              to={`/buildings/${id}/edit`}
              className='green button'
            >
              <FormattedMessage id='shared.change' />
            </Link>
            <Link
              to='/buildings'
              className='gray button'
            ><FormattedMessage id='shared.cancel' />
            </Link>
          </div>
          <div className='float-right'>
            <button
              className='button gray'
              onClick={this.destroyHandler}
            >
              <i className='icon'>
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Actions.propTypes = {
  building: PropTypes.object.isRequired
};

export default Actions;
