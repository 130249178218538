import React from 'react';
import styled from 'styled-components';

import TopBarSearchBox from './TopBarSearchBox';
import TopBarActionBox from './TopBarActionBox';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 0 2.5rem;
`;

function TopBarContent() {
  return (
    <Wrapper>
      <TopBarSearchBox />

      <TopBarActionBox />
    </Wrapper>
  );
}

export default TopBarContent;
