import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={838}
    height={430}
    viewBox='0 0 838 430'
    backgroundColor='#f3f3f3'
    foregroundColor='#e6e6e6'
    {...props}
  >
    <circle cx='16' cy='16' r='16' />
    <circle cx='822' cy='16' r='16' />

    <rect x='150' y='10' rx='3' ry='3' width='100' height='17' />
    <rect x='588' y='10' rx='3' ry='3' width='100' height='17' />

    <rect x='15' y='60' rx='3' ry='3' width='370' height='6' />
    <rect x='453' y='60' rx='3' ry='3' width='370' height='6' />

    <rect x='0' y='90' rx='3' ry='3' width='400' height='340' />
    <rect x='438' y='90' rx='3' ry='3' width='400' height='340' />
  </ContentLoader>
);

export default Loader;
