import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

@withRouter
@inject('dashboardStore')
@observer
class Event extends Component {
  componentDidMount() {
    this.stateHandler = reaction(
      () => this.isActive,
      (isActive) => this.setDashboardPosition(isActive),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.stateHandler();
  }

  setDashboardPosition = (isActive) => {
    if (isActive != true) return;

    const element = this.eventEl.getElementsByTagName('a')[0];
    if (!element) return;

    const { event, dashboardStore, location, history } = this.props;
    const bounding = element.getBoundingClientRect();

    const { state } = location;
    const delay = state ? state.delay : 1000;

    history.push();
    if (delay === 0) return;

    setTimeout(() => {
      dashboardStore.calcDashboardPosition(event, bounding);
    }, delay);
  }

  navigateHandler = (e) => {
    e.preventDefault();

    const { history } = this.props;
    const state = { delay: 0 };

    history.push(this.linkTo, state);
  }

  @computed get style() {
    const { check_in, check_out } = this.props.event;

    const checkInMoment = moment(check_in, 'YYYY-MM-DD hh:mm:sss+-HH:mm');
    const checkOutMoment = moment(check_out, 'YYYY-MM-DD hh:mm:sss+-HH:mm');
    const diffInDays = checkOutMoment.diff(checkInMoment);

    const style = {
      position: 'absolute',
      left: this.props.dashboardStore.calcEventOffset(check_in),
      width: this.props.dashboardStore.convertMsToPx(diffInDays)
    };

    return style;
  }

  @computed get linkTo() {
    const { reservation_id } = this.props.event;
    return `/dashboard/${reservation_id}`;
  }

  @computed get isActive() {
    const { location, event } = this.props;
    return this.linkTo === location.pathname;
  }

  @computed get klassName() {
    const { state } = this.props.event;

    return classNames('event', {
      cian: state == 'booked'
    });
  }

  render() {
    const { name } = this.props.event;

    return (
      <div ref={(node) => { this.eventEl = node; }}>
        <NavLink
          className={this.klassName}
          style={this.style}
          to={this.linkTo}
          onClick={this.navigateHandler}
        >
          <span>{name}</span>
        </NavLink>
      </div>
    );
  }
}

Event.propTypes = {
  event: PropTypes.object.isRequired
};

export default Event;
