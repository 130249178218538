import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Category from './Category';

@observer
class Categories extends Component {
  render() {
    const { servicesStore } = this.props;

    const listItems = servicesStore.categories.map((item) => {
      return <Category key={item.id} category={item} />;
    });

    return (
      <div className='groups'>
        {listItems}
      </div>
    );
  }
}

Categories.propTypes = {
  servicesStore: PropTypes.object.isRequired
};

export default Categories;
