import * as React from 'react';
import styled from 'styled-components';

import { Alert } from 'evergreen-ui';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined
}>

function EmptyAlert({ className, children }: Props) {
  return (
    <Wrapper className={className}>
      <Alert intent="danger"
        title="Услуги не найдены"
      >
        Для сопоставления тарифов необходимо выбрать прайс-лист из списка.
      </Alert>
    </Wrapper>
  );
}

export default EmptyAlert;
