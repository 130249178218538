import { types, destroy } from 'mobx-state-tree';

const ImageVersion = types.model('ImageVersion', {
  url: types.string
});

const Image = types.model('RoomTypeImage', {
  id: types.identifier(types.string),
  url: types.string,
  thumb: ImageVersion
}, {
  destroy() {
    destroy(this);
  }
});

export default Image;
