import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import StayPeriod from './StayPeriod';
import StayPeriodTips from './StayPeriodTips';
import CalculationRuleTips from './CalculationRuleTips';
import CalculationRule from './CalculationRule';

@observer
class PriceCalculationRule extends Component {
  render() {
    const { form } = this.props;
    return (
      <form className="form">
        <div className="form__body">
          {form.tariffType !== "base" &&
            <div className="row">
              <div className="col col-5">
                <div className="form__fields-group">
                  <StayPeriod
                    stay_period={form.$("stay_period")}
                    billing_hour={form.getBillingHour}
                    type={form.values().type}
                  />
                </div>
              </div>

              <div className="col col-6 offset-1">
                <div className="form__fields-group">
                  <StayPeriodTips
                    stay_period={form.$("stay_period")}
                    billing_hour={form.getBillingHour}
                  />
                </div>
              </div>
            </div>
          }
          {form.tariffType === "base" && (
            <div className="row">
              <div className="col col-5">
                <div className="form__fields-group">
                  <CalculationRule
                    price_calculation_rule={form.$("price_calculation_rule")}
                  />
                </div>
              </div>

              <div className="col col-6 offset-1">
                <div className="form__fields-group">
                  <CalculationRuleTips />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

PriceCalculationRule.propTypes = {
  form: PropTypes.object.isRequired
};

export default PriceCalculationRule;
