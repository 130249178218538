import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FormattedMessage } from 'react-intl';

@observer
class PriceCalculationRule extends Component {
  render() {
    const { labels, price_calculation_rule } = this.props;

    return (
      <div className='options'>
        <div className='options__item'>
          <span className='key'>
            {labels.price_calculation_rule}
          </span>

          <span className='value'>
            <FormattedMessage id={`tariffs.price_calculation_rules.${price_calculation_rule}`} />
          </span>
        </div>
      </div>
    );
  }
}

PriceCalculationRule.propTypes = {
  price_calculation_rule: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired
};

export default PriceCalculationRule;
