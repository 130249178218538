import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import PrivateRoute from 'Shared/components/PrivateRoute';

import Menu from 'Shared/components/Menu';
import Notifications from './Notifications';

import Login from './Login';
import NotFound from './NotFound';
import Dashboard from './Dashboard';
import Rates from './Rates';
import Buildings from './Buildings';
import RoomTypes from './RoomTypes';
import Rooms from './Rooms';
import Services from './Services/List';
import Tariffs from './Tariffs';
// import Invoices from './Invoices';
import Hotel from './Hotel';
import Photo from './Photo';
import Orders from './Orders';
import PriceList from './PriceList';
import Sogaz from './Sogaz';
import Settings from './Settings';

// TODO HIDE Invoice
@inject('store')
@observer
class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store: { userStore: { authenticated } } } = this.props;

    return (
      <Router>
        <div className='layout'>
          {authenticated && <Menu />}

          <Switch>
            <Redirect
              exact
              from='/'
              to='/dashboard'
            />

            <PrivateRoute
              path='/orders'
              component={Orders}
            />

            <PrivateRoute
              path='/hotel'
              component={Hotel}
            />

            <PrivateRoute
              path='/photo'
              component={Photo}
            />

            <PrivateRoute
              path='/dashboard'
              component={Dashboard}
            />

            <PrivateRoute
              exact
              path='/rates'
              component={Rates}
            />

            <Redirect
              exact
              from='/wizard'
              to='/dashboard'
            />

            <PrivateRoute
              path='/buildings'
              component={Buildings}
            />

            <PrivateRoute
              path='/room_types'
              component={RoomTypes}
            />

            <PrivateRoute
              path='/rooms'
              component={Rooms}
            />

            <PrivateRoute
              path='/services'
              component={Services}
            />

            <PrivateRoute
              path='/tariffs'
              component={Tariffs}
            />

            {/* <PrivateRoute */}
            {/*  exact */}
            {/*  path='/invoices' */}
            {/*  component={Invoices} */}
            {/* /> */}

            <PrivateRoute
                path='/settings'
                component={Settings}
            />

            <PrivateRoute
              path='/price_lists'
              component={PriceList}
            />

            <PrivateRoute
              path='/sogaz'
              component={Sogaz}
            />

            <Route
              exact
              path='/login'
              component={Login}
            />

            <Route
              component={NotFound}
            />
          </Switch>

          {authenticated && <Notifications />}
        </div>
      </Router>
    );
  }
}

export default App;
