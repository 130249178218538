import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import LocationTypesTree from './LocationTypesTree';

@observer
class LocationTypes extends Component {
  render() {
    const { hotel } = this.props;
    const locationTypes = this.props.hotel.location_types.toJSON();

    return (
      <div className='groups__item'>
        <div className='groups__item-header'>
          <FormattedMessage id='hotel.location_types' />
        </div>

        <div className='groups__item-content'>
          <div className='segment'>
            {locationTypes.length > 0
              ? (<LocationTypesTree hotel={hotel} />)
              : (
                <p className='muted'>
                  <FormattedMessage id='hotel.location_types_didnt_choosed' />
                </p>
                )}
          </div>
        </div>
      </div>
    );
  }
}

LocationTypes.propTypes = {
  hotel: PropTypes.object.isRequired
};

export default LocationTypes;
