import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import Navigation from '../Navigation';
import Form from '../Form';

import FormState, { fields } from '../FormState';

@observer
class PageBody extends Component {
  @observable form = new FormState(fields)

  render() {
    return (
      <div className='infoblock'>
        <Navigation />

        <div className='infoblock__body'>
          <div className='content'>
            <Form form={this.form} />
          </div>
        </div>
      </div>
    );
  }
}

export default PageBody;
