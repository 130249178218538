import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

import DuplicateIcon from 'react-icons/lib/md/control-point-duplicate';
import { FormattedMessage } from 'react-intl';

@withRouter
@observer
class Actions extends Component {
  duplicateHandler = (e) => {
    e.preventDefault();

    const { history, room } = this.props;

    history.push('/rooms/new', {
      values: room.toClone()
    });
  }

  render() {
    const { id } = this.props.room;

    return (
      <div className='actions'>
        <div className='group'>
          <div className='float-left'>
            <Link
              to={`/rooms/${id}/edit`}
              className='green button'
            >
              <FormattedMessage id='shared.change' />
            </Link>
            <Link
              to='/rooms'
              className='gray button'
            >
              <FormattedMessage id='shared.cancel' />
            </Link>
          </div>

          <div className='float-right'>
            <button className='button gray' onClick={this.duplicateHandler}>
              <i className='icon'>
                <DuplicateIcon />
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Actions.propTypes = {
  room: PropTypes.object.isRequired
};

export default Actions;
