import styled from 'styled-components';

export const GroupsItemHeader = styled.div`
  font-size: 20px;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div``;

export const DeleteImagesBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #1F6FCC;
`;
