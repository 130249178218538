import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { observable } from 'mobx';

import Modal from 'Shared/ui/Modal';
import Switch from 'Shared/ui/Switch';
import Spinner from 'Shared/ui/Spinner';
import { QuotasStore as LocalQuotasStore } from 'Stores/QuotasStore/List';
import { formatMessage } from 'Utils/formatMessage';
import Form from './components/Form';
import RoomsForm from './components/RoomsForm';

import FormState, { fields } from './components/FormState';
import moment from 'Utils/moment';
import Calendar from '../../containers/EditQuotaModal/components/Calendar/Calendar';
import Loader from '../../containers/EditQuotaModal/components/ContentLoader';
import { CloseIcon } from 'Components/ui/Icons';

const Title = styled.div`
  font-size: 30px;
  line-height: 25px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 20px;
  line-height: 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

const Periods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DatesPeriods = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const Link = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #1f6fcc;
  cursor: pointer;
`;

const EditSectionDisclaimer = styled.div`
  font-size: 14px;
  line-height: 19px;
  background: #f6f9fd;
  border-radius: 8px;
  padding: 16px;
  width: 308px;
`;

const DateGroup = styled.div`
  border-radius: 48px;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  line-height: 19px;
  background: #eae9e4;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  width: auto;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;

  button {
    margin-right: 13px;
  }
  ${({ unresponsive }) => unresponsive && 'pointer-events: none'}
`;

const SpinnerWrapper = styled(Spinner)`
  margin-left: 5px;
`;

const Error = styled.div`
  display: inline-block;
  vertical-align: middle;
  color: #ff7f7f;
  max-width: 400px;
`;

const QUOTA_EDIT_TYPE = 'quota';
const AVAILABILITY_EDIT_TYPE = 'availability';
const LOAD_UP_MONTHS = 5;
const NOW = Date.now();
const CHECK_IN = moment(NOW).subtract(LOAD_UP_MONTHS, 'month').startOf('month');
const CHECK_OUT = moment(NOW).add(LOAD_UP_MONTHS, 'month').endOf('month');

@inject('dashboardStore', 'quotasStore', 'searchState')
@observer
class GroupsQuotasModal extends Component {
  constructor(props) {
    super(props);

    this.localQuotasStore = LocalQuotasStore.create();

    this.switchItems = [
      {
        label: formatMessage('dashboard.quota.edit.availability.self'),
        value: AVAILABILITY_EDIT_TYPE
      },
      {
        label: formatMessage('dashboard.quota.edit.quota.self'),
        value: QUOTA_EDIT_TYPE
      }
    ];

    this.state = {
      addPeriod: false,
      rangeStartDate: null,
      rangeEndDate: null,
      selectedSourceId: null,
      selectedRoomTypeId: null,
      dates: [],
      sources: null,
      roomTypes: null,
      error: null,
      calendarKey: Math.random(),
      editType: QUOTA_EDIT_TYPE,
      allSources: false
    };
  }

  @observable form = new FormState(fields);
  @observable isPending = false;

  async componentDidMount() {
    try {
      const [sourcesData, roomsTypesData] = await Promise.all([
        this.localQuotasStore.getSources(),
        this.localQuotasStore.getRoomTypes()
      ]);

      await this.fetchLocalStoreQuotas(
        CHECK_IN.format('Y-MM-DD'),
        CHECK_OUT.format('Y-MM-DD')
      );

      const sources = sourcesData.sources.map((source) => ({
        id: source.id,
        name: source.name,
        selected: false
      }));

      const rooms = roomsTypesData.rooms.map((room) => ({
        id: room.id,
        name: room.name,
        selected: false,
        quantity: null
      }));

      this.setState({
        selectedSourceId: sources[0].id,
        sources,
        roomTypes: rooms,
        selectedRoomTypeId: rooms[0].id
      });

      this.form.update({
        sources,
        room_types: rooms,
        action: this.state.editType
      });
    } catch (error) {
      console.error('Error in componentDidMount:', error);
    }
  }

  componentDidUpdate() {
    if (this.isPending) {
      return false;
    }

    const { dates, editType } = this.state;
    const { searchState } = this.props;

    if (dates.length > 0) {
      try {
        const { check_in, check_out } = searchState.values();
        this.form.update({
          check_in,
          check_out,
          periods: dates,
          action: editType
        });
      } catch (error) {
        console.error('Error in componentDidUpdate:', error);
      }
    }
  }

  checkTotalLimit() {
    const {
      capacity: { total }
    } = this.props.dashboardStore.room_types.find(
      (item) => item.id === this.state.selectedRoomTypeId
    );

    const { sources: formSources } = this.form.values();
    let error;
    formSources.forEach(({ quantity, name }) => {
      if (quantity > total) {
        error = formatMessage('dashboard.quota.edit.quota.limit_error', {
          sourceName: name,
          limit: total
        });
      }
    });
    return error;
  }

  async fetchLocalStoreQuotas(check_in, check_out) {
    try {
      return await this.localQuotasStore.fetch({ check_in, check_out });
    } catch (error) {
      console.error('Error in fetchLocalStoreQuotas:', error);
    }
  }

  reRenderCalendar() {
    this.setState((prevState) => ({
      calendarKey: prevState.calendarKey + 1
    }));
  }

  handleSourceSelectChange = (val) => {
    if (this.state.selectedSourceId === val) return;

    this.setState({ selectedSourceId: val }, this.reRenderCalendar);
  };

  handleSwitchChange = (selectedItem) => {
    this.setState({ editType: selectedItem.value }, this.reRenderCalendar);
  };

  handleChangeMonth = async (activeDate) => {
    try {
      const startOfMonth = activeDate.clone().startOf('month');
      const endOfMonth = activeDate.clone().endOf('month');

      const checkInStartOfMonth = CHECK_IN.clone()
        .startOf('month')
        .format('Y-MM-DD');
      const checkOutEndOfMonth = CHECK_OUT.clone()
        .endOf('month')
        .format('Y-MM-DD');

      if (
        startOfMonth.isSame(checkInStartOfMonth) &&
        activeDate.isBefore(CHECK_IN)
      ) {
        const startDate = CHECK_IN.clone()
          .subtract(LOAD_UP_MONTHS, 'month')
          .startOf('month')
          .format('Y-MM-DD');
        await this.fetchLocalStoreQuotas(startDate, CHECK_IN.format('Y-MM-DD'));
        CHECK_IN.subtract(LOAD_UP_MONTHS, 'month').startOf('month');
      } else if (
        endOfMonth.isSame(checkOutEndOfMonth) &&
        activeDate.isAfter(CHECK_OUT)
      ) {
        const endDate = CHECK_OUT.clone()
          .add(LOAD_UP_MONTHS, 'month')
          .endOf('month')
          .format('Y-MM-DD');
        await this.fetchLocalStoreQuotas(CHECK_OUT.format('Y-MM-DD'), endDate);
        CHECK_OUT.add(LOAD_UP_MONTHS, 'month').endOf('month');
      }
    } catch (error) {
      console.error('Error in handleChangeMonth:', error);
    }
  };

  handleAddPeriod = () => {
    this.setState((prevState) => ({
      addPeriod: !prevState.addPeriod,
      error: null
    }));
  };

  onDatesChange = ({ startDate, endDate }) => {
    const { rangeEndDate, dates } = this.state;
    if (rangeEndDate) {
      this.setState({
        rangeStartDate: startDate,
        rangeEndDate: null
      });
    } else {
      this.setState({
        rangeStartDate: startDate,
        rangeEndDate: endDate
      });
    }

    if (startDate && endDate) {
      this.setState({
        error: null,
        addPeriod: false,
        dates: [
          ...dates,
          {
            from_date: startDate.format('Y-MM-DD'),
            to_date: endDate.format('Y-MM-DD')
          }
        ],
        rangeStartDate: null,
        rangeEndDate: null
      });
      this.reRenderCalendar();
    }
  };

  closeModal = () => {
    const { sources, roomTypes, editType } = this.state;
    const { searchState } = this.props;
    const { check_in, check_out } = searchState.values();

    const sourcesData = sources.map((source) => ({
      id: source.id,
      name: source.name,
      selected: false
    }));

    const roomsTypesData = roomTypes.map((room) => ({
      id: room.id,
      name: room.name,
      selected: false,
      quantity: null
    }));

    this.form.update({
      check_in,
      check_out,
      sources: sourcesData,
      room_types: roomsTypesData,
      action: QUOTA_EDIT_TYPE,
      periods: []
    });

    this.setState({
      error: null,
      addPeriod: false,
      dates: [],
      editType: QUOTA_EDIT_TYPE
    });

    this.props.closeQuotasModal(true);
  };

  onSubmitHandler = (e) => {
    e.preventDefault();

    const {
      quotasStore: { ignore_room_type_capacity }
    } = this.props;

    const roomTypesErr = this.form
      .values()
      .room_types.some(
        ({ quantity, selected }) =>
          (selected === false && quantity) || (selected === true && !quantity)
      );
    const sourcesErr = this.form
      .values()
      .sources.every(({ selected }) => selected === false);

    if (roomTypesErr) {
      this.setState({
        error: formatMessage('Проверьте правильность заполнения категорий')
      });
      return;
    } else {
      this.setState({
        error: null
      });
    }

    if (sourcesErr) {
      this.setState({
        error: formatMessage('Выберите исторчник')
      });
      return;
    } else {
      this.setState({
        error: null
      });
    }

    if (this.form.values().periods.length === 0) {
      this.setState({
        error: formatMessage('dashboard.quota.edit.range_error')
      });
      return;
    } else {
      this.setState({
        error: null
      });
    }

    const quotaLimitError = this.checkTotalLimit();
    if (quotaLimitError && !ignore_room_type_capacity) {
      this.setState({ error: quotaLimitError });
      return;
    }

    this.isPending = true;

    this.form.onSubmit(e, {
      onSuccess: this.onSuccessHandler,
      onError: this.onErrorHandler
    });
  };

  onSuccessHandler = async (form) => {
    const { searchState, quotasStore } = this.props;

    const values = form.values();
    const { editType: special } = this.state;
    const { check_in, check_out } = searchState.values();

    const filteredRoomTypes = values.room_types.filter(
      ({ selected }) => selected === true
    );

    const newObjValues = {
      ...values,
      room_types: filteredRoomTypes
    };

    const updateResult = await quotasStore.updateQuotas({ ...newObjValues });
    const fetchResult = await quotasStore.fetch({ check_in, check_out });
    this.isPending = false;
    this.closeModal();
  };

  onErrorHandler = (form) => {
    this.isPending = false;

    if (form.errors().periods) {
      this.setState({
        error: formatMessage('dashboard.quota.edit.range_error')
      });
    }
  };

  handleDeleteDate = (ind) => {
    const { dates } = this.state;
    const updatedDates = dates.filter((_, index) => index !== ind);

    this.setState({
      dates: updatedDates
    });
  };

  handleCloseModal = () => {
    const { closeQuotasModal } = this.props;
    closeQuotasModal(true);
    this.closeModal();
  };

  render() {
    const { isOpenGroupsQuotas } = this.props;
    const { isPending } = this;
    const {
      rangeStartDate,
      rangeEndDate,
      selectedSourceId,
      error,
      dates,
      addPeriod,
      calendarKey,
      editType
    } = this.state;

    return (
      <Modal isOpen={isOpenGroupsQuotas} onRequestClose={this.handleCloseModal}>
        <Title>Групповое изменение квот</Title>

        <Content>
          <Periods>
            <Label>Периоды</Label>
            {error && <Error>{error}</Error>}
            {dates.length > 0 && (
              <DatesPeriods>
                {dates.map((date, index) => {
                  const convertDate = (date) =>
                    date.split('-').reverse().join('.');

                  return (
                    <DateGroup key={index}>
                      {convertDate(date.from_date)} -{' '}
                      {convertDate(date.to_date)}
                      <IconWrapper onClick={() => this.handleDeleteDate(index)}>
                        <CloseIcon />
                      </IconWrapper>
                    </DateGroup>
                  );
                })}
              </DatesPeriods>
            )}
            {addPeriod && (
              <>
                {selectedSourceId
                  ? (
                  <Calendar
                    isGroups
                    key={calendarKey}
                    startDate={rangeStartDate}
                    endDate={rangeEndDate}
                    onDatesChange={this.onDatesChange}
                    onPrevMonthClick={this.handleChangeMonth}
                    onNextMonthClick={this.handleChangeMonth}
                    isLoading={this.localQuotasStore.isPending}
                  />
                    )
                  : (
                  <Loader />
                    )}
              </>
            )}
            <Link onClick={this.handleAddPeriod}>
              {addPeriod ? 'Закрыть календарь' : 'Добавить период'}
            </Link>
          </Periods>

          <Row>
            <Label>
              <FormattedMessage id="shared.edit" />
            </Label>
            <Switch
              theme="green"
              items={this.switchItems}
              defaultSelected={editType}
              onChange={this.handleSwitchChange}
            />
          </Row>
          <Row>
            <Form form={this.form} />
            <EditSectionDisclaimer>
              <FormattedMessage
                id={`dashboard.quota.edit.${editType}.disclaimer`}
              />
              <br />
              <strong>
                <FormattedMessage
                  id={`dashboard.quota.edit.${editType}.formula`}
                />
              </strong>
            </EditSectionDisclaimer>
          </Row>
          <Categories>
            <Label>Категории номеров</Label>
            <RoomsForm form={this.form} />
          </Categories>
        </Content>
        <ButtonsWrapper unresponsive={isPending}>
          <button className="button green" onClick={this.onSubmitHandler}>
            <FormattedMessage id="shared.confirm" />
            {isPending && <SpinnerWrapper />}
          </button>
          <button className="button gray" onClick={this.closeModal}>
            <FormattedMessage id="shared.cancel_do" />
          </button>
        </ButtonsWrapper>
      </Modal>
    );
  }
}

GroupsQuotasModal.propTypes = {
  form: PropTypes.object.isRequired
};

export default GroupsQuotasModal;
