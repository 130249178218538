import { types } from 'mobx-state-tree';
import moment from 'Utils/moment';

import { Price } from 'Stores/models/Price';

export const Rule = types.model('Rule', {
  date: types.string,
  price: Price
});

export const Period = types.model('Period', {
  price: Price,
  start_date: types.string,
  end_date: types.string
});

export const Rate = types.model('Rate', {
  type: types.string,
  age_group: types.string,
  description: types.string,
  occupation: types.string,
  price: Price,
  cash: types.optional(types.boolean, false),
  rules: types.optional(types.array(Rule), []),
  periods: types.optional(types.array(Period), []),
  count: types.number,

  totalPrice() {
    return self.periods.reduce((sum, period) => {
      let { start_date, end_date, price } = period;

      start_date = moment(start_date);
      end_date = moment(end_date);

      const count = end_date.diff(start_date, 'days');

      const amount = sum.amount + price.amount * count;
      const currency = price.currency;

      return { amount, currency };
    }, { amount: 0, currency: 'RUB' });
  }
});
