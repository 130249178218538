import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import instance from 'connection/instance';

import { Room } from './Room';
import { RoomsMeta } from './RoomsMeta';

const RoomsStore = types.model('RoomsStore', {
  rooms: types.optional(types.array(Room), []),
  meta: types.optional(RoomsMeta, {}),
  state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

  get isFetched() {
    return this.state === 'done';
  },

  get isPending() {
    return this.state === 'pending';
  },

  get isError() {
    return this.state === 'error';
  }
}, {
  fetch(params = {}) {
    this.setState('pending');

    return instance.get('/api/rooms', { params: params })
      .then((response) => this.applySnapshot(response))
      .then(() => this.setState('done'))
      .catch(error => this.errorHandler(error));
  },

  create(values) {
    this.setState('pending');

    const params = { room: values };

    return instance.post('/api/rooms', params)
      .then((response) => this.add(response))
      .then((response) => this.setState('done', response))
      .catch(error => this.errorHandler(error));
  },

  clear() {
    const data = { meta: {}, rooms: [] };
    applySnapshot(this, data);
  },

  setState(state, response = undefined) {
    this.state = state;
    return response || this;
  },

  errorHandler(error) {
    this.setState('error');
    return Promise.reject(error);
  },

  applySnapshot(response) {
    const { status, data } = response;

    if (status === 200) { applySnapshot(this, data); }

    return response;
  },

  find(id) {
    return resolveIdentifier(Room, this, id);
  },

  add(response) {
    const { room } = response.data;
    this.rooms.push(room);

    return room;
  }
});

export default RoomsStore;
